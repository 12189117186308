import React from 'react'
import { styled, Button } from '@mui/material'
import T from '../../../../../components/typography/t'
import DropZone from './dropzone'
// @ts-ignore
import TakeAPhotoIcon from '../../../../../assets/img/take-a-photo.svg?react'

const Input = styled('input')`
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
`
const TakePhotoIcon = styled(TakeAPhotoIcon)`
    width: ${({ theme }) => theme.spacing(9)};
    height: ${({ theme }) => theme.spacing(9)};
`

const MobilePhotoInput: React.FC<{
    chosePhoto: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ chosePhoto }) => {
    return (
        <DropZone>
            <TakePhotoIcon sx={{ mb: 3, fill: (theme) => theme.palette.primary.main }} />
            <T variant="h3" mb={1} i18nKey="serviceDesk.shared.photoStep.mobilePhotoInput.label" />
            <T i18nKey="serviceDesk.shared.photoStep.mobilePhotoInput.description" />
            <Button component={'label'} sx={{ mt: 4 }}>
                <T i18nKey="serviceDesk.shared.photoStep.mobilePhotoInput.uploadPhotoButton" />
                <Input
                    type="file"
                    accept="image/*"
                    onChange={chosePhoto}
                    data-testid="photo-input"
                />
            </Button>
            <Button component={'label'} sx={{ mt: 2 }} variant="contained">
                <T i18nKey="serviceDesk.shared.photoStep.mobilePhotoInput.takePhotoButton" />
                <Input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    onChange={chosePhoto}
                    data-testid="photo-input"
                />
            </Button>
        </DropZone>
    )
}

export default MobilePhotoInput
