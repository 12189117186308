import React from 'react'
import { MultiStepFormError } from '../context-types'

const useMultiStepError = () => {
    const [errors, setErrors] = React.useState<MultiStepFormError[]>([])
    const pushError = (error: MultiStepFormError) => {
        setErrors((errors) => [...errors, error])
    }
    const popError = () => {
        setErrors((errors) => errors.slice(0, errors.length - 1))
    }

    return { errors, pushError, popError }
}
export default useMultiStepError
