export default [
    {
        id: 67698,
        url: 'https://inspector.brakeable.com/ufixit/67698',
        title: 'Wie entferne ich Pilling an meinem Vaude Pullover?',
        summary: 'Als sog. Pilling bezeichnet man kleine...',
        image: 'https://guide-images.cdn.ifixit.com/igi/vUIWxPQhbfVDXOn2.full',
        brandId: '3vk3eo',
    },
    {
        id: 69099,
        url: 'https://inspector.brakeable.com/ufixit/69099',
        title: 'Wie fixiere ich ein abgelöstes Tape an meiner Vaude Jacke?',
        summary: 'Hin und wieder kann es vorkommen, dass sich z....',
        image: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.full',
        brandId: '3vk3eo',
    },
    {
        id: 69101,
        url: 'https://inspector.brakeable.com/ufixit/69101',
        title: 'Wie ersetze ich einen Kordelstopper am Gummizug meiner Vaude Jacke?',
        summary: 'Hin und wieder kann es vorkommen, dass sich ein...',
        image: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.full',
        brandId: '3vk3eo',
    },
    {
        id: 69102,
        url: 'https://inspector.brakeable.com/ufixit/69102',
        title: 'Wie ersetze ich einen defekten Reißverschluss-Schieber an meiner Vaude Bekleidung?',
        summary: 'VAUDE verwendet Qualitäts-Reißverschlüsse von...',
        image: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.full',
        brandId: '3vk3eo',
    },
    {
        id: 69105,
        url: 'https://inspector.brakeable.com/ufixit/69105',
        title: 'Wie schmiere ich einen schwergängigen Reißverschluss an meiner Vaude Bekleidung?',
        summary: 'Oft kommt es vor, dass ein Reißverschluss nicht...',
        image: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.full',
        brandId: '3vk3eo',
    },
    {
        id: 69115,
        url: 'https://inspector.brakeable.com/ufixit/69115',
        title: 'Wie repariere ich einen defekten Frontreißverschluss an meiner Vaude Jacke?',
        summary: 'Sollte die Spirale eines Reißverschlusses...',
        image: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.full',
        brandId: '3vk3eo',
    },
    {
        id: 69137,
        url: 'https://inspector.brakeable.com/ufixit/69137',
        title: 'Wie repariere ich einen Zwei-Wege-Reißverschluss meiner Vaude Jacke bei einer falsch verzahnten Spirale?',
        summary: 'Ein Zwei-Wege-Reißverschluss (d. h. hier sind...',
        image: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.full',
        brandId: '3vk3eo',
    },
    {
        id: 69138,
        url: 'https://inspector.brakeable.com/ufixit/69138',
        title: 'Wie kann ich einen losen Klett an einer Vaude Jacke wieder annähen?',
        summary: 'Sollte die Naht an deinem Klett z. B. am...',
        image: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.full',
        brandId: '3vk3eo',
    },
    {
        id: 69140,
        url: 'https://inspector.brakeable.com/ufixit/69140',
        title: 'Wann muss ich mein Vaude Kleidungsstück neu imprägnieren?',
        summary: 'Wenn das Wasser nicht mehr vom Oberstoff...',
        image: 'https://guide-images.cdn.ifixit.com/igi/WyUIZt1dlCJ1QqAO.full',
        brandId: '3vk3eo',
    },
    {
        id: 69141,
        url: 'https://inspector.brakeable.com/ufixit/69141',
        title: 'Wie wasche und trockne ich meine Vaude Daunenjacke richtig?',
        summary: 'Isolationsbekleidung mit Daunenfüllung muss...',
        image: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.full',
        brandId: '3vk3eo',
    },
    {
        id: 69144,
        url: 'https://inspector.brakeable.com/ufixit/69144',
        title: 'Wie wasche ich mein Vaude Produkt aus Wolle?',
        summary: 'Wolle ist eine Naturfaser und ähnlich dem...',
        image: 'https://guide-images.cdn.ifixit.com/igi/XMU1l5kdrDjQuBbK.full',
        brandId: '3vk3eo',
    },
    {
        id: 69150,
        url: 'https://inspector.brakeable.com/ufixit/69150',
        title: 'Wie wasche und trockne ich meine Vaude Membran-Jacke/-Hose?',
        summary: 'Grundsätzlich gilt auch hier: So wenig wie...',
        image: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.full',
        brandId: '3vk3eo',
    },
    {
        id: 69155,
        url: 'https://inspector.brakeable.com/ufixit/69155',
        title: 'Wie wasche und trockne ich meine Vaude Softshell-Jacke am besten?',
        summary: 'Grundsätzlich gilt für wasserdichte,...',
        image: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.full',
        brandId: '3vk3eo',
    },
    {
        id: 69208,
        url: 'https://inspector.brakeable.com/ufixit/69208',
        title: 'Wie nähe ich einen Knopf an mein Vaude Oberteil an?',
        summary: 'Knöpfe können sich durch ständigen Gebrauch hin...',
        image: 'https://guide-images.cdn.ifixit.com/igi/cKDLpcLLvBqaXrIu.full',
        brandId: '3vk3eo',
    },
    {
        id: 74517,
        url: 'https://inspector.brakeable.com/ufixit/74517',
        title: 'Wie bringe ich einen Vaude Druckknopf an?',
        summary: 'Nicht selten passiert es, dass sich bei...',
        image: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.full',
        brandId: '3vk3eo',
    },
    {
        id: 74529,
        url: 'https://inspector.brakeable.com/ufixit/74529',
        title: 'Wie kann ich einen Riss an meiner Regenjacke/-hose flicken?',
        summary: 'Outdoorbekleidung ist auf Ski-, Wander- oder...',
        image: 'https://guide-images.cdn.ifixit.com/igi/QMKxNeuCeeMygTfq.full',
        brandId: '3vk3eo',
    },
    {
        id: 74532,
        url: 'https://inspector.brakeable.com/ufixit/74532',
        title: 'Wie flicke ich ein Vaude Bekleidungsstück?',
        summary: 'Durchgescheuerter Stoff am Knie oder Ellbogen,...',
        image: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.full',
        brandId: '3vk3eo',
    },
    {
        id: 74786,
        url: 'https://inspector.brakeable.com/ufixit/74786',
        title: 'Wie stopfe ich ein Loch an meinem Vaude Strickpullover?',
        summary: 'Löcher an einem Bekleidungsstück aus Strickware...',
        image: 'https://guide-images.cdn.ifixit.com/igi/VUcNXXVZHwEWUBNs.full',
        brandId: '3vk3eo',
    },
]
