import React from 'react'
import { RepairMultiStepFormContext } from '../../context'
import T from '../../../../../components/typography/t'
import styled from '@mui/material/styles/styled'
import HappyIllustration from './repair-thank-you.jpg'
import { Stack, Button } from '@mui/material'

const PageContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`

const Outro: React.FC<{ subtitle: any; links: { href: string; label: any }[] }> = ({
    subtitle,
    links,
}) => {
    return (
        <PageContainer>
            <img src={HappyIllustration} style={{ maxWidth: '90%', width: '400px' }} />
            <T
                i18nKey="serviceDesk.repair.outroStep.title"
                variant="h4"
                component="h1"
                mt={4}
                mb={1}
                color={'primary'}
            />
            <T i18nKey={subtitle} variant="body1" component="p" align="center" mx={2} />
            <Stack>
                {links.map((link) => (
                    <Button
                        key={link.href}
                        component="a"
                        href={link.href}
                        target="_blank"
                        sx={{ mt: 2 }}
                    >
                        <T i18nKey={link.label} />
                    </Button>
                ))}
            </Stack>
        </PageContainer>
    )
}

const OutroContext = () => {
    const { ctx } = React.useContext(RepairMultiStepFormContext)

    return (
        <Outro
            subtitle={ctx.whitelabel.config.repair.outro.lead}
            links={ctx.whitelabel.config.repair.outro.links}
        />
    )
}

export default OutroContext
