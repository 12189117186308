import React from 'react'
import { RepairMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import { findParentById } from '../../../../../shared/data/products/helpers'
import ProductSelectionStep from '../../shared/steps/product-selection'
import { ProductCategoryId } from '../../../../../shared/data/products/types'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'
import LabelledDelimiter from '../../shared/components/labelled-delimiter'
import { Button } from '@mui/material'
import T from '../../../../components/typography/t'

const CategorySelectionContext = () => {
    const childRef = React.useRef<any>()
    const { form, props, ctx } = React.useContext(RepairMultiStepFormContext)
    const selectedId = form.formValues.selectedProduct
    const productCategories = ctx.productCategoryTree
    const [selectedProductCategoryId, setSelectedProductCategoryId] = React.useState<
        ProductCategoryId | undefined | null
    >(selectedId ? findParentById(selectedId, productCategories)?.id : undefined)

    return (
        <StepFrameworkWrapper
            wide
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: !selectedId,
                prevButtonDisabled:
                    !selectedProductCategoryId ||
                    (selectedProductCategoryId
                        ? !findParentById(selectedProductCategoryId, productCategories)
                        : true),
                onValidPrevClick: () => {
                    childRef.current?.goBackInterceptor(props.prevSlide)
                },
            }}
        >
            <InspectorStepTitle
                i18nKey="serviceDesk.repair.productStep.title"
                Icon={undefined}
                mb={4}
            />
            <ProductSelectionStep
                ref={childRef}
                onSelectedProductTypeId={(id) => {
                    form.setValue('selectedProduct', id)
                    props.setSlideSkip('PinpointStep', false)
                    props.nextSlide()
                }}
                onSelectedProductCategoryId={setSelectedProductCategoryId}
                setItemReference={undefined}
                initialValue={selectedId ? findParentById(selectedId, productCategories) : null}
                productCategories={productCategories}
            />
            <LabelledDelimiter sx={{ my: 4 }}>
                <T component="span" i18nKey="serviceDesk.repair.productStep.productNotFoundLabel" />
            </LabelledDelimiter>
            <Button
                onClick={() => {
                    props.setSlideSkip('PinpointStep', true)
                    props.nextSlide()
                }}
            >
                <T i18nKey="serviceDesk.repair.productStep.productNotFoundAction" />
            </Button>
        </StepFrameworkWrapper>
    )
}

export default CategorySelectionContext
