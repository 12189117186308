// @ts-ignore
import KiteIcon from '../../../../../assets/img/poc-categories/kite.svg?react'
// @ts-ignore
import KiteBarIcon from '../../../../../assets/img/poc-categories/kite-bar.svg?react'
// @ts-ignore
import WingIcon from '../../../../../assets/img/poc-categories/wing.svg?react'
// @ts-ignore
import ApparelIcon from '../../../../../assets/img/poc-categories/apparel.svg?react'
// @ts-ignore
import BackpackIcon from '../../../../../assets/img/poc-categories/backpack.svg?react'
// @ts-ignore
import BeanieIcon from '../../../../../assets/img/poc-categories/beanie.svg?react'
// @ts-ignore
import BikiniIcon from '../../../../../assets/img/poc-categories/bikini.svg?react'
// @ts-ignore
import CampingChairIcon from '../../../../../assets/img/poc-categories/camping-chair.svg?react'
// @ts-ignore
import FlipflopIcon from '../../../../../assets/img/poc-categories/flipflop.svg?react'
// @ts-ignore
import GloveIcon from '../../../../../assets/img/poc-categories/glove.svg?react'
// @ts-ignore
import HikingPoleIcon from '../../../../../assets/img/poc-categories/hiking-pole.svg?react'
// @ts-ignore
import HikingShoeIcon from '../../../../../assets/img/poc-categories/hiking-shoe.svg?react'
// @ts-ignore
import JacketIcon from '../../../../../assets/img/poc-categories/jacket.svg?react'
// @ts-ignore
import MattressIcon from '../../../../../assets/img/poc-categories/mattress.svg?react'
// @ts-ignore
import PantsIcon from '../../../../../assets/img/poc-categories/pants.svg?react'
// @ts-ignore
import PulloverIcon from '../../../../../assets/img/poc-categories/pullover.svg?react'
// @ts-ignore
import SkiingPantsIcon from '../../../../../assets/img/poc-categories/skiing-pants.svg?react'
// @ts-ignore
import SleepingBagIcon from '../../../../../assets/img/poc-categories/sleeping-bag.svg?react'
// @ts-ignore
import SneakerIcon from '../../../../../assets/img/poc-categories/sneaker.svg?react'
// @ts-ignore
import SnowBootIcon from '../../../../../assets/img/poc-categories/snow-boot.svg?react'
// @ts-ignore
import SportsShoeIcon from '../../../../../assets/img/poc-categories/sports-shoe.svg?react'
// @ts-ignore
import SupIcon from '../../../../../assets/img/poc-categories/sup.svg?react'
// @ts-ignore
import TentIcon from '../../../../../assets/img/poc-categories/tent.svg?react'
// @ts-ignore
import TShirtIcon from '../../../../../assets/img/poc-categories/t-shirt.svg?react'
// @ts-ignore
import LeggingIcon from '../../../../../assets/img/poc-categories/legging.svg?react'
// @ts-ignore
import SweaterIcon from '../../../../../assets/img/poc-categories/sweater.svg?react'
// @ts-ignore
import LongsleeveIcon from '../../../../../assets/img/poc-categories/long-sleeve.svg?react'
// @ts-ignore
import TanktopIcon from '../../../../../assets/img/poc-categories/tanktop.svg?react'
// @ts-ignore
import TopIcon from '../../../../../assets/img/poc-categories/top.svg?react'
// @ts-ignore
import ShortsIcon from '../../../../../assets/img/poc-categories/shorts.svg?react'

const allIcons: { [index: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } = {
    KiteIcon,
    KiteBarIcon,
    ApparelIcon,
    BackpackIcon,
    BeanieIcon,
    BikiniIcon,
    CampingChairIcon,
    FlipflopIcon,
    GloveIcon,
    HikingPoleIcon,
    HikingShoeIcon,
    JacketIcon,
    MattressIcon,
    PantsIcon,
    PulloverIcon,
    SkiingPantsIcon,
    SleepingBagIcon,
    SneakerIcon,
    SnowBootIcon,
    SportsShoeIcon,
    SupIcon,
    TentIcon,
    TShirtIcon,
    WingIcon,
    LeggingIcon,
    SweaterIcon,
    LongsleeveIcon,
    TanktopIcon,
    TopIcon,
    ShortsIcon,
}

export default (icon: string) => {
    const Fallback = (props: any) => <img {...props} src={icon} />
    const Icon = allIcons[icon] ?? Fallback
    return Icon
}
