import React from 'react'
import { styled, Button, alpha, Stack, Box, useTheme } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import T from '../../../../../../components/typography/t'
import InspectorStepSubtitle from '../../../../../../components/typography/inspector-step-subtitle'
import QRCode from 'react-qr-code'
// @ts-ignore
import PhotoIcon from '../../../../../../assets/img/photo.svg?react'
// @ts-ignore
import TakeAPhotoIcon from '../../../../../../assets/img/take-a-photo.svg?react'
import routeBuilder from '../../../../../../routes'

const Input = styled('input')`
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
`

const DropZone = styled('div')<{ $dragActive?: boolean }>`
    padding: ${({ theme }) => theme.spacing(8)};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    text-align: center;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.1s ease-in-out;

    &:hover {
        background: ${({ theme }) => alpha(theme.palette.primary.main, 0.025)};
    }

    &::before {
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        border: 1px dashed ${({ theme }) => theme.palette.primary.main};
        border-radius: 8px;
        z-index: -1;
    }

    ${({ $dragActive, theme }) =>
        $dragActive &&
        `
        background: ${alpha(theme.palette.primary.main, 0.1)};
    `}
`

const PhotoUploadIcon = styled(PhotoIcon)`
    width: ${({ theme }) => theme.spacing(9)};
    height: ${({ theme }) => theme.spacing(9)};
`
const TakePhotoIcon = styled(TakeAPhotoIcon)`
    width: ${({ theme }) => theme.spacing(9)};
    height: ${({ theme }) => theme.spacing(9)};
`

const QRCodeBox = styled('div')`
    padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(5)};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.primary.main};
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(4)};
`

const fileTypes = ['.png', '.apng', '.avif', '.jpg', '.jpeg', '.webp', '.svg']

export const QrMobilePhotoUpload = ({
    mobileUploadId,
    brandId,
}: {
    mobileUploadId: string
    brandId: string
}) => {
    const theme = useTheme()
    // The "socket" instance is typed with the types you defined on the server.

    return (
        <QRCodeBox>
            <QRCode
                value={
                    routeBuilder.base() + `/mobile-photo-upload?i=${mobileUploadId}&b=${brandId}`
                }
                size={120}
                fgColor={theme.palette.primary.main}
            />
            <div>
                <T variant="h3" mb={1} i18nKey="poc.support.PhotoStep.QrCodeAlternativeTitle" />
                <InspectorStepSubtitle
                    i18nKey="poc.support.PhotoStep.QrCodeAlternative"
                    sx={{ textAlign: 'left' }}
                />
            </div>
        </QRCodeBox>
    )
}

export const PhotoDragDrop: React.FC<{
    choosePhoto: (photo?: File) => void
    showFakeFeatures: boolean
    fakeUpload: () => void
}> = ({ choosePhoto, showFakeFeatures, fakeUpload }) => {
    const onDrop = React.useCallback((acceptedFiles: File[]) => {
        choosePhoto(acceptedFiles[0])
    }, [])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const photo = e.target.files?.[0]
        choosePhoto(photo)
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': fileTypes,
        },
        useFsAccessApi: false,
    })

    return (
        <DropZone {...getRootProps()} $dragActive={isDragActive}>
            <PhotoUploadIcon sx={{ mb: 3, fill: (theme) => theme.palette.primary.main }} />
            <T variant="h3" mb={1} i18nKey="poc.support.PhotoStep.dropzone.label" />
            {isDragActive ? (
                <T i18nKey="poc.support.PhotoStep.dropzone.dropLabel" />
            ) : (
                <T i18nKey="poc.support.PhotoStep.dropzone.description" />
            )}
            <Button component="span" sx={{ mt: 4 }}>
                <T i18nKey="poc.support.PhotoStep.dropzone.button"></T>
                <input {...getInputProps()} accept="image/*" onChange={onChange} />
            </Button>
        </DropZone>
    )
}

export const MobilePhotoInput: React.FC<{
    chosePhoto: (e: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ chosePhoto }) => {
    return (
        <DropZone>
            <TakePhotoIcon sx={{ mb: 3, fill: (theme) => theme.palette.primary.main }} />
            <T variant="h3" mb={1} i18nKey="poc.support.PhotoStep.mobile.label" />
            <T i18nKey="poc.support.PhotoStep.mobile.description" />
            <Button component={'label'} sx={{ mt: 4 }}>
                <T i18nKey="poc.support.PhotoStep.mobile.uploadPhotoButton" />
                <Input
                    type="file"
                    accept="image/*"
                    onChange={chosePhoto}
                    data-testid="photo-input"
                />
            </Button>
            <Button component={'label'} sx={{ mt: 2 }} variant="contained">
                <T i18nKey="poc.support.PhotoStep.mobile.takePhotoButton" />
                <Input
                    type="file"
                    accept="image/*"
                    capture="environment"
                    onChange={chosePhoto}
                    data-testid="photo-input"
                />
            </Button>
        </DropZone>
    )
}

export default MobilePhotoInput
