import React from 'react'
import Coord from '../../../../shared/types/common/coord'
import { type Whitelabel } from '../../../../shared/data/whitelabel/types'
import { ProductCategoryNode, ProductTypeId } from '../../../../shared/data/products/types'
import { MultiStepFormContextType, getDefaultContextValues } from '../shared/context-types'

export type WhitelabelWithWarrantyConfig = Whitelabel & {
    config: Required<Pick<Whitelabel['config'], 'warranty'>> &
        Omit<Whitelabel['config'], 'warranty'>
}

export type StepCtx = {
    productCategoryTree: ProductCategoryNode
    whitelabel: WhitelabelWithWarrantyConfig
}

export type FormValues = {
    selectedProduct?: ProductTypeId
    selectedProductView: number
    description?: string
    defectPhotoUrl?: string
    receiptPhotoUrl?: string
    proofOfPurchaseForm?: Record<string, string | number | boolean>
    pinpoint?: Coord
    selectedComponent?: string
    contactFormValues: Record<string, string | number | boolean>
}

export const WarrantyMultiStepFormContext = React.createContext<
    MultiStepFormContextType<StepCtx, FormValues>
>(getDefaultContextValues())
