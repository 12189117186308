import { ProductCategoryId, ProductCategoryNode, ProductTypeId } from './types'

export const productCategories: ProductCategoryNode = {
    id: 0 as ProductCategoryId,
    name: 'serviceDesk.shared.productStep.categories.all',
    Icon: 'SneakerIcon',
    subItems: [
        {
            id: 1 as ProductCategoryId,
            name: 'serviceDesk.shared.productStep.categories.apparel',
            Icon: 'ApparelIcon',
            subItems: [
                {
                    id: 11 as ProductCategoryId,
                    name: 'serviceDesk.shared.productStep.categories.upperwear',
                    Icon: 'JacketIcon',
                    subItems: [
                        {
                            id: 112 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.tshirt',
                            internalName: 'tshirt',
                            Icon: 'TShirtIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                        {
                            id: 113 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.pullover',
                            internalName: 'pullover',
                            Icon: 'PulloverIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                        {
                            id: 111 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.jacket',
                            internalName: 'jacket',
                            Icon: 'JacketIcon',
                            applicableGuides: [
                                // Intersport
                                169514, 169517, 169519, 169520, 169521, 169522, 169525, 169526,
                                169527, 169531, 169940, 170249,
                                // Vaude
                                69099, 69101, 69102, 69105, 69115, 69137, 69138, 69141, 69150,
                                69155, 74532, 74517,
                            ],
                            applicableServices: [1, 2, 3, 4, 5, 8, 9, 10],
                            views: [
                                {
                                    label: 'serviceDesk.shared.pinpointStep.views.front',
                                    sketchUrl:
                                        'https://ucarecdn.com/46188606-f794-455e-889f-3dc712aac059/',
                                    details: [
                                        // Zippers (Front)
                                        {
                                            name: 'Front Zipper',
                                            features: [],
                                            x1: 0.475,
                                            x2: 0.515,
                                            y1: 0.275,
                                            y2: 0.96,
                                        },
                                        {
                                            name: 'Front Zipper Slider',
                                            features: [],
                                            x1: 0.48,
                                            x2: 0.51,
                                            y1: 0.38,
                                            y2: 0.43,
                                        },
                                        // Zippers (Belly)
                                        {
                                            name: 'Pocket Zipper', // Belly Left
                                            features: [],
                                            x1: 0.25,
                                            x2: 0.34,
                                            y1: 0.66,
                                            y2: 0.88,
                                        },
                                        {
                                            name: 'Pocket Zipper Slider', // Slider
                                            features: [],
                                            x1: 0.295,
                                            x2: 0.325,
                                            y1: 0.68,
                                            y2: 0.73,
                                        },
                                        {
                                            name: 'Pocket Zipper', // Belly Right
                                            features: [],
                                            x1: 0.66,
                                            x2: 0.75,
                                            y1: 0.66,
                                            y2: 0.88,
                                        },
                                        {
                                            name: 'Pocket Zipper Slider', // Slider
                                            features: [],
                                            x1: 0.67,
                                            x2: 0.7,
                                            y1: 0.68,
                                            y2: 0.73,
                                        },
                                        // Zippers (Chest)
                                        {
                                            name: 'Chest Pocket Zipper', // Chest Left
                                            features: [],
                                            x1: 0.36,
                                            x2: 0.44,
                                            y1: 0.42,
                                            y2: 0.64,
                                        },
                                        {
                                            name: 'Chest Pocket Zipper Slider', // Slider
                                            features: [],
                                            x1: 0.37,
                                            x2: 0.4,
                                            y1: 0.44,
                                            y2: 0.49,
                                        },
                                        {
                                            name: 'Chest Pocket Zipper', // Chest Right
                                            features: [],
                                            x1: 0.56,
                                            x2: 0.64,
                                            y1: 0.42,
                                            y2: 0.64,
                                        },
                                        {
                                            name: 'Chest Pocket Zipper Slider', // Slider
                                            features: [],
                                            x1: 0.6,
                                            x2: 0.63,
                                            y1: 0.44,
                                            y2: 0.49,
                                        },
                                        // Zippers (Arm)
                                        {
                                            name: 'Arm Pocket Zipper', // Arm Top
                                            features: [],
                                            x1: 0.1,
                                            x2: 0.19,
                                            y1: 0.48,
                                            y2: 0.6,
                                        },
                                        {
                                            name: 'Arm Pocket Zipper Slider', // Slider
                                            features: [],
                                            x1: 0.15,
                                            x2: 0.18,
                                            y1: 0.48,
                                            y2: 0.53,
                                        },
                                        {
                                            name: 'Arm Pocket Zipper', // Arm Bottom
                                            features: [],
                                            x1: 0.06,
                                            x2: 0.1,
                                            y1: 0.75,
                                            y2: 0.88,
                                        },
                                        {
                                            name: 'Arm Pocket Zipper Slider', // Slider
                                            features: [],
                                            x1: 0.065,
                                            x2: 0.09,
                                            y1: 0.76,
                                            y2: 0.8,
                                        },
                                        {
                                            name: 'Vent Zipper', // Arm Inner Left
                                            features: [],
                                            x1: 0.17,
                                            x2: 0.23,
                                            y1: 0.6,
                                            y2: 0.66,
                                        },
                                        {
                                            name: 'Vent Zipper Slider', // Slider
                                            features: [],
                                            x1: 0.19,
                                            x2: 0.22,
                                            y1: 0.6,
                                            y2: 0.645,
                                        },
                                        {
                                            name: 'Vent Zipper', // Arm Inner Right
                                            features: [],
                                            x1: 0.77,
                                            x2: 0.83,
                                            y1: 0.6,
                                            y2: 0.66,
                                        },
                                        {
                                            name: 'Vent Zipper Slider', // Slider
                                            features: [],
                                            x1: 0.78,
                                            x2: 0.81,
                                            y1: 0.6,
                                            y2: 0.645,
                                        },
                                        // Hems
                                        {
                                            name: 'Hem / Cuff',
                                            features: ['Cordel Stopper'],
                                            x1: 0.22,
                                            x2: 0.78,
                                            y1: 0.91,
                                            y2: 0.999,
                                        },
                                        {
                                            name: 'Hem / Cuff',
                                            features: [],
                                            x1: 0.02,
                                            x2: 0.17,
                                            y1: 0.9,
                                            y2: 0.985,
                                        },
                                        {
                                            name: 'Hem / Cuff',
                                            features: [],
                                            x1: 0.83,
                                            x2: 0.98,
                                            y1: 0.9,
                                            y2: 0.985,
                                        },
                                        // Cordel Stopper
                                        {
                                            name: 'Cordel Stopper', // Top Left
                                            features: [],
                                            x1: 0.39,
                                            x2: 0.43,
                                            y1: 0.26,
                                            y2: 0.3,
                                        },
                                        {
                                            name: 'Cordel Stopper', // Top Right
                                            features: [],
                                            x1: 0.57,
                                            x2: 0.61,
                                            y1: 0.26,
                                            y2: 0.3,
                                        },
                                        {
                                            name: 'Cordel Stopper', // Bottom Left
                                            features: [],
                                            x1: 0.23,
                                            x2: 0.27,
                                            y1: 0.95,
                                            y2: 0.99,
                                        },
                                        {
                                            name: 'Cordel Stopper', // Bottom Right
                                            features: [],
                                            x1: 0.73,
                                            x2: 0.77,
                                            y1: 0.95,
                                            y2: 0.99,
                                        },
                                        // Buttons
                                        {
                                            name: 'Button', // Arm Left
                                            features: [],
                                            x1: 0.1,
                                            x2: 0.135,
                                            y1: 0.93,
                                            y2: 0.955,
                                        },
                                        {
                                            name: 'Button', // Arm Right
                                            features: [],
                                            x1: 0.865,
                                            x2: 0.9,
                                            y1: 0.93,
                                            y2: 0.955,
                                        },
                                        {
                                            name: 'Button', // Front Top
                                            features: [],
                                            x1: 0.45,
                                            x2: 0.56,
                                            y1: 0.28,
                                            y2: 0.32,
                                        },
                                        {
                                            name: 'Button', // Front bottom
                                            features: [],
                                            x1: 0.42,
                                            x2: 0.54,
                                            y1: 0.91,
                                            y2: 0.955,
                                        },
                                        // Tapes
                                        {
                                            name: 'Tape',
                                            features: [],
                                            x1: 0.4,
                                            x2: 0.43,
                                            y1: 0.03,
                                            y2: 0.13,
                                        },
                                        {
                                            name: 'Tape',
                                            features: [],
                                            x1: 0.57,
                                            x2: 0.6,
                                            y1: 0.03,
                                            y2: 0.13,
                                        },
                                        // Inner lining
                                        {
                                            name: 'Inner lining',
                                            features: [],
                                            x1: 0.4,
                                            x2: 0.6,
                                            y1: 0.13,
                                            y2: 0.26,
                                        },
                                    ],
                                },
                                {
                                    label: 'serviceDesk.shared.pinpointStep.views.back',
                                    sketchUrl:
                                        'https://ucarecdn.com/3ddd237b-8555-4730-a94f-af1f53696f4c/',
                                    details: [
                                        // Zippers
                                        {
                                            name: 'Vent Zipper', // Left
                                            features: [],
                                            x1: 0.17,
                                            x2: 0.23,
                                            y1: 0.61,
                                            y2: 0.66,
                                        },
                                        {
                                            name: 'Vent Zipper Slider',
                                            features: [],
                                            x1: 0.19,
                                            x2: 0.22,
                                            y1: 0.61,
                                            y2: 0.65,
                                        },
                                        {
                                            name: 'Vent Zipper', // Right
                                            features: [],
                                            x1: 0.77,
                                            x2: 0.83,
                                            y1: 0.61,
                                            y2: 0.66,
                                        },
                                        {
                                            name: 'Vent Zipper Slider',
                                            features: [],
                                            x1: 0.78,
                                            x2: 0.81,
                                            y1: 0.61,
                                            y2: 0.65,
                                        },
                                        // Tapes
                                        {
                                            name: 'Tape',
                                            features: [],
                                            x1: 0.4,
                                            x2: 0.43,
                                            y1: 0.03,
                                            y2: 0.32,
                                        },
                                        {
                                            name: 'Tape',
                                            features: [],
                                            x1: 0.57,
                                            x2: 0.6,
                                            y1: 0.03,
                                            y2: 0.32,
                                        },
                                        // Cordel Stopper
                                        {
                                            name: 'Cordel Stopper', // hood
                                            features: [],
                                            x1: 0.48,
                                            x2: 0.52,
                                            y1: 0.16,
                                            y2: 0.2,
                                        },
                                        {
                                            name: 'Cordel Stopper', // Bottom Left
                                            features: [],
                                            x1: 0.23,
                                            x2: 0.27,
                                            y1: 0.95,
                                            y2: 0.99,
                                        },
                                        {
                                            name: 'Cordel Stopper', // Bottom Right
                                            features: [],
                                            x1: 0.73,
                                            x2: 0.77,
                                            y1: 0.95,
                                            y2: 0.99,
                                        },
                                        // Velcro
                                        {
                                            name: 'Velcro', // hood
                                            features: [],
                                            x1: 0.47,
                                            x2: 0.53,
                                            y1: 0.1,
                                            y2: 0.16,
                                        },
                                        // Hems
                                        {
                                            name: 'Hem / Cuff',
                                            features: ['Cordel Stopper'],
                                            x1: 0.22,
                                            x2: 0.78,
                                            y1: 0.91,
                                            y2: 0.999,
                                        },
                                        {
                                            name: 'Hem / Cuff',
                                            features: [],
                                            x1: 0.02,
                                            x2: 0.17,
                                            y1: 0.9,
                                            y2: 0.985,
                                        },
                                        {
                                            name: 'Hem / Cuff',
                                            features: [],
                                            x1: 0.83,
                                            x2: 0.98,
                                            y1: 0.9,
                                            y2: 0.985,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 114 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.bikini',
                            internalName: 'bikini',
                            Icon: 'BikiniIcon',
                            applicableGuides: [169533, 170053],
                            applicableServices: [1],
                            views: [],
                        },
                    ],
                },
                {
                    id: 12 as ProductCategoryId,
                    name: 'serviceDesk.shared.productStep.categories.pant',
                    Icon: 'PantsIcon',
                    subItems: [
                        {
                            id: 121 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.pant',
                            internalName: 'pants',
                            Icon: 'PantsIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                        {
                            id: 122 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.skiingPant',
                            internalName: 'skiingPants',
                            Icon: 'SkiingPantsIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                    ],
                },
                {
                    id: 13 as ProductCategoryId,
                    name: 'serviceDesk.shared.productStep.categories.accessories',
                    Icon: 'BeanieIcon',
                    subItems: [
                        {
                            id: 121 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.beanie',
                            internalName: 'beanie',
                            Icon: 'BeanieIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                        {
                            id: 122 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.gloves',
                            internalName: 'gloves',
                            Icon: 'GloveIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                    ],
                },
            ],
        },
        {
            id: 2 as ProductCategoryId,
            name: 'serviceDesk.shared.productStep.categories.shoes',
            Icon: 'HikingShoeIcon',
            subItems: [
                {
                    id: 201 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.hikingShoes',
                    internalName: 'hikingShoes',
                    Icon: 'HikingShoeIcon',
                    applicableGuides: [1],
                    applicableServices: [1],
                    views: [],
                },
                {
                    id: 202 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.snowBoots',
                    internalName: 'snowBoots',
                    Icon: 'SnowBootIcon',
                    applicableGuides: [1],
                    applicableServices: [1],
                    views: [],
                },
                {
                    id: 203 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.sportShoes',
                    internalName: 'sportShoes',
                    Icon: 'SportsShoeIcon',
                    applicableGuides: [1],
                    applicableServices: [1],
                    views: [],
                },
                {
                    id: 204 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.sneakers',
                    internalName: 'sneakers',
                    Icon: 'SneakerIcon',
                    applicableGuides: [1],
                    applicableServices: [1],
                    views: [],
                },
                {
                    id: 205 as ProductTypeId,
                    name: 'serviceDesk.shared.productStep.categories.flipflop',
                    internalName: 'flipflop',
                    Icon: 'FlipflopIcon',
                    applicableGuides: [1],
                    applicableServices: [1],
                    views: [],
                },
            ],
        },
        {
            id: 3 as ProductCategoryId,
            name: 'serviceDesk.shared.productStep.categories.hardware',
            Icon: 'BackpackIcon',
            subItems: [
                {
                    id: 31 as ProductCategoryId,
                    name: 'serviceDesk.shared.productStep.categories.camping',
                    Icon: 'TentIcon',
                    subItems: [
                        {
                            id: 311 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.tent',
                            internalName: 'tents',
                            Icon: 'TentIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                        {
                            id: 312 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.campingChair',
                            internalName: 'campingChairs',
                            Icon: 'CampingChairIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                        {
                            id: 313 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.mat',
                            internalName: 'mats',
                            Icon: 'MattressIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                        {
                            id: 314 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.sleepingBag',
                            internalName: 'sleepingBags',
                            Icon: 'SleepingBagIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                    ],
                },
                {
                    id: 32 as ProductCategoryId,
                    name: 'Hiking',
                    Icon: 'BackpackIcon',
                    subItems: [
                        {
                            id: 321 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.backpack',
                            internalName: 'backpack',
                            Icon: 'BackpackIcon',
                            applicableGuides: [
                                // Intersport
                                172345, 173041, 173073, 173043,
                                // Vaude
                                69111, 69120, 71477,
                            ],
                            applicableServices: [],
                            views: [
                                {
                                    label: 'serviceDesk.shared.pinpointStep.views.front',
                                    sketchUrl:
                                        'https://ucarecdn.com/c23cbe06-bc67-4250-9a8c-35605708a972/',
                                    details: [
                                        // Front
                                        {
                                            name: 'Lid',
                                            features: ['Drawstring'],
                                            x1: 0.25,
                                            x2: 0.92,
                                            y1: 0.04,
                                            y2: 0.22,
                                        },
                                        {
                                            name: 'Webbing Strap',
                                            features: [''],
                                            x1: 0.21,
                                            x2: 0.31,
                                            y1: 0.24,
                                            y2: 0.31,
                                        },
                                        {
                                            name: 'Webbing Strap',
                                            features: [''],
                                            x1: 0.39,
                                            x2: 0.5,
                                            y1: 0.24,
                                            y2: 0.29,
                                        },
                                        {
                                            name: 'Webbing Strap',
                                            features: [''],
                                            x1: 0.78,
                                            x2: 0.91,
                                            y1: 0.24,
                                            y2: 0.29,
                                        },
                                        {
                                            name: 'Hip Strap',
                                            features: [''],
                                            x1: 0.01,
                                            x2: 0.31,
                                            y1: 0.65,
                                            y2: 0.79,
                                        },
                                        {
                                            name: 'Bottle Holder',
                                            features: [''],
                                            x1: 0.22,
                                            x2: 0.44,
                                            y1: 0.42,
                                            y2: 0.7,
                                        },
                                        {
                                            name: 'Hiking Pole Attachment',
                                            features: [''],
                                            x1: 0.42,
                                            x2: 0.57,
                                            y1: 0.73,
                                            y2: 0.8,
                                        },
                                        {
                                            name: 'Ice Axe Holder',
                                            features: [''],
                                            x1: 0.72,
                                            x2: 0.92,
                                            y1: 0.73,
                                            y2: 0.8,
                                        },
                                        {
                                            name: 'Front Opening Zipper',
                                            features: [''],
                                            x1: 0.42,
                                            x2: 0.55,
                                            y1: 0.6,
                                            y2: 0.68,
                                        },
                                        {
                                            name: 'Mesh Front Case',
                                            features: [''],
                                            x1: 0.54,
                                            x2: 0.82,
                                            y1: 0.39,
                                            y2: 0.6,
                                        },
                                    ],
                                },
                                {
                                    label: 'serviceDesk.shared.pinpointStep.views.back',
                                    sketchUrl:
                                        'https://ucarecdn.com/e106e1a9-3be5-4b6a-ab34-fa29ed363af8/',
                                    details: [
                                        // Back
                                        {
                                            name: 'Side Strap',
                                            features: [''],
                                            x1: 0.1,
                                            x2: 0.37,
                                            y1: 0.22,
                                            y2: 0.29,
                                        },
                                        {
                                            name: 'Shoulder Strap', // Left
                                            features: [''],
                                            x1: 0.38,
                                            x2: 0.58,
                                            y1: 0.1,
                                            y2: 0.53,
                                        },
                                        {
                                            name: 'Load Control Strap', // Left
                                            features: [''],
                                            x1: 0.42,
                                            x2: 0.5,
                                            y1: 0.11,
                                            y2: 0.22,
                                        },
                                        {
                                            name: 'Shoulder Strap', // Right
                                            features: [''],
                                            x1: 0.7,
                                            x2: 0.88,
                                            y1: 0.1,
                                            y2: 0.53,
                                        },
                                        {
                                            name: 'Load Control Strap', // Right
                                            features: [''],
                                            x1: 0.73,
                                            x2: 0.82,
                                            y1: 0.11,
                                            y2: 0.22,
                                        },
                                        {
                                            name: 'Chest Belt',
                                            features: ['Buckle', 'Strap'],
                                            x1: 0.58,
                                            x2: 0.7,
                                            y1: 0.31,
                                            y2: 0.37,
                                        },
                                    ],
                                },
                                {
                                    label: 'serviceDesk.shared.pinpointStep.views.inside',
                                    sketchUrl:
                                        'https://ucarecdn.com/73b9fa2c-f1f8-407d-91dc-43ddd9ad94dd/',
                                    details: [
                                        {
                                            name: 'Hydration Bladder',
                                            features: ['Buckle', 'Strap'],
                                            x1: 0.3,
                                            x2: 0.6,
                                            y1: 0.2,
                                            y2: 0.55,
                                        },
                                        {
                                            name: 'Interior Pockets',
                                            features: ['Zipper', 'Zipper Slider', 'Mesh Material'],
                                            x1: 0.4,
                                            x2: 0.9,
                                            y1: 0.68,
                                            y2: 0.88,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 322 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.hikingPole',
                            internalName: 'hikingPole',
                            Icon: 'HikingPoleIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                    ],
                },
                {
                    id: 33 as ProductCategoryId,
                    name: 'serviceDesk.shared.productStep.categories.watersport',
                    Icon: 'SupIcon',
                    subItems: [
                        {
                            id: 331 as ProductTypeId,
                            name: 'serviceDesk.shared.productStep.categories.sup',
                            internalName: 'sup',
                            Icon: 'SupIcon',
                            applicableGuides: [1],
                            applicableServices: [1],
                            views: [],
                        },
                    ],
                },
            ],
        },
    ],
}

export default productCategories
