import React from 'react'
import { RepairMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import DescriptionStep from '../../shared/steps/description-step'

const DescriptionStepContext = () => {
    const { form, ctx, props } = React.useContext(RepairMultiStepFormContext)
    const [isLoading, setIsloading] = React.useState(false)

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: !form.formValues.description,
                onValidNextClick: async () => {
                    setIsloading(true)
                    try {
                        const resultServices = ctx.whitelabel.config.repair.activateCostEstimation
                            ? await ctx.getRepairServiceAssignments()
                            : undefined
                        const resultGuides = ctx.whitelabel.config.repair.includeDiyGuides
                            ? await ctx.getRepairGuideSuggestions()
                            : undefined
                        await Promise.all([resultServices, resultGuides])
                    } catch (error) {
                        setIsloading(false)
                        console.error(error)
                    } finally {
                        setIsloading(false)
                        props.nextSlide()
                    }
                },
            }}
        >
            <DescriptionStep
                onDescriptionChange={(description) => form.setValue('description', description)}
                initialDescription={form.formValues.description}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </StepFrameworkWrapper>
    )
}

export default DescriptionStepContext
