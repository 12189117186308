import { PropsWithChildren } from 'react'
import styled from 'styled-components'
import PageContent from './page-content'
import LogoWrapper from './navigation/logo-wrapper'
import Logo from './navigation/logo'
import AppSuite from '../../../shared/app-suite'
import routeBuilder from '../../routes'
import CompanyId from '../../../shared/types/company/id'
import IconButton from '@mui/material/IconButton'
import SettingsIcon from '@mui/icons-material/Settings'
import RoleProtector from '../auth/role-protector'
import { Role } from '../../../shared/auth/role'
import NavBar from './NavBar'
import WidthContainer from './width-container'
import Stack from '@mui/material/Stack'
import NavLink from './navigation/nav-link'
import theme from '../../theme'

const AppSuiteNavigation = styled.nav`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    ${theme.breakpoints.down('sm')} {
        gap: 10px;
    }
`

const AccountNavigation = styled.nav`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`

const PageWrapper: React.FC<
    PropsWithChildren<{
        centered?: boolean
        activeLogo?: AppSuite
        companyId?: CompanyId
    }>
> = ({ children, activeLogo, centered = false, companyId = undefined }) => {
    return (
        <div className="blurry-background">
            <NavBar>
                <WidthContainer>
                    <Stack direction="row" justifyContent="space-between">
                        <AppSuiteNavigation>
                            <LogoWrapper href={routeBuilder.base()}>
                                <Logo
                                    app={AppSuite.Inspector}
                                    isActive={activeLogo === AppSuite.Home}
                                />
                            </LogoWrapper>
                            <NavLink
                                app={AppSuite.Inspector}
                                isActive={activeLogo === AppSuite.Inspector}
                                href={routeBuilder.inspectorApp(companyId)}
                            />
                            <NavLink
                                app={AppSuite.Report}
                                isActive={activeLogo === AppSuite.Report}
                                href={routeBuilder.reportApp(companyId)}
                            />
                            <NavLink
                                app={AppSuite.UserManagement}
                                isActive={activeLogo === AppSuite.UserManagement}
                                href={routeBuilder.userApp(companyId)}
                            />
                            <NavLink
                                app={AppSuite.Support}
                                isActive={activeLogo === AppSuite.Support}
                                href={routeBuilder.supportApp(companyId)}
                            />
                        </AppSuiteNavigation>
                        <AccountNavigation>
                            <IconButton href={routeBuilder.companySettings(companyId)}>
                                <SettingsIcon />
                            </IconButton>
                        </AccountNavigation>
                    </Stack>
                </WidthContainer>
            </NavBar>
            <PageContent $centered={centered}>{children}</PageContent>
        </div>
    )
}

export default PageWrapper
