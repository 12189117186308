const parseQueryString = (): Record<string, string> => {
    // Create a URLSearchParams object from the current window location's search
    const params = new URLSearchParams(window.location.search)

    // Initialize an empty object to store query parameters
    const queryParams: Record<string, string> = {}

    // Iterate over the URLSearchParams entries and populate the queryParams object
    params.forEach((value, key) => {
        queryParams[key] = value
    })

    return queryParams // Return the populated queryParams object
}

export default parseQueryString
