export default [
    {
        id: 151451,
        url: 'https://inspector.brakeable.com/ufixit/151451',
        category: 'Sleeping Bag',
        title: 'How to repair a broken webbing in a sleeping bag',
        summary: 'The lightweight fabrics on sleeping bags carry...',
        image: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.standard',
        brandId: '2dh79y',
    },
    {
        id: 151453,
        url: 'https://www.ifixit.com/Guide/How+to+Patch+a+Fabric+Tear+in+a+Sleeping+Bag/151453',
        category: 'Sleeping Bag',
        title: 'How to Patch a Fabric Tear in a Sleeping Bag',
        summary: 'The lightweight performance fabrics on sleeping...',
        image: 'https://guide-images.cdn.ifixit.com/igi/nM3UMu3ytUTndlJZ.standard',
        brandId: '2dh79y',
    },
    {
        id: 169514,
        url: 'https://inspector.brakeable.com/ufixit/169514',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a hole in woven textile (using an iron-on patch)',
        summary: 'Accidents happen, and especially when hiking...',
        image: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.standard',
        brandId: '2dh79y',
    },
    {
        id: 169515,
        url: 'https://inspector.brakeable.com/ufixit/169515',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a hole in knitted textile (hand stitching)',
        summary: 'When practicing your sport in summer or winter,...',
        image: 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.standard',
        brandId: '2dh79y',
    },
    {
        id: 169517,
        url: 'https://inspector.brakeable.com/ufixit/169517',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a trouser/cuff hem (without shortening)',
        summary: 'During sport, the hem of your trousers or...',
        image: 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.standard',
        brandId: '2dh79y',
    },
    {
        id: 169519,
        url: 'https://inspector.brakeable.com/ufixit/169519',
        category: 'INTERSPORT Apparel',
        title: 'How to replace a zipper slider',
        summary: 'Over time, the zipper puller of your item might...',
        image: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.standard',
        brandId: '2dh79y',
    },
    {
        id: 169520,
        url: 'https://inspector.brakeable.com/ufixit/169520',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a broken seam',
        summary: 'During sport, a strong stretch can damage the...',
        image: 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.standard',
        brandId: '2dh79y',
    },
    {
        id: 169521,
        url: 'https://inspector.brakeable.com/ufixit/169521',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a taped seam',
        summary: 'After repeated use or washing of waterproof...',
        image: 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.standard',
        brandId: '2dh79y',
    },
    {
        id: 169522,
        url: 'https://inspector.brakeable.com/ufixit/169522',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a hook & loop fastening',
        summary: 'Hook and loop fastenings are quick and...',
        image: 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.standard',
        brandId: '2dh79y',
    },
    {
        id: 169523,
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+buckle+(ski+braces)/169523',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a buckle (ski braces)',
        summary: 'After a long time of use, the buckles or buckle...',
        image: 'https://guide-images.cdn.ifixit.com/igi/53FXl6nJDVRpkTUX.standard',
        brandId: '2dh79y',
    },
    {
        id: 169524,
        url: 'https://inspector.brakeable.com/ufixit/169524',
        category: 'INTERSPORT Apparel',
        title: 'How to replace a button',
        summary: "Don't worry about repairing a simple button on...",
        image: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.standard',
        brandId: '2dh79y',
    },
    {
        id: 169525,
        url: 'https://inspector.brakeable.com/ufixit/169525',
        category: 'INTERSPORT Apparel',
        title: 'How to repair hem/cuff binding',
        summary: 'Binding hems can break if they get stretched...',
        image: 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.standard',
        brandId: '2dh79y',
    },
    {
        id: 169526,
        url: 'https://inspector.brakeable.com/ufixit/169526',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a trouser/cuff hem (by shortening)',
        summary: 'During sport, the hem of your trousers or...',
        image: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.standard',
        brandId: '2dh79y',
    },
    {
        id: 169527,
        url: 'https://inspector.brakeable.com/ufixit/169527',
        category: 'INTERSPORT Apparel',
        title: 'How to remove pilling',
        summary: 'Some fabrics can degrade over time with...',
        image: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.standard',
        brandId: '2dh79y',
    },
    {
        id: 169528,
        url: 'https://inspector.brakeable.com/ufixit/169528',
        category: 'INTERSPORT Apparel',
        title: 'How to repair loose threads',
        summary: 'Knitted beanies or pullovers are easy to snag...',
        image: 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.standard',
        brandId: '2dh79y',
    },
    {
        id: 169529,
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+torn+legging+seam/169529',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a torn legging seam',
        summary: 'High-stretch sports clothing, such as fitness...',
        image: 'https://guide-images.cdn.ifixit.com/igi/OSNQrIMN4O2SCBTI.standard',
        brandId: '2dh79y',
    },
    {
        id: 169530,
        url: 'https://www.ifixit.com/Guide/How+to+replace+worn-out+elastic+(ski+braces)/169530',
        category: 'INTERSPORT Apparel',
        title: 'How to replace worn-out elastic (ski braces)',
        summary: 'After repeated use, some elastics may lose...',
        image: 'https://guide-images.cdn.ifixit.com/igi/FcQbTVPbTnrdDsEM.standard',
        brandId: '2dh79y',
    },
    {
        id: 169531,
        url: 'https://inspector.brakeable.com/ufixit/169531',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a "lost" drawstring',
        summary: 'The adjustment drawstring on hoodies or shorts...',
        image: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.standard',
        brandId: '2dh79y',
    },
    {
        id: 169532,
        url: 'https://inspector.brakeable.com/ufixit/169532',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a hole in a knitted fabric (using darning)',
        summary: 'A hole in knitted sports items can easily...',
        image: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.standard',
        brandId: '2dh79y',
    },
    {
        id: 169533,
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+bikini+underwire/169533',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a bikini underwire',
        summary: "It's only when you want to enjoy the most of...",
        image: 'https://guide-images.cdn.ifixit.com/igi/DOpeVjyElreuHeI1.standard',
        brandId: '2dh79y',
    },
    {
        id: 169940,
        url: 'https://inspector.brakeable.com/ufixit/169940',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a hole in woven textile (using a self adhesive patch)',
        summary: "When you're out hiking in wild terrain, it's...",
        image: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.standard',
        brandId: '2dh79y',
    },
    {
        id: 170053,
        url: 'https://www.ifixit.com/Guide/How+to+replace+a+bikini+buckle/170053',
        category: 'INTERSPORT Apparel',
        title: 'How to replace a bikini buckle',
        summary: 'After a few summers use, the buckle on your...',
        image: 'https://guide-images.cdn.ifixit.com/igi/5q36ToTfiMBQNVp2.standard',
        brandId: '2dh79y',
    },
    {
        id: 170249,
        url: 'https://inspector.brakeable.com/ufixit/170249',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a hole in woven textile (using a sewing machine)',
        summary: "When doing sports, it's common to get some...",
        image: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.standard',
        brandId: '2dh79y',
    },
    {
        id: 170756,
        url: 'https://www.ifixit.com/Guide/How+to+Replace+a+Shoe+Eyelet/170756',
        category: 'INTERSPORT Footwear',
        title: 'How to Replace a Shoe Eyelet',
        summary: "If you've got a damaged or missing eyelet on...",
        image: 'https://guide-images.cdn.ifixit.com/igi/RYWRo1s6CfsGEVit.standard',
        brandId: '2dh79y',
    },
    {
        id: 170758,
        url: 'https://www.ifixit.com/Guide/How+to+Replace+an+Inner+Sole/170758',
        category: 'INTERSPORT Footwear',
        title: 'How to Replace an Inner Sole',
        summary: 'After repeated use from hiking, running,...',
        image: 'https://guide-images.cdn.ifixit.com/igi/oUI64mXjTqHZEYlk.standard',
        brandId: '2dh79y',
    },
    {
        id: 170759,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Flipflop+Strap/170759',
        category: 'INTERSPORT Footwear',
        title: 'How to Repair a Flipflop Strap',
        summary: 'After a couple of summers use, the plastic...',
        image: 'https://guide-images.cdn.ifixit.com/igi/ad1KNSOyDcjYoPh2.standard',
        brandId: '2dh79y',
    },
    {
        id: 170977,
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+broken+label+loop/170977',
        category: 'INTERSPORT Apparel',
        title: 'How to repair a broken label loop',
        summary: 'Small tags on gloves, hats, and other...',
        image: 'https://guide-images.cdn.ifixit.com/igi/wkQ4o1fAgqn6GJbA.standard',
        brandId: '2dh79y',
    },
    {
        id: 171005,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Damaged+Heel+Lining/171005',
        category: 'INTERSPORT Footwear',
        title: 'How to Repair a Damaged Heel Lining',
        summary: 'Your athletic shoes are built to last, but...',
        image: 'https://guide-images.cdn.ifixit.com/igi/HUrYqKOZAdiWcwl4.standard',
        brandId: '2dh79y',
    },
    {
        id: 171006,
        url: 'https://www.ifixit.com/Guide/How+to+Replace+a+Speedlace+With+a+Stopper/171006',
        category: 'INTERSPORT Footwear',
        title: 'How to Replace a Speedlace With a Stopper',
        summary: 'Speedlace systems are really useful for giving...',
        image: 'https://guide-images.cdn.ifixit.com/igi/25gUFnBMLQnZVBXX.standard',
        brandId: '2dh79y',
    },
    {
        id: 171007,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Broken+Hook+and+Loop+Strap/171007',
        category: 'INTERSPORT Footwear',
        title: 'How to Repair a Broken Hook and Loop Strap',
        summary: "If you've noticed a falling-apart velcro strap...",
        image: 'https://guide-images.cdn.ifixit.com/igi/mx6EQ2lVayexJZwe.standard',
        brandId: '2dh79y',
    },
    {
        id: 171008,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Sole+Delamination+From+the+Midsole/171008',
        category: 'INTERSPORT Footwear',
        title: 'How to Repair a Sole Delamination From the Midsole',
        summary: "If you've noticed the rubber on your shoe's...",
        image: 'https://guide-images.cdn.ifixit.com/igi/OmtRNRYCey3jHnMA.standard',
        brandId: '2dh79y',
    },
    {
        id: 171009,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+an+Upper+Delamination/171009',
        category: 'INTERSPORT Footwear',
        title: 'How to Repair an Upper Delamination',
        summary: 'Sole delamination, where the sole separates...',
        image: 'https://guide-images.cdn.ifixit.com/igi/g2sNydhMQtAxURQv.standard',
        brandId: '2dh79y',
    },
    {
        id: 171010,
        url: 'https://www.ifixit.com/Guide/How+to+Replace+a+Draw+Cord+in+an+Insulated+Boot/171010',
        category: 'INTERSPORT Footwear',
        title: 'How to Replace a Draw Cord in an Insulated Boot',
        summary: 'If the draw cord on your boot is frayed or...',
        image: 'https://guide-images.cdn.ifixit.com/igi/fZEAKOIUwRlLDTc5.standard',
        brandId: '2dh79y',
    },
    {
        id: 171320,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Tear+in+a+Knitted+Upper+(using+a+self-adhesive+patch)/171320',
        category: 'INTERSPORT Footwear',
        title: 'How to Repair a Tear in a Knitted Upper (using a self-adhesive patch)',
        summary: 'A tear in the knitted upper of your favorite...',
        image: 'https://guide-images.cdn.ifixit.com/igi/E1LKq1SRpTevmMhA.standard',
        brandId: '2dh79y',
    },
    {
        id: 171321,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Hole+in+a+Shoe+Sole/171321',
        category: 'INTERSPORT Footwear',
        title: 'How to Repair a Hole in a Shoe Sole',
        summary: "Discovering a hole in your shoe sole doesn't...",
        image: 'https://guide-images.cdn.ifixit.com/igi/5TCTpwsgNdZewjQr.standard',
        brandId: '2dh79y',
    },
    {
        id: 172345,
        url: 'https://inspector.brakeable.com/ufixit/172345',
        category: 'INTERSPORT Equipment',
        title: 'How to Replace a Backpack Buckle (using existing strap)',
        summary: 'Sometimes you need to change the buckle of your...',
        image: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.standard',
        brandId: '2dh79y',
    },
    {
        id: 173041,
        url: 'https://inspector.brakeable.com/ufixit/173041',
        category: 'INTERSPORT Equipment',
        title: 'How to Repair a Loose Webbing Strap in a Backpack',
        summary: 'Your backpack strap is one of the most...',
        image: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.standard',
        brandId: '2dh79y',
    },
    {
        id: 173043,
        url: 'https://inspector.brakeable.com/ufixit/173043',
        category: 'INTERSPORT Equipment',
        title: 'How to Repair a Broken Drawstring Tunnel in a Backpack',
        summary: "If your backpack's drawstring is loose and the...",
        image: 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.standard',
        brandId: '2dh79y',
    },
    {
        id: 173073,
        url: 'https://inspector.brakeable.com/ufixit/173073',
        category: 'INTERSPORT Equipment',
        title: 'How to Replace a "Strap Keeper" in a Backpack',
        summary: '&quot;Strap keepers&quot; are the small pieces of elastic...',
        image: 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.standard',
        brandId: '2dh79y',
    },
    {
        id: 173074,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Tear+in+a+Sleeping+Bag/173074',
        category: 'INTERSPORT Equipment',
        title: 'How to Repair a Tear in a Sleeping Bag',
        summary: "Sometimes, you're out there having the time of...",
        image: 'https://guide-images.cdn.ifixit.com/igi/aUMipFEFwQZjHnyR.standard',
        brandId: '2dh79y',
    },
    {
        id: 173093,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Velcro+Strap+in+a+Sleeping+Bag/173093',
        category: 'INTERSPORT Equipment',
        title: 'How to Repair a Velcro Strap in a Sleeping Bag',
        summary: "There's nothing better than snuggling up in...",
        image: 'https://guide-images.cdn.ifixit.com/igi/4FIjEhns6xUDDMKQ.standard',
        brandId: '2dh79y',
    },
    {
        id: 173096,
        url: 'https://www.ifixit.com/Guide/How+to+Patch+a+Fabric+Tear+in+a+Sleeping+Bag/173096',
        category: 'INTERSPORT Equipment',
        title: 'How to Patch a Fabric Tear in a Sleeping Bag',
        summary: 'The lightweight performance fabrics on sleeping...',
        image: 'https://guide-images.cdn.ifixit.com/igi/3eC4tyFuaJMkoArC.standard',
        brandId: '2dh79y',
    },
    {
        id: 173098,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Broken+Webbing+in+a+Sleeping+Bag/173098',
        category: 'INTERSPORT Equipment',
        title: 'How to Repair a Broken Webbing in a Sleeping Bag',
        summary: 'The lightweight fabrics on sleeping bags carry...',
        image: 'https://guide-images.cdn.ifixit.com/igi/HldHLDC2vXIwTUEQ.standard',
        brandId: '2dh79y',
    },
    {
        id: 173316,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Seam+Split+in+a+Sleeping+Bag/173316',
        category: 'INTERSPORT Equipment',
        title: 'How to Repair a Seam Split in a Sleeping Bag',
        summary: 'After many adventures out in the woods, your...',
        image: 'https://guide-images.cdn.ifixit.com/igi/vXYyJuBE6tw2YdLe.standard',
        brandId: '2dh79y',
    },
    {
        id: 173409,
        url: 'https://www.ifixit.com/Guide/How+to+Replace+the+Cord+Stopper+in+a+Sleeping+Bag/173409',
        category: 'INTERSPORT Equipment',
        title: 'How to Replace the Cord Stopper in a Sleeping Bag',
        summary: 'The cord stopper is a small but useful part of...',
        image: 'https://guide-images.cdn.ifixit.com/igi/TXK15XB4IGRZVZIk.standard',
        brandId: '2dh79y',
    },
    {
        id: 173782,
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Loose+Armrest+on+a+Camping+Chair/173782',
        category: 'INTERSPORT Equipment',
        title: 'How to Repair a Loose Armrest on a Camping Chair',
        summary: 'A camping chair is an key piece of camping...',
        image: 'https://guide-images.cdn.ifixit.com/igi/edoywXGSl2QcVXZU.standard',
        brandId: '2dh79y',
    },
    {
        id: 173783,
        url: 'https://www.ifixit.com/Guide/How+to+Patch+a+Small+Leak+in+a+Self-Inflatable+Air+Mat/173783',
        category: 'INTERSPORT Equipment',
        title: 'How to Patch a Small Leak in a Self-Inflatable Air Mat',
        summary: "Nothing beats a good night's sleep when you are...",
        image: 'https://guide-images.cdn.ifixit.com/igi/oJLbj2tstwDJJVjU.standard',
        brandId: '2dh79y',
    },
    {
        id: 174126,
        url: 'https://www.ifixit.com/Guide/How+to+Patch+a+Hole+in+the+%E2%80%9CFurry%E2%80%9D+Side+of+an+Air+Mattress/174126',
        category: 'INTERSPORT Equipment',
        title: 'How to Patch a Hole in the “Furry” Side of an Air Mattress',
        summary: 'Most air mattresses have velour or &quot;flocking&quot;...',
        image: 'https://guide-images.cdn.ifixit.com/igi/avugchfj6RvW5DAa.standard',
        brandId: '2dh79y',
    },
]
