import { IfixitGuide } from './ifixit-types'

export type Guides = {
    [key: number | string]: IfixitGuide
}

const guides: Guides = {
    // --- Sleeping Bag ---
    // intersport
    151451: {
        brandId: '43574',
        conclusion_raw: 'To reassemble your device, follow these instructions in reverse order.',
        conclusion_rendered:
            '<p>To reassemble your device, follow these instructions in reverse order.</p>',
        difficulty: 'Moderate',
        documents: [],
        flags: [
            {
                title: 'Request Guide Deletion',
                flagid: 'GUIDE_DELETE',
                text: 'This guide will be reviewed by admins for deletion.',
            },
            {
                title: 'Needs More Images',
                flagid: 'GUIDE_MISSING_IMAGES',
                text: "A few more images would make this guide's procedures crystal clear.",
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
            {
                title: 'Missing Details',
                flagid: 'NO_DETAILS',
                text: "Some of this guide's details are missing. Help users by adding tools, difficulty, or time.",
            },
        ],
        guideid: 151451,
        image: {
            id: 2692184,
            guid: 'pXbIhHNFe3TMLKTu',
            mini: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.full',
        },
        introduction_raw:
            'The lightweight fabrics on sleeping bags carry bags can often tear after repeated use of the compression straps.  This can make packing the sleeping bag more difficult, and less effective.\n\nThis guide will show how to use a sewing machine to fix a torn seam between the fabric and the compression webbing. This is a simple fix for anyone with experience of sewing machines, but may be a bit challenging without sewing knowledge.',
        introduction_rendered:
            '<p>The lightweight fabrics on sleeping bags carry bags can often tear after repeated use of the compression straps.  This can make packing the sleeping bag more difficult, and less effective.</p>\n\n<p>This guide will show how to use a sewing machine to fix a torn seam between the fabric and the compression webbing. This is a simple fix for anyone with experience of sewing machines, but may be a bit challenging without sewing knowledge.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Broken Compression Strap',
                lines: [
                    {
                        text_raw:
                            'Find the location of the tear on your product. In best case, only the seam stitching should be broken, and no additional tears or damage to the webbing of fabric.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Find the location of the tear on your product. In best case, only the seam stitching should be broken, and no additional tears or damage to the webbing of fabric.',
                    },
                ],
                guideid: 151451,
                stepid: 315116,
                orderby: 1,
                revisionid: 2919949,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 2692184,
                            guid: 'pXbIhHNFe3TMLKTu',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/pXbIhHNFe3TMLKTu.full',
                        },
                        {
                            id: 2692185,
                            guid: 'QYCdQCZa6jfs4s22',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/QYCdQCZa6jfs4s22.full',
                        },
                        {
                            id: 2692186,
                            guid: 'HwKSAxMIBYHQso1y',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HwKSAxMIBYHQso1y.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Turn the bag inside-out',
                lines: [
                    {
                        text_raw:
                            'Unpack the sleeping bag and set it aside. Turn the carry bag inside-out and inspect the damage from inside. The seam should be clearly visible and there should be no fraying on the fabric or the webbing - if there is, you should carefully trim off frayed edges so long as enough fabric remains to run through the sewing machine later.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Unpack the sleeping bag and set it aside. Turn the carry bag inside-out and inspect the damage from inside. The seam should be clearly visible and there should be no fraying on the fabric or the webbing - if there is, you should carefully trim off frayed edges so long as enough fabric remains to run through the sewing machine later.',
                    },
                ],
                guideid: 151451,
                stepid: 315117,
                orderby: 2,
                revisionid: 3118229,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 2692187,
                            guid: 'KcBfQbMoDMOx4442',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/KcBfQbMoDMOx4442.full',
                        },
                        {
                            id: 2692188,
                            guid: '1Vn4frf2uoca1WlG',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/1Vn4frf2uoca1WlG.full',
                        },
                        {
                            id: 2692189,
                            guid: 'mgjrN2SPVPiBtZwO',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/mgjrN2SPVPiBtZwO.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare the seam',
                lines: [
                    {
                        text_raw: 'Use pins to secure the webbing back into the correct place.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Use pins to secure the webbing back into the correct place.',
                    },
                    {
                        text_raw:
                            'Check the other (unbroken) webbing seams for the correct positioning on top or between the seam fabrics. This is unique to each product, so take care to match what you have and not copying the example shown.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Check the other (unbroken) webbing seams for the correct positioning on top or between the seam fabrics. This is unique to each product, so take care to match what you have and not copying the example shown.',
                    },
                    {
                        text_raw: 'Trim away any lose threads or fraying.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Trim away any lose threads or fraying.',
                    },
                ],
                guideid: 151451,
                stepid: 315118,
                orderby: 3,
                revisionid: 2924107,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 2726002,
                            guid: 'Ff4CLyVhHYbGuqeA',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Ff4CLyVhHYbGuqeA.full',
                        },
                        {
                            id: 2726001,
                            guid: 'HMOqbOrJyLkqY1aV',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HMOqbOrJyLkqY1aV.full',
                        },
                        {
                            id: 2726003,
                            guid: 'vMQPjMBGMDuOXwcE',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/vMQPjMBGMDuOXwcE.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/vMQPjMBGMDuOXwcE.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/vMQPjMBGMDuOXwcE.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/vMQPjMBGMDuOXwcE.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/vMQPjMBGMDuOXwcE.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/vMQPjMBGMDuOXwcE.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/vMQPjMBGMDuOXwcE.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/vMQPjMBGMDuOXwcE.large',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/vMQPjMBGMDuOXwcE.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Sewing the Seam',
                lines: [
                    {
                        text_raw:
                            'Prepare your sewing machine and the area around it. Position the broken seam underneath the foot and pull the foot down.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Prepare your sewing machine and the area around it. Position the broken seam underneath the foot and pull the foot down.',
                    },
                    {
                        text_raw: '***Remove the pins before starting!***',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: '<strong>Remove the pins before starting!</strong>',
                    },
                    {
                        text_raw:
                            'Start at one end of the seam and end at the other. Turn the bag around 180 degrees and repeat the process back the other way. Continue to do this until the repair looks to be a similar (or stronger) strength to the original. Lock the repair at both ends with a lock stitch or back stitch. Check the repair and finally cut the thread.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Start at one end of the seam and end at the other. Turn the bag around 180 degrees and repeat the process back the other way. Continue to do this until the repair looks to be a similar (or stronger) strength to the original. Lock the repair at both ends with a lock stitch or back stitch. Check the repair and finally cut the thread.',
                    },
                ],
                guideid: 151451,
                stepid: 317523,
                orderby: 4,
                revisionid: 2924100,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 2726005,
                            guid: '2B1KM5laDAvHYYkT',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2B1KM5laDAvHYYkT.full',
                        },
                        {
                            id: 2726006,
                            guid: 'LQcABLSCF4nQfmXj',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LQcABLSCF4nQfmXj.full',
                        },
                        {
                            id: 2726010,
                            guid: '5I12FnYnHtaKDMD3',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/5I12FnYnHtaKDMD3.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/5I12FnYnHtaKDMD3.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/5I12FnYnHtaKDMD3.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/5I12FnYnHtaKDMD3.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/5I12FnYnHtaKDMD3.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/5I12FnYnHtaKDMD3.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/5I12FnYnHtaKDMD3.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/5I12FnYnHtaKDMD3.large',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/5I12FnYnHtaKDMD3.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'You repair is finished! Check the repair strength by pulling an unbroken strap gently, and then pulling the repaired strap in the same way.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'You repair is finished! Check the repair strength by pulling an unbroken strap gently, and then pulling the repaired strap in the same way.',
                    },
                    {
                        text_raw:
                            'When pulling the repaired strap, if it looks weak or strained, repeat step 4 again and add some additional stitching on the sewing machine.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'When pulling the repaired strap, if it looks weak or strained, repeat step 4 again and add some additional stitching on the sewing machine.',
                    },
                    {
                        text_raw:
                            "Turn the bag back the correct way, and stuff the sleeping bag back in. If it looks unstrained and strong, you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "Turn the bag back the correct way, and stuff the sleeping bag back in. If it looks unstrained and strong, you're ready to go!",
                    },
                    {
                        text_raw:
                            '***Note: ***it is normal for the repaired strap to be 5-10mm shorter after the repair, and this may be noticeable when the bag is packed.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            '<strong>Note: </strong>it is normal for the repaired strap to be 5-10mm shorter after the repair, and this may be noticeable when the bag is packed.',
                    },
                ],
                guideid: 151451,
                stepid: 317531,
                orderby: 5,
                revisionid: 2924116,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 2726039,
                            guid: 'VlBgsSLVshjffxPb',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/VlBgsSLVshjffxPb.full',
                        },
                        {
                            id: 2726040,
                            guid: 'xbQTOMysMbXuLZdY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/xbQTOMysMbXuLZdY.full',
                        },
                        {
                            id: 2726038,
                            guid: 'LXpKnuuJaJlOdx1m',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LXpKnuuJaJlOdx1m.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'The lightweight fabrics on sleeping bags carry...',
        time_required: '20 minutes',
        time_required_min: 1200,
        time_required_max: 1200,
        title: 'How to repair a broken webbing in a sleeping bag',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1993430,
        created_date: 1657021302,
        published_date: 1661938007,
        modified_date: 1716551406,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'Sleeping Bag',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+broken+webbing+in+a+sleeping+bag/151451',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4154414,
            username: 'Ben Blischke',
            unique_username: 'benblischke',
            join_date: 1657021302,
            image: {
                id: 2692173,
                guid: 'cL4MeFrlpgFlXfXZ',
                mini: 'https://guide-images.cdn.ifixit.com/igi/cL4MeFrlpgFlXfXZ.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/cL4MeFrlpgFlXfXZ.thumbnail',
                medium: 'https://guide-images.cdn.ifixit.com/igi/cL4MeFrlpgFlXfXZ.medium',
                original: 'https://guide-images.cdn.ifixit.com/igi/cL4MeFrlpgFlXfXZ.full',
            },
            reputation: 208,
            url: 'https://www.ifixit.com/User/4154414/Ben+Blischke',
            teams: [],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },

    // --- Backpack ---
    // intersport
    172345: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Very easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 172345,
        image: {
            id: 3325572,
            guid: '62GhQlaKYd1sopF1',
            mini: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.full',
        },
        introduction_raw:
            'Sometimes you need to change the buckle of your favourite backpack. It can be due to it being broken or simply not closing like it used to. We will show this quick fix that can be done in just couple of minutes of your time!',
        introduction_rendered:
            '<p>Sometimes you need to change the buckle of your favourite backpack. It can be due to it being broken or simply not closing like it used to. We will show this quick fix that can be done in just couple of minutes of your time!</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the repair area',
                lines: [
                    {
                        text_raw: 'Locate the buckle that needs to be replaced.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Locate the buckle that needs to be replaced.',
                    },
                    {
                        text_raw:
                            '***Note:*** For this simple solution we are using strap with a single fold and stitch (red circle). For straps with double stitching, additional steps would be needed to unstitch the buckle and then restitch it once it is finished.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Note:</strong> For this simple solution we are using strap with a single fold and stitch (red circle). For straps with double stitching, additional steps would be needed to unstitch the buckle and then restitch it once it is finished.',
                    },
                ],
                guideid: 172345,
                stepid: 369656,
                orderby: 1,
                revisionid: 3395950,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3325572,
                            guid: '62GhQlaKYd1sopF1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/62GhQlaKYd1sopF1.full',
                        },
                        {
                            id: 3325576,
                            guid: 'CDQ5nsldBBVYGPmB',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/CDQ5nsldBBVYGPmB.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Remove the existing buckle',
                lines: [
                    {
                        text_raw: 'Following the yellow arrows, push the strap out of the buckle.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Following the yellow arrows, push the strap out of the buckle.',
                    },
                    {
                        text_raw:
                            'Keep pushing the strap and gently slide the last bit following the direction of the red arrows.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Keep pushing the strap and gently slide the last bit following the direction of the red arrows.',
                    },
                    {
                        text_raw:
                            'Keep going forward in the direction of the green arrows until the buckle is out of the strap.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Keep going forward in the direction of the green arrows until the buckle is out of the strap.',
                    },
                ],
                guideid: 172345,
                stepid: 369657,
                orderby: 2,
                revisionid: 3395960,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3325602,
                            guid: 'IfGQvamvdogyymCX',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/IfGQvamvdogyymCX.full',
                        },
                        {
                            id: 3325600,
                            guid: 'TFRtDaSm3KAXRJHf',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/TFRtDaSm3KAXRJHf.full',
                        },
                        {
                            id: 3325599,
                            guid: '3De5oGedTJgJDHJW',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/3De5oGedTJgJDHJW.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Insert the new buckle',
                lines: [
                    {
                        text_raw:
                            'Repeat the previous step in reverse. Start by introducing the buckle into the strap and following the direction of the green arrows.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Repeat the previous step in reverse. Start by introducing the buckle into the strap and following the direction of the green arrows.',
                    },
                    {
                        text_raw:
                            'Keep introducing the buckle following the direction of the yellow arrows.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Keep introducing the buckle following the direction of the yellow arrows.',
                    },
                    {
                        text_raw:
                            'When the strap has passed on both parts of the buckle, pull the tip of the strap in the direction of the red arrows.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'When the strap has passed on both parts of the buckle, pull the tip of the strap in the direction of the red arrows.',
                    },
                ],
                guideid: 172345,
                stepid: 369658,
                orderby: 3,
                revisionid: 3396034,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3325911,
                            guid: '3VoOVPKGyVNIiEQY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/3VoOVPKGyVNIiEQY.full',
                        },
                        {
                            id: 3325612,
                            guid: 'Pt6dFVRK14RSNHVr',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Pt6dFVRK14RSNHVr.full',
                        },
                        {
                            id: 3325611,
                            guid: 'aAIDfFDedwCZDs2p',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/aAIDfFDedwCZDs2p.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Tight the buckle',
                lines: [
                    {
                        text_raw:
                            'Pull the strap in the direction of the red arrow to tighten it up.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Pull the strap in the direction of the red arrow to tighten it up.',
                    },
                    {
                        text_raw:
                            'Introduce the strap inside the "strap keeper" and then verify that the new buckle fits its counterpart. If it doesn\'t, you will need to repeat the previous steps to replace it as well.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "Introduce the strap inside the &quot;strap keeper&quot; and then verify that the new buckle fits its counterpart. If it doesn't, you will need to repeat the previous steps to replace it as well.",
                    },
                    {
                        text_raw:
                            '***Tip: ***if you need help replacing the "strap keeper", check [guide|173073|this guide|new_window=true].',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>if you need help replacing the &quot;strap keeper&quot;, check <a href="https://www.ifixit.com/Guide/How+to+Replace+a+%22Strap+Keeper%22+in+a+Backpack/173073" target="_blank">this guide</a>.',
                    },
                ],
                guideid: 172345,
                stepid: 369659,
                orderby: 4,
                revisionid: 3396051,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3325917,
                            guid: 'fIle6qGgSi5Nke6w',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/fIle6qGgSi5Nke6w.full',
                        },
                        {
                            id: 3325635,
                            guid: 'dfhiHOP1weNFZ3Gd',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/dfhiHOP1weNFZ3Gd.full',
                        },
                        {
                            id: 3325636,
                            guid: 'GRIlLAbLCuLMjYsp',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/GRIlLAbLCuLMjYsp.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 172345,
                stepid: 369660,
                orderby: 5,
                revisionid: 3395633,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3325640,
                            guid: 'X6EJFhdjL3lUtBaY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/X6EJFhdjL3lUtBaY.full',
                        },
                        {
                            id: 3325011,
                            guid: 'G2lDUvglUoMcNHZn',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/G2lDUvglUoMcNHZn.full',
                        },
                        {
                            id: 3325642,
                            guid: '2PCbCv4TVCpOctJy',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2PCbCv4TVCpOctJy.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Sometimes you need to change the buckle of your...',
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How to Replace a Backpack Buckle (using existing strap)',
        tools: [],
        type: 'technique',
        revisionid: 1994471,
        created_date: 1714130844,
        published_date: 1716564649,
        modified_date: 1720770356,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Equipment',
        url: 'https://www.ifixit.com/Guide/How+to+Replace+a+Backpack+Buckle+(using+existing+strap)/172345',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    173041: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Moderate',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 173041,
        image: {
            id: 3324001,
            guid: 'QI6wKtREfEB1UEcj',
            mini: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.full',
        },
        introduction_raw:
            "Your backpack strap is one of the most important parts of your bag, so it's important to take care of it! After many journeys and adventures, your strap can start to weather down. Don't worry, we've got you covered with these simple steps to ensure your backpack is as sturdy and like new again!",
        introduction_rendered:
            "<p>Your backpack strap is one of the most important parts of your bag, so it's important to take care of it! After many journeys and adventures, your strap can start to weather down. Don't worry, we've got you covered with these simple steps to ensure your backpack is as sturdy and like new again!</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the repair area',
                lines: [
                    {
                        text_raw: 'Identify the defective area and assess how large the damage is.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Identify the defective area and assess how large the damage is.',
                    },
                ],
                guideid: 173041,
                stepid: 369570,
                orderby: 1,
                revisionid: 3393896,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324001,
                            guid: 'QI6wKtREfEB1UEcj',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/QI6wKtREfEB1UEcj.full',
                        },
                        {
                            id: 3324000,
                            guid: 'a5BWIvCRqJfhJCNc',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/a5BWIvCRqJfhJCNc.full',
                        },
                        {
                            id: 3324004,
                            guid: 'bGhk3bmbUHRJRrgh',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/bGhk3bmbUHRJRrgh.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare the straps',
                lines: [
                    {
                        text_raw:
                            'Cut away the tips of the straps to create a clean surface to repair.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Cut away the tips of the straps to create a clean surface to repair.',
                    },
                    {
                        text_raw: '***Note: ***In this example, we cut 2 cm away from the tip.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Note: </strong>In this example, we cut 2 cm away from the tip.',
                    },
                    {
                        text_raw: 'Pin the two straps together and set aside.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Pin the two straps together and set aside.',
                    },
                ],
                guideid: 173041,
                stepid: 369571,
                orderby: 2,
                revisionid: 3393947,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324011,
                            guid: 'GELI5AE4GyxuIoYV',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/GELI5AE4GyxuIoYV.full',
                        },
                        {
                            id: 3324263,
                            guid: 'XKNoJDYiNTXGrnba',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/XKNoJDYiNTXGrnba.full',
                        },
                        {
                            id: 3324262,
                            guid: 'MTojHycelIT1nEdF',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MTojHycelIT1nEdF.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Clean the area',
                lines: [
                    {
                        text_raw:
                            'Use a "seam ripper" tool to unstitch the remaining piece of strap.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Use a &quot;seam ripper&quot; tool to unstitch the remaining piece of strap.',
                    },
                    {
                        text_raw:
                            '***Note:*** This will leave a hole in the border of the backpack (red circle), that will be useful in the next step.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Note:</strong> This will leave a hole in the border of the backpack (red circle), that will be useful in the next step.',
                    },
                ],
                guideid: 173041,
                stepid: 369573,
                orderby: 3,
                revisionid: 3393956,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324021,
                            guid: 'fyfxQQXKRKVj2EbL',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/fyfxQQXKRKVj2EbL.full',
                        },
                        {
                            id: 3324020,
                            guid: 'YF3vWRMYFqrEUDgS',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/YF3vWRMYFqrEUDgS.full',
                        },
                        {
                            id: 3324269,
                            guid: '3BNXcDjOHUMAuPVh',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/3BNXcDjOHUMAuPVh.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Hold the straps',
                lines: [
                    {
                        text_raw:
                            'Insert the pinned straps inside the hole and use a safety pin to hold the pieces together.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Insert the pinned straps inside the hole and use a safety pin to hold the pieces together.',
                    },
                ],
                guideid: 173041,
                stepid: 369574,
                orderby: 4,
                revisionid: 3393932,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324027,
                            guid: 'O1ScIMSkoTEIppVR',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/O1ScIMSkoTEIppVR.full',
                        },
                        {
                            id: 3324026,
                            guid: 'xtCFhcwTLbtH4BAf',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/xtCFhcwTLbtH4BAf.full',
                        },
                        {
                            id: 3324041,
                            guid: 'g4tPVIpo2MCGQ2dw',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/g4tPVIpo2MCGQ2dw.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Turn the backpack inside-out',
                lines: [
                    {
                        text_raw:
                            'Locate the straps that were inserted in the previous step (red circle).',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Locate the straps that were inserted in the previous step (red circle).',
                    },
                ],
                guideid: 173041,
                stepid: 369575,
                orderby: 5,
                revisionid: 3394725,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324034,
                            guid: 'tPB4yTyPYECIk1Ru',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/tPB4yTyPYECIk1Ru.full',
                        },
                        {
                            id: 3324047,
                            guid: '6ALKZPAikOLQMoZU',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/6ALKZPAikOLQMoZU.full',
                        },
                        {
                            id: 3324280,
                            guid: 'sIUXCmIfDYG1UEER',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/sIUXCmIfDYG1UEER.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Clean the area before stitching',
                lines: [
                    {
                        text_raw:
                            'Cut away the remaining pieces of the binding material that were present before (yellow arrows).',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Cut away the remaining pieces of the binding material that were present before (yellow arrows).',
                    },
                ],
                guideid: 173041,
                stepid: 369576,
                orderby: 6,
                revisionid: 3393979,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324052,
                            guid: 'ZrmeUWikhQgvXoKB',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ZrmeUWikhQgvXoKB.full',
                        },
                        {
                            id: 3324284,
                            guid: 'oGATQFkREC3j5dIY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/oGATQFkREC3j5dIY.full',
                        },
                        {
                            id: 3324054,
                            guid: 'VRiSmRHYAoWWPLhc',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/VRiSmRHYAoWWPLhc.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Stitch the strap',
                lines: [
                    {
                        text_raw: 'Use a straight stitch to fix the strap in the right position.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Use a straight stitch to fix the strap in the right position.',
                    },
                    {
                        text_raw:
                            '***Tip: ***For tips on setting up the machine take a look at [guide|19463|this iFixit guide|new_window=true].',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>For tips on setting up the machine take a look at <a href="https://www.ifixit.com/Guide/Thread+a+Sewing+Machine/19463" target="_blank">this iFixit guide</a>.',
                    },
                ],
                guideid: 173041,
                stepid: 369577,
                orderby: 7,
                revisionid: 3396666,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324058,
                            guid: 'coEXATXGTmHPdwDt',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/coEXATXGTmHPdwDt.full',
                        },
                        {
                            id: 3324057,
                            guid: 'N6OrjCByFAecTQ4c',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/N6OrjCByFAecTQ4c.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Reenforce the seam',
                lines: [
                    {
                        text_raw:
                            'Change the settings in the sewing machine and switch from a "straight" to a "zigzag" stich.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Change the settings in the sewing machine and switch from a &quot;straight&quot; to a &quot;zigzag&quot; stich.',
                    },
                    {
                        text_raw: 'Sew along the same line of the previous step.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Sew along the same line of the previous step.',
                    },
                ],
                guideid: 173041,
                stepid: 369578,
                orderby: 8,
                revisionid: 3396699,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324293,
                            guid: 'urRUphm4QVCenNIj',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/urRUphm4QVCenNIj.full',
                        },
                        {
                            id: 3324062,
                            guid: 'gcddGuVqdXhKSUyi',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/gcddGuVqdXhKSUyi.full',
                        },
                        {
                            id: 3324063,
                            guid: 'oqDnAPTwgrZGDcZt',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/oqDnAPTwgrZGDcZt.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Clean the area',
                lines: [
                    {
                        text_raw: 'Locate the remaining piece of strap (red line) and cut it out.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Locate the remaining piece of strap (red line) and cut it out.',
                    },
                ],
                guideid: 173041,
                stepid: 369579,
                orderby: 9,
                revisionid: 3394646,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324744,
                            guid: 'AATRofVtPPtkQGca',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/AATRofVtPPtkQGca.full',
                        },
                        {
                            id: 3324069,
                            guid: 'D45YUsNGfEYZZuph',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/D45YUsNGfEYZZuph.full',
                        },
                        {
                            id: 3324070,
                            guid: 'HNZJockMmJ2AcxJv',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HNZJockMmJ2AcxJv.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Pin the "binding" strap',
                lines: [
                    {
                        text_raw:
                            'Select a "binding" material, it can be a strap or any spare textile you have at home.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Select a &quot;binding&quot; material, it can be a strap or any spare textile you have at home.',
                    },
                    {
                        text_raw:
                            'Make sure it is the same length as the area you are trying to repair (red line).',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Make sure it is the same length as the area you are trying to repair (red line).',
                    },
                    {
                        text_raw:
                            'Fold the side of binding strap inwards (yellow arrow) and fix the position using with pins.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Fold the side of binding strap inwards (yellow arrow) and fix the position using with pins.',
                    },
                ],
                guideid: 173041,
                stepid: 369580,
                orderby: 10,
                revisionid: 3394556,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324318,
                            guid: 'cHGOLgQG4kGpCocN',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/cHGOLgQG4kGpCocN.full',
                        },
                        {
                            id: 3324321,
                            guid: 'uMyLCgVYlTrOsIjC',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/uMyLCgVYlTrOsIjC.full',
                        },
                        {
                            id: 3324236,
                            guid: 'oZlDUXDftXCSVI1T',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/oZlDUXDftXCSVI1T.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Sew the "binding" strap to the inner part',
                lines: [
                    {
                        text_raw:
                            'Change the settings in the sewing machine and switch back to a "straight" stich.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Change the settings in the sewing machine and switch back to a &quot;straight&quot; stich.',
                    },
                    {
                        text_raw:
                            'Start sewing and remove any pins in the way of the sewing route.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Start sewing and remove any pins in the way of the sewing route.',
                    },
                    {
                        text_raw:
                            'Turn the backpack inside-out to return to the original position.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Turn the backpack inside-out to return to the original position.',
                    },
                ],
                guideid: 173041,
                stepid: 369581,
                orderby: 11,
                revisionid: 3396718,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324242,
                            guid: 'uO4efy2BTsPRJGw3',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/uO4efy2BTsPRJGw3.full',
                        },
                        {
                            id: 3324241,
                            guid: 'tedUSrFyoEWlNqnE',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/tedUSrFyoEWlNqnE.full',
                        },
                        {
                            id: 3324250,
                            guid: 'EXIU3f2EesZL1orh',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/EXIU3f2EesZL1orh.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 173041,
                stepid: 369582,
                orderby: 12,
                revisionid: 3393929,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324251,
                            guid: 'fbfCi2snSSZBlsbP',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/fbfCi2snSSZBlsbP.full',
                        },
                        {
                            id: 3324254,
                            guid: '5d5ylKuSBBFR4geQ',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/5d5ylKuSBBFR4geQ.full',
                        },
                        {
                            id: 3324252,
                            guid: 'VWsjE1NTHhkPubLW',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/VWsjE1NTHhkPubLW.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Your backpack strap is one of the most...',
        time_required: '35 minutes',
        time_required_min: 2100,
        time_required_max: 2100,
        title: 'How to Repair a Loose Webbing Strap in a Backpack',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Seam Ripper',
                notes: null,
                url: 'http://www.amazon.com/Clover-463-Seam-Ripper/dp/B000YZ8MVU/ref=sr_1_1?ie=UTF8&qid=1346440445&sr=8-1&keywords=seam+ripper',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Binding strap',
                notes: null,
                url: '/Item/Binding_strap',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Pins',
                notes: null,
                url: 'https://www.amazon.com/ForSewian-Pieces-Sewing-Straight-Quilting/dp/B08R2XCFZ5/ref=sr_1_9?crid=2BJKXOED4FMVZ&keywords=fabric+pins&qid=1654208111&s=arts-crafts&sprefix=fabric+pins%2Carts-crafts%2C143&sr=1-9',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1997788,
        created_date: 1716361752,
        published_date: 1716462676,
        modified_date: 1721317144,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Equipment',
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Loose+Webbing+Strap+in+a+Backpack/173041',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    173073: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 173073,
        image: {
            id: 3324921,
            guid: 'kgMptkDQAZQOuklT',
            mini: 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/kgMptkDQAZQOuklT.full',
        },
        introduction_raw:
            '"Strap keepers" are the small pieces of elastic that hold in place the strap of your backpack. Although little in size, they play a big role in regulating the perfect length for the straps.\n\nAfter constant use, you might notice that the "keepers" get overstretched. This is your cue to take matters in your own hands and start repairing! We will walk you through the steps of fixing this small but relevant inconvenience in no time.',
        introduction_rendered:
            '<p>&quot;Strap keepers&quot; are the small pieces of elastic that hold in place the strap of your backpack. Although little in size, they play a big role in regulating the perfect length for the straps.</p>\n\n<p>After constant use, you might notice that the &quot;keepers&quot; get overstretched. This is your cue to take matters in your own hands and start repairing! We will walk you through the steps of fixing this small but relevant inconvenience in no time.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the repair area',
                lines: [
                    {
                        text_raw: 'Check the "strap keepers" of your backpack (red arrows).',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Check the &quot;strap keepers&quot; of your backpack (red arrows).',
                    },
                    {
                        text_raw:
                            'The length of the elastic should be tight. In this example, we have a worn-out elastic that has expanded and is bigger than the width of the strap (green arrow).',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'The length of the elastic should be tight. In this example, we have a worn-out elastic that has expanded and is bigger than the width of the strap (green arrow).',
                    },
                ],
                guideid: 173073,
                stepid: 369599,
                orderby: 1,
                revisionid: 3397040,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3325870,
                            guid: '3yGruJnWDftSYuQv',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/3yGruJnWDftSYuQv.full',
                        },
                        {
                            id: 3325871,
                            guid: 'GykkQaAvfqxrbSbf',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/GykkQaAvfqxrbSbf.full',
                        },
                        {
                            id: 3325828,
                            guid: 'MOeqRj5kJVKTxeqw',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MOeqRj5kJVKTxeqw.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Measure the correct width',
                lines: [
                    {
                        text_raw: 'Use a pair of scissors to cut the old elastic.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Use a pair of scissors to cut the old elastic.',
                    },
                    {
                        text_raw:
                            'Measure the width of the strap. In this example, the measurement was ***2 cm*** (red line).',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Measure the width of the strap. In this example, the measurement was <strong>2 cm</strong> (red line).',
                    },
                    {
                        text_raw:
                            'For the strap keeper replacement, we need to use the following formula:',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'For the strap keeper replacement, we need to use the following formula:',
                    },
                    {
                        text_raw:
                            "***Length = (Strap's width * 2.5) + 2 *** ''In our example this would be: (2 * 2.5) + 2 = 7''",
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            "<strong>Length = (Strap's width * 2.5) + 2 </strong> <em>In our example this would be: (2 * 2.5) + 2 = 7</em>",
                    },
                    {
                        text_raw:
                            'This means that the width of the new strap keeper would need to be*** 7 cm ***(green line).',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'This means that the width of the new strap keeper would need to be<strong> 7 cm </strong>(green line).',
                    },
                ],
                guideid: 173073,
                stepid: 369600,
                orderby: 2,
                revisionid: 3396853,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324927,
                            guid: 'Wsv3vL6HdXTLS4r4',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Wsv3vL6HdXTLS4r4.full',
                        },
                        {
                            id: 3324936,
                            guid: 'SZGTJRBKyaKe2ZN1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/SZGTJRBKyaKe2ZN1.full',
                        },
                        {
                            id: 3324938,
                            guid: 'gPeJRaAT4RFFhomN',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/gPeJRaAT4RFFhomN.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare for stitching',
                lines: [
                    {
                        text_raw:
                            'Using a fabric pencil, mark the length calculated on the previous step. In this example, we are marking 7cm (green line).',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Using a fabric pencil, mark the length calculated on the previous step. In this example, we are marking 7cm (green line).',
                    },
                    {
                        text_raw:
                            '***Note:  *** We are using an elastic band that will serve as the new "strap keeper". For this example, we chose the color black in order to make it stand out.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Note:  </strong> We are using an elastic band that will serve as the new &quot;strap keeper&quot;. For this example, we chose the color black in order to make it stand out.',
                    },
                    {
                        text_raw:
                            'Cut along the marked length and then fold the elastic band around the strap.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Cut along the marked length and then fold the elastic band around the strap.',
                    },
                ],
                guideid: 173073,
                stepid: 369601,
                orderby: 3,
                revisionid: 3395803,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324939,
                            guid: 'yXhcTqLutFsUsu3b',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/yXhcTqLutFsUsu3b.full',
                        },
                        {
                            id: 3324943,
                            guid: 'NWOJMXdot1T3IM41',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/NWOJMXdot1T3IM41.full',
                        },
                        {
                            id: 3324952,
                            guid: 'X4ZAWfO3YUG6KPaq',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/X4ZAWfO3YUG6KPaq.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Stitch the "strap keeper"',
                lines: [
                    {
                        text_raw:
                            'Before stitching remove the strap and align the elastic in the correct position.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Before stitching remove the strap and align the elastic in the correct position.',
                    },
                    {
                        text_raw:
                            '***Tip: ***For tips on setting up the machine take a look at [guide|19463|this iFixit guide|new_window=true].',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>For tips on setting up the machine take a look at <a href="https://www.ifixit.com/Guide/Thread+a+Sewing+Machine/19463" target="_blank">this iFixit guide</a>.',
                    },
                    {
                        text_raw: 'Make sure you leave 1 cm away from the edge (red line).',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Make sure you leave 1 cm away from the edge (red line).',
                    },
                    {
                        text_raw: 'Clean the remaining loose threads.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Clean the remaining loose threads.',
                    },
                ],
                guideid: 173073,
                stepid: 369602,
                orderby: 4,
                revisionid: 3395809,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324967,
                            guid: 'gplWDuUigoDnAyDe',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/gplWDuUigoDnAyDe.full',
                        },
                        {
                            id: 3324959,
                            guid: 'NyStUkdCvmoZCPFi',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/NyStUkdCvmoZCPFi.full',
                        },
                        {
                            id: 3324962,
                            guid: '2dFUL6LfGYyITqFa',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2dFUL6LfGYyITqFa.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Turn the "strap keeper" inside out',
                lines: [
                    {
                        text_raw:
                            'Turn the "strap keeper" so that the sewn part is facing inwards.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Turn the &quot;strap keeper&quot; so that the sewn part is facing inwards.',
                    },
                ],
                guideid: 173073,
                stepid: 369603,
                orderby: 5,
                revisionid: 3395777,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324971,
                            guid: 'bRWJjgSOvLEoc1LP',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/bRWJjgSOvLEoc1LP.full',
                        },
                        {
                            id: 3324984,
                            guid: 'aKIPN65E2XvKZlSm',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/aKIPN65E2XvKZlSm.full',
                        },
                        {
                            id: 3324985,
                            guid: '6bfPjrlEqTGNKpEr',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/6bfPjrlEqTGNKpEr.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Insert the "strap keeper"',
                lines: [
                    {
                        text_raw:
                            'Following the red arrows, insert the "strap keeper" into the strap with the buckle.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Following the red arrows, insert the &quot;strap keeper&quot; into the strap with the buckle.',
                    },
                    {
                        text_raw:
                            'Once it has been inserted into the buckle, follow the direction of the yellow arrows.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Once it has been inserted into the buckle, follow the direction of the yellow arrows.',
                    },
                    {
                        text_raw:
                            'Keep going forward and gently slide the "strap keeper" in the direction of the green arrows till it is holding the "loose" strap in place.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Keep going forward and gently slide the &quot;strap keeper&quot; in the direction of the green arrows till it is holding the &quot;loose&quot; strap in place.',
                    },
                ],
                guideid: 173073,
                stepid: 369604,
                orderby: 6,
                revisionid: 3395788,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324980,
                            guid: 'HBHueSZkR25opwJU',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HBHueSZkR25opwJU.full',
                        },
                        {
                            id: 3324995,
                            guid: 'cMFHGPoaRvDlbG6V',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/cMFHGPoaRvDlbG6V.full',
                        },
                        {
                            id: 3324996,
                            guid: '1g3iPtBbyOPxZWEG',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/1g3iPtBbyOPxZWEG.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 173073,
                stepid: 369605,
                orderby: 7,
                revisionid: 3395633,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3325002,
                            guid: 'rwEDeGHrJHsB2lld',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/rwEDeGHrJHsB2lld.full',
                        },
                        {
                            id: 3325008,
                            guid: 'LTHHnIxU3igymiAx',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LTHHnIxU3igymiAx.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: '&quot;Strap keepers&quot; are the small pieces of elastic...',
        time_required: '10 minutes',
        time_required_min: 600,
        time_required_max: 600,
        title: 'How to Replace a "Strap Keeper" in a Backpack',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Measuring Tape or Yard Stick',
                notes: null,
                url: 'https://a.co/d/7dUrK7g',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Fabric pencil',
                notes: null,
                url: 'https://www.amazon.com/SINGER-00310-Measure-Marking-Pencil/dp/B00566EA7M/ref=sr_1_5?crid=18SD0V1SZ9KCY&keywords=fabric+pencil&qid=1672161455&sprefix=fabric+pencil%2Caps%2C102&sr=8-5',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Scissors',
                notes: null,
                url: 'https://eustore.ifixit.com/products/utility-scissors',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/YZFc6xfCFTuHFhsP.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'elastic band',
                notes: null,
                url: '/Item/elastic_band',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1997793,
        created_date: 1716477032,
        published_date: 1716562120,
        modified_date: 1720770424,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Equipment',
        url: 'https://www.ifixit.com/Guide/How+to+Replace+a+%22Strap+Keeper%22+in+a+Backpack/173073',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    173043: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 173043,
        image: {
            id: 3323953,
            guid: 'twjxZMWf2eqgvExT',
            mini: 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/twjxZMWf2eqgvExT.full',
        },
        introduction_raw:
            "If your backpack's drawstring is loose and the closing mechanism isn't working as it should, don't worry! We've got you covered. In just a few simple steps, we'll show you how to repair the string and make your favourite backpack as secure and comfortable as it used to be!",
        introduction_rendered:
            "<p>If your backpack's drawstring is loose and the closing mechanism isn't working as it should, don't worry! We've got you covered. In just a few simple steps, we'll show you how to repair the string and make your favourite backpack as secure and comfortable as it used to be!</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the repair area',
                lines: [
                    {
                        text_raw: 'Identify the defective area and assess how large the damage is.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Identify the defective area and assess how large the damage is.',
                    },
                ],
                guideid: 173043,
                stepid: 369563,
                orderby: 1,
                revisionid: 3393846,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3324107,
                            guid: 'NSs2cMKxrUE6Otdt',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/NSs2cMKxrUE6Otdt.full',
                        },
                        {
                            id: 3323941,
                            guid: '1K6TUJi6OMP4melt',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/1K6TUJi6OMP4melt.full',
                        },
                        {
                            id: 3324108,
                            guid: 'TX1FCccFOF2AxTIg',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/TX1FCccFOF2AxTIg.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Clean the area',
                lines: [
                    {
                        text_raw:
                            'Cut away any damaged threads around the drawstring tunnel, to create a clean surface to repair.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Cut away any damaged threads around the drawstring tunnel, to create a clean surface to repair.',
                    },
                ],
                guideid: 173043,
                stepid: 369564,
                orderby: 2,
                revisionid: 3395642,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3323948,
                            guid: 'oPZTadRurqLGABSY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/oPZTadRurqLGABSY.full',
                        },
                        {
                            id: 3323947,
                            guid: 'hAEb314KJqNneZfN',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/hAEb314KJqNneZfN.full',
                        },
                        {
                            id: 3323949,
                            guid: 'FM3Jnrc5HyXoAMKI',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/FM3Jnrc5HyXoAMKI.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare the drawstring',
                lines: [
                    {
                        text_raw: 'Place the drawstring inside the tunnel.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Place the drawstring inside the tunnel.',
                    },
                    {
                        text_raw: 'Turn the edges inside, so that the drawstring is tucked inside.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Turn the edges inside, so that the drawstring is tucked inside.',
                    },
                ],
                guideid: 173043,
                stepid: 369565,
                orderby: 3,
                revisionid: 3394587,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3323961,
                            guid: 'HfEfIj2fq1putLUy',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HfEfIj2fq1putLUy.full',
                        },
                        {
                            id: 3323957,
                            guid: 'jv6PUUJWFPDGKktl',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/jv6PUUJWFPDGKktl.full',
                        },
                        {
                            id: 3323959,
                            guid: 'TUKultuMoCOpFKcX',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/TUKultuMoCOpFKcX.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Pin the area',
                lines: [
                    {
                        text_raw:
                            'Pin the folded parts of the tunnel securely in place and prepare the sewing machine.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Pin the folded parts of the tunnel securely in place and prepare the sewing machine.',
                    },
                    {
                        text_raw:
                            '***Tip: ***For tips on setting up the machine take a look at [guide|19463|this iFixit guide|new_window=true].',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>For tips on setting up the machine take a look at <a href="https://www.ifixit.com/Guide/Thread+a+Sewing+Machine/19463" target="_blank">this iFixit guide</a>.',
                    },
                    {
                        text_raw:
                            'Depress the sewing machine foot and remove any pins in the way of the sewing route.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Depress the sewing machine foot and remove any pins in the way of the sewing route.',
                    },
                ],
                guideid: 173043,
                stepid: 369566,
                orderby: 4,
                revisionid: 3393867,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3323968,
                            guid: '1wt1aB2dFAYiZIax',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/1wt1aB2dFAYiZIax.full',
                        },
                        {
                            id: 3323970,
                            guid: 'qWOggCtStUPOdqi4',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/qWOggCtStUPOdqi4.full',
                        },
                        {
                            id: 3323976,
                            guid: 'D4N5SVC4xxXxYHex',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/D4N5SVC4xxXxYHex.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Sew the repair',
                lines: [
                    {
                        text_raw:
                            'Start sewing a straight line from one side to the other and add a lock stitch at the beginning and the end.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Start sewing a straight line from one side to the other and add a lock stitch at the beginning and the end.',
                    },
                    {
                        text_raw:
                            'Finish the sewing and cut the loose thread to make a neat finish.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Finish the sewing and cut the loose thread to make a neat finish.',
                    },
                ],
                guideid: 173043,
                stepid: 369568,
                orderby: 5,
                revisionid: 3394610,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3323980,
                            guid: 'uQbT6svFTRNEKiM5',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/uQbT6svFTRNEKiM5.full',
                        },
                        {
                            id: 3323981,
                            guid: 'NFcQZdiVDs5tPhQY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/NFcQZdiVDs5tPhQY.full',
                        },
                        {
                            id: 3323982,
                            guid: 'I642XVIiLLBaBVwn',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/I642XVIiLLBaBVwn.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 173043,
                stepid: 369569,
                orderby: 6,
                revisionid: 3393758,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3323990,
                            guid: 'CnC33PwcVFVDVPCh',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/CnC33PwcVFVDVPCh.full',
                        },
                        {
                            id: 3323991,
                            guid: 'DhqftQloNoAM5doO',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/DhqftQloNoAM5doO.full',
                        },
                        {
                            id: 3323992,
                            guid: 'TpYIluOpESqk2SOH',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/TpYIluOpESqk2SOH.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: "If your backpack's drawstring is loose and the...",
        time_required: '15 minutes',
        time_required_min: 900,
        time_required_max: 900,
        title: 'How to Repair a Broken Drawstring Tunnel in a Backpack',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Sewing Scissors',
                notes: null,
                url: 'https://www.amazon.com/-/es/tijeras-profesionales-pulgadas-inclinadas-resistentes/dp/B002PIE4JI/ref=sr_1_8?dchild=1&ie=UTF8&keywords=sewing%20scissors&language=en_US&qid=1626450645&sr=8-8',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Pins',
                notes: null,
                url: 'https://www.amazon.com/ForSewian-Pieces-Sewing-Straight-Quilting/dp/B08R2XCFZ5/ref=sr_1_9?crid=2BJKXOED4FMVZ&keywords=fabric+pins&qid=1654208111&s=arts-crafts&sprefix=fabric+pins%2Carts-crafts%2C143&sr=1-9',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1997784,
        created_date: 1716362194,
        published_date: 1716453587,
        modified_date: 1720787370,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Equipment',
        url: 'https://www.ifixit.com/Guide/How+to+Repair+a+Broken+Drawstring+Tunnel+in+a+Backpack/173043',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    // Vaude
    69111: {
        brandId: '122278',
        conclusion_raw: 'Follow the instructions in reverse order to finish your repair.',
        conclusion_rendered:
            '<p>Follow the instructions in reverse order to finish your repair.</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69111,
        image: {
            id: 909010,
            guid: 'JM1tmsOyrKPSosbj',
            mini: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.full',
        },
        introduction_raw:
            'If the hook end (male end) of your buckle is broken, it’s easy to replace. We’ll show you how in just a few easy steps.',
        introduction_rendered:
            '<p>If the hook end (male end) of your buckle is broken, it’s easy to replace. We’ll show you how in just a few easy steps.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [
            {
                text: 'VAUDE Slide-in buckles Single Adjust',
                notes: null,
                type: '',
                quantity: 1,
                url: 'https://www.vaude.com/de/de/11297-steckschnalle-15-mm-single-adjust.html#?colour=451&size=13424',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/6xVhT2IGYXsZ6cDr.thumbnail',
                isoptional: false,
            },
            {
                text: 'VAUDE Slide-in buckles Dual Adjust',
                notes: null,
                type: '',
                quantity: 1,
                url: 'https://www.vaude.com/de/de/11299-steckschnalle-20-mm-dual-adjust.html#?colour=451',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/6LUJCJDqpFoaPDnd.thumbnail',
                isoptional: false,
            },
        ],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'In order to determine the correct buckle size, measure the webbing. The webbing illustrated here is 15 mm, so you would need a 15 mm buckle.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'In order to determine the correct buckle size, measure the webbing. The webbing illustrated here is 15 mm, so you would need a 15 mm buckle.',
                    },
                ],
                guideid: 69111,
                stepid: 139037,
                orderby: 1,
                revisionid: 1552611,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909010,
                            guid: 'JM1tmsOyrKPSosbj',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/JM1tmsOyrKPSosbj.full',
                        },
                    ],
                },
                comments: [
                    {
                        commentid: 732693,
                        locale: 'en',
                        context: 'step',
                        contextid: 139037,
                        parentid: null,
                        author: {
                            userid: 3909056,
                            username: 'scott',
                            unique_username: 'scott34629',
                            join_date: 1617808105,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/3909056/scott',
                            teams: [],
                        },
                        title: 'How do I replace a broken buckle on my backpack?',
                        text_raw:
                            'I have the same problem but the nylon straps are sewn on so you can’t slip a new buckle on. Is there a version available that slips on somehow?',
                        text_rendered:
                            '<p>I have the same problem but the nylon straps are sewn on so you can’t slip a new buckle on. Is there a version available that slips on somehow?</p>',
                        rating: 0,
                        date: 1617808106,
                        modified_date: 1617808106,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Remove the broken buckle from the webbing by first pulling the webbing through the locking strap and then unthreading it from the buckle.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Remove the broken buckle from the webbing by first pulling the webbing through the locking strap and then unthreading it from the buckle.',
                    },
                    {
                        text_raw:
                            'Use a screwdriver or tweezers to help pull the webbing through, if necessary.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Use a screwdriver or tweezers to help pull the webbing through, if necessary.',
                    },
                ],
                guideid: 69111,
                stepid: 139039,
                orderby: 2,
                revisionid: 1604527,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909004,
                            guid: 'jlXJntiWiMMd4bB1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/jlXJntiWiMMd4bB1.full',
                        },
                        {
                            id: 909003,
                            guid: 'ytbYnEqLevJrwXH5',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ytbYnEqLevJrwXH5.full',
                        },
                        {
                            id: 909005,
                            guid: 'PaoowYxKlWpegeRL',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/PaoowYxKlWpegeRL.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: 'Buckle on backpack',
        summary: 'If the hook end (male end) of your buckle is...',
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How do I replace a broken buckle on my backpack?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Flathead 3/32" or 2.5 mm Screwdriver',
                notes: null,
                url: 'https://eustore.ifixit.com/products/flathead-3-32-or-2-5-mm-screwdriver',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/qoKrddNsF1G6VRgO.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Measuring Tape',
                notes: null,
                url: 'http://www.amazon.com/dp/B000PSA6GU/',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'replacement',
        revisionid: 1057170,
        created_date: 1476089309,
        published_date: 1481132623,
        modified_date: 1613198173,
        prereq_modified_date: 0,
        public: true,
        comments: [
            {
                commentid: 601895,
                locale: 'en',
                context: 'guide',
                contextid: 69111,
                parentid: null,
                author: {
                    userid: 747636,
                    username: 'tahiruysal',
                    unique_username: 'tahiruysal',
                    join_date: 1386975078,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/747636/tahiruysal',
                    teams: [],
                },
                title: 'How do I replace a broken buckle on my backpack?',
                text_raw:
                    'Used to use Ortlieb panniers and I have recently changes to Vaude Aqua Back Plus for some incremental additional volume and also the external pocket. I could see that it is easy to replace the male end of the buckle but I guess it could almost be the end of the bag if the female end is broken?\n\n…or maybe you could show us as well how to fix that too, so that we could have some comfort other simply praying that this would not happen!',
                text_rendered:
                    '<p>Used to use Ortlieb panniers and I have recently changes to Vaude Aqua Back Plus for some incremental additional volume and also the external pocket. I could see that it is easy to replace the male end of the buckle but I guess it could almost be the end of the bag if the female end is broken?</p>\n\n<p>…or maybe you could show us as well how to fix that too, so that we could have some comfort other simply praying that this would not happen!</p>',
                rating: 0,
                date: 1577286057,
                modified_date: 1577286057,
                status: 'public',
                replies: [],
            },
        ],
        category: 'VAUDE Rucksack',
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+a+broken+buckle+on+my+backpack-/69111',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    69120: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69120,
        image: {
            id: 916472,
            guid: '3CWd5ZwgV4rkrfyM',
            mini: 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/3CWd5ZwgV4rkrfyM.full',
        },
        introduction_raw:
            'If the sternum strap comes off of its rail or gets lost, you can easily replace it.',
        introduction_rendered:
            '<p>If the sternum strap comes off of its rail or gets lost, you can easily replace it.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [
            {
                text: 'VAUDE Chest belt for backpacks',
                notes: null,
                type: '',
                quantity: 1,
                url: 'https://www.vaude.com/de/de/12583-rucksack-brustgurt-25-mm.html#?colour=451&size=13424',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/5jspleqYv13g3dSm.thumbnail',
                isoptional: false,
            },
            {
                text: 'VAUDE Chest Belt for Kids',
                notes: null,
                type: '',
                quantity: 1,
                url: 'https://www.vaude.com/en-GB/Equipment/Spare-Parts-Accessories/Backpack-Spare-Parts/Chest-belt-20-mm-Kids',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/cjvleWNf4DZQIBEP.thumbnail',
                isoptional: false,
            },
        ],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Guide the new sternum strap onto the thin end of the rail.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Guide the new sternum strap onto the thin end of the rail.',
                    },
                ],
                guideid: 69120,
                stepid: 139072,
                orderby: 1,
                revisionid: 1569140,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 914773,
                            guid: '35BUBNnUaDiAJFoE',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/35BUBNnUaDiAJFoE.full',
                        },
                    ],
                },
                comments: [
                    {
                        commentid: 469030,
                        locale: 'en',
                        context: 'step',
                        contextid: 139072,
                        parentid: null,
                        author: {
                            userid: 2670083,
                            username: 'Leif Svendsen',
                            unique_username: 'leifsvend',
                            join_date: 1535268091,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/2670083/Leif+Svendsen',
                            teams: [],
                        },
                        title: 'How do I replace a sternum strap on my backpack?',
                        text_raw:
                            'Fine Way to do it If you have a thin end of the trail at your Vaude backpack. I do not have that.\n\nAny tips on how to get the strap onto the trail without “a thin end og the trail”?',
                        text_rendered:
                            '<p>Fine Way to do it If you have a thin end of the trail at your Vaude backpack. I do not have that.</p>\n\n<p>Any tips on how to get the strap onto the trail without “a thin end og the trail”?</p>',
                        rating: 0,
                        date: 1535268092,
                        modified_date: 1535268092,
                        status: 'public',
                        replies: [
                            {
                                commentid: 823087,
                                locale: 'en',
                                context: 'step',
                                contextid: 139072,
                                parentid: 469030,
                                author: {
                                    userid: 4173660,
                                    username: 'K c',
                                    unique_username: 'kay12',
                                    join_date: 1660678634,
                                    image: {
                                        id: -32,
                                        guid: 'default_avatar_placeholder_guid',
                                        original:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10',
                                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.mini',
                                        thumbnail:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.thumbnail',
                                        '140x105':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.140x105',
                                        '200x150':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.200x150',
                                        standard:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.standard',
                                        '440x330':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.440x330',
                                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.medium',
                                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.large',
                                    },
                                    reputation: 1,
                                    url: 'https://www.ifixit.com/User/4173660/K+c',
                                    teams: [],
                                },
                                title: 'How do I replace a sternum strap on my backpack?',
                                text_raw: 'Yep. Same here. Any advice?',
                                text_rendered: '<p>Yep. Same here. Any advice?</p>',
                                rating: 0,
                                date: 1660678635,
                                modified_date: 0,
                                status: 'public',
                            },
                        ],
                    },
                    {
                        commentid: 764952,
                        locale: 'en',
                        context: 'step',
                        contextid: 139072,
                        parentid: null,
                        author: {
                            userid: 4033370,
                            username: 'CMcCraw',
                            unique_username: 'cmccraw',
                            join_date: 1633000602,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/4033370/CMcCraw',
                            teams: [],
                        },
                        title: 'How do I replace a sternum strap on my backpack?',
                        text_raw:
                            'Where can I find a rail sternum strap to replace the missing one?',
                        text_rendered:
                            '<p>Where can I find a rail sternum strap to replace the missing one?</p>',
                        rating: 0,
                        date: 1633000603,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Guide the other side of the sternum strap onto the thin end of the rail and position it.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Guide the other side of the sternum strap onto the thin end of the rail and position it.',
                    },
                ],
                guideid: 69120,
                stepid: 142096,
                orderby: 2,
                revisionid: 1967613,
                media: {
                    type: 'video',
                    data: {
                        objectid: 687,
                        videoid: 608,
                        srcid: null,
                        image: {
                            image: {
                                id: 914778,
                                guid: 'rNOK1wDoMnLDSPEV',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/rNOK1wDoMnLDSPEV.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/rNOK1wDoMnLDSPEV.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/rNOK1wDoMnLDSPEV.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/rNOK1wDoMnLDSPEV.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/rNOK1wDoMnLDSPEV.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/rNOK1wDoMnLDSPEV.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/rNOK1wDoMnLDSPEV.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/rNOK1wDoMnLDSPEV.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/rNOK1wDoMnLDSPEV.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: 'replace sternum strap VAUDE.mov',
                        duration: 18333,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 310576133,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 18333,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 14059962,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6126,
                            channels: '2',
                            video_bitrate_in_kbps: 5998,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/dYnZZlTOqCeyCAO3_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [
                    {
                        commentid: 579095,
                        locale: 'de',
                        context: 'step',
                        contextid: 142096,
                        parentid: null,
                        author: {
                            userid: 3225237,
                            username: 'Hermann-Josef Meyer',
                            unique_username: 'hermannjos48503',
                            join_date: 1569774307,
                            image: {
                                id: 1854410,
                                guid: 'SrO2MyYdd4yKOEDr',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/SrO2MyYdd4yKOEDr.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/SrO2MyYdd4yKOEDr.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/SrO2MyYdd4yKOEDr.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/SrO2MyYdd4yKOEDr.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/SrO2MyYdd4yKOEDr.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/SrO2MyYdd4yKOEDr.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/SrO2MyYdd4yKOEDr.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/SrO2MyYdd4yKOEDr.full',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/3225237/Hermann-Josef+Meyer',
                            teams: [],
                        },
                        title: 'How do I replace a sternum strap on my backpack?',
                        text_raw:
                            'Das sieht so leicht aus….ich schaffe das nicht….\n\nmeyerbestwig@gmail.com',
                        text_rendered:
                            '<p>Das sieht so leicht aus….ich schaffe das nicht….</p>\n\n<p>meyerbestwig@gmail.com</p>',
                        rating: 0,
                        date: 1569774307,
                        modified_date: 1569774307,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 769104,
                        locale: 'en',
                        context: 'step',
                        contextid: 142096,
                        parentid: null,
                        author: {
                            userid: 4041212,
                            username: 'Christopher McCraw',
                            unique_username: 'christophe56235',
                            join_date: 1634678672,
                            image: {
                                id: 2500600,
                                guid: 'JXtUSvmMutIjjV3h',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/JXtUSvmMutIjjV3h.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/JXtUSvmMutIjjV3h.thumbnail',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/JXtUSvmMutIjjV3h.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/JXtUSvmMutIjjV3h.full',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/4041212/Christopher+McCraw',
                            teams: [],
                        },
                        title: 'How do I replace a sternum strap on my backpack?',
                        text_raw:
                            'Where could I find a replacement rail buckle? I need one to install as you suggest!',
                        text_rendered:
                            '<p>Where could I find a replacement rail buckle? I need one to install as you suggest!</p>',
                        rating: 0,
                        date: 1634678673,
                        modified_date: 0,
                        status: 'public',
                        replies: [
                            {
                                commentid: 812077,
                                locale: 'en',
                                context: 'step',
                                contextid: 142096,
                                parentid: 769104,
                                author: {
                                    userid: 4146179,
                                    username: 'zinelg',
                                    unique_username: 'zinelg',
                                    join_date: 1655401891,
                                    image: {
                                        id: -32,
                                        guid: 'default_avatar_placeholder_guid',
                                        original:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4',
                                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.mini',
                                        thumbnail:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.thumbnail',
                                        '140x105':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.140x105',
                                        '200x150':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.200x150',
                                        standard:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.standard',
                                        '440x330':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.440x330',
                                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.medium',
                                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.large',
                                    },
                                    reputation: 1,
                                    url: 'https://www.ifixit.com/User/4146179/zinelg',
                                    teams: [],
                                },
                                title: 'How do I replace a sternum strap on my backpack?',
                                text_raw: 'Have you ever found out where to buy it?',
                                text_rendered: '<p>Have you ever found out where to buy it?</p>',
                                rating: 0,
                                date: 1655401893,
                                modified_date: 0,
                                status: 'public',
                            },
                            {
                                commentid: 823090,
                                locale: 'en',
                                context: 'step',
                                contextid: 142096,
                                parentid: 769104,
                                author: {
                                    userid: 1511745,
                                    username: 'VAUDE Reparaturteam',
                                    unique_username: 'vaudesport2',
                                    join_date: 1456153000,
                                    image: {
                                        id: -32,
                                        guid: 'default_avatar_placeholder_guid',
                                        original:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                                        thumbnail:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                                        '140x105':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                                        '200x150':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                                        standard:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                                        '440x330':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
                                    },
                                    reputation: 6303,
                                    url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
                                    teams: [12497],
                                },
                                title: 'How do I replace a sternum strap on my backpack?',
                                text_raw:
                                    'check this out: https://www.vaude.com/de-DE/Ausruestung/Ersatzteile/?p=1',
                                text_rendered:
                                    '<p>check this out: <a href="https://www.vaude.com/de-DE/Ausruestung/Ersatzteile/?p=1" rel="nofollow noopener noreferrer" title="https://www.vaude.com/de-DE/Ausruestung/Ersatzteile/?p=1">https://www.vaude.com/de-DE/Ausruestung/...</a></p>',
                                rating: 0,
                                date: 1660679506,
                                modified_date: 0,
                                status: 'public',
                            },
                        ],
                    },
                ],
            },
        ],
        subject: 'Sternum strap on backpack',
        summary: 'If the sternum strap comes off of its rail or...',
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How do I replace a sternum strap on my backpack?',
        tools: [],
        type: 'replacement',
        revisionid: 1014907,
        created_date: 1476092609,
        published_date: 1481132594,
        modified_date: 1481132594,
        prereq_modified_date: 0,
        public: true,
        comments: [
            {
                commentid: 283374,
                locale: 'en',
                context: 'guide',
                contextid: 69120,
                parentid: null,
                author: {
                    userid: 1841217,
                    username: 'larsv',
                    unique_username: 'larsv',
                    join_date: 1482506286,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/1841217/larsv',
                    teams: [],
                },
                title: 'How do I replace a sternum strap on my backpack?',
                text_raw:
                    'Also works on the Vaude Petros bag (115630100). It does not have the thin end on the rail, but you can easily make one of the ends thin by gently squeezing it with a pair of pliers.',
                text_rendered:
                    '<p>Also works on the Vaude Petros bag (115630100). It does not have the thin end on the rail, but you can easily make one of the ends thin by gently squeezing it with a pair of pliers.</p>',
                rating: 0,
                date: 1482506287,
                modified_date: 1482506287,
                status: 'public',
                replies: [],
            },
        ],
        category: 'VAUDE Rucksack',
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+a+sternum+strap+on+my+backpack-/69120',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    71477: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 71477,
        image: {
            id: 916470,
            guid: 'FJ2PYO6ri6hkbQb1',
            mini: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.full',
        },
        introduction_raw:
            'If the sternum strap comes off of its rail or gets lost, it’s easy to replace. The following instructions will show you how you replace a sternum strap with a hydration tube holder.',
        introduction_rendered:
            '<p>If the sternum strap comes off of its rail or gets lost, it’s easy to replace. The following instructions will show you how you replace a sternum strap with a hydration tube holder.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [
            {
                text: 'VAUDE Chest Belt with Drinking Tube Mount',
                notes: null,
                type: '',
                quantity: 1,
                url: 'https://www.vaude.com/de/de/12783-chest-belt-rucksack-brustgurt-15-mm.html#?colour=484&size=13424',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/w4YD6ZRPVXh22ONA.thumbnail',
                isoptional: false,
            },
        ],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'The sternum strap has come off on both sides of the backpack suspension system.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'The sternum strap has come off on both sides of the backpack suspension system.',
                    },
                ],
                guideid: 71477,
                stepid: 142465,
                orderby: 1,
                revisionid: 1562467,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 916470,
                            guid: 'FJ2PYO6ri6hkbQb1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/FJ2PYO6ri6hkbQb1.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Guide the sternum strap onto the rail at the thin end.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Guide the sternum strap onto the rail at the thin end.',
                    },
                    {
                        text_raw:
                            'Depending on where you want to attach your hydration tube, thread the sternum strap with the attachment onto the right or left rail.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Depending on where you want to attach your hydration tube, thread the sternum strap with the attachment onto the right or left rail.',
                    },
                ],
                guideid: 71477,
                stepid: 142466,
                orderby: 2,
                revisionid: 1562471,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 916469,
                            guid: 'K5OkAfRyWBAUVX1k',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/K5OkAfRyWBAUVX1k.full',
                        },
                        {
                            id: 916467,
                            guid: 'DmcY6VPtZIMU5wE4',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/DmcY6VPtZIMU5wE4.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Guide the other side of the sternum strap onto the thin end of the rail and position it.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Guide the other side of the sternum strap onto the thin end of the rail and position it.',
                    },
                ],
                guideid: 71477,
                stepid: 142468,
                orderby: 3,
                revisionid: 1562475,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 916465,
                            guid: 'GqiMbWc1VVG4IJnI',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/GqiMbWc1VVG4IJnI.full',
                        },
                        {
                            id: 916466,
                            guid: 'PHsBTUbN35SYlQXu',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/PHsBTUbN35SYlQXu.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: 'sternum strap with a hydration tube holder',
        summary: 'If the sternum strap comes off of its rail or...',
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How do I replace a sternum strap with a hydration tube holder on my backpack?',
        tools: [],
        type: 'replacement',
        revisionid: 1077848,
        created_date: 1476966941,
        published_date: 1481132852,
        modified_date: 1481132852,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'VAUDE Rucksack',
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+a+sternum+strap+with+a+hydration+tube+holder+on+my+backpack-/71477',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },

    // --- Jacket ---
    // intersport
    169514: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169514,
        image: {
            id: 3222443,
            guid: 'RAvgVhFaBoePxPXD',
            mini: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.full',
        },
        introduction_raw:
            "Accidents happen, and especially when hiking outdoors it's easy to tear a hole in woven items such a trousers. This guide will walk you through a simple and effective method of repairing a hole using an iron-on patch. Before you start, we recommend also looking at the guide for repairs using a self-adhesive patch, as a quick alternative when you're outside without access to an iron: [link|https://www.ifixit.com/Guide/How+to+repair+a+hole+in+woven+textile+(using+a+self+adhesive+patch)/169940|How to repair a hole in woven textile (using a self adhesive patch)|new_window=true]",
        introduction_rendered:
            '<p>Accidents happen, and especially when hiking outdoors it\'s easy to tear a hole in woven items such a trousers. This guide will walk you through a simple and effective method of repairing a hole using an iron-on patch. Before you start, we recommend also looking at the guide for repairs using a self-adhesive patch, as a quick alternative when you\'re outside without access to an iron: <a href="https://www.ifixit.com/Guide/How+to+repair+a+hole+in+woven+textile+(using+a+self+adhesive+patch)/169940" target="_blank">How to repair a hole in woven textile (using a self adhesive patch)</a></p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Prepare and clean the area',
                lines: [
                    {
                        text_raw:
                            'Start by ensuring the damaged area is clean and free from any loose threads or debris.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Start by ensuring the damaged area is clean and free from any loose threads or debris.',
                    },
                    {
                        text_raw: 'Prepare the iron-on patch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Prepare the iron-on patch.',
                    },
                    {
                        text_raw:
                            "''***Note: ***in this guide we are demonstrating with a high-stretch patch, to match the stretch of the trousers. There are many patches available, and you should pick a quality closely matching the original fabric.''",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            '<em><strong>Note: </strong>in this guide we are demonstrating with a high-stretch patch, to match the stretch of the trousers. There are many patches available, and you should pick a quality closely matching the original fabric.</em>',
                    },
                ],
                guideid: 169514,
                stepid: 359158,
                orderby: 1,
                revisionid: 3333951,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222443,
                            guid: 'RAvgVhFaBoePxPXD',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/RAvgVhFaBoePxPXD.full',
                        },
                        {
                            id: 3222444,
                            guid: 'RY4DCUcRNux6Xe1x',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/RY4DCUcRNux6Xe1x.full',
                        },
                        {
                            id: 3222445,
                            guid: '2DXdoYPQEXaZCUPT',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2DXdoYPQEXaZCUPT.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Position the iron-on Patch',
                lines: [
                    {
                        text_raw:
                            'For this repair, we decided to put the patch on the inside of the garment; so the hole will remain slightly visible on the front but will be repaired.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'For this repair, we decided to put the patch on the inside of the garment; so the hole will remain slightly visible on the front but will be repaired.',
                    },
                    {
                        text_raw: 'Turn the item inside-out, and flatten the area to be repaired.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Turn the item inside-out, and flatten the area to be repaired.',
                    },
                    {
                        text_raw:
                            'Cut the patch to size, ensuring it completely covers the damaged area. The patch should be 1cm larger than the hole on all sides.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Cut the patch to size, ensuring it completely covers the damaged area. The patch should be 1cm larger than the hole on all sides.',
                    },
                ],
                guideid: 169514,
                stepid: 359297,
                orderby: 2,
                revisionid: 3333953,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222451,
                            guid: 'LyJBqykARZEL4RXu',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LyJBqykARZEL4RXu.full',
                        },
                        {
                            id: 3222452,
                            guid: 'PpAQe6uUsNPDCawy',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/PpAQe6uUsNPDCawy.full',
                        },
                        {
                            id: 3222453,
                            guid: 'EFR1WcfYKBZMjMs5',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/EFR1WcfYKBZMjMs5.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Iron the patch',
                lines: [
                    {
                        text_raw:
                            'Place the glue side of the badge against the fabric. This is often shiny, or with a different pattern.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Place the glue side of the badge against the fabric. This is often shiny, or with a different pattern.',
                    },
                    {
                        text_raw:
                            '***Take care: ***If the patch is upside-down, the glue will melt directly to your iron and the patch will get stuck to it.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Take care: </strong>If the patch is upside-down, the glue will melt directly to your iron and the patch will get stuck to it.',
                    },
                    {
                        text_raw:
                            'Preheat your iron to the maximum heat setting, suitable for your clothing item. Check the composition label for guidance and then adjust the iron accordingly.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Preheat your iron to the maximum heat setting, suitable for your clothing item. Check the composition label for guidance and then adjust the iron accordingly.',
                    },
                    {
                        text_raw:
                            'Gently press the iron onto the cloth-covered patch, applying even pressure.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Gently press the iron onto the cloth-covered patch, applying even pressure.',
                    },
                    {
                        text_raw: 'Move the iron in circular motions for about 15-30 seconds.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Move the iron in circular motions for about 15-30 seconds.',
                    },
                ],
                guideid: 169514,
                stepid: 359298,
                orderby: 3,
                revisionid: 3333955,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222459,
                            guid: 'plcCSB3nFDRtn6Zf',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/plcCSB3nFDRtn6Zf.full',
                        },
                        {
                            id: 3222460,
                            guid: 'yESR4b4K2NLLZIkr',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/yESR4b4K2NLLZIkr.full',
                        },
                        {
                            id: 3222461,
                            guid: 'RMPvsKIplPnwVuEq',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/RMPvsKIplPnwVuEq.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Allow to cool',
                lines: [
                    {
                        text_raw: 'After ironing, let the patch cool for a few minutes.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'After ironing, let the patch cool for a few minutes.',
                    },
                    {
                        text_raw: 'Once cooled, carefully check that the badge is properly fixed.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Once cooled, carefully check that the badge is properly fixed.',
                    },
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                    {
                        text_raw:
                            "''... however! If you'd like to make the repair extra secure follow the next two steps too.''",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "<em>... however! If you'd like to make the repair extra secure follow the next two steps too.</em>",
                    },
                ],
                guideid: 169514,
                stepid: 359299,
                orderby: 4,
                revisionid: 3333957,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222465,
                            guid: 'NUyQUMc3KSCOSNtx',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/NUyQUMc3KSCOSNtx.full',
                        },
                        {
                            id: 3222467,
                            guid: 'qAvA1Zn4nxSXXPX3',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/qAvA1Zn4nxSXXPX3.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Optional: Add a patch on the outside',
                lines: [
                    {
                        text_raw:
                            'If you want to reinforce this repair, you can choose to add a additional patch on the outside.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'If you want to reinforce this repair, you can choose to add a additional patch on the outside.',
                    },
                    {
                        text_raw:
                            'We suggest to cut a shape with rounded corners to improve the fixation and durability.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'We suggest to cut a shape with rounded corners to improve the fixation and durability.',
                    },
                    {
                        text_raw:
                            '***Get creative:*** You might also like to cut a customized shape such as a leaf, bear, or heart. Wear it as a badge of pride, to remember the adventure that caused it!',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Get creative:</strong> You might also like to cut a customized shape such as a leaf, bear, or heart. Wear it as a badge of pride, to remember the adventure that caused it!',
                    },
                ],
                guideid: 169514,
                stepid: 359300,
                orderby: 5,
                revisionid: 3333959,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222475,
                            guid: 'yoNETWkCyI5H1hHl',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/yoNETWkCyI5H1hHl.full',
                        },
                        {
                            id: 3222476,
                            guid: '2ycnH4jpoXSLWEpO',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2ycnH4jpoXSLWEpO.full',
                        },
                        {
                            id: 3222477,
                            guid: 'nwmJWiaKpNI4DxZl',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/nwmJWiaKpNI4DxZl.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169514,
                stepid: 360496,
                orderby: 6,
                revisionid: 3333963,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222483,
                            guid: 'BhfLLeIbsxyZwFTf',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/BhfLLeIbsxyZwFTf.full',
                        },
                        {
                            id: 3222484,
                            guid: 'nGAGMQWTQ2MkTETN',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/nGAGMQWTQ2MkTETN.full',
                        },
                        {
                            id: 3222485,
                            guid: 'MsuCqbmtRVPcaMjV',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MsuCqbmtRVPcaMjV.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Accidents happen, and especially when hiking...',
        time_required: '20 minutes',
        time_required_min: 1200,
        time_required_max: 1200,
        title: 'How to repair a hole in woven textile (using an iron-on patch)',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Iron-on fabric Patches',
                notes: null,
                url: 'https://www.amazon.com/dp/B08429B5DP?psc=1&ref=ppx_yo2ov_dt_b_product_details',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Steam iron',
                notes: null,
                url: 'https://www.amazon.com/PurSteam-Professional-Steam-Iron-Display/dp/B07WJJTBP2/ref=sr_1_3?crid=2JURPKMRWKD8N&keywords=steam%2Biron&qid=1654207995&sprefix=steam%2Biron%2Caps%2C153&sr=8-3&th=1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Ironing board or heat-resistant surface',
                notes: null,
                url: '/Item/Ironing_board_or_heat-resistant_surface',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982986,
        created_date: 1705927836,
        published_date: 1712756640,
        modified_date: 1716982563,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+hole+in+woven+textile+(using+an+iron-on+patch)/169514',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169515: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169515,
        image: {
            id: 3221277,
            guid: 'Hy2HBfnuwDBZ1aGJ',
            mini: 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/Hy2HBfnuwDBZ1aGJ.full',
        },
        introduction_raw:
            "When practicing your sport in summer or winter, it's easy to get a tear in your T-shirt, leggings or jacket. Knitted fabrics are very stretchy and the best way to repair a hole is to sew it by hand, as explained in this guide.",
        introduction_rendered:
            "<p>When practicing your sport in summer or winter, it's easy to get a tear in your T-shirt, leggings or jacket. Knitted fabrics are very stretchy and the best way to repair a hole is to sew it by hand, as explained in this guide.</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the hole',
                lines: [
                    {
                        text_raw:
                            'Lay the woven textile on a flat surface with the tear fully exposed.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Lay the woven textile on a flat surface with the tear fully exposed.',
                    },
                    {
                        text_raw:
                            'Check how large the tear is, where it is on the item, and whether it is fraying at the edges.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Check how large the tear is, where it is on the item, and whether it is fraying at the edges.',
                    },
                    {
                        text_raw:
                            "''Consider: this repair will be visible at the end, so you might wish to use a matching thread colour - ***or*** - you might want to celebrate this memory with some contrast colour thread! Think about how you want the final repair to look before starting.''",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            '<em>Consider: this repair will be visible at the end, so you might wish to use a matching thread colour - <strong>or</strong> - you might want to celebrate this memory with some contrast colour thread! Think about how you want the final repair to look before starting.</em>',
                    },
                ],
                guideid: 169515,
                stepid: 359159,
                orderby: 1,
                revisionid: 3333736,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221281,
                            guid: 'mYFYamNdXV32YIYd',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/mYFYamNdXV32YIYd.full',
                        },
                        {
                            id: 3221282,
                            guid: 'v1h4vnSAw2CFFYoe',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/v1h4vnSAw2CFFYoe.full',
                        },
                        {
                            id: 3221283,
                            guid: 'JQlfsPjgX3wyABZ1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/JQlfsPjgX3wyABZ1.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare the needle and start sewing',
                lines: [
                    {
                        text_raw: 'Prepare the needle with the thread.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Prepare the needle with the thread.',
                    },
                    {
                        text_raw:
                            'Turn the defective product inside-out, and pinch the broken edges together, so that they are facing towards you.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Turn the defective product inside-out, and pinch the broken edges together, so that they are facing towards you.',
                    },
                    {
                        text_raw:
                            'Pass the needle through both pieces of fabric, starting at one end of the hole. Tie a knot to secure it, and pull gently. Be careful not to bunch the fabric, especially if it is lightweight and thin.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Pass the needle through both pieces of fabric, starting at one end of the hole. Tie a knot to secure it, and pull gently. Be careful not to bunch the fabric, especially if it is lightweight and thin.',
                    },
                ],
                guideid: 169515,
                stepid: 359303,
                orderby: 2,
                revisionid: 3333742,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221288,
                            guid: 'FURxTGXh5HgtGgGw',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/FURxTGXh5HgtGgGw.full',
                        },
                        {
                            id: 3221289,
                            guid: 'uaTHH4eBbKytmkMA',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/uaTHH4eBbKytmkMA.full',
                        },
                        {
                            id: 3221290,
                            guid: '4iPAv5DS4KSWfUpx',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/4iPAv5DS4KSWfUpx.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Continue sewingalong the hole',
                lines: [
                    {
                        text_raw:
                            'Continue sewing along the hole with a regular sewing technique; passing the needle through both pieces of fabric from one side, then from next side, and so on.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Continue sewing along the hole with a regular sewing technique; passing the needle through both pieces of fabric from one side, then from next side, and so on.',
                    },
                    {
                        text_raw:
                            'Work your way along the seam until the entire hole is closed. Before Making a knot, check whether you think the repair is strong enough - if not, repeat the sewing procces back along the hole to the starting point.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Work your way along the seam until the entire hole is closed. Before Making a knot, check whether you think the repair is strong enough - if not, repeat the sewing procces back along the hole to the starting point.',
                    },
                    {
                        text_raw: 'Make a knot at the end of the repair.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Make a knot at the end of the repair.',
                    },
                ],
                guideid: 169515,
                stepid: 359304,
                orderby: 3,
                revisionid: 3333751,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221296,
                            guid: '5uhyLDnFHOV2EDl1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/5uhyLDnFHOV2EDl1.full',
                        },
                        {
                            id: 3221297,
                            guid: 'XrkAaXAkZ2BMHXRS',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/XrkAaXAkZ2BMHXRS.full',
                        },
                        {
                            id: 3221298,
                            guid: 'HdrKXvCjKjqRwCAS',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HdrKXvCjKjqRwCAS.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw: 'Make a final test of the repair by lightly stretching the area.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Make a final test of the repair by lightly stretching the area.',
                    },
                    {
                        text_raw: 'Trim off the excess thread',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Trim off the excess thread',
                    },
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169515,
                stepid: 359305,
                orderby: 4,
                revisionid: 3333756,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221302,
                            guid: 'PsTEfhwBqT5iwMua',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/PsTEfhwBqT5iwMua.full',
                        },
                        {
                            id: 3221303,
                            guid: 'CgJaJaW5XLORSgmU',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/CgJaJaW5XLORSgmU.full',
                        },
                        {
                            id: 3221304,
                            guid: 'bKUUWvL6aRhc5gEu',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/bKUUWvL6aRhc5gEu.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'When practicing your sport in summer or winter,...',
        time_required: '20 minutes',
        time_required_min: 1200,
        time_required_max: 1200,
        title: 'How to repair a hole in knitted textile (hand stitching)',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Thread',
                notes: null,
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Fabric Scissors',
                notes: null,
                url: 'http://www.amazon.com/Gingher-8-Inch-Knife-Dressmakers-Shears/dp/B000UU6SR4/',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/X6YchoNrn3JofSsY.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Hand Sewing Needle',
                notes: null,
                url: 'https://www.amazon.com/SINGER-Large-Needles-Magnetic-Card-12/dp/B002I35QMO/ref=sxin_0_ac_d_rm?crid=2D44X2L0TRTDM&keywords=sewing+needles+hand&pd_rd_i=B002I35QMO&pd_rd_r=7dfc4a4d-85d9-4fa9-bae0-e641c5d5fa59&pd_rd_w=TG8zg&pd_rd_wg=IkY1h&pf_rd_p=91b604bb-c371-4573-970f-bed68a552852&pf_rd_r=14CZ6X5JHRAM9W311PP0&qid=1559661764&s=gateway&sprefix=sewing+needle%2Caps%2C148',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/qXEyJJAuKEktP1Ha.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1994851,
        created_date: 1705927898,
        published_date: 1708075905,
        modified_date: 1716982522,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+hole+in+knitted+textile+(hand+stitching)/169515',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169517: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169517,
        image: {
            id: 3221307,
            guid: 'IjmGvIxGPvQVM5uC',
            mini: 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/IjmGvIxGPvQVM5uC.full',
        },
        introduction_raw:
            "During sport, the hem of your trousers or jacket sleeves may become damaged and frayed. This will often get worse if not fixed, so don't let it get to that stage! This guide is one of two options for fixing this defect, and which does not shorten the length of your item. If you'd like to check the other option, please follow this link: [link|https://www.ifixit.com/Guide/How+to+repair+a+trouser-cuff+hem+(by+shortening)/169526|How to repair a trouser/cuff hem (by shortening)|new_window=true].",
        introduction_rendered:
            '<p>During sport, the hem of your trousers or jacket sleeves may become damaged and frayed. This will often get worse if not fixed, so don\'t let it get to that stage! This guide is one of two options for fixing this defect, and which does not shorten the length of your item. If you\'d like to check the other option, please follow this link: <a href="https://www.ifixit.com/Guide/How+to+repair+a+trouser-cuff+hem+(by+shortening)/169526" target="_blank">How to repair a trouser/cuff hem (by shortening)</a>.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the defect',
                lines: [
                    {
                        text_raw: 'Identify the defective area and assess how large the damage is.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Identify the defective area and assess how large the damage is.',
                    },
                ],
                guideid: 169517,
                stepid: 359160,
                orderby: 1,
                revisionid: 3332756,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221316,
                            guid: '4KkRPSBfluFKPqFF',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/4KkRPSBfluFKPqFF.full',
                        },
                        {
                            id: 3221317,
                            guid: '1K51QBhdVKiStyxF',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/1K51QBhdVKiStyxF.full',
                        },
                        {
                            id: 3221318,
                            guid: 'R2ZNrFFCOmmXJfKu',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/R2ZNrFFCOmmXJfKu.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare the stitching',
                lines: [
                    {
                        text_raw:
                            'Cut away any damaged thread around the hole, to create a clean surface to repair.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Cut away any damaged thread around the hole, to create a clean surface to repair.',
                    },
                    {
                        text_raw:
                            "***Take care: ***don't cut away fabric, as this will shorten the repair.",
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            "<strong>Take care: </strong>don't cut away fabric, as this will shorten the repair.",
                    },
                    {
                        text_raw:
                            'Turn the edges of the hole inside, so that they are tucked inside the hole.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Turn the edges of the hole inside, so that they are tucked inside the hole.',
                    },
                ],
                guideid: 169517,
                stepid: 359307,
                orderby: 2,
                revisionid: 3332758,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221322,
                            guid: 'uJDIAAP2nIiNuHeD',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/uJDIAAP2nIiNuHeD.full',
                        },
                        {
                            id: 3221323,
                            guid: 'qsjubA4ZE4joJNpO',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/qsjubA4ZE4joJNpO.full',
                        },
                        {
                            id: 3221324,
                            guid: 'eBPKLVlGiEbqHxqM',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/eBPKLVlGiEbqHxqM.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Sew the repair',
                lines: [
                    {
                        text_raw: 'Pin the folded parts of the hole securely in place.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Pin the folded parts of the hole securely in place.',
                    },
                    {
                        text_raw: 'Prepare the sewing machine and offer up the repaired area.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Prepare the sewing machine and offer up the repaired area.',
                    },
                    {
                        text_raw:
                            '***Tip: ***For tips on setting up the machine take a look at [guide|19463|this iFixit guide|new_window=true].',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>For tips on setting up the machine take a look at <a href="https://www.ifixit.com/Guide/Thread+a+Sewing+Machine/19463" target="_blank">this iFixit guide</a>.',
                    },
                    {
                        text_raw:
                            'Depress the sewing machine foot and remove any pins in the way of the sewing route. Start sewing a straight line from one side of the hole to the other, close to the edge of the hem, and add a lock stitch at the beginning and end.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Depress the sewing machine foot and remove any pins in the way of the sewing route. Start sewing a straight line from one side of the hole to the other, close to the edge of the hem, and add a lock stitch at the beginning and end.',
                    },
                ],
                guideid: 169517,
                stepid: 359308,
                orderby: 3,
                revisionid: 3374856,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221328,
                            guid: 'fHTWoQZyNesoCkKT',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/fHTWoQZyNesoCkKT.full',
                        },
                        {
                            id: 3221330,
                            guid: 'uZGnpIMDJYkK5DPl',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/uZGnpIMDJYkK5DPl.full',
                        },
                        {
                            id: 3221331,
                            guid: 'IIEEKdUIG3tmkhfd',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/IIEEKdUIG3tmkhfd.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            'Finish the sewing and cut the loose thread to make a neat finish.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Finish the sewing and cut the loose thread to make a neat finish.',
                    },
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169517,
                stepid: 359309,
                orderby: 4,
                revisionid: 3332775,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221336,
                            guid: 'ugJRatGo6IRChJYB',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ugJRatGo6IRChJYB.full',
                        },
                        {
                            id: 3221337,
                            guid: 'BX5pBoKNOk15rkQS',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/BX5pBoKNOk15rkQS.full',
                        },
                        {
                            id: 3221338,
                            guid: 'rm1HYQaYnoteaiS2',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/rm1HYQaYnoteaiS2.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'During sport, the hem of your trousers or...',
        time_required: '10 minutes',
        time_required_min: 600,
        time_required_max: 600,
        title: 'How to repair a trouser/cuff hem (without shortening)',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Thread',
                notes: null,
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Scissors',
                notes: null,
                url: 'https://eustore.ifixit.com/products/utility-scissors',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/YZFc6xfCFTuHFhsP.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982630,
        created_date: 1705929060,
        published_date: 1712673753,
        modified_date: 1716982655,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+trouser-cuff+hem+(without+shortening)/169517',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169519: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169519,
        image: {
            id: 3234317,
            guid: 'HCxv1W2WbGan11nb',
            mini: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.full',
        },
        introduction_raw:
            "Over time, the zipper puller of your item might get damaged or broken. This doesn't mean the end, and it doesn't need to be a difficult repair or replacement of the entire zip! In this guide, we will demonstrate a simple and practical method for replacing the zipper slider, and keeping your favourite item going.",
        introduction_rendered:
            "<p>Over time, the zipper puller of your item might get damaged or broken. This doesn't mean the end, and it doesn't need to be a difficult repair or replacement of the entire zip! In this guide, we will demonstrate a simple and practical method for replacing the zipper slider, and keeping your favourite item going.</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the problem',
                lines: [
                    {
                        text_raw:
                            'Inspect your product to understand the way the zipper has been constructed, and what the defect is.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Inspect your product to understand the way the zipper has been constructed, and what the defect is.',
                    },
                    {
                        text_raw:
                            'In this example, the zipper sliding component is defective, and cannot slide properly anymore. It needs to be replaced.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'In this example, the zipper sliding component is defective, and cannot slide properly anymore. It needs to be replaced.',
                    },
                    {
                        text_raw:
                            '***Note:*** at this stage, it is important to determine which part of the zipper is broken. If there is something wrong with the zipper teeth (i.e., one tooth has snapped off) then this guide will not help solve the problem',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Note:</strong> at this stage, it is important to determine which part of the zipper is broken. If there is something wrong with the zipper teeth (i.e., one tooth has snapped off) then this guide will not help solve the problem',
                    },
                ],
                guideid: 169519,
                stepid: 359162,
                orderby: 1,
                revisionid: 3334950,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234317,
                            guid: 'HCxv1W2WbGan11nb',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HCxv1W2WbGan11nb.full',
                        },
                        {
                            id: 3234318,
                            guid: 'tFo2NG3WvirdjPJd',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/tFo2NG3WvirdjPJd.full',
                        },
                        {
                            id: 3234319,
                            guid: 'o4HITiQbGLolKm1G',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/o4HITiQbGLolKm1G.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Remove the zipper "top stop"',
                lines: [
                    {
                        text_raw:
                            'To perform this repair, we will first remove the "top stop" of the zipper. This is piece of plastic at the top of the zipper, which is slightly larger than the other zipper teeth.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'To perform this repair, we will first remove the &quot;top stop&quot; of the zipper. This is piece of plastic at the top of the zipper, which is slightly larger than the other zipper teeth.',
                    },
                    {
                        text_raw:
                            '***Important:*** This procedure will permanently remove this part of the zipper and we will replace it with a thick knot instead. Do not perform this repair if you do not wish to cause this type of damage/repair.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Important:</strong> This procedure will permanently remove this part of the zipper and we will replace it with a thick knot instead. Do not perform this repair if you do not wish to cause this type of damage/repair.',
                    },
                    {
                        text_raw:
                            'Use sturdy scissors, or preferably pliers, to remove the top stop. Take care not to damage the surrounding material or zipper teeth; and take care with your fingers!',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Use sturdy scissors, or preferably pliers, to remove the top stop. Take care not to damage the surrounding material or zipper teeth; and take care with your fingers!',
                    },
                    {
                        text_raw:
                            '***Note: ***For this guide, we have demonstrated on a plastic top-stop. Some zippers may have a metal top-stop, which can be carefully removed by un-bending, and re-attached at the end.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Note: </strong>For this guide, we have demonstrated on a plastic top-stop. Some zippers may have a metal top-stop, which can be carefully removed by un-bending, and re-attached at the end.',
                    },
                    {
                        text_raw:
                            'Once the top stop is removed, keep it to one side, for referring to later when making the replacement knot.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Once the top stop is removed, keep it to one side, for referring to later when making the replacement knot.',
                    },
                ],
                guideid: 169519,
                stepid: 359312,
                orderby: 2,
                revisionid: 3334952,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234325,
                            guid: 'jwTT4ZXpOOJguVTC',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/jwTT4ZXpOOJguVTC.full',
                        },
                        {
                            id: 3234326,
                            guid: 'NAymaRrXPb6X2P5H',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/NAymaRrXPb6X2P5H.full',
                        },
                        {
                            id: 3234327,
                            guid: 'VNhSNb2Xh2HfJLkF',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/VNhSNb2Xh2HfJLkF.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Remove the defective slider',
                lines: [
                    {
                        text_raw:
                            'Slide the defective slider up to the top of the zipper, and remove it.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Slide the defective slider up to the top of the zipper, and remove it.',
                    },
                ],
                guideid: 169519,
                stepid: 361529,
                orderby: 3,
                revisionid: 3334955,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234332,
                            guid: 'ZnoZ2y3Ox3iQg3jw',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ZnoZ2y3Ox3iQg3jw.full',
                        },
                        {
                            id: 3234333,
                            guid: 'LHSxJvRwYfNM2YDX',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LHSxJvRwYfNM2YDX.full',
                        },
                        {
                            id: 3234334,
                            guid: 'iyfwFVHtaoGuNWrw',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/iyfwFVHtaoGuNWrw.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Replace the zip slider',
                lines: [
                    {
                        text_raw:
                            'Remove any pullers/toggles/fasteners from the zipper puller, and set them aside. These can be reused later.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Remove any pullers/toggles/fasteners from the zipper puller, and set them aside. These can be reused later.',
                    },
                    {
                        text_raw:
                            'Find a new slider, matching with the zip tape size. Craft shops, sewing shops, or even second-hand shops are great places to find these.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Find a new slider, matching with the zip tape size. Craft shops, sewing shops, or even second-hand shops are great places to find these.',
                    },
                    {
                        text_raw:
                            '***Tip: *** to determine the zipper puller size, check for the number on the backside. For more information, check out the iFixit guide   [[Plastic Tooth Zipper Size|Plastic Tooth Zipper Size|new_window=true]]',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong> to determine the zipper puller size, check for the number on the backside. For more information, check out the iFixit guide   <a href="https://www.ifixit.com/Wiki/Plastic_Tooth_Zipper_Size" target="_blank">Plastic Tooth Zipper Size</a>',
                    },
                    {
                        text_raw:
                            'Take your new zipper puller, and insert it back onto the zip from the topside...',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Take your new zipper puller, and insert it back onto the zip from the topside...',
                    },
                    {
                        text_raw: '... and pull it down to the middle of the zip.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: '... and pull it down to the middle of the zip.',
                    },
                ],
                guideid: 169519,
                stepid: 359313,
                orderby: 4,
                revisionid: 3372057,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234339,
                            guid: 'MUSqCSIDacMwaHbJ',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MUSqCSIDacMwaHbJ.full',
                        },
                        {
                            id: 3234340,
                            guid: 'WplHlUGsnN52PQBp',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/WplHlUGsnN52PQBp.full',
                        },
                        {
                            id: 3234341,
                            guid: 'wLS4f4P1KfokWwFa',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/wLS4f4P1KfokWwFa.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Tie a new "top stop" knot',
                lines: [
                    {
                        text_raw:
                            'Because we removed the plastic top stop, we need to create a new one, which we will do by tying a thick knot. For this, we will use a sewing needle and thick thread. The knot must be thick enough to stop the slider at the tape end again.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Because we removed the plastic top stop, we need to create a new one, which we will do by tying a thick knot. For this, we will use a sewing needle and thick thread. The knot must be thick enough to stop the slider at the tape end again.',
                    },
                    {
                        text_raw:
                            '***Get creative: ***this knot will be visible, so might choose a matching thread colour to be less noticeable; or a bright contract colour to celebrate your repair!',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Get creative: </strong>this knot will be visible, so might choose a matching thread colour to be less noticeable; or a bright contract colour to celebrate your repair!',
                    },
                    {
                        text_raw:
                            'Start by putting the needle through the zipper tape, and making a standard hand-sewing knot. Then, keep repeating the process of sewing through the tape, to build a thicker and thicker bundle of thread.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Start by putting the needle through the zipper tape, and making a standard hand-sewing knot. Then, keep repeating the process of sewing through the tape, to build a thicker and thicker bundle of thread.',
                    },
                    {
                        text_raw:
                            "***Tip: ***periodically pull the zipper up to the repair, and gently test if the thread bundle is large enough to stop it coming off. If it's still too small, keep adding more thread.",
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            "<strong>Tip: </strong>periodically pull the zipper up to the repair, and gently test if the thread bundle is large enough to stop it coming off. If it's still too small, keep adding more thread.",
                    },
                    {
                        text_raw:
                            'Once the thread bundle is large enough to prevent the zipper puller from coming off, make a final securing knot, and cut the excess thread away.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Once the thread bundle is large enough to prevent the zipper puller from coming off, make a final securing knot, and cut the excess thread away.',
                    },
                ],
                guideid: 169519,
                stepid: 359314,
                orderby: 5,
                revisionid: 3334961,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234346,
                            guid: 'YaUO1LmVKQPVchyV',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/YaUO1LmVKQPVchyV.full',
                        },
                        {
                            id: 3234347,
                            guid: 'FkrgsXNXr4gcnMM6',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/FkrgsXNXr4gcnMM6.full',
                        },
                        {
                            id: 3234348,
                            guid: 'qCcWuhC3mCSPscXj',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/qCcWuhC3mCSPscXj.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Replace the zip puller',
                lines: [
                    {
                        text_raw:
                            'The thread bundle is now complete, and will stop the new slider from coming off.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'The thread bundle is now complete, and will stop the new slider from coming off.',
                    },
                    {
                        text_raw:
                            'Re-attach any additional strong/thread/loops which were on the original zipper puller (here, we re-attached the pulling string).',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Re-attach any additional strong/thread/loops which were on the original zipper puller (here, we re-attached the pulling string).',
                    },
                    {
                        text_raw:
                            'Check that the repair functions correctly by pulling the zip and and down. Put on the item and test it again.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Check that the repair functions correctly by pulling the zip and and down. Put on the item and test it again.',
                    },
                ],
                guideid: 169519,
                stepid: 359315,
                orderby: 6,
                revisionid: 3334963,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234352,
                            guid: 'E31ccN2ZrZCWxxIc',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/E31ccN2ZrZCWxxIc.full',
                        },
                        {
                            id: 3234353,
                            guid: 'bxEMa2fnUoSovpQL',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/bxEMa2fnUoSovpQL.full',
                        },
                        {
                            id: 3234354,
                            guid: 'lPbLaEvfFYmN2CZy',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/lPbLaEvfFYmN2CZy.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169519,
                stepid: 361530,
                orderby: 7,
                revisionid: 3334966,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234357,
                            guid: 'cr2qfIXDFCHBxkZo',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/cr2qfIXDFCHBxkZo.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Over time, the zipper puller of your item might...',
        time_required: '30 minutes',
        time_required_min: 1800,
        time_required_max: 1800,
        title: 'How to replace a zipper slider',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Thread',
                notes: null,
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Hand Sewing Needle',
                notes: null,
                url: 'https://www.amazon.com/dp/B002PI751C',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/FLCpkwpfQ5rCtj6L.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Scissors',
                notes: null,
                url: 'https://eustore.ifixit.com/products/utility-scissors',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/YZFc6xfCFTuHFhsP.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'new zip slider',
                notes: null,
                url: '/Item/new_zip_slider',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982609,
        created_date: 1705929140,
        published_date: 1712656527,
        modified_date: 1716982694,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+replace+a+zipper+slider/169519',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169520: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169520,
        image: {
            id: 3221242,
            guid: 'Lws1WjrIFJOjQH5J',
            mini: 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/Lws1WjrIFJOjQH5J.full',
        },
        introduction_raw:
            "During sport, a strong stretch can damage the stitching on your jacket or T-shirt. Some seams may break under this heavy strain. Don't worry, they can be easily repaired with a sewing machine and you will be able to continue wearing your favorite outfit for more and more sports to come.",
        introduction_rendered:
            "<p>During sport, a strong stretch can damage the stitching on your jacket or T-shirt. Some seams may break under this heavy strain. Don't worry, they can be easily repaired with a sewing machine and you will be able to continue wearing your favorite outfit for more and more sports to come.</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the damaged seam',
                lines: [
                    {
                        text_raw: 'Locate the damaged area of your item.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Locate the damaged area of your item.',
                    },
                    {
                        text_raw:
                            'Check how it looks on the outside, and whether it is spreading further by gently pulling the seam.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Check how it looks on the outside, and whether it is spreading further by gently pulling the seam.',
                    },
                    {
                        text_raw:
                            'Also check how the damage looks on the inside, and take note of the type of stitching, and fabric condition around the seam.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Also check how the damage looks on the inside, and take note of the type of stitching, and fabric condition around the seam.',
                    },
                ],
                guideid: 169520,
                stepid: 359163,
                orderby: 1,
                revisionid: 3332652,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221246,
                            guid: 'xRcV5TJ2ASLNDRdB',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/xRcV5TJ2ASLNDRdB.full',
                        },
                        {
                            id: 3221247,
                            guid: 'nCWqvcpqWSruQZx3',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/nCWqvcpqWSruQZx3.full',
                        },
                        {
                            id: 3221248,
                            guid: 'hPQG3qjmSx3WkC5S',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/hPQG3qjmSx3WkC5S.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare & repair',
                lines: [
                    {
                        text_raw:
                            'Prepare and clean the area around the damaged seam by removing broken thread and trimming any loose threads.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Prepare and clean the area around the damaged seam by removing broken thread and trimming any loose threads.',
                    },
                    {
                        text_raw: 'Prepare the damaged seam by placing the fabric edge to edge.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Prepare the damaged seam by placing the fabric edge to edge.',
                    },
                    {
                        text_raw:
                            'Place the item under the sewing machine, and make a short back-and-forth lockstitch using the reverse lever, to secure one end. Then proceed to make a single long stitch along the seam until the end of the damage. Secure at the end with another locking stitch. Inspect the repair, and if it requires another long stitch, repeat the process.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Place the item under the sewing machine, and make a short back-and-forth lockstitch using the reverse lever, to secure one end. Then proceed to make a single long stitch along the seam until the end of the damage. Secure at the end with another locking stitch. Inspect the repair, and if it requires another long stitch, repeat the process.',
                    },
                    {
                        text_raw:
                            '***Tip: ***check the iFixit [[Topic:Basic Sewing|basic sewing|new_window=true]] skills page for more tips on sewing machine setup.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>check the iFixit <a href="https://www.ifixit.com/Topic/Basic_Sewing" target="_blank">basic sewing</a> skills page for more tips on sewing machine setup.',
                    },
                ],
                guideid: 169520,
                stepid: 359316,
                orderby: 2,
                revisionid: 3369741,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221265,
                            guid: 'R1v2C6NaAKXywC1e',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/R1v2C6NaAKXywC1e.full',
                        },
                        {
                            id: 3221266,
                            guid: 'TtdJb6REPINjvlEt',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/TtdJb6REPINjvlEt.full',
                        },
                        {
                            id: 3221267,
                            guid: 'qBHBAa61RQvoNQVp',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/qBHBAa61RQvoNQVp.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169520,
                stepid: 359317,
                orderby: 3,
                revisionid: 3332656,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221258,
                            guid: 'lKZxGG3ILPGYTJkm',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/lKZxGG3ILPGYTJkm.full',
                        },
                        {
                            id: 3221259,
                            guid: 'LLQVAGmuOpJbrn4B',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LLQVAGmuOpJbrn4B.full',
                        },
                        {
                            id: 3221260,
                            guid: 'NKeVS4HNrRJ2XbUX',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/NKeVS4HNrRJ2XbUX.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'During sport, a strong stretch can damage the...',
        time_required: '20 minutes',
        time_required_min: 1200,
        time_required_max: 1200,
        title: 'How to repair a broken seam',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Thread',
                notes: null,
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Fabric Scissors',
                notes: null,
                url: 'http://www.amazon.com/Gingher-8-Inch-Knife-Dressmakers-Shears/dp/B000UU6SR4/',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/X6YchoNrn3JofSsY.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982595,
        created_date: 1705929191,
        published_date: 1712663119,
        modified_date: 1716982483,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+broken+seam/169520',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169521: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Very easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169521,
        image: {
            id: 3221118,
            guid: 'ChjQeDLNrHgxYWAl',
            mini: 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/ChjQeDLNrHgxYWAl.full',
        },
        introduction_raw:
            "After repeated use or washing of waterproof sports item, taped seams can become damaged as the glue wears away. This can reduce the waterproof performance and you may feel that it's time to buy a new item - but repair is possible! This guide will show how to repair defective taped seams, so that you can continue enjoying going outside in wet weather.",
        introduction_rendered:
            "<p>After repeated use or washing of waterproof sports item, taped seams can become damaged as the glue wears away. This can reduce the waterproof performance and you may feel that it's time to buy a new item - but repair is possible! This guide will show how to repair defective taped seams, so that you can continue enjoying going outside in wet weather.</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Preparation',
                lines: [
                    {
                        text_raw:
                            'Identify the defective taped seam, and the extent of the damage. Is it only a small area peeling up, or something larger?',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Identify the defective taped seam, and the extent of the damage. Is it only a small area peeling up, or something larger?',
                    },
                    {
                        text_raw:
                            '***Note: ***We will be using a hot iron to re-weld the taped seam, so prepare a tidy area matching the size of your repair.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Note: </strong>We will be using a hot iron to re-weld the taped seam, so prepare a tidy area matching the size of your repair.',
                    },
                    {
                        text_raw:
                            'Lay a heatproof material underneath the item, such as an ironing board, or ironing board cover. Set the iron at 150 °C maximum.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Lay a heatproof material underneath the item, such as an ironing board, or ironing board cover. Set the iron at 150 °C maximum.',
                    },
                ],
                guideid: 169521,
                stepid: 359164,
                orderby: 1,
                revisionid: 3377691,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221122,
                            guid: '2d1BwhBvMnUSGFZ3',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2d1BwhBvMnUSGFZ3.full',
                        },
                        {
                            id: 3221123,
                            guid: '6jWePDBoD1Zc412S',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/6jWePDBoD1Zc412S.full',
                        },
                        {
                            id: 3221124,
                            guid: '2BjQfsqnD2lCmQAx',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2BjQfsqnD2lCmQAx.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare the hot-melt tape',
                lines: [
                    {
                        text_raw:
                            "***Note: *** In this guide, we assume that the tape cannot be re-welded due to the glue being damaged or worn-out. But if you want to make as test first, move to the next step ''without ''adding extra thermo-tape.",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            '<strong>Note: </strong> In this guide, we assume that the tape cannot be re-welded due to the glue being damaged or worn-out. But if you want to make as test first, move to the next step <em>without </em>adding extra thermo-tape.',
                    },
                    {
                        text_raw:
                            'Prepare the hot-melt tape by cutting a small piece to the same size as the defect. The tape should fit underneath the damaged tape, so that none is protruding out from the edges',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Prepare the hot-melt tape by cutting a small piece to the same size as the defect. The tape should fit underneath the damaged tape, so that none is protruding out from the edges',
                    },
                    {
                        text_raw:
                            '***Tip: ***we have used a common form of curtain tape here, as it is widely available; but more advanced tapes are also available from sewing/craft stores.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>we have used a common form of curtain tape here, as it is widely available; but more advanced tapes are also available from sewing/craft stores.',
                    },
                ],
                guideid: 169521,
                stepid: 359318,
                orderby: 2,
                revisionid: 3332649,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221131,
                            guid: 'yMQSh3n4mte3lBIy',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/yMQSh3n4mte3lBIy.full',
                        },
                        {
                            id: 3221132,
                            guid: 'OVFJEPZFhAMsEkCH',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/OVFJEPZFhAMsEkCH.full',
                        },
                        {
                            id: 3221133,
                            guid: 'pMZyEXDDiEZLafIv',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/pMZyEXDDiEZLafIv.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Iron the taped seam',
                lines: [
                    {
                        text_raw:
                            "''We will now use the head from the iron to melt the hot-melt tape in between the broken seam layers, to bond them together again.''",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            '<em>We will now use the head from the iron to melt the hot-melt tape in between the broken seam layers, to bond them together again.</em>',
                    },
                    {
                        text_raw:
                            'Carefully check the tape is underneath the old taped seam and not protruding out from the edges. Position the heated iron on top of this area, and apply light pressure, for up to 10 seconds.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Carefully check the tape is underneath the old taped seam and not protruding out from the edges. Position the heated iron on top of this area, and apply light pressure, for up to 10 seconds.',
                    },
                    {
                        text_raw:
                            'Let the area cool down, and then check whether the hot-melt tape has melted and bonded the defect successfully. If it has not, apply the iron again, and increase the pressure a little. Continue this process until it is fully glued down.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Let the area cool down, and then check whether the hot-melt tape has melted and bonded the defect successfully. If it has not, apply the iron again, and increase the pressure a little. Continue this process until it is fully glued down.',
                    },
                    {
                        text_raw:
                            '***Tip: ***you may find in this process, that some extra hot-melt tape is needed in some areas. simply cut some more small pieces and slide them between the seam, and repeat the steps above.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>you may find in this process, that some extra hot-melt tape is needed in some areas. simply cut some more small pieces and slide them between the seam, and repeat the steps above.',
                    },
                ],
                guideid: 169521,
                stepid: 360409,
                orderby: 3,
                revisionid: 3332657,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221137,
                            guid: 'lAELfEcCjWhLftqH',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/lAELfEcCjWhLftqH.full',
                        },
                        {
                            id: 3221138,
                            guid: 'STQOmibXTyVOsV5g',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/STQOmibXTyVOsV5g.full',
                        },
                        {
                            id: 3221139,
                            guid: 'PT6GifwWJLrsm1AY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/PT6GifwWJLrsm1AY.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169521,
                stepid: 360410,
                orderby: 4,
                revisionid: 3332660,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221141,
                            guid: '5ltPT2vJOgflABZi',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/5ltPT2vJOgflABZi.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'After repeated use or washing of waterproof...',
        time_required: '20 minutes',
        time_required_min: 1200,
        time_required_max: 1200,
        title: 'How to repair a taped seam',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Steam iron',
                notes: null,
                url: 'https://www.amazon.com/PurSteam-Professional-Steam-Iron-Display/dp/B07WJJTBP2/ref=sr_1_3?crid=2JURPKMRWKD8N&keywords=steam%2Biron&qid=1654207995&sprefix=steam%2Biron%2Caps%2C153&sr=8-3&th=1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'thermo glue tape',
                notes: null,
                url: '/Item/thermo_glue_tape',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Scissors',
                notes: null,
                url: 'https://eustore.ifixit.com/products/utility-scissors',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/YZFc6xfCFTuHFhsP.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982605,
        created_date: 1705929216,
        published_date: 1712666398,
        modified_date: 1716982597,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+taped+seam/169521',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169522: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169522,
        image: {
            id: 3221480,
            guid: 'fs3sMTpSdETipqlT',
            mini: 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/fs3sMTpSdETipqlT.full',
        },
        introduction_raw:
            "Hook and loop fastenings are quick and convenient for adjusting cuffs and hems on many sportwear items. But sometimes, the fastening is so strong, that repeated use can break the surrounding stitching. Especially on children's items, this can become very frustrating. In this guide, we'll show you how to repair this quickly and easily.",
        introduction_rendered:
            "<p>Hook and loop fastenings are quick and convenient for adjusting cuffs and hems on many sportwear items. But sometimes, the fastening is so strong, that repeated use can break the surrounding stitching. Especially on children's items, this can become very frustrating. In this guide, we'll show you how to repair this quickly and easily.</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the defect',
                lines: [
                    {
                        text_raw:
                            'Check the defective area, and assess how big the repair will need to be.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Check the defective area, and assess how big the repair will need to be.',
                    },
                    {
                        text_raw:
                            'Is the hook & loop part still mostly held in place with the original stitching? If so (like our example) then only a small repair is needed.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Is the hook &amp; loop part still mostly held in place with the original stitching? If so (like our example) then only a small repair is needed.',
                    },
                ],
                guideid: 169522,
                stepid: 359165,
                orderby: 1,
                revisionid: 3332705,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221484,
                            guid: 'UwA4jbZQ4vKsR1si',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/UwA4jbZQ4vKsR1si.full',
                        },
                        {
                            id: 3221485,
                            guid: 'wLZKfYDnlmLEAXwG',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/wLZKfYDnlmLEAXwG.full',
                        },
                        {
                            id: 3221486,
                            guid: 'IlNrWkhs4MHe2wZF',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/IlNrWkhs4MHe2wZF.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare & repair',
                lines: [
                    {
                        text_raw:
                            'Cut away any damaged thread around the defect, to provide a clean base for the repair.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Cut away any damaged thread around the defect, to provide a clean base for the repair.',
                    },
                    {
                        text_raw:
                            'Then, position the item under the sewing machine, and make a small locking stitch with the forward & reverse function.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Then, position the item under the sewing machine, and make a small locking stitch with the forward &amp; reverse function.',
                    },
                    {
                        text_raw:
                            '***Tip: ***use the iFixit [[Topic:Basic Sewing|basic sewing|new_window=true]] resources for more help on sewing machine setup.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>use the iFixit <a href="https://www.ifixit.com/Topic/Basic_Sewing" target="_blank">basic sewing</a> resources for more help on sewing machine setup.',
                    },
                    {
                        text_raw:
                            'Continue sewing around the defective zone, following the line of the original thread.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Continue sewing around the defective zone, following the line of the original thread.',
                    },
                ],
                guideid: 169522,
                stepid: 360420,
                orderby: 2,
                revisionid: 3332712,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221492,
                            guid: 'KGg5SDsa2bkjErKx',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/KGg5SDsa2bkjErKx.full',
                        },
                        {
                            id: 3221493,
                            guid: 'RiINL4rpe5uSTsMY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/RiINL4rpe5uSTsMY.full',
                        },
                        {
                            id: 3221494,
                            guid: 'Wl1fMlRgMcxQq5HB',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Wl1fMlRgMcxQq5HB.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                    {
                        text_raw:
                            "''Note: we have used a contrast thread and a basic sewing pattern to demonstrate this repair. Bu you can have fun with bright colours, or interesting stitch patterns to further embellish your repair.  ''",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            '<em>Note: we have used a contrast thread and a basic sewing pattern to demonstrate this repair. Bu you can have fun with bright colours, or interesting stitch patterns to further embellish your repair.  </em>',
                    },
                ],
                guideid: 169522,
                stepid: 360421,
                orderby: 3,
                revisionid: 3332715,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221499,
                            guid: 'ehoPUMb1puQwVeIo',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ehoPUMb1puQwVeIo.full',
                        },
                        {
                            id: 3221500,
                            guid: 'WyQdE4iSZXUvUEwH',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/WyQdE4iSZXUvUEwH.full',
                        },
                        {
                            id: 3221501,
                            guid: 'xm1UxHIwN2gA2MUS',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/xm1UxHIwN2gA2MUS.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Hook and loop fastenings are quick and...',
        time_required: '10 minutes',
        time_required_min: 600,
        time_required_max: 600,
        title: 'How to repair a hook & loop fastening',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982606,
        created_date: 1705929321,
        published_date: 1712667076,
        modified_date: 1716982575,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+hook+&+loop+fastening/169522',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169524: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Very easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169524,
        image: {
            id: 3216751,
            guid: 'a1GfyJvFKxugsV13',
            mini: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.full',
        },
        introduction_raw:
            "Don't worry about repairing a simple button on your shirt, trousers or any other sport items. They can often break off - but they're very easy to fix it again and again. This guide will show you how to do it with a simple needle and thread.",
        introduction_rendered:
            "<p>Don't worry about repairing a simple button on your shirt, trousers or any other sport items. They can often break off - but they're very easy to fix it again and again. This guide will show you how to do it with a simple needle and thread.</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Prepare the area',
                lines: [
                    {
                        text_raw:
                            'Identify where the button fell out. Clean up the area including removing any old thread which is still there.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Identify where the button fell out. Clean up the area including removing any old thread which is still there.',
                    },
                    {
                        text_raw: 'Prepare the needle and thread, and the replacement button.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Prepare the needle and thread, and the replacement button.',
                    },
                    {
                        text_raw:
                            '***Tip: ***many garments come with a spare button on the inside, sewn to the care label. Check there, before buying a new button.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>many garments come with a spare button on the inside, sewn to the care label. Check there, before buying a new button.',
                    },
                ],
                guideid: 169524,
                stepid: 359167,
                orderby: 1,
                revisionid: 3332217,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216751,
                            guid: 'a1GfyJvFKxugsV13',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/a1GfyJvFKxugsV13.full',
                        },
                        {
                            id: 3216752,
                            guid: 'k4PZUvYP6KjWxgS4',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/k4PZUvYP6KjWxgS4.full',
                        },
                        {
                            id: 3216753,
                            guid: '3VdpVZ1VqsROCekl',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/3VdpVZ1VqsROCekl.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Hand sewing',
                lines: [
                    {
                        text_raw:
                            'Start by threading the needle onto approximately 30cm of pre-cut thread. Next, tie both ends together to create a loop of thread with the needle on it.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Start by threading the needle onto approximately 30cm of pre-cut thread. Next, tie both ends together to create a loop of thread with the needle on it.',
                    },
                    {
                        text_raw:
                            'Add a second or third knot over the first, to make it thicker. It should be large enough to not pull through the fabric.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            'Add a second or third knot over the first, to make it thicker. It should be large enough to not pull through the fabric.',
                    },
                    {
                        text_raw:
                            'Push the needle through the fabric, from the backside of the fabric and pull tight. Thread the needle through one hole of the button, from the backside.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Push the needle through the fabric, from the backside of the fabric and pull tight. Thread the needle through one hole of the button, from the backside.',
                    },
                    {
                        text_raw:
                            'Next, thread the needle back through the other (opposite) hole in the button, and then push through the fabric ++in the same location ++as the thread coming from the backside.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Next, thread the needle back through the other (opposite) hole in the button, and then push through the fabric <ins>in the same location </ins>as the thread coming from the backside.',
                    },
                    {
                        text_raw: 'Repeat the complete Step 2 again, to add extra strength.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered: 'Repeat the complete Step 2 again, to add extra strength.',
                    },
                    {
                        text_raw:
                            "''***Note: ***We have demonstrated on a 2-hole button. For a 4-hole button, simply repeat Step 2 for the other button holes.''",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            '<em><strong>Note: </strong>We have demonstrated on a 2-hole button. For a 4-hole button, simply repeat Step 2 for the other button holes.</em>',
                    },
                ],
                guideid: 169524,
                stepid: 359319,
                orderby: 2,
                revisionid: 3332232,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216754,
                            guid: 'W1LBI2K1ypC2CAkP',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/W1LBI2K1ypC2CAkP.full',
                        },
                        {
                            id: 3216755,
                            guid: 'FZixNlDyXtPrRSx2',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/FZixNlDyXtPrRSx2.full',
                        },
                        {
                            id: 3216756,
                            guid: 'oyXTEXFS5dATdWVS',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/oyXTEXFS5dATdWVS.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Secure with a knot',
                lines: [
                    {
                        text_raw:
                            'Once the button is secure, it is time to finish the repair on the inside, with a knot.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Once the button is secure, it is time to finish the repair on the inside, with a knot.',
                    },
                    {
                        text_raw:
                            'Carefully pass the needle through the loops of thread on the backside of the repair, taking care not to pass it through the fabric itself.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Carefully pass the needle through the loops of thread on the backside of the repair, taking care not to pass it through the fabric itself.',
                    },
                    {
                        text_raw:
                            '***Careful: ***Do not pull tight! Leave a loop large enough to pass the needle through.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Careful: </strong>Do not pull tight! Leave a loop large enough to pass the needle through.',
                    },
                    {
                        text_raw:
                            'Pass the needle through the loop, and now pull tight to create the knot. Repeat this process once more to add a second knot, for extra security.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Pass the needle through the loop, and now pull tight to create the knot. Repeat this process once more to add a second knot, for extra security.',
                    },
                ],
                guideid: 169524,
                stepid: 359320,
                orderby: 3,
                revisionid: 3332236,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216757,
                            guid: '6GbDnAgRkGd5lmHr',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/6GbDnAgRkGd5lmHr.full',
                        },
                        {
                            id: 3216758,
                            guid: 'voTgtRRIsOVYsTEH',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/voTgtRRIsOVYsTEH.full',
                        },
                        {
                            id: 3216759,
                            guid: '2HpGPbUmBoSGYKwb',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2HpGPbUmBoSGYKwb.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169524,
                stepid: 360196,
                orderby: 4,
                revisionid: 3332242,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216760,
                            guid: '4DG3cNRnNbACJf5x',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/4DG3cNRnNbACJf5x.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: "Don't worry about repairing a simple button on...",
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How to replace a button',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Hand Sewing Needle',
                notes: null,
                url: 'https://www.amazon.com/SINGER-Large-Needles-Magnetic-Card-12/dp/B002I35QMO/ref=sxin_0_ac_d_rm?crid=2D44X2L0TRTDM&keywords=sewing+needles+hand&pd_rd_i=B002I35QMO&pd_rd_r=7dfc4a4d-85d9-4fa9-bae0-e641c5d5fa59&pd_rd_w=TG8zg&pd_rd_wg=IkY1h&pf_rd_p=91b604bb-c371-4573-970f-bed68a552852&pf_rd_r=14CZ6X5JHRAM9W311PP0&qid=1559661764&s=gateway&sprefix=sewing+needle%2Caps%2C148',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/qXEyJJAuKEktP1Ha.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Thread',
                notes: null,
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Scissors',
                notes: null,
                url: 'https://eustore.ifixit.com/products/utility-scissors',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/YZFc6xfCFTuHFhsP.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'New button',
                notes: null,
                url: '/Item/New_button',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982623,
        created_date: 1705929369,
        published_date: 1712670709,
        modified_date: 1716982685,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+replace+a+button/169524',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169525: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169525,
        image: {
            id: 3237798,
            guid: 'XCYJCgSWQ34Uyaq4',
            mini: 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/XCYJCgSWQ34Uyaq4.full',
        },
        introduction_raw:
            'Binding hems can break if they get stretched too much, and can easily become more and more frayed. This simple fix can repair the hem quickly and easily.',
        introduction_rendered:
            '<p>Binding hems can break if they get stretched too much, and can easily become more and more frayed. This simple fix can repair the hem quickly and easily.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the defect',
                lines: [
                    {
                        text_raw:
                            'Here, you can see the hole in the binding hem, where the stitching has broken.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Here, you can see the hole in the binding hem, where the stitching has broken.',
                    },
                    {
                        text_raw:
                            'If the binding is undamaged, you can easily repair this defective hem.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'If the binding is undamaged, you can easily repair this defective hem.',
                    },
                ],
                guideid: 169525,
                stepid: 359168,
                orderby: 1,
                revisionid: 3336611,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3237811,
                            guid: 'uYEYbij2G1NXrAee',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/uYEYbij2G1NXrAee.full',
                        },
                        {
                            id: 3237812,
                            guid: 'fCEsMGwyMwtKnjjs',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/fCEsMGwyMwtKnjjs.full',
                        },
                        {
                            id: 3237813,
                            guid: 'Tq1E6eRyYMsFZk1f',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Tq1E6eRyYMsFZk1f.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Tidy up the area',
                lines: [
                    {
                        text_raw: 'Cut away any broken threads, to clean the binding area.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Cut away any broken threads, to clean the binding area.',
                    },
                    {
                        text_raw: 'Then, position the binding properly again along the sleeve hem.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Then, position the binding properly again along the sleeve hem.',
                    },
                ],
                guideid: 169525,
                stepid: 361751,
                orderby: 2,
                revisionid: 3336615,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3237817,
                            guid: 'DQOx2XaSEoM3qTUM',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/DQOx2XaSEoM3qTUM.full',
                        },
                        {
                            id: 3237818,
                            guid: 'kWTkcu1YDf4JoTb1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/kWTkcu1YDf4JoTb1.full',
                        },
                        {
                            id: 3237819,
                            guid: 'YWcBBorXqG4Hgmd1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/YWcBBorXqG4Hgmd1.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Position the hem on the sewing machine',
                lines: [
                    {
                        text_raw:
                            'Remove the wide casing of the sewing machine, and slide the sleeve over the narrow part remaining.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Remove the wide casing of the sewing machine, and slide the sleeve over the narrow part remaining.',
                    },
                    {
                        text_raw:
                            'Carefully line up the hem with the needle, and reposition the binding into the correct place.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Carefully line up the hem with the needle, and reposition the binding into the correct place.',
                    },
                ],
                guideid: 169525,
                stepid: 361752,
                orderby: 3,
                revisionid: 3336619,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3237825,
                            guid: 'BstZr5UnMbgqH5Wy',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/BstZr5UnMbgqH5Wy.full',
                        },
                        {
                            id: 3237826,
                            guid: 'WfY6T11fhpknPylc',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/WfY6T11fhpknPylc.full',
                        },
                        {
                            id: 3237827,
                            guid: 'wpYGrRvT4BAjBVbu',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/wpYGrRvT4BAjBVbu.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Sew carefully',
                lines: [
                    {
                        text_raw:
                            'Start sewing with a smooth tension to ensure the elasticity remains afterwards. Avoid bunching or over stretching the fabric.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Start sewing with a smooth tension to ensure the elasticity remains afterwards. Avoid bunching or over stretching the fabric.',
                    },
                    {
                        text_raw:
                            'Continue carefully until the end of the defective zone, and finish with a locking stitch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Continue carefully until the end of the defective zone, and finish with a locking stitch.',
                    },
                    {
                        text_raw:
                            'Remove the item and check if the repair is holding, and whether the elasticity still stretches correctly.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Remove the item and check if the repair is holding, and whether the elasticity still stretches correctly.',
                    },
                ],
                guideid: 169525,
                stepid: 361755,
                orderby: 4,
                revisionid: 3336624,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3237831,
                            guid: 'PKmGcdsTZbRbBVhs',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/PKmGcdsTZbRbBVhs.full',
                        },
                        {
                            id: 3237833,
                            guid: 'ES22ZoaN2MRwpkcW',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ES22ZoaN2MRwpkcW.full',
                        },
                        {
                            id: 3237834,
                            guid: 'sDUOSF4LAOZdcK1V',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/sDUOSF4LAOZdcK1V.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169525,
                stepid: 361756,
                orderby: 5,
                revisionid: 3336627,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3237839,
                            guid: 'ZIcVFF6BChNoDFN3',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ZIcVFF6BChNoDFN3.full',
                        },
                        {
                            id: 3237840,
                            guid: 'tnPBQPqB6JCXKJGc',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/tnPBQPqB6JCXKJGc.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Binding hems can break if they get stretched...',
        time_required: '10 minutes',
        time_required_min: 600,
        time_required_max: 600,
        title: 'How to repair hem/cuff binding',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Thread',
                notes: null,
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982986,
        created_date: 1705929400,
        published_date: 1712752747,
        modified_date: 1716982662,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+hem-cuff+binding/169525',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169526: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Moderate',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169526,
        image: {
            id: 3214187,
            guid: 'x1AMKuY3TtPkWPKW',
            mini: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.full',
        },
        introduction_raw:
            "During sport, the hem of your trousers or jacket sleeves may become damaged and frayed. This will often get worse if not fixed, so don't let it get to that stage! This guide is one of two options for fixing this defect, and will result in the leg/sleeve becoming shorter. If you prefer not to make the item shorter, please check the other guide: [link|https://www.ifixit.com/Guide/How+to+repair+a+trouser-cuff+hem+(without+shortening)/169517|How to repair a trouser/cuff hem (without shortening)|new_window=true].",
        introduction_rendered:
            '<p>During sport, the hem of your trousers or jacket sleeves may become damaged and frayed. This will often get worse if not fixed, so don\'t let it get to that stage! This guide is one of two options for fixing this defect, and will result in the leg/sleeve becoming shorter. If you prefer not to make the item shorter, please check the other guide: <a href="https://www.ifixit.com/Guide/How+to+repair+a+trouser-cuff+hem+(without+shortening)/169517" target="_blank">How to repair a trouser/cuff hem (without shortening)</a>.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Defective hem',
                lines: [
                    {
                        text_raw: 'Identify the defective area and assess how large the damage is.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Identify the defective area and assess how large the damage is.',
                    },
                ],
                guideid: 169526,
                stepid: 359169,
                orderby: 1,
                revisionid: 3331956,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3214187,
                            guid: 'x1AMKuY3TtPkWPKW',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/x1AMKuY3TtPkWPKW.full',
                        },
                        {
                            id: 3214195,
                            guid: 'DBQ41wFh5wIbcSwT',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/DBQ41wFh5wIbcSwT.full',
                        },
                        {
                            id: 3214189,
                            guid: 'vyRTXUUcfySH3akU',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/vyRTXUUcfySH3akU.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare the hem',
                lines: [
                    {
                        text_raw: 'Use a "seam ripper" tool to unstitch the original hem.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Use a &quot;seam ripper&quot; tool to unstitch the original hem.',
                    },
                    {
                        text_raw: 'Unfold the hem.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Unfold the hem.',
                    },
                    {
                        text_raw: 'Draw line above the defective part that you want to remove.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Draw line above the defective part that you want to remove.',
                    },
                ],
                guideid: 169526,
                stepid: 360200,
                orderby: 2,
                revisionid: 3332260,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216818,
                            guid: 'AtrUDMCiL2xA6ZPb',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/AtrUDMCiL2xA6ZPb.full',
                        },
                        {
                            id: 3216820,
                            guid: 'HULHLxn2jIVYSD15',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HULHLxn2jIVYSD15.full',
                        },
                        {
                            id: 3216822,
                            guid: 'GNSQrfHNroaGpuXv',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/GNSQrfHNroaGpuXv.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Cut and invert the item',
                lines: [
                    {
                        text_raw: 'Cut along the line and set the removed piece aside.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Cut along the line and set the removed piece aside.',
                    },
                    {
                        text_raw: 'Turn the item inside-out.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Turn the item inside-out.',
                    },
                ],
                guideid: 169526,
                stepid: 359321,
                orderby: 3,
                revisionid: 3332268,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216838,
                            guid: 'AHmnAUY3vY2biHBo',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/AHmnAUY3vY2biHBo.full',
                        },
                        {
                            id: 3216839,
                            guid: '1KFJ4Rb3EqrQqbQe',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/1KFJ4Rb3EqrQqbQe.full',
                        },
                        {
                            id: 3216840,
                            guid: 'QYQN3UQW5GgLBdhA',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/QYQN3UQW5GgLBdhA.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Mark out the new hem',
                lines: [
                    {
                        text_raw:
                            'Draw a 1st line 2 cm from the edge -  and a 2nd line with 2cm on top of it.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Draw a 1st line 2 cm from the edge -  and a 2nd line with 2cm on top of it.',
                    },
                    {
                        text_raw: 'Fold the new hem twice on those lines.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Fold the new hem twice on those lines.',
                    },
                ],
                guideid: 169526,
                stepid: 359322,
                orderby: 4,
                revisionid: 3332273,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216846,
                            guid: 'LRZXx1RHA4APpLyZ',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LRZXx1RHA4APpLyZ.full',
                        },
                        {
                            id: 3216847,
                            guid: 'fRqnKLiUkZZ5PRQe',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/fRqnKLiUkZZ5PRQe.full',
                        },
                        {
                            id: 3216848,
                            guid: 'EdeUXtytYJNDZFeD',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/EdeUXtytYJNDZFeD.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Iron and pin the hem',
                lines: [
                    {
                        text_raw: 'Set up the iron on moderate heat without stream',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Set up the iron on moderate heat without stream',
                    },
                    {
                        text_raw: 'Fixed the folded hem by ironing and then with pins.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Fixed the folded hem by ironing and then with pins.',
                    },
                ],
                guideid: 169526,
                stepid: 360203,
                orderby: 5,
                revisionid: 3332276,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216853,
                            guid: 'MaLtPVRSEBFVUHGU',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MaLtPVRSEBFVUHGU.full',
                        },
                        {
                            id: 3216854,
                            guid: 'Dbx426ooAVnjoxaa',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Dbx426ooAVnjoxaa.full',
                        },
                        {
                            id: 3216857,
                            guid: 'VK3wuJ2xvXYDrPXp',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/VK3wuJ2xvXYDrPXp.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Sewing the new hem',
                lines: [
                    {
                        text_raw: 'Use a sewing machine to fix the hem.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Use a sewing machine to fix the hem.',
                    },
                    {
                        text_raw: 'Stitch 2mm from the edge and add a locking stitch at both ends.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Stitch 2mm from the edge and add a locking stitch at both ends.',
                    },
                    {
                        text_raw: 'Stitch the 2nd line parallel with the first.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Stitch the 2nd line parallel with the first.',
                    },
                    {
                        text_raw:
                            'Cut the extra thread to make a clean finished seam. Turn the item right-way-round.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Cut the extra thread to make a clean finished seam. Turn the item right-way-round.',
                    },
                ],
                guideid: 169526,
                stepid: 360204,
                orderby: 6,
                revisionid: 3332284,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216875,
                            guid: 'Ig4jRBLFIGU6gAyC',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Ig4jRBLFIGU6gAyC.full',
                        },
                        {
                            id: 3216876,
                            guid: 'WmuE2154WZouV2Zo',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/WmuE2154WZouV2Zo.full',
                        },
                        {
                            id: 3216877,
                            guid: 'hyYNKKAkneyDXP4k',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/hyYNKKAkneyDXP4k.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169526,
                stepid: 360205,
                orderby: 7,
                revisionid: 3332287,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216881,
                            guid: 'MN4MtXvPglDHjp6v',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MN4MtXvPglDHjp6v.full',
                        },
                        {
                            id: 3216882,
                            guid: 'ecQnQPrOBDa3T5aH',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ecQnQPrOBDa3T5aH.full',
                        },
                        {
                            id: 3216883,
                            guid: 'r4WlPkZLArPfOmdC',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/r4WlPkZLArPfOmdC.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'During sport, the hem of your trousers or...',
        time_required: '30 minutes',
        time_required_min: 1800,
        time_required_max: 1800,
        title: 'How to repair a trouser/cuff hem (by shortening)',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Pen',
                notes: null,
                url: 'https://www.amazon.com/Pilot-Retractable-Premium-Roller-Point/dp/B0030F32L6/ref=sr_1_8?crid=1ZF5SMXOJHXJF&keywords=pen&qid=1693498763&sprefix=pen%2Caps%2C95&sr=8-8',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Ruler',
                notes: null,
                url: 'https://www.amazon.com/Officemate-Classic-Stainless-Measurements-66612/dp/B00IXLPO8G/ref=sr_1_7?crid=6EUBJDC52GJT&keywords=ruler&qid=1704926908&sprefix=ruler%2Caps%2C155&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/1tdEZbuwvcnosbOi.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Fabric Scissors',
                notes: null,
                url: 'http://www.amazon.com/Gingher-8-Inch-Knife-Dressmakers-Shears/dp/B000UU6SR4/',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/X6YchoNrn3JofSsY.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Steam iron',
                notes: null,
                url: 'https://www.amazon.com/PurSteam-Professional-Steam-Iron-Display/dp/B07WJJTBP2/ref=sr_1_3?crid=2JURPKMRWKD8N&keywords=steam%2Biron&qid=1654207995&sprefix=steam%2Biron%2Caps%2C153&sr=8-3&th=1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'unstitching small cutter',
                notes: null,
                url: '/Item/unstitching_small_cutter',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982621,
        created_date: 1705929416,
        published_date: 1712671746,
        modified_date: 1716982618,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+trouser-cuff+hem+(by+shortening)/169526',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169527: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Very easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169527,
        image: {
            id: 3216775,
            guid: '6de6aAetR3xCkSTP',
            mini: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.full',
        },
        introduction_raw:
            'Some fabrics can degrade over time with pilling. But this could be removed with a very easy process.\n\nPilling often appears on winter products like knitted jumpers, merino wool sweaters, knitted 2nd layers like fleece jackets - but can sometimes also be visible on cotton or polyester T-Shirts.\n\nWith this repair guide, you will be able to refresh your damaged products to look as good as new!',
        introduction_rendered:
            '<p>Some fabrics can degrade over time with pilling. But this could be removed with a very easy process.</p>\n\n<p>Pilling often appears on winter products like knitted jumpers, merino wool sweaters, knitted 2nd layers like fleece jackets - but can sometimes also be visible on cotton or polyester T-Shirts.</p>\n\n<p>With this repair guide, you will be able to refresh your damaged products to look as good as new!</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Material preparation',
                lines: [
                    {
                        text_raw:
                            'Lay out the garment on a clean surface. Prepare the pilling shaver.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Lay out the garment on a clean surface. Prepare the pilling shaver.',
                    },
                ],
                guideid: 169527,
                stepid: 359170,
                orderby: 1,
                revisionid: 3293578,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216775,
                            guid: '6de6aAetR3xCkSTP',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/6de6aAetR3xCkSTP.full',
                        },
                        {
                            id: 3216776,
                            guid: 'ZkCHZWEapYLbqLNG',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ZkCHZWEapYLbqLNG.full',
                        },
                        {
                            id: 3216777,
                            guid: 'n3PCG2eSYRigUts4',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/n3PCG2eSYRigUts4.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Remove the pilling',
                lines: [
                    {
                        text_raw: 'Smooth the garment to make sure it is flat before starting.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Smooth the garment to make sure it is flat before starting.',
                    },
                    {
                        text_raw:
                            'Make little circles with light pressure on the damaged fabric. The pilling will be cut away by the shaver.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Make little circles with light pressure on the damaged fabric. The pilling will be cut away by the shaver.',
                    },
                ],
                guideid: 169527,
                stepid: 359323,
                orderby: 2,
                revisionid: 3332352,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216782,
                            guid: 'w4MMUMKpnIlPfjwK',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/w4MMUMKpnIlPfjwK.full',
                        },
                        {
                            id: 3216783,
                            guid: 'TaLNaydLsPYH5mKC',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/TaLNaydLsPYH5mKC.full',
                        },
                        {
                            id: 3216784,
                            guid: 'SWpdcCI26KmmqjFe',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/SWpdcCI26KmmqjFe.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                    {
                        text_raw:
                            "''Be sure to clean the lint from inside your shaver after use.''",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            '<em>Be sure to clean the lint from inside your shaver after use.</em>',
                    },
                ],
                guideid: 169527,
                stepid: 360197,
                orderby: 3,
                revisionid: 3332358,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3216788,
                            guid: '3VEfdfdU5TwHGFDc',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/3VEfdfdU5TwHGFDc.full',
                        },
                        {
                            id: 3216789,
                            guid: 'VbrIwh3fOxqZkJqk',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/VbrIwh3fOxqZkJqk.full',
                        },
                        {
                            id: 3216790,
                            guid: 'MB2bnmqLFjl1GYmY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MB2bnmqLFjl1GYmY.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Some fabrics can degrade over time with...',
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How to remove pilling',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Pilling Shaver',
                notes: null,
                url: '/Item/Pilling_Rasierer',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982626,
        created_date: 1705929433,
        published_date: 1712674138,
        modified_date: 1716981981,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+remove+pilling/169527',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169528: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169528,
        image: {
            id: 3221116,
            guid: 'yHocBdXgHgQOVw1N',
            mini: 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/yHocBdXgHgQOVw1N.full',
        },
        introduction_raw:
            "Knitted beanies or pullovers are easy to snag during use, resulting in a loose thread loop. But it's not serious, and not hard to fix - follow this guide to find out how!",
        introduction_rendered:
            "<p>Knitted beanies or pullovers are easy to snag during use, resulting in a loose thread loop. But it's not serious, and not hard to fix - follow this guide to find out how!</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Prepare the repair',
                lines: [
                    {
                        text_raw: 'Identify the snag',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Identify the snag',
                    },
                    {
                        text_raw:
                            'Prepare your needle - for this guide we are using a specialized "snag repair needle" which has a textured end. These are available from most sewing shops.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Prepare your needle - for this guide we are using a specialized &quot;snag repair needle&quot; which has a textured end. These are available from most sewing shops.',
                    },
                ],
                guideid: 169528,
                stepid: 359331,
                orderby: 1,
                revisionid: 3332854,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221097,
                            guid: 'jXeHWAFNJlXKMLjS',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/jXeHWAFNJlXKMLjS.full',
                        },
                        {
                            id: 3221098,
                            guid: 'LE3UnCoGA5AeQLEI',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LE3UnCoGA5AeQLEI.full',
                        },
                        {
                            id: 3221099,
                            guid: '4ReoGdZyRLdClCiM',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/4ReoGdZyRLdClCiM.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Pull snag loop through',
                lines: [
                    {
                        text_raw: 'Pass the needle through the bottom of the snag loop.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Pass the needle through the bottom of the snag loop.',
                    },
                    {
                        text_raw: 'Wrap the thread around the sharp end of the needle.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Wrap the thread around the sharp end of the needle.',
                    },
                    {
                        text_raw:
                            'Then, pass the needle through the fabric. The snag loop will also be pulled through by the textured part of the needle. Keep pulling it through, until both the needle and the snag loop are on the inside of the item.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Then, pass the needle through the fabric. The snag loop will also be pulled through by the textured part of the needle. Keep pulling it through, until both the needle and the snag loop are on the inside of the item.',
                    },
                ],
                guideid: 169528,
                stepid: 359332,
                orderby: 2,
                revisionid: 3332868,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221104,
                            guid: 'TbL1KInb3eQGUSTM',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/TbL1KInb3eQGUSTM.full',
                        },
                        {
                            id: 3221105,
                            guid: 'cMWQeran2ItANe3r',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/cMWQeran2ItANe3r.full',
                        },
                        {
                            id: 3221106,
                            guid: 'vZZtjcuSwBBgkcTh',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/vZZtjcuSwBBgkcTh.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            'Pull tightly on the thread to secure it to the back of the fabric.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Pull tightly on the thread to secure it to the back of the fabric.',
                    },
                    {
                        text_raw:
                            'Then, reposition the fabric appearance on the front, by stretching it to balance the tension.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Then, reposition the fabric appearance on the front, by stretching it to balance the tension.',
                    },
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169528,
                stepid: 359333,
                orderby: 3,
                revisionid: 3332875,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3221111,
                            guid: 'STNdOTaXrLDjGaAW',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/STNdOTaXrLDjGaAW.full',
                        },
                        {
                            id: 3221112,
                            guid: 'vjDhrSIvmwMeRM1j',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/vjDhrSIvmwMeRM1j.full',
                        },
                        {
                            id: 3221113,
                            guid: 'rHqOeoCE3VeAhe2O',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/rHqOeoCE3VeAhe2O.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Knitted beanies or pullovers are easy to snag...',
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How to repair loose threads',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Thread',
                notes: null,
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'snag needle',
                notes: null,
                url: '/Item/snag_needle',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982657,
        created_date: 1705929450,
        published_date: 1712674893,
        modified_date: 1716982670,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+loose+threads/169528',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169531: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Very easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169531,
        image: {
            id: 3230484,
            guid: '6wvNxEVolsZOIIB3',
            mini: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.full',
        },
        introduction_raw:
            "The adjustment drawstring on hoodies or shorts can easily get “lost” in the hem tunnel - especially if the knot has come loose during washing. But no problem: it's easy to repair, and doesn't take long at all!",
        introduction_rendered:
            "<p>The adjustment drawstring on hoodies or shorts can easily get “lost” in the hem tunnel - especially if the knot has come loose during washing. But no problem: it's easy to repair, and doesn't take long at all!</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the lost drawstring',
                lines: [
                    {
                        text_raw:
                            "One end of the drawstring has got lost inside the hem tunnel and you can't get it back.",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "One end of the drawstring has got lost inside the hem tunnel and you can't get it back.",
                    },
                ],
                guideid: 169531,
                stepid: 359481,
                orderby: 1,
                revisionid: 3335353,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3230484,
                            guid: '6wvNxEVolsZOIIB3',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/6wvNxEVolsZOIIB3.full',
                        },
                        {
                            id: 3230485,
                            guid: 'f2aexAURbiFnPK3W',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/f2aexAURbiFnPK3W.full',
                        },
                        {
                            id: 3230486,
                            guid: 'HocT4pOn324hcSr3',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HocT4pOn324hcSr3.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Pull out the drawstring completely',
                lines: [
                    {
                        text_raw: 'Pull it on the drawstring and take it out completely.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Pull it on the drawstring and take it out completely.',
                    },
                    {
                        text_raw:
                            '***Note: ***some drawstrings are sewn in place at the back, for safety reasons. If your item has this, simply remove this stitch with small scissors or a seam ripper.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Note: </strong>some drawstrings are sewn in place at the back, for safety reasons. If your item has this, simply remove this stitch with small scissors or a seam ripper.',
                    },
                ],
                guideid: 169531,
                stepid: 361274,
                orderby: 2,
                revisionid: 3335357,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3230490,
                            guid: 'vkxgKAytMA1FFOBs',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/vkxgKAytMA1FFOBs.full',
                        },
                        {
                            id: 3230491,
                            guid: 'ZLajSfVojEhEUtnQ',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ZLajSfVojEhEUtnQ.full',
                        },
                        {
                            id: 3230492,
                            guid: 'MpJVFJUPSGjPvinZ',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MpJVFJUPSGjPvinZ.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Attach the safety pin to the drawstring',
                lines: [
                    {
                        text_raw:
                            'Locate the knot at one end of the drawstring; or if there is no knot, make one.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Locate the knot at one end of the drawstring; or if there is no knot, make one.',
                    },
                    {
                        text_raw: 'Push the safety pin through the knot and re-clasp it.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Push the safety pin through the knot and re-clasp it.',
                    },
                ],
                guideid: 169531,
                stepid: 359482,
                orderby: 3,
                revisionid: 3335363,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3230496,
                            guid: 'vOVgNTWPTYxLeUx3',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/vOVgNTWPTYxLeUx3.full',
                        },
                        {
                            id: 3230497,
                            guid: 'IJYvhGWSXCyLoHCL',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/IJYvhGWSXCyLoHCL.full',
                        },
                        {
                            id: 3230502,
                            guid: 'xaBWJ6UQXUE16sxV',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/xaBWJ6UQXUE16sxV.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Pull the drawstring through the hem tunnel',
                lines: [
                    {
                        text_raw: 'Push the safety pin into one of the holes in the hem tunnel.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Push the safety pin into one of the holes in the hem tunnel.',
                    },
                    {
                        text_raw:
                            'Push the pin as far as possible, and then hold it in place through the fabric. With the other hand, bunch the hem tunnel towards the first hand.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Push the pin as far as possible, and then hold it in place through the fabric. With the other hand, bunch the hem tunnel towards the first hand.',
                    },
                    {
                        text_raw:
                            'Slide the pin into the bunched hem tunnel, and try to pinch it with the receiving hand. Now, let go of the pin with the first hand, and use this hand to pull the hem tunnel taught.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Slide the pin into the bunched hem tunnel, and try to pinch it with the receiving hand. Now, let go of the pin with the first hand, and use this hand to pull the hem tunnel taught.',
                    },
                    {
                        text_raw:
                            'When done correctly, the pin will transfer from one hand to the next hand, slowly moving along the hem tunnel with every bunching-tautening motion.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'When done correctly, the pin will transfer from one hand to the next hand, slowly moving along the hem tunnel with every bunching-tautening motion.',
                    },
                    {
                        text_raw:
                            'Keep repeating this action until the pin reaches the other end of the hem tunnel. Now, pull out the pin and drawstring together.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Keep repeating this action until the pin reaches the other end of the hem tunnel. Now, pull out the pin and drawstring together.',
                    },
                ],
                guideid: 169531,
                stepid: 359483,
                orderby: 4,
                revisionid: 3335365,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3230507,
                            guid: 'PmCgo2KTQdgQrLQf',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/PmCgo2KTQdgQrLQf.full',
                        },
                        {
                            id: 3230508,
                            guid: 'eXoWVAkx5SAc4b4N',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/eXoWVAkx5SAc4b4N.full',
                        },
                        {
                            id: 3230509,
                            guid: '23AA6U2AbDuqPXpr',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/23AA6U2AbDuqPXpr.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Rearrange the drawstring',
                lines: [
                    {
                        text_raw: 'Fully remove the drawstring end, and pin, from the hem tunnel',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Fully remove the drawstring end, and pin, from the hem tunnel',
                    },
                    {
                        text_raw: 'Remove the safety pin from the drawstring',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Remove the safety pin from the drawstring',
                    },
                    {
                        text_raw: 'Re-center the drawstring by pulling on each side appropriately',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Re-center the drawstring by pulling on each side appropriately',
                    },
                    {
                        text_raw:
                            "''***Note: ***if you don't want to re-sew the security stitch at the back, then you're done! ... 🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!''",
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            "<em><strong>Note: </strong>if you don't want to re-sew the security stitch at the back, then you're done! ... 🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!</em>",
                    },
                ],
                guideid: 169531,
                stepid: 361275,
                orderby: 5,
                revisionid: 3335368,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3230517,
                            guid: 'HqLCVNwaP2iaJSMi',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HqLCVNwaP2iaJSMi.full',
                        },
                        {
                            id: 3230516,
                            guid: 'CduuRiQQ6vMvunFO',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/CduuRiQQ6vMvunFO.full',
                        },
                        {
                            id: 3230515,
                            guid: '6JoWfXGlVy2Hvk2k',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/6JoWfXGlVy2Hvk2k.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Optional: Re-sew the security stitch',
                lines: [
                    {
                        text_raw:
                            'If you wish, the cord drawstring can be re-attached at the back of the item with a security stitch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'If you wish, the cord drawstring can be re-attached at the back of the item with a security stitch.',
                    },
                    {
                        text_raw:
                            'This can also be a useful step for drawcords without knots, to help them stay in place during washing.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            'This can also be a useful step for drawcords without knots, to help them stay in place during washing.',
                    },
                    {
                        text_raw: 'Place the item under the sewing machine',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Place the item under the sewing machine',
                    },
                    {
                        text_raw:
                            'Make a short straight stitch over the drawcord, to attach it to the fabric. Repeat 1-2 times for extra security and finish with a lock stitch',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Make a short straight stitch over the drawcord, to attach it to the fabric. Repeat 1-2 times for extra security and finish with a lock stitch',
                    },
                ],
                guideid: 169531,
                stepid: 361276,
                orderby: 6,
                revisionid: 3335370,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3230525,
                            guid: 'OAdCRO1hNd3YCJMg',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/OAdCRO1hNd3YCJMg.full',
                        },
                        {
                            id: 3230526,
                            guid: 'xcfkFY5VlavGZlpR',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/xcfkFY5VlavGZlpR.full',
                        },
                        {
                            id: 3230527,
                            guid: 'kufXKZMVOp5nxwFj',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/kufXKZMVOp5nxwFj.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169531,
                stepid: 361277,
                orderby: 7,
                revisionid: 3335373,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3230532,
                            guid: '2pMyfddXKAetpnus',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2pMyfddXKAetpnus.full',
                        },
                        {
                            id: 3230533,
                            guid: 'DRgIOwb4yWGE3Zs1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/DRgIOwb4yWGE3Zs1.full',
                        },
                        {
                            id: 3230534,
                            guid: 'KVujRCCuMBIGjrgm',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/KVujRCCuMBIGjrgm.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'The adjustment drawstring on hoodies or shorts...',
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How to repair a "lost" drawstring',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Safety Pin',
                notes: null,
                url: 'http://www.amazon.com/Singer-Assorted-Safety-Multisize-50-Count/dp/B000PSJSRI/ref=sr_1_1?ie=UTF8&qid=1377552190&sr=8-1&keywords=safety+pin',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/5r25MP26HNBJ1guP.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1982943,
        created_date: 1705929505,
        published_date: 1712739522,
        modified_date: 1716982428,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+%22lost%22+drawstring/169531',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169532: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Moderate',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169532,
        image: {
            id: 3234149,
            guid: 'VaUmuYtoyeCY4YfD',
            mini: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.full',
        },
        introduction_raw:
            'A hole in knitted sports items can easily appear after snagging it on something sharp, especially winter items such as beanies or jumpers.\n\nHere we will explain how to perform a repair using a standard darning technique; and demonstrate with a contrast colour thread to make the photos clearer. Depending on your preference, you might choose a matching thread colour to hide the repair, or a contrast thread colour to celebrate your repair and the adventure which made the hole!',
        introduction_rendered:
            '<p>A hole in knitted sports items can easily appear after snagging it on something sharp, especially winter items such as beanies or jumpers.</p>\n\n<p>Here we will explain how to perform a repair using a standard darning technique; and demonstrate with a contrast colour thread to make the photos clearer. Depending on your preference, you might choose a matching thread colour to hide the repair, or a contrast thread colour to celebrate your repair and the adventure which made the hole!</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Prepare the repair',
                lines: [
                    {
                        text_raw:
                            'Prepare a clean and uncluttered space to perform the repair. Find the hole in your item of clothing, and inspect it noting the size and shape, and the stretch and thickness of the surrounding fabric.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Prepare a clean and uncluttered space to perform the repair. Find the hole in your item of clothing, and inspect it noting the size and shape, and the stretch and thickness of the surrounding fabric.',
                    },
                    {
                        text_raw:
                            'Choose your thread colour, and prepare the needle and darning egg.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Choose your thread colour, and prepare the needle and darning egg.',
                    },
                    {
                        text_raw:
                            "***Note: ***in this guide we use a darning ''egg'', but darning mushrooms and many other options are also available.",
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Note: </strong>in this guide we use a darning <em>egg</em>, but darning mushrooms and many other options are also available.',
                    },
                ],
                guideid: 169532,
                stepid: 359173,
                orderby: 1,
                revisionid: 3333397,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234149,
                            guid: 'VaUmuYtoyeCY4YfD',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/VaUmuYtoyeCY4YfD.full',
                        },
                        {
                            id: 3234150,
                            guid: 'KsQDIa1Q2xRqijEA',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/KsQDIa1Q2xRqijEA.full',
                        },
                        {
                            id: 3234151,
                            guid: 'TAWtVKP1LyrdJKrZ',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/TAWtVKP1LyrdJKrZ.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Start darning',
                lines: [
                    {
                        text_raw: 'Position the egg underneath the hole.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Position the egg underneath the hole.',
                    },
                    {
                        text_raw:
                            'Hold the fabric around the egg, so that both the egg and fabric create a stable and secure position to work on. Do not apply excess pressure or tension to the fabric - the hole size should not increase.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Hold the fabric around the egg, so that both the egg and fabric create a stable and secure position to work on. Do not apply excess pressure or tension to the fabric - the hole size should not increase.',
                    },
                    {
                        text_raw:
                            'Start the repair on one end of the hole, and position the needle to one side of the hole - at an area which is not broken. Thread the needle into the fabric from the bottom side of the hole, and exit the fabric at the top side of the hole, to create the first vertical stitch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Start the repair on one end of the hole, and position the needle to one side of the hole - at an area which is not broken. Thread the needle into the fabric from the bottom side of the hole, and exit the fabric at the top side of the hole, to create the first vertical stitch.',
                    },
                    {
                        text_raw:
                            '***Tip: ***the darning technique will gradually build up a series of interlocking vertical and horizonal stitches to fill the hole. This is the first one!',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>the darning technique will gradually build up a series of interlocking vertical and horizonal stitches to fill the hole. This is the first one!',
                    },
                ],
                guideid: 169532,
                stepid: 359310,
                orderby: 2,
                revisionid: 3333402,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234157,
                            guid: 'qwmfvkyEM5ZOonS1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/qwmfvkyEM5ZOonS1.full',
                        },
                        {
                            id: 3234158,
                            guid: 'ipwVXpg2OlSHbPAQ',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ipwVXpg2OlSHbPAQ.full',
                        },
                        {
                            id: 3234159,
                            guid: 'aUsO1QhXGVHmsKOt',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/aUsO1QhXGVHmsKOt.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Continue darning vertically',
                lines: [
                    {
                        text_raw:
                            'After the first vertical stitch, there is no need to make a knot at the end of the thread; just leave it free.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'After the first vertical stitch, there is no need to make a knot at the end of the thread; just leave it free.',
                    },
                    {
                        text_raw:
                            'Make a second vertical stitch through the fabric and back out in the vertical direction, but this time downwards.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Make a second vertical stitch through the fabric and back out in the vertical direction, but this time downwards.',
                    },
                    {
                        text_raw:
                            'Continue with the same "up and down" process, spacing the stitches in regular intervals all along the hole.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Continue with the same &quot;up and down&quot; process, spacing the stitches in regular intervals all along the hole.',
                    },
                ],
                guideid: 169532,
                stepid: 361517,
                orderby: 3,
                revisionid: 3333419,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234165,
                            guid: 'BBhWCJBmMKmpH3pc',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/BBhWCJBmMKmpH3pc.full',
                        },
                        {
                            id: 3234166,
                            guid: '5V1jjZ25TRiHjQ4x',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/5V1jjZ25TRiHjQ4x.full',
                        },
                        {
                            id: 3234167,
                            guid: 'SJRVqDAsOaKGTOSJ',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/SJRVqDAsOaKGTOSJ.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Start darning horizontally',
                lines: [
                    {
                        text_raw:
                            'You should now have completed the vertical stitching. Check that the vertical stitches are evenly spread, and not too spaced out before continuing.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'You should now have completed the vertical stitching. Check that the vertical stitches are evenly spread, and not too spaced out before continuing.',
                    },
                    {
                        text_raw:
                            '***Tip: ***if needed, add extra vertical stitches to fill in big gaps, or even repeat the whole vertical stitching process again to add a second row of vertical stitches. The amount of stitches will determine the density and durability of the repair, and is unique to each item/fabric.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>if needed, add extra vertical stitches to fill in big gaps, or even repeat the whole vertical stitching process again to add a second row of vertical stitches. The amount of stitches will determine the density and durability of the repair, and is unique to each item/fabric.',
                    },
                    {
                        text_raw:
                            "Once you're satisfied with the vertical stitches, begin adding the horizontal stitches. For this, start again at one side of the hole and start stitching in the opposite orientation to the first set of stitches. Turn the product 90 degrees if it helps.",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "Once you're satisfied with the vertical stitches, begin adding the horizontal stitches. For this, start again at one side of the hole and start stitching in the opposite orientation to the first set of stitches. Turn the product 90 degrees if it helps.",
                    },
                    {
                        text_raw:
                            '***Important: ***the horizontal stitches must weave between the vertical stitches. To do this, thread the needle under and over each vertical stitch in an alternating fashion.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Important: </strong>the horizontal stitches must weave between the vertical stitches. To do this, thread the needle under and over each vertical stitch in an alternating fashion.',
                    },
                    {
                        text_raw:
                            'Proceed with this "under and over" action as you continue across the hole, until the hole is fully covered.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Proceed with this &quot;under and over&quot; action as you continue across the hole, until the hole is fully covered.',
                    },
                ],
                guideid: 169532,
                stepid: 361518,
                orderby: 4,
                revisionid: 3334921,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234182,
                            guid: 'mvuWJXvfTOhHyHFS',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/mvuWJXvfTOhHyHFS.full',
                        },
                        {
                            id: 3234183,
                            guid: 'OYeJWhHETnRx2RNE',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/OYeJWhHETnRx2RNE.full',
                        },
                        {
                            id: 3234184,
                            guid: 'SVShCDyWRiI4CRVw',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/SVShCDyWRiI4CRVw.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Complete the darning',
                lines: [
                    {
                        text_raw:
                            'Continue the horizontal stitching until the hole is fully covered.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Continue the horizontal stitching until the hole is fully covered.',
                    },
                    {
                        text_raw:
                            'Try to finish the last horizontal stitch, so that the thread-end is at the opposite corner of the starting point.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Try to finish the last horizontal stitch, so that the thread-end is at the opposite corner of the starting point.',
                    },
                    {
                        text_raw:
                            '***Tip:*** If you ended up on the same side as the starting point, simply make one more horizontal stitch.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip:</strong> If you ended up on the same side as the starting point, simply make one more horizontal stitch.',
                    },
                ],
                guideid: 169532,
                stepid: 359311,
                orderby: 5,
                revisionid: 3334924,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234188,
                            guid: 'UpTOnfqQwfCNVwgB',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/UpTOnfqQwfCNVwgB.full',
                        },
                        {
                            id: 3234189,
                            guid: 'q6nS2reipRWG1R1o',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/q6nS2reipRWG1R1o.full',
                        },
                        {
                            id: 3234190,
                            guid: 'O2ZVZgTUGCU1tOQt',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/O2ZVZgTUGCU1tOQt.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Tidy the repair',
                lines: [
                    {
                        text_raw:
                            'Inspect your repair, and ensure there are no further stitches to be made before cutting the threads. Then, make a double stitch at the final point, to secure the thread - there is no need to make a knot.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Inspect your repair, and ensure there are no further stitches to be made before cutting the threads. Then, make a double stitch at the final point, to secure the thread - there is no need to make a knot.',
                    },
                    {
                        text_raw: 'Carefully cut one loose thread...',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Carefully cut one loose thread...',
                    },
                    {
                        text_raw: '... and then carefully cut the other loose thread.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: '... and then carefully cut the other loose thread.',
                    },
                    {
                        text_raw:
                            'Flatten down the edges of the repair and smooth the entire repaired area.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Flatten down the edges of the repair and smooth the entire repaired area.',
                    },
                    {
                        text_raw:
                            '***Tip: ***flatten item on a hard   and flat surface, and use the rounded side of your darning egg or mushroom to firmly apply pressure on a circular motion to the repaired areas, to smooth down the edges.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>flatten item on a hard   and flat surface, and use the rounded side of your darning egg or mushroom to firmly apply pressure on a circular motion to the repaired areas, to smooth down the edges.',
                    },
                ],
                guideid: 169532,
                stepid: 361519,
                orderby: 6,
                revisionid: 3334928,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234196,
                            guid: 'PCv6IVBF6vHtK2Nf',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/PCv6IVBF6vHtK2Nf.full',
                        },
                        {
                            id: 3234197,
                            guid: 'ulDGhXgf4U2OBO3K',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ulDGhXgf4U2OBO3K.full',
                        },
                        {
                            id: 3234198,
                            guid: 'cQhgmCDxEFlJyXIt',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/cQhgmCDxEFlJyXIt.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 Congratulations! The repair is complete, and you're ready to go!",
                    },
                ],
                guideid: 169532,
                stepid: 361520,
                orderby: 7,
                revisionid: 3334931,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234201,
                            guid: 'AiGAnBkOwqRahxgj',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/AiGAnBkOwqRahxgj.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'A hole in knitted sports items can easily...',
        time_required: '10 minutes',
        time_required_min: 600,
        time_required_max: 600,
        title: 'How to repair a hole in a knitted fabric (using darning)',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Thread',
                notes: null,
                url: 'https://www.amazon.com/LEONIS-Color-Polyester-Sewing-Threads/dp/B00LMZTS78/ref=sr_1_6?crid=1138NLDX7HWLI&keywords=thread%2Bfor%2Bsewing&qid=1651861007&sprefix=thread%2Caps%2C167&sr=8-6&th=1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Hand Sewing Needle',
                notes: null,
                url: 'https://www.amazon.com/SINGER-Large-Needles-Magnetic-Card-12/dp/B002I35QMO/ref=sxin_0_ac_d_rm?crid=2D44X2L0TRTDM&keywords=sewing+needles+hand&pd_rd_i=B002I35QMO&pd_rd_r=7dfc4a4d-85d9-4fa9-bae0-e641c5d5fa59&pd_rd_w=TG8zg&pd_rd_wg=IkY1h&pf_rd_p=91b604bb-c371-4573-970f-bed68a552852&pf_rd_r=14CZ6X5JHRAM9W311PP0&qid=1559661764&s=gateway&sprefix=sewing+needle%2Caps%2C148',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/qXEyJJAuKEktP1Ha.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Scissors',
                notes: null,
                url: 'https://eustore.ifixit.com/products/utility-scissors',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/YZFc6xfCFTuHFhsP.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'darning egg',
                notes: null,
                url: '/Item/darning_egg',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1981935,
        created_date: 1705929657,
        published_date: 1712311376,
        modified_date: 1716982515,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+hole+in+a+knitted+fabric+(using+darning)/169532',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    169940: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Very easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 169940,
        image: {
            id: 3222394,
            guid: 'iisResulMJIPTcap',
            mini: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.full',
        },
        introduction_raw:
            "When you're out hiking in wild terrain, it's easy to get your trousers snagged on thorns or fences. A self-adhesive patch is a great alternative to traditional iron-on patches, and can repair the hole quickly and easily either at home or in the field. There are various types of patches to match different types of fabrics; and in this guide we use an elasticated fabric with high stretch, to match the stretch of the hiking trousers.",
        introduction_rendered:
            "<p>When you're out hiking in wild terrain, it's easy to get your trousers snagged on thorns or fences. A self-adhesive patch is a great alternative to traditional iron-on patches, and can repair the hole quickly and easily either at home or in the field. There are various types of patches to match different types of fabrics; and in this guide we use an elasticated fabric with high stretch, to match the stretch of the hiking trousers.</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Prepare the area',
                lines: [
                    {
                        text_raw: 'Start by finding a clean and flat place to perform the repair.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Start by finding a clean and flat place to perform the repair.',
                    },
                    {
                        text_raw:
                            'Ensure the damaged area is clean and free from any loose threads or debris. Gently brush away any particles to create a smooth surface for the patch to adhere to;  and cut the damaged thread around the hole.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Ensure the damaged area is clean and free from any loose threads or debris. Gently brush away any particles to create a smooth surface for the patch to adhere to;  and cut the damaged thread around the hole.',
                    },
                    {
                        text_raw: 'Lay the item on the flat and clean surface.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Lay the item on the flat and clean surface.',
                    },
                ],
                guideid: 169940,
                stepid: 360490,
                orderby: 1,
                revisionid: 3331244,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222394,
                            guid: 'iisResulMJIPTcap',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/iisResulMJIPTcap.full',
                        },
                        {
                            id: 3222395,
                            guid: 'kNyEbT2ODLnSKulT',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/kNyEbT2ODLnSKulT.full',
                        },
                        {
                            id: 3222397,
                            guid: 'XMbdNuKmi1BMUymD',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/XMbdNuKmi1BMUymD.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare the patch',
                lines: [
                    {
                        text_raw:
                            'Choose a matching patch for your broken item, considering the size of the repair, the colour, and qualities of the fabric (stretch, toughness, texture, etc.).  Consider that the location of the defect will affect this choice, and a hard-wearing material might be required in high-wear/stress zones.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Choose a matching patch for your broken item, considering the size of the repair, the colour, and qualities of the fabric (stretch, toughness, texture, etc.).  Consider that the location of the defect will affect this choice, and a hard-wearing material might be required in high-wear/stress zones.',
                    },
                    {
                        text_raw:
                            "''***Tip:*** In many cases, an exact colour match will not be possible. Embrace it! Find some creativity with your repair using contrasting colours or printed designs.''",
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<em><strong>Tip:</strong> In many cases, an exact colour match will not be possible. Embrace it! Find some creativity with your repair using contrasting colours or printed designs.</em>',
                    },
                    {
                        text_raw:
                            'Carefully remove the patch from the backing paper liner. Avoid touching the adhesive surface too much, as this will reduce the durability of the repair. Take care also, that thinner patches may crease or stick themselves more easily.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Carefully remove the patch from the backing paper liner. Avoid touching the adhesive surface too much, as this will reduce the durability of the repair. Take care also, that thinner patches may crease or stick themselves more easily.',
                    },
                ],
                guideid: 169940,
                stepid: 360491,
                orderby: 2,
                revisionid: 3331247,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222406,
                            guid: 'rE4Q1JNTY5PWjxqI',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/rE4Q1JNTY5PWjxqI.full',
                        },
                        {
                            id: 3222407,
                            guid: 'QvkeaaZlAvnQFwpl',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/QvkeaaZlAvnQFwpl.full',
                        },
                        {
                            id: 3222408,
                            guid: 'mJTBKRr2MWZIaHwX',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/mJTBKRr2MWZIaHwX.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Position & place the patch',
                lines: [
                    {
                        text_raw:
                            'Position the stretchy direction of the patch matching with the stretchy direction of the fabric.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Position the stretchy direction of the patch matching with the stretchy direction of the fabric.',
                    },
                    {
                        text_raw:
                            'Place the top edge of the patch carefully onto the item, and apply gentle pressure.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Place the top edge of the patch carefully onto the item, and apply gentle pressure.',
                    },
                    {
                        text_raw:
                            '***Before proceeding further: ***check whether the middle of the patch will align with the tear. If it will not, carefully pull up the top edge and reposition. Take care, as each repositioning will reduce the durability of the patch, and ideally repositioning should not be done.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Before proceeding further: </strong>check whether the middle of the patch will align with the tear. If it will not, carefully pull up the top edge and reposition. Take care, as each repositioning will reduce the durability of the patch, and ideally repositioning should not be done.',
                    },
                    {
                        text_raw:
                            'Continue holding the lifted edge, while applying gentle pressure from the direction of the adhered edge. Gently push your finger along the patch towards the bottom edge until the whole patch is secured onto the item.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Continue holding the lifted edge, while applying gentle pressure from the direction of the adhered edge. Gently push your finger along the patch towards the bottom edge until the whole patch is secured onto the item.',
                    },
                ],
                guideid: 169940,
                stepid: 360492,
                orderby: 3,
                revisionid: 3331252,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222413,
                            guid: 'rtFiAr1ngFQvTPeX',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/rtFiAr1ngFQvTPeX.full',
                        },
                        {
                            id: 3222414,
                            guid: 'bIxiZwLstNe4jqR5',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/bIxiZwLstNe4jqR5.full',
                        },
                        {
                            id: 3222415,
                            guid: '1LhSpwfmE1kDPyMX',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/1LhSpwfmE1kDPyMX.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Secure the patch firmly',
                lines: [
                    {
                        text_raw:
                            'The patch is now only lightly secured; and now it is important to give some additional pressure, to secure it fully.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'The patch is now only lightly secured; and now it is important to give some additional pressure, to secure it fully.',
                    },
                    {
                        text_raw:
                            'First, use your hands to apply pressure to all parts of the patch. Give special focus to the edges of the patch (as these will fray the quickest from wear and laundering), and the area surrounding the tear.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'First, use your hands to apply pressure to all parts of the patch. Give special focus to the edges of the patch (as these will fray the quickest from wear and laundering), and the area surrounding the tear.',
                    },
                    {
                        text_raw:
                            'Next, use the backside of a spoon to apply harder pressure, and use a circular motion to press down around the patch for 1-2 minutes.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Next, use the backside of a spoon to apply harder pressure, and use a circular motion to press down around the patch for 1-2 minutes.',
                    },
                ],
                guideid: 169940,
                stepid: 360493,
                orderby: 4,
                revisionid: 3331256,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222426,
                            guid: 'cgcKY5fATcYEYymX',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/cgcKY5fATcYEYymX.full',
                        },
                        {
                            id: 3222427,
                            guid: 'FyoEX1Xu5JEMAwSD',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/FyoEX1Xu5JEMAwSD.full',
                        },
                        {
                            id: 3222428,
                            guid: 'MtO4kZLWyFf5JnYD',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MtO4kZLWyFf5JnYD.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The patch has been applied, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The patch has been applied, and you're ready to go!",
                    },
                    {
                        text_raw:
                            'Be aware: Different patches work in different way, but often the garment should be left 2-3 days before being washed.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            'Be aware: Different patches work in different way, but often the garment should be left 2-3 days before being washed.',
                    },
                    {
                        text_raw:
                            'Be prepared: Consider taking additional patches with you during your next trip, for quick on-the-go fixes',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            'Be prepared: Consider taking additional patches with you during your next trip, for quick on-the-go fixes',
                    },
                    {
                        text_raw:
                            'Extra secure: if the tear or defect was very large, or touching a sensitive part of your body, it might be worthwhile to add a second patch on the inside (other side) of the defect. Simply turn the item inside-out and repeat this guide.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            'Extra secure: if the tear or defect was very large, or touching a sensitive part of your body, it might be worthwhile to add a second patch on the inside (other side) of the defect. Simply turn the item inside-out and repeat this guide.',
                    },
                ],
                guideid: 169940,
                stepid: 360494,
                orderby: 5,
                revisionid: 3369924,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3222434,
                            guid: 'u25NJW3DP35jYHEn',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/u25NJW3DP35jYHEn.full',
                        },
                        {
                            id: 3222435,
                            guid: 'I6fLsQhmjgtBqEwe',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/I6fLsQhmjgtBqEwe.full',
                        },
                        {
                            id: 3222436,
                            guid: 'V6RFLm3RPPZOvPBK',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/V6RFLm3RPPZOvPBK.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: "When you're out hiking in wild terrain, it's...",
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How to repair a hole in woven textile (using a self adhesive patch)',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Self-adhesive patch',
                notes: null,
                url: 'https://www.homedepot.com/p/reviews/BOEN-12-in-x-12-in-Drywall-Self-Adhesive-Wall-Repair-Patch-WR-1212/204834672/1',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Spoon',
                notes: null,
                url: 'https://www.amazon.com/AmazonBasics-Stainless-Dinner-Spoons-Pearled/dp/B0736W5C31/ref=sr_1_5?crid=JGHUWGOEVC2B&keywords=spoon&qid=1684950346&sprefix=spoon%2Caps%2C227&sr=8-5',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1735185,
        created_date: 1707395783,
        published_date: 1712304031,
        modified_date: 1716982532,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+hole+in+woven+textile+(using+a+self+adhesive+patch)/169940',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    170249: {
        brandId: '43574',
        conclusion_raw:
            "In need of another repair? Don't worry! Check out our [link|https://www.ifixit.com/brand/intersport|other guides|new_window=true] to find quick solutions for your favourite clothing, footwear and equipment!",
        conclusion_rendered:
            '<p>In need of another repair? Don\'t worry! Check out our <a href="https://www.ifixit.com/brand/intersport" target="_blank">other guides</a> to find quick solutions for your favourite clothing, footwear and equipment!</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 170249,
        image: {
            id: 3234208,
            guid: 'WQpIfiRyKTTpV5ZU',
            mini: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.full',
        },
        introduction_raw:
            "When doing sports, it's common to get some damage - from small tears to big rips. Even on swimtrunks, sharp coral or equipment can make a hole. If you've got access to a sewing machine, these can often be quickly patched up! This guide will walk you through at a level suitable for beginners and upwards.",
        introduction_rendered:
            "<p>When doing sports, it's common to get some damage - from small tears to big rips. Even on swimtrunks, sharp coral or equipment can make a hole. If you've got access to a sewing machine, these can often be quickly patched up! This guide will walk you through at a level suitable for beginners and upwards.</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Identify the hole',
                lines: [
                    {
                        text_raw:
                            'Identify the tear or hole in your garment and note the size: is it a large tear or a small tear?',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Identify the tear or hole in your garment and note the size: is it a large tear or a small tear?',
                    },
                    {
                        text_raw:
                            '***Tip: ***smaller holes might be fixable using a self-adhesive patch. Check out our guide on this technique before pulling out the sewing machine!',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>smaller holes might be fixable using a self-adhesive patch. Check out our guide on this technique before pulling out the sewing machine!',
                    },
                ],
                guideid: 170249,
                stepid: 361521,
                orderby: 1,
                revisionid: 3333391,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234208,
                            guid: 'WQpIfiRyKTTpV5ZU',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/WQpIfiRyKTTpV5ZU.full',
                        },
                        {
                            id: 3234209,
                            guid: 'uNRYkanuxx1Jj3is',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/uNRYkanuxx1Jj3is.full',
                        },
                        {
                            id: 3234210,
                            guid: 'NQh6Q23SyHmfK5sv',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/NQh6Q23SyHmfK5sv.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Prepare the repair',
                lines: [
                    {
                        text_raw:
                            'Turn the item inside-out, and lay it on a flat and clean surface with the tear fully exposed. Inspect the tear, and use scissors to gently cut any frayed edges.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Turn the item inside-out, and lay it on a flat and clean surface with the tear fully exposed. Inspect the tear, and use scissors to gently cut any frayed edges.',
                    },
                    {
                        text_raw:
                            '***Extra:*** If the fabric is wrinkled or creased, use a hot iron to smooth the area around the repair first.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Extra:</strong> If the fabric is wrinkled or creased, use a hot iron to smooth the area around the repair first.',
                    },
                    {
                        text_raw:
                            'Pinch the seam together carefully, to create an accurate match. Ensure there is enough fabric pinched together to fit underneath the sewing machine foot, and be sewn together.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Pinch the seam together carefully, to create an accurate match. Ensure there is enough fabric pinched together to fit underneath the sewing machine foot, and be sewn together.',
                    },
                    {
                        text_raw:
                            'Use sewing pins to tack the two sides together. The goal is to hold the fabric in place, in exactly the same orientation as you had it pinched by hand. This is an important step, as it will determine the location and accuracy of the final repair.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Use sewing pins to tack the two sides together. The goal is to hold the fabric in place, in exactly the same orientation as you had it pinched by hand. This is an important step, as it will determine the location and accuracy of the final repair.',
                    },
                ],
                guideid: 170249,
                stepid: 361522,
                orderby: 2,
                revisionid: 3333406,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234216,
                            guid: 'e4aG6PkQJ5DXJm1A',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/e4aG6PkQJ5DXJm1A.full',
                        },
                        {
                            id: 3234217,
                            guid: 'aUSnB6jcYT1hPgF4',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/aUSnB6jcYT1hPgF4.full',
                        },
                        {
                            id: 3234218,
                            guid: 'wtb1AdLsVsV5RAEk',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/wtb1AdLsVsV5RAEk.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Use the sewing machine to fix the hole',
                lines: [
                    {
                        text_raw:
                            'Time to sew the repair closed! Take time to set up the sewing machine, and create a clean and open area to work in.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Time to sew the repair closed! Take time to set up the sewing machine, and create a clean and open area to work in.',
                    },
                    {
                        text_raw:
                            '***Tip:*** Use the iFixit resources for [guide|19463|setting up|new_window=true] a sewing machine, and practicing [[Topic:Basic Sewing|basic|new_window=true]] stitch techniques on some scrap fabric first.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip:</strong> Use the iFixit resources for <a href="https://www.ifixit.com/Guide/Thread+a+Sewing+Machine/19463" target="_blank">setting up</a> a sewing machine, and practicing <a href="https://www.ifixit.com/Topic/Basic_Sewing" target="_blank">basic</a> stitch techniques on some scrap fabric first.',
                    },
                    {
                        text_raw:
                            'Carefully offer up the item to the sewing machine, and position one end of the repair under the needle, and depress the foot to hold the item in place. Set the machine to a straight stitch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Carefully offer up the item to the sewing machine, and position one end of the repair under the needle, and depress the foot to hold the item in place. Set the machine to a straight stitch.',
                    },
                    {
                        text_raw:
                            '***Take care:*** remove the tacking pins one-by-one as needed - they must not run under the sewing machine, or it may break the sewing machine needle.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Take care:</strong> remove the tacking pins one-by-one as needed - they must not run under the sewing machine, or it may break the sewing machine needle.',
                    },
                    {
                        text_raw:
                            'Make a locking stitch by using the reverse lever, and then make a single stitch along the length of the seam. At the end, secure the stitch with another lock stitch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Make a locking stitch by using the reverse lever, and then make a single stitch along the length of the seam. At the end, secure the stitch with another lock stitch.',
                    },
                    {
                        text_raw:
                            '***Note: ***Depending on the fabric, repeat the stich 1.2 times if needed, for extra durability.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Note: </strong>Depending on the fabric, repeat the stich 1.2 times if needed, for extra durability.',
                    },
                ],
                guideid: 170249,
                stepid: 361523,
                orderby: 3,
                revisionid: 3333404,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234243,
                            guid: 'MgbTRI1vSUwL1TPC',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MgbTRI1vSUwL1TPC.full',
                        },
                        {
                            id: 3234244,
                            guid: 'pkkmWHNQW3WCrvfb',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/pkkmWHNQW3WCrvfb.full',
                        },
                        {
                            id: 3234245,
                            guid: 'UF5avmpTZVFBsiud',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/UF5avmpTZVFBsiud.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Inspect & trim the repair',
                lines: [
                    {
                        text_raw:
                            'Remove the items from the sewing machine and inspect the repair. The old edges will be uneven, but the new stitch should look secure and well fastened on both ends.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Remove the items from the sewing machine and inspect the repair. The old edges will be uneven, but the new stitch should look secure and well fastened on both ends.',
                    },
                    {
                        text_raw:
                            '***Tip: ***Turn the item right-way-round and inspect it from the outside too. Does it look correct? This will be the final appearance of the repair, so any small adjustment should be made at this stage, by making additional stitches or unpicking and re-stitching any errors.',
                        bullet: 'black',
                        level: 1,
                        lineid: null,
                        text_rendered:
                            '<strong>Tip: </strong>Turn the item right-way-round and inspect it from the outside too. Does it look correct? This will be the final appearance of the repair, so any small adjustment should be made at this stage, by making additional stitches or unpicking and re-stitching any errors.',
                    },
                    {
                        text_raw:
                            'Carefully cut away the excess fabric which is no longer needed. Make the cut carefully, and not too close to the stitching, or the repair will break during use. Remove any sharp corners of fabric to prevent any itchiness during use.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Carefully cut away the excess fabric which is no longer needed. Make the cut carefully, and not too close to the stitching, or the repair will break during use. Remove any sharp corners of fabric to prevent any itchiness during use.',
                    },
                ],
                guideid: 170249,
                stepid: 361524,
                orderby: 4,
                revisionid: 3333413,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234251,
                            guid: '2cenGw2xqodTZDPN',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2cenGw2xqodTZDPN.full',
                        },
                        {
                            id: 3234252,
                            guid: '5hNPSlDolPSbOw3P',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/5hNPSlDolPSbOw3P.full',
                        },
                        {
                            id: 3234253,
                            guid: 'pI5NJcQ6gaqYpVZh',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/pI5NJcQ6gaqYpVZh.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Finished and ready!',
                lines: [
                    {
                        text_raw:
                            "🛠️🏆👏 ***Congratulations***! The repair is complete, and you're ready to go!",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "🛠️🏆👏 <strong>Congratulations</strong>! The repair is complete, and you're ready to go!",
                    },
                    {
                        text_raw: 'Turn the item right-way-round, and give a final check.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Turn the item right-way-round, and give a final check.',
                    },
                ],
                guideid: 170249,
                stepid: 361525,
                orderby: 5,
                revisionid: 3333417,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 3234257,
                            guid: 'TnBKkBf5CWwpVUmF',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/TnBKkBf5CWwpVUmF.full',
                        },
                        {
                            id: 3234258,
                            guid: 'pUZSOLaY6xxsoCZY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/pUZSOLaY6xxsoCZY.full',
                        },
                        {
                            id: 3234259,
                            guid: 'l3HJLW4rUskSKjwL',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/l3HJLW4rUskSKjwL.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: "When doing sports, it's common to get some...",
        time_required: '10 minutes',
        time_required_min: 600,
        time_required_max: 600,
        title: 'How to repair a hole in woven textile (using a sewing machine)',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1981935,
        created_date: 1708423643,
        published_date: 1712308835,
        modified_date: 1716982554,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'INTERSPORT Apparel',
        url: 'https://www.ifixit.com/Guide/How+to+repair+a+hole+in+woven+textile+(using+a+sewing+machine)/170249',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 4351285,
            username: 'Ben Blischke',
            unique_username: 'intersport_ben',
            join_date: 1689836366,
            image: {
                id: 3181396,
                guid: '2I6I34UNHYXg5nLg',
                mini: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.200x150',
                standard: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.standard',
                '440x330': 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.440x330',
                original: 'https://guide-images.cdn.ifixit.com/igi/2I6I34UNHYXg5nLg.full',
            },
            reputation: 5411,
            url: 'https://www.ifixit.com/User/4351285/Ben+Blischke',
            teams: [47206],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    // Vaude
    69099: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69099,
        image: {
            id: 908867,
            guid: 'XKopZMBcGcwPSgd2',
            mini: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.full',
        },
        introduction_raw:
            'Taped seams can occasionally separate from a jacket due to incorrect product care or other outer influences.  It takes just a few steps to reattach them – we’ll show you how here.',
        introduction_rendered:
            '<p>Taped seams can occasionally separate from a jacket due to incorrect product care or other outer influences.  It takes just a few steps to reattach them – we’ll show you how here.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Set the iron at 150 °C maximum. Lay the dish towel on the affected area.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Set the iron at 150 °C maximum. Lay the dish towel on the affected area.',
                    },
                ],
                guideid: 69099,
                stepid: 139001,
                orderby: 1,
                revisionid: 1552547,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 908867,
                            guid: 'XKopZMBcGcwPSgd2',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/XKopZMBcGcwPSgd2.full',
                        },
                        {
                            id: 908868,
                            guid: 'oMM1bpGMwWPTPplV',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/oMM1bpGMwWPTPplV.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Press the iron down firmly on the affected area. Wait 10 seconds. Repeat if necessary.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Press the iron down firmly on the affected area. Wait 10 seconds. Repeat if necessary.',
                    },
                ],
                guideid: 69099,
                stepid: 139002,
                orderby: 2,
                revisionid: 1552550,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 908866,
                            guid: 'LQkZJFKPLIUrTrtw',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LQkZJFKPLIUrTrtw.full',
                        },
                        {
                            id: 908865,
                            guid: 'C2MN6ud3fjWaZDkB',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/C2MN6ud3fjWaZDkB.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Taped seams can occasionally separate from a...',
        time_required: '10 minutes',
        time_required_min: 600,
        time_required_max: 600,
        title: 'How do I repair taped seams on my Vaude jacket?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Flat Iron',
                notes: null,
                url: '/Item/B%C3%BCgeleisen',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Dish Towel',
                notes: null,
                url: 'https://www.amazon.com/Homaxy-Cotton-Waffle-Kitchen-Absorbent/dp/B07WMQP4SF/ref=sr_1_2?crid=2M0ZIE4C3K0U3&keywords=dish+towel&qid=1684952113&sprefix=dish+towel%2Caps%2C101&sr=8-2',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/R6WB1C5SuCQd563n.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1017603,
        created_date: 1476083135,
        published_date: 1481132541,
        modified_date: 1481132541,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'Tops',
        url: 'https://www.ifixit.com/Guide/How+do+I+repair+taped+seams+on+my+Vaude+jacket-/69099',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    69101: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69101,
        image: {
            id: 908903,
            guid: 'Kn1MYF6bZyoGbJAW',
            mini: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.full',
        },
        introduction_raw:
            'Occasionally, a cord stopper can come loose from the drawstring on your jacket. You can put it back on in just a few steps – read on to find out how.',
        introduction_rendered:
            '<p>Occasionally, a cord stopper can come loose from the drawstring on your jacket. You can put it back on in just a few steps – read on to find out how.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [
            {
                text: 'Cordstopper',
                notes: null,
                type: '',
                quantity: 1,
                url: 'https://www.vaude.com/de/de/12582-tanka-kordelstopper.html#?colour=451',
                thumbnail: null,
                isoptional: false,
            },
        ],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Thread the end of the drawstring through the grommet. Reach into the inner pocket and pull the drawstring through.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Thread the end of the drawstring through the grommet. Reach into the inner pocket and pull the drawstring through.',
                    },
                ],
                guideid: 69101,
                stepid: 139004,
                orderby: 1,
                revisionid: 1552555,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 908903,
                            guid: 'Kn1MYF6bZyoGbJAW',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Kn1MYF6bZyoGbJAW.full',
                        },
                        {
                            id: 908902,
                            guid: 'wQ3LKToFf3eBWtX2',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/wQ3LKToFf3eBWtX2.full',
                        },
                        {
                            id: 908901,
                            guid: 'uLx2VY1iSRX1X1Jp',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/uLx2VY1iSRX1X1Jp.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Thread the cord stopper onto the drawstring, tie a knot in the end and cut off the leftover drawstring.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Thread the cord stopper onto the drawstring, tie a knot in the end and cut off the leftover drawstring.',
                    },
                ],
                guideid: 69101,
                stepid: 139005,
                orderby: 2,
                revisionid: 1552558,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 908899,
                            guid: 'LeWQUWhlPgfyL2km',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LeWQUWhlPgfyL2km.full',
                        },
                        {
                            id: 908900,
                            guid: 'LZQmR2fNqClDkhjx',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LZQmR2fNqClDkhjx.full',
                        },
                        {
                            id: 908898,
                            guid: 'LgZOURtSdDOKFT2r',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LgZOURtSdDOKFT2r.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: 'Cord stopper on my Vaude jacket',
        summary: 'Occasionally, a cord stopper can come loose...',
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How do I replace a cord stopper on my Vaude jacket drawstring?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Scissors',
                notes: null,
                url: 'https://eustore.ifixit.com/products/utility-scissors',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/YZFc6xfCFTuHFhsP.thumbnail',
                isoptional: false,
            },
        ],
        type: 'replacement',
        revisionid: 1023995,
        created_date: 1476084701,
        published_date: 1481132626,
        modified_date: 1482777188,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'Outerwear',
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+a+cord+stopper+on+my+Vaude+jacket+drawstring-/69101',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    69102: {
        brandId: '122278',
        conclusion_raw: 'Done! You made it.',
        conclusion_rendered: '<p>Done! You made it.</p>',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69102,
        image: {
            id: 908919,
            guid: 'OHAHsJoGmH6uyO5W',
            mini: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.full',
        },
        introduction_raw:
            'VAUDE uses premium zips from YKK and other brand name manufacturers. Nevertheless, frequent or improper use can cause the slider to become bent or expand so the teeth no longer zip together.\n\nIt only takes a few steps to replace the slider – read on to find out how.',
        introduction_rendered:
            '<p>VAUDE uses premium zips from YKK and other brand name manufacturers. Nevertheless, frequent or improper use can cause the slider to become bent or expand so the teeth no longer zip together.</p>\n\n<p>It only takes a few steps to replace the slider – read on to find out how.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [
            {
                text: 'Zipper slider',
                notes: null,
                type: '',
                quantity: 1,
                url: 'https://www.vaude.com/de-DE/Wo-kaufen/Finde-einen-Haendler/Online-Haendler/',
                thumbnail: null,
                isoptional: false,
            },
            {
                text: 'Zip stop',
                notes: null,
                type: '',
                quantity: 2,
                url: '/Item/Krampen',
                thumbnail: null,
                isoptional: false,
            },
        ],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Select a replacement slider and zip stop.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Select a replacement slider and zip stop.',
                    },
                ],
                guideid: 69102,
                stepid: 139006,
                orderby: 1,
                revisionid: 1856005,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 908919,
                            guid: 'OHAHsJoGmH6uyO5W',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/OHAHsJoGmH6uyO5W.full',
                        },
                        {
                            id: 908917,
                            guid: 'rEERqPYOKaTGyX3Y',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/rEERqPYOKaTGyX3Y.full',
                        },
                        {
                            id: 908939,
                            guid: 'fFAfFZGkuXBTPD6J',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/fFAfFZGkuXBTPD6J.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Remove the zip stop at the end of the zip by prying it open with a wire cutter and pulling it away.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Remove the zip stop at the end of the zip by prying it open with a wire cutter and pulling it away.',
                    },
                ],
                guideid: 69102,
                stepid: 139007,
                orderby: 2,
                revisionid: 1552563,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 908916,
                            guid: 'BKnjxLUGIOCb26YN',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/BKnjxLUGIOCb26YN.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Remove the broken slider.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Remove the broken slider.',
                    },
                ],
                guideid: 69102,
                stepid: 139008,
                orderby: 3,
                revisionid: 1552565,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 908914,
                            guid: 'cy3RUJdiEl4iQsGm',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/cy3RUJdiEl4iQsGm.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Insert the new slider onto the zip and use the wire cutter to press the zip stop into the position where the old stop had been.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Insert the new slider onto the zip and use the wire cutter to press the zip stop into the position where the old stop had been.',
                    },
                ],
                guideid: 69102,
                stepid: 139009,
                orderby: 4,
                revisionid: 1552567,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 908910,
                            guid: '2ukcEhQheRSckrYU',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/2ukcEhQheRSckrYU.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Positon the zip stop together with the help of the tweezers.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Positon the zip stop together with the help of the tweezers.',
                    },
                ],
                guideid: 69102,
                stepid: 139010,
                orderby: 5,
                revisionid: 1557132,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 908922,
                            guid: 'RyCgTUfaueHDxdCK',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/RyCgTUfaueHDxdCK.full',
                        },
                        {
                            id: 908920,
                            guid: 'ibbNUdoiBtwEZIBj',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ibbNUdoiBtwEZIBj.full',
                        },
                        {
                            id: 908921,
                            guid: 'KlatmXAQGHRxk5c1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/KlatmXAQGHRxk5c1.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Pinch the zip stop together with the help of the tweezers.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Pinch the zip stop together with the help of the tweezers.',
                    },
                ],
                guideid: 69102,
                stepid: 139011,
                orderby: 6,
                revisionid: 1552571,
                media: {
                    type: 'video',
                    data: {
                        objectid: 645,
                        videoid: 569,
                        srcid: null,
                        image: {
                            image: {
                                id: 908940,
                                guid: 'oNlGbNEuRGavRGQS',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/oNlGbNEuRGavRGQS.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/oNlGbNEuRGavRGQS.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/oNlGbNEuRGavRGQS.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/oNlGbNEuRGavRGQS.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/oNlGbNEuRGavRGQS.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/oNlGbNEuRGavRGQS.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/oNlGbNEuRGavRGQS.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/oNlGbNEuRGavRGQS.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/oNlGbNEuRGavRGQS.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '02 fixing a cramp with tweezers VAUDE.mov',
                        duration: 16958,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307869243,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 16958,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 13064568,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6153,
                            channels: '2',
                            video_bitrate_in_kbps: 6025,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/dO1FyQHcRuCHJXeO_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Remove the zip puller (black) from the slider and thread it onto the new slider.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Remove the zip puller (black) from the slider and thread it onto the new slider.',
                    },
                ],
                guideid: 69102,
                stepid: 139012,
                orderby: 7,
                revisionid: 1552573,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 908912,
                            guid: 'M23wJwlPMxCTID5C',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/M23wJwlPMxCTID5C.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: 'zip slider',
        summary: 'VAUDE uses premium zips from YKK and other...',
        time_required: '15 minutes',
        time_required_min: 900,
        time_required_max: 900,
        title: 'How do I replace a broken zip slider on my Vaude clothing?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Flush Cutter',
                notes: null,
                url: 'https://eustore.ifixit.com/products/flush-cutter',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/TGjS3jPberQTAqR2.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Tweezers',
                notes: null,
                url: 'https://eustore.ifixit.com/products/tweezers',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/eQGTt3iN1MmOAQDy.thumbnail',
                isoptional: false,
            },
        ],
        type: 'replacement',
        revisionid: 1030255,
        created_date: 1476085295,
        published_date: 1481132584,
        modified_date: 1655998095,
        prereq_modified_date: 0,
        public: true,
        comments: [
            {
                commentid: 280366,
                locale: 'de',
                context: 'guide',
                contextid: 69102,
                parentid: null,
                author: {
                    userid: 1829764,
                    username: 'rosselba',
                    unique_username: 'rosselba',
                    join_date: 1481700968,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/1829764/rosselba',
                    teams: [],
                },
                title: 'How do I replace a broken zip slider on my Vaude clothing?',
                text_raw:
                    'Eine tolle Idee, diese Anleitungen und würde auch gerne mein geliebtes Radtrikot reparieren. Ich habe nur das Problem, dass ich den defekten Schieber von meinem Radtrikot Matera nicht identifizieren kann. Können Sie mir da weiter helfen?',
                text_rendered:
                    '<p>Eine tolle Idee, diese Anleitungen und würde auch gerne mein geliebtes Radtrikot reparieren. Ich habe nur das Problem, dass ich den defekten Schieber von meinem Radtrikot Matera nicht identifizieren kann. Können Sie mir da weiter helfen?</p>',
                rating: 0,
                date: 1481700968,
                modified_date: 1481700968,
                status: 'public',
                replies: [],
            },
            {
                commentid: 281113,
                locale: 'de',
                context: 'guide',
                contextid: 69102,
                parentid: null,
                author: {
                    userid: 1511745,
                    username: 'VAUDE Reparaturteam',
                    unique_username: 'vaudesport2',
                    join_date: 1456153000,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
                    },
                    reputation: 6303,
                    url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
                    teams: [12497],
                },
                title: 'How do I replace a broken zip slider on my Vaude clothing?',
                text_raw:
                    'Hallo Rosselba, Prinzipiell steht immer hinten auf den Slidern eine Buchstaben/ Zahlen - Kombi. Anhand der kann man ablesen welcher Slider als Ersatz passen würde.',
                text_rendered:
                    '<p>Hallo Rosselba, Prinzipiell steht immer hinten auf den Slidern eine Buchstaben/ Zahlen - Kombi. Anhand der kann man ablesen welcher Slider als Ersatz passen würde.</p>',
                rating: 0,
                date: 1481902485,
                modified_date: 1481902485,
                status: 'public',
                replies: [],
            },
            {
                commentid: 281696,
                locale: 'de',
                context: 'guide',
                contextid: 69102,
                parentid: null,
                author: {
                    userid: 1834775,
                    username: 'Jochen Klingbeil',
                    unique_username: 'jochenklingbeil',
                    join_date: 1482050959,
                    image: {
                        id: 992378,
                        guid: 'klnXAy1Hbwl4jIdb',
                        mini: 'https://guide-images.cdn.ifixit.com/igi/klnXAy1Hbwl4jIdb.mini',
                        thumbnail:
                            'https://guide-images.cdn.ifixit.com/igi/klnXAy1Hbwl4jIdb.thumbnail',
                        '140x105':
                            'https://guide-images.cdn.ifixit.com/igi/klnXAy1Hbwl4jIdb.140x105',
                        '200x150':
                            'https://guide-images.cdn.ifixit.com/igi/klnXAy1Hbwl4jIdb.200x150',
                        standard:
                            'https://guide-images.cdn.ifixit.com/igi/klnXAy1Hbwl4jIdb.standard',
                        '440x330':
                            'https://guide-images.cdn.ifixit.com/igi/klnXAy1Hbwl4jIdb.440x330',
                        medium: 'https://guide-images.cdn.ifixit.com/igi/klnXAy1Hbwl4jIdb.medium',
                        original: 'https://guide-images.cdn.ifixit.com/igi/klnXAy1Hbwl4jIdb.full',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/1834775/Jochen+Klingbeil',
                    teams: [],
                },
                title: 'How do I replace a broken zip slider on my Vaude clothing?',
                text_raw:
                    'Links zu den Ersatzteilen könnten besser sein. Komme immer zuerst auf Angebote für neue Kleidung..',
                text_rendered:
                    '<p>Links zu den Ersatzteilen könnten besser sein. Komme immer zuerst auf Angebote für neue Kleidung..</p>',
                rating: 0,
                date: 1482050960,
                modified_date: 1482050960,
                status: 'public',
                replies: [],
            },
            {
                commentid: 313628,
                locale: 'de',
                context: 'guide',
                contextid: 69102,
                parentid: null,
                author: {
                    userid: 1948473,
                    username: 'Emanuel',
                    unique_username: 'emanuel13',
                    join_date: 1490695905,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/1948473/Emanuel',
                    teams: [],
                },
                title: 'How do I replace a broken zip slider on my Vaude clothing?',
                text_raw:
                    'Bei ausgeleierten  Schiebern hilft es auch oft, diesen an der offenen Seite mit einer Zange leicht zusammenzudrücken. Das hat bei mir schon häufig funktioniert und es geht sehr schnell.\n\nBeste Grüße',
                text_rendered:
                    '<p>Bei ausgeleierten  Schiebern hilft es auch oft, diesen an der offenen Seite mit einer Zange leicht zusammenzudrücken. Das hat bei mir schon häufig funktioniert und es geht sehr schnell.</p>\n\n<p>Beste Grüße</p>',
                rating: 0,
                date: 1490695905,
                modified_date: 1490695938,
                status: 'public',
                replies: [],
            },
            {
                commentid: 318751,
                locale: 'en',
                context: 'guide',
                contextid: 69102,
                parentid: null,
                author: {
                    userid: 1965920,
                    username: 'order',
                    unique_username: 'order48200',
                    join_date: 1491997534,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/1965920/order',
                    teams: [],
                },
                title: 'How do I replace a broken zip slider on my Vaude clothing?',
                text_raw:
                    'Hi\n\nZipper sliders are available here: https://www.shelby.fi/?cPath=402_31_82\n\nFor example the type  in pictures: https://www.shelby.fi/product_info.php?products_id=2891\n\nStoppers available here: https://www.shelby.fi/product_info.php?cPath=402_31_82&products_id=1864\n\n(this one is easier to install than the one in pictured in instructions)',
                text_rendered:
                    '<p>Hi</p>\n\n<p>Zipper sliders are available here: <a href="https://www.shelby.fi/?cPath=402_31_82" rel="nofollow noopener noreferrer">https://www.shelby.fi/?cPath=402_31_82</a></p>\n\n<p>For example the type  in pictures: <a href="https://www.shelby.fi/product_info.php?products_id=2891" rel="nofollow noopener noreferrer" title="https://www.shelby.fi/product_info.php?products_id=2891">https://www.shelby.fi/product_info.php?p...</a></p>\n\n<p>Stoppers available here: <a href="https://www.shelby.fi/product_info.php?cPath=402_31_82&amp;products_id=1864" rel="nofollow noopener noreferrer" title="https://www.shelby.fi/product_info.php?cPath=402_31_82&amp;amp;products_id=1864">https://www.shelby.fi/product_info.php?c...</a></p>\n\n<p>(this one is easier to install than the one in pictured in instructions)</p>',
                rating: 0,
                date: 1491997536,
                modified_date: 1491997536,
                status: 'public',
                replies: [],
            },
            {
                commentid: 321941,
                locale: 'en',
                context: 'guide',
                contextid: 69102,
                parentid: null,
                author: {
                    userid: 1976390,
                    username: 'Shelby Oy',
                    unique_username: 'shelbyoutdoors',
                    join_date: 1492754775,
                    image: {
                        id: 1095622,
                        guid: 'quvAKvwbPLBUcIie',
                        mini: 'https://guide-images.cdn.ifixit.com/igi/quvAKvwbPLBUcIie.mini',
                        thumbnail:
                            'https://guide-images.cdn.ifixit.com/igi/quvAKvwbPLBUcIie.thumbnail',
                        medium: 'https://guide-images.cdn.ifixit.com/igi/quvAKvwbPLBUcIie.medium',
                        original: 'https://guide-images.cdn.ifixit.com/igi/quvAKvwbPLBUcIie.full',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/1976390/Shelby+Oy',
                    teams: [],
                },
                title: 'How do I replace a broken zip slider on my Vaude clothing?',
                text_raw:
                    'And when zipper has 2 sliders and it is broken due wear (most common), it always lots of sense to replace both upper and lower  slider.',
                text_rendered:
                    '<p>And when zipper has 2 sliders and it is broken due wear (most common), it always lots of sense to replace both upper and lower  slider.</p>',
                rating: 0,
                date: 1492755028,
                modified_date: 1492755028,
                status: 'public',
                replies: [],
            },
        ],
        category: 'Zipper',
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+a+broken+zip+slider+on+my+Vaude+clothing-/69102',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    69105: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69105,
        image: {
            id: 908985,
            guid: '6draOBRknNP2fuNG',
            mini: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/6draOBRknNP2fuNG.full',
        },
        introduction_raw:
            'Zips often become harder to open or close. Most of the time, lubricating the zip helps it run smoothly again. We’ll show you how you can lubricate a zip on a jacket, but it works just as well on other zips such as on a tent or backpack.',
        introduction_rendered:
            '<p>Zips often become harder to open or close. Most of the time, lubricating the zip helps it run smoothly again. We’ll show you how you can lubricate a zip on a jacket, but it works just as well on other zips such as on a tent or backpack.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Open the zip if possible. Spray a small amount of silicone spray at both openings of the slider. Move the slider up and down several times to distribute the silicone.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Open the zip if possible. Spray a small amount of silicone spray at both openings of the slider. Move the slider up and down several times to distribute the silicone.',
                    },
                ],
                guideid: 69105,
                stepid: 139017,
                orderby: 1,
                revisionid: 1552584,
                media: {
                    type: 'video',
                    data: {
                        objectid: 646,
                        videoid: 570,
                        srcid: null,
                        image: {
                            image: {
                                id: 908980,
                                guid: 'fVR4pDAFS1sH6nwk',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/fVR4pDAFS1sH6nwk.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/fVR4pDAFS1sH6nwk.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/fVR4pDAFS1sH6nwk.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/fVR4pDAFS1sH6nwk.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/fVR4pDAFS1sH6nwk.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/fVR4pDAFS1sH6nwk.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/fVR4pDAFS1sH6nwk.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/fVR4pDAFS1sH6nwk.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/fVR4pDAFS1sH6nwk.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: 'oiling a rough running zipper VAUDE.mov',
                        duration: 27125,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307876430,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 27125,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 20347280,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 5993,
                            channels: '2',
                            video_bitrate_in_kbps: 5865,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/MWal1wQSZxj2HguC_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [
                    {
                        commentid: 409588,
                        locale: 'de',
                        context: 'step',
                        contextid: 139017,
                        parentid: null,
                        author: {
                            userid: 1393082,
                            username: 'Juvallos',
                            unique_username: 'juvallos',
                            join_date: 1446918571,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.large',
                            },
                            reputation: 119,
                            url: 'https://www.ifixit.com/User/1393082/Juvallos',
                            teams: [],
                        },
                        title: 'How do I lubricate a sticky zip on my Vaude clothing?',
                        text_raw:
                            'Das Funktioniert auch mit Graphit. Natürlich kann man sich Graphitöl oder -pulver besorgen, oder einfach eine Bleistiftmine ein paar mal über die Zähne reiben.\n\nAchtung: Natürlich entsteht dabei der graue Graphitstaub, also Vorsicht bei weißer und heller Kleidung!',
                        text_rendered:
                            '<p>Das Funktioniert auch mit Graphit. Natürlich kann man sich Graphitöl oder -pulver besorgen, oder einfach eine Bleistiftmine ein paar mal über die Zähne reiben.</p>\n\n<p>Achtung: Natürlich entsteht dabei der graue Graphitstaub, also Vorsicht bei weißer und heller Kleidung!</p>',
                        rating: 0,
                        date: 1518129792,
                        modified_date: 1518130004,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
        ],
        subject: '',
        summary: 'Zips often become harder to open or close. Most...',
        time_required: '5 minutes',
        time_required_min: 300,
        time_required_max: 300,
        title: 'How do I lubricate a sticky zip on my Vaude clothing?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Silicone spray',
                notes: null,
                url: 'https://www.reidl.de/katalog/105639-fuer-die-Kunststofftechnik/874224-Silicon-Trennmittel-Spray400ml-OKS-1361.html?gclid=CPzCobToz88CFQgz0wodlioByA',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1017581,
        created_date: 1476087663,
        published_date: 1481132611,
        modified_date: 1481132611,
        prereq_modified_date: 0,
        public: true,
        comments: [
            {
                commentid: 712785,
                locale: 'en',
                context: 'guide',
                contextid: 69105,
                parentid: null,
                author: {
                    userid: 3815950,
                    username: 'sotarto',
                    unique_username: 'sotarto',
                    join_date: 1610714708,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/3815950/sotarto',
                    teams: [],
                },
                title: 'How do I lubricate a sticky zip on my Vaude clothing?',
                text_raw:
                    "Hi Dear,\n\nYou can use a soapbar also. The soap needs to be dried before use as lubricant. Just rub it a couple of times onto the full length of zipper, then pull the zipper up and down a few. That's all.\n\nGrandma's trick :)",
                text_rendered:
                    "<p>Hi Dear,</p>\n\n<p>You can use a soapbar also. The soap needs to be dried before use as lubricant. Just rub it a couple of times onto the full length of zipper, then pull the zipper up and down a few. That's all.</p>\n\n<p>Grandma's trick :)</p>",
                rating: 0,
                date: 1610714708,
                modified_date: 1610714708,
                status: 'public',
                replies: [],
            },
        ],
        category: 'Zipper',
        url: 'https://www.ifixit.com/Guide/How+do+I+lubricate+a+sticky+zip+on+my+Vaude+clothing-/69105',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    69115: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Difficult',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69115,
        image: {
            id: 909044,
            guid: 'HnFokidbJhDKAVMV',
            mini: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/HnFokidbJhDKAVMV.full',
        },
        introduction_raw:
            'If the zip on your jacket breaks, sometimes the only solution is to take out the entire zip and replace it with a new one. We’ll show you the steps to take here. You’ll need to be able to use a sewing machine.',
        introduction_rendered:
            '<p>If the zip on your jacket breaks, sometimes the only solution is to take out the entire zip and replace it with a new one. We’ll show you the steps to take here. You’ll need to be able to use a sewing machine.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [
            {
                text: 'Zipper',
                notes: null,
                type: '',
                quantity: 1,
                url: '/Item/Ersatzrei%C3%9Fverschluss',
                thumbnail: null,
                isoptional: false,
            },
            {
                text: 'Zip stop',
                notes: null,
                type: '',
                quantity: 2,
                url: '/Item/Krampen',
                thumbnail: null,
                isoptional: false,
            },
        ],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Separate the seams of the broken zip using the seam ripper. It’s best to begin on the outside of the jacket.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Separate the seams of the broken zip using the seam ripper. It’s best to begin on the outside of the jacket.',
                    },
                ],
                guideid: 69115,
                stepid: 139048,
                orderby: 1,
                revisionid: 1580092,
                media: {
                    type: 'video',
                    data: {
                        objectid: 650,
                        videoid: 574,
                        srcid: null,
                        image: {
                            image: {
                                id: 909021,
                                guid: 'LIwsKBYbBB6HAYMd',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/LIwsKBYbBB6HAYMd.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/LIwsKBYbBB6HAYMd.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/LIwsKBYbBB6HAYMd.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/LIwsKBYbBB6HAYMd.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/LIwsKBYbBB6HAYMd.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/LIwsKBYbBB6HAYMd.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/LIwsKBYbBB6HAYMd.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/LIwsKBYbBB6HAYMd.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/LIwsKBYbBB6HAYMd.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '03 separating a seam VAUDE.mov',
                        duration: 16292,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307883278,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 16292,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 12557271,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6156,
                            channels: '2',
                            video_bitrate_in_kbps: 6028,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/Adri4nfIZUGXiEtj_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Next, separate the seam between the jacket and the front flap.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Next, separate the seam between the jacket and the front flap.',
                    },
                ],
                guideid: 69115,
                stepid: 139049,
                orderby: 2,
                revisionid: 1557178,
                media: {
                    type: 'video',
                    data: {
                        objectid: 651,
                        videoid: 575,
                        srcid: null,
                        image: {
                            image: {
                                id: 909022,
                                guid: 'g3ADFMuXREVgrckJ',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/g3ADFMuXREVgrckJ.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/g3ADFMuXREVgrckJ.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/g3ADFMuXREVgrckJ.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/g3ADFMuXREVgrckJ.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/g3ADFMuXREVgrckJ.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/g3ADFMuXREVgrckJ.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/g3ADFMuXREVgrckJ.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/g3ADFMuXREVgrckJ.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/g3ADFMuXREVgrckJ.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '04 separating the front flap at a jacket VAUDE.mov',
                        duration: 17125,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307883927,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 17125,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 13342432,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6223,
                            channels: '2',
                            video_bitrate_in_kbps: 6095,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/1ZKCPfsXNa5tfwtB_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'After you´ve separated the seam between the jacket and the front flap,  you’ll see the lining of your jacket.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'After you´ve separated the seam between the jacket and the front flap,  you’ll see the lining of your jacket.',
                    },
                ],
                guideid: 69115,
                stepid: 142097,
                orderby: 3,
                revisionid: 1574744,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 914805,
                            guid: 'oNR5Z2JZ5Fa2nLIi',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/oNR5Z2JZ5Fa2nLIi.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Then you can separate the zip from the jacket.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Then you can separate the zip from the jacket.',
                    },
                ],
                guideid: 69115,
                stepid: 139054,
                orderby: 4,
                revisionid: 1557184,
                media: {
                    type: 'video',
                    data: {
                        objectid: 652,
                        videoid: 576,
                        srcid: null,
                        image: {
                            image: {
                                id: 909023,
                                guid: 'kjjdq2TJNbofIfsJ',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/kjjdq2TJNbofIfsJ.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/kjjdq2TJNbofIfsJ.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/kjjdq2TJNbofIfsJ.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/kjjdq2TJNbofIfsJ.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/kjjdq2TJNbofIfsJ.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/kjjdq2TJNbofIfsJ.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/kjjdq2TJNbofIfsJ.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/kjjdq2TJNbofIfsJ.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/kjjdq2TJNbofIfsJ.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '05 separating the zipper from the jacket VAUDE.mov',
                        duration: 23167,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307884170,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 23167,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 17799805,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6138,
                            channels: '2',
                            video_bitrate_in_kbps: 6010,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/PTYeMIYnXEyEkMAc_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'After you’ve separated the zip and front flap from the jacket, you’ll need to separate the zip from the front flap. You should then have both sides of the zip and the front flap laying before you.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'After you’ve separated the zip and front flap from the jacket, you’ll need to separate the zip from the front flap. You should then have both sides of the zip and the front flap laying before you.',
                    },
                ],
                guideid: 69115,
                stepid: 139055,
                orderby: 5,
                revisionid: 1557186,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909043,
                            guid: 'UtS5uZwCbJDerp5V',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/UtS5uZwCbJDerp5V.full',
                        },
                        {
                            id: 909041,
                            guid: 'lWCWBgmBBOTEcUey',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/lWCWBgmBBOTEcUey.full',
                        },
                        {
                            id: 909040,
                            guid: 'XlUrOgQLEIkDypVu',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/XlUrOgQLEIkDypVu.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Measure the length of the zip that you’ve removed and mark the length on the new zip. Use the tweezers to crimp the zip stop onto the new zip in the correct position.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Measure the length of the zip that you’ve removed and mark the length on the new zip. Use the tweezers to crimp the zip stop onto the new zip in the correct position.',
                    },
                ],
                guideid: 69115,
                stepid: 139056,
                orderby: 6,
                revisionid: 1557188,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909037,
                            guid: 'Ynu5TnFKk3vMTkxK',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Ynu5TnFKk3vMTkxK.full',
                        },
                        {
                            id: 909038,
                            guid: 'myIHgZLS2hQNIAwX',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/myIHgZLS2hQNIAwX.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Cut off any excess zip approx. 2 cm beyond the zip stop.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Cut off any excess zip approx. 2 cm beyond the zip stop.',
                    },
                ],
                guideid: 69115,
                stepid: 139057,
                orderby: 7,
                revisionid: 1567098,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 936374,
                            guid: 'rZZk4IWFxtaURcWD',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/rZZk4IWFxtaURcWD.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Use the wire cutters to pry off the zip teeth after the stopper.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Use the wire cutters to pry off the zip teeth after the stopper.',
                    },
                ],
                guideid: 69115,
                stepid: 139058,
                orderby: 8,
                revisionid: 1557192,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909036,
                            guid: 'LhI1UBt4wO3GqCac',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LhI1UBt4wO3GqCac.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Then remove any leftover pieces with the seam ripper.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Then remove any leftover pieces with the seam ripper.',
                    },
                ],
                guideid: 69115,
                stepid: 142098,
                orderby: 9,
                revisionid: 1567068,
                media: {
                    type: 'video',
                    data: {
                        objectid: 722,
                        videoid: 634,
                        srcid: null,
                        image: {
                            image: {
                                id: 936247,
                                guid: '3KaUMhORNtR4V2Vm',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/3KaUMhORNtR4V2Vm.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/3KaUMhORNtR4V2Vm.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/3KaUMhORNtR4V2Vm.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/3KaUMhORNtR4V2Vm.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/3KaUMhORNtR4V2Vm.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/3KaUMhORNtR4V2Vm.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/3KaUMhORNtR4V2Vm.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/3KaUMhORNtR4V2Vm.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/3KaUMhORNtR4V2Vm.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '06 removing zipper tooths VAUDE.mov',
                        duration: 30042,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 316208773,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 30042,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 23972536,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6160,
                            channels: '2',
                            video_bitrate_in_kbps: 6032,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/YmOqPD2tPmxcP1Ha_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Fold over the seam allowance of the zip and sew it down with the sewing machine.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Fold over the seam allowance of the zip and sew it down with the sewing machine.',
                    },
                ],
                guideid: 69115,
                stepid: 139060,
                orderby: 10,
                revisionid: 1557196,
                media: {
                    type: 'video',
                    data: {
                        objectid: 654,
                        videoid: 578,
                        srcid: null,
                        image: {
                            image: {
                                id: 909048,
                                guid: 'B2MZWbYYhcMaotbW',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/B2MZWbYYhcMaotbW.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/B2MZWbYYhcMaotbW.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/B2MZWbYYhcMaotbW.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/B2MZWbYYhcMaotbW.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/B2MZWbYYhcMaotbW.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/B2MZWbYYhcMaotbW.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/B2MZWbYYhcMaotbW.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/B2MZWbYYhcMaotbW.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/B2MZWbYYhcMaotbW.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '07 folding and fixing end of new zipper VAUDE.mov',
                        duration: 21542,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307887381,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 21542,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 16388993,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6078,
                            channels: '2',
                            video_bitrate_in_kbps: 5950,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/HnusQprdWuE5MdGY_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Put the inlay aside.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Put the inlay aside.',
                    },
                ],
                guideid: 69115,
                stepid: 142099,
                orderby: 11,
                revisionid: 1574750,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 914794,
                            guid: 'nOAPcCKjNP2TOQdE',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/nOAPcCKjNP2TOQdE.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Position the pocket attachment correctly and sew it on to the closure edge.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Position the pocket attachment correctly and sew it on to the closure edge.',
                    },
                ],
                guideid: 69115,
                stepid: 139061,
                orderby: 12,
                revisionid: 1557198,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909028,
                            guid: 'k6nmEjJS1QHThnkj',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/k6nmEjJS1QHThnkj.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Sew the zip onto the front flap using the zip foot.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Sew the zip onto the front flap using the zip foot.',
                    },
                ],
                guideid: 69115,
                stepid: 139062,
                orderby: 13,
                revisionid: 1876603,
                media: {
                    type: 'video',
                    data: {
                        objectid: 655,
                        videoid: 579,
                        srcid: null,
                        image: {
                            image: {
                                id: 909049,
                                guid: 'lD4CXJWYKI2FlVwV',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/lD4CXJWYKI2FlVwV.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/lD4CXJWYKI2FlVwV.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/lD4CXJWYKI2FlVwV.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/lD4CXJWYKI2FlVwV.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/lD4CXJWYKI2FlVwV.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/lD4CXJWYKI2FlVwV.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/lD4CXJWYKI2FlVwV.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/lD4CXJWYKI2FlVwV.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/lD4CXJWYKI2FlVwV.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '08 stitching the new zipper on front flap VAUDE.mov',
                        duration: 20542,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307887928,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 20542,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 15716943,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6112,
                            channels: '2',
                            video_bitrate_in_kbps: 5984,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/aKVhUTX6kTWFqsoY_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Sew the front flap with the zip attached onto the jacket lining.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Sew the front flap with the zip attached onto the jacket lining.',
                    },
                ],
                guideid: 69115,
                stepid: 139063,
                orderby: 14,
                revisionid: 1567073,
                media: {
                    type: 'video',
                    data: {
                        objectid: 723,
                        videoid: 635,
                        srcid: null,
                        image: {
                            image: {
                                id: 936250,
                                guid: 'VuDTKTyRfWStTVdP',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/VuDTKTyRfWStTVdP.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/VuDTKTyRfWStTVdP.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/VuDTKTyRfWStTVdP.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/VuDTKTyRfWStTVdP.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/VuDTKTyRfWStTVdP.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/VuDTKTyRfWStTVdP.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/VuDTKTyRfWStTVdP.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/VuDTKTyRfWStTVdP.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/VuDTKTyRfWStTVdP.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '09 stitching the front flap on the Jacket VAUDE.mov',
                        duration: 29958,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 316209812,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 29958,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 23173008,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6165,
                            channels: '2',
                            video_bitrate_in_kbps: 6037,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/ZAVS1y1ZaxeVaMpW_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Sew the remaining zip half (without flap) onto the other side of the open jacket.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Sew the remaining zip half (without flap) onto the other side of the open jacket.',
                    },
                ],
                guideid: 69115,
                stepid: 139065,
                orderby: 15,
                revisionid: 1557205,
                media: {
                    type: 'video',
                    data: {
                        objectid: 656,
                        videoid: 580,
                        srcid: null,
                        image: {
                            image: {
                                id: 909051,
                                guid: 'nejxxowUJnIRSt62',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/nejxxowUJnIRSt62.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/nejxxowUJnIRSt62.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/nejxxowUJnIRSt62.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/nejxxowUJnIRSt62.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/nejxxowUJnIRSt62.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/nejxxowUJnIRSt62.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/nejxxowUJnIRSt62.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/nejxxowUJnIRSt62.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/nejxxowUJnIRSt62.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '10 stitching the zipper on the underlap VAUDE .mov',
                        duration: 23458,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307888647,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 23458,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 17929350,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6106,
                            channels: '2',
                            video_bitrate_in_kbps: 5978,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/VToN2mm5kFX2onRw_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Sew along the zip again on the outer fabric.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Sew along the zip again on the outer fabric.',
                    },
                ],
                guideid: 69115,
                stepid: 139066,
                orderby: 16,
                revisionid: 1567077,
                media: {
                    type: 'video',
                    data: {
                        objectid: 724,
                        videoid: 636,
                        srcid: null,
                        image: {
                            image: {
                                id: 936252,
                                guid: '1EZxEPwUjQ4xGLSl',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/1EZxEPwUjQ4xGLSl.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/1EZxEPwUjQ4xGLSl.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/1EZxEPwUjQ4xGLSl.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/1EZxEPwUjQ4xGLSl.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/1EZxEPwUjQ4xGLSl.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/1EZxEPwUjQ4xGLSl.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/1EZxEPwUjQ4xGLSl.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/1EZxEPwUjQ4xGLSl.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/1EZxEPwUjQ4xGLSl.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '11 turning around the fixed zipper VAUDE.mov',
                        duration: 29958,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 316210737,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 29958,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 23064670,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6153,
                            channels: '2',
                            video_bitrate_in_kbps: 6025,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/myBhmygjHYlk4Scy_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Smooth out the other half of the jacket, aligning the two sides together using a seam that comes together or other design element. This is important so that the zip is correctly aligned at the end.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Smooth out the other half of the jacket, aligning the two sides together using a seam that comes together or other design element. This is important so that the zip is correctly aligned at the end.',
                    },
                ],
                guideid: 69115,
                stepid: 139067,
                orderby: 17,
                revisionid: 1557209,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909030,
                            guid: 'pa5G3emD6KNSbf4T',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/pa5G3emD6KNSbf4T.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Then sew the second side of the zip on. Move the slider as you sew to keep it out of the way.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Then sew the second side of the zip on. Move the slider as you sew to keep it out of the way.',
                    },
                ],
                guideid: 69115,
                stepid: 139068,
                orderby: 18,
                revisionid: 1567080,
                media: {
                    type: 'video',
                    data: {
                        objectid: 725,
                        videoid: 637,
                        srcid: null,
                        image: {
                            image: {
                                id: 936261,
                                guid: 'sfr2jWFQjamvWywd',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/sfr2jWFQjamvWywd.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/sfr2jWFQjamvWywd.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/sfr2jWFQjamvWywd.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/sfr2jWFQjamvWywd.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/sfr2jWFQjamvWywd.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/sfr2jWFQjamvWywd.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/sfr2jWFQjamvWywd.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/sfr2jWFQjamvWywd.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/sfr2jWFQjamvWywd.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '12 turing around the fixed zipper_02 VAUDE.mov',
                        duration: 29958,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 316211667,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 29958,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 23301931,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6200,
                            channels: '2',
                            video_bitrate_in_kbps: 6072,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/MfoJ5s6QdUZIRmTH_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'The last step is to re-sew the hem seam. If any sewing holes are still visible in the jacket, you can close them using the steam setting on an iron. Be sure that the iron does not touch the jacket!',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'The last step is to re-sew the hem seam. If any sewing holes are still visible in the jacket, you can close them using the steam setting on an iron. Be sure that the iron does not touch the jacket!',
                    },
                ],
                guideid: 69115,
                stepid: 139069,
                orderby: 19,
                revisionid: 1557213,
                media: {
                    type: 'video',
                    data: {
                        objectid: 657,
                        videoid: 581,
                        srcid: null,
                        image: {
                            image: {
                                id: 909052,
                                guid: 'Ws1mNcIDwLAgY5RI',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/Ws1mNcIDwLAgY5RI.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/Ws1mNcIDwLAgY5RI.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/Ws1mNcIDwLAgY5RI.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/Ws1mNcIDwLAgY5RI.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/Ws1mNcIDwLAgY5RI.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/Ws1mNcIDwLAgY5RI.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/Ws1mNcIDwLAgY5RI.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/Ws1mNcIDwLAgY5RI.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/Ws1mNcIDwLAgY5RI.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '13 final stitching of the zipper VAUDE.mov',
                        duration: 28042,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307889403,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 28042,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 21599818,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6154,
                            channels: '2',
                            video_bitrate_in_kbps: 6026,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/MFUqAeXfqVcqX52L_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
        ],
        subject: 'A zip on a jacket',
        summary: 'If the zip on your jacket breaks, sometimes the...',
        time_required: '2 hours',
        time_required_min: 7200,
        time_required_max: 7200,
        title: 'How do I replace a broken zip on my Vaude jacket?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Yarn',
                notes: null,
                url: '/Item/Yarn',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Seam Ripper',
                notes: null,
                url: 'http://www.amazon.com/Clover-463-Seam-Ripper/dp/B000YZ8MVU/ref=sr_1_1?ie=UTF8&qid=1346440445&sr=8-1&keywords=seam+ripper',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Fabric Scissors',
                notes: null,
                url: 'http://www.amazon.com/Gingher-8-Inch-Knife-Dressmakers-Shears/dp/B000UU6SR4/',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/X6YchoNrn3JofSsY.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Tweezers',
                notes: null,
                url: 'https://eustore.ifixit.com/products/tweezers',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/eQGTt3iN1MmOAQDy.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Flush Cutter',
                notes: null,
                url: 'https://eustore.ifixit.com/products/flush-cutter',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/TGjS3jPberQTAqR2.thumbnail',
                isoptional: false,
            },
        ],
        type: 'replacement',
        revisionid: 1017595,
        created_date: 1476089845,
        published_date: 1481132854,
        modified_date: 1481132855,
        prereq_modified_date: 0,
        public: true,
        comments: [
            {
                commentid: 354836,
                locale: 'de',
                context: 'guide',
                contextid: 69115,
                parentid: null,
                author: {
                    userid: 2122353,
                    username: 'Eva',
                    unique_username: 'evala',
                    join_date: 1502652106,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/2122353/Eva',
                    teams: [],
                },
                title: 'How do I replace a broken zip on my Vaude jacket?',
                text_raw:
                    'Prima Anleitung, dankeschön\n\nFür den Hausgebrauch schlage ich vor, den Nähfuß zwischen den Schritten zu wechseln.\n\nFür die Naht einen Standard-Fuß und für den Reißverschluss den Reißverschluss-Fuß.',
                text_rendered:
                    '<p>Prima Anleitung, dankeschön</p>\n\n<p>Für den Hausgebrauch schlage ich vor, den Nähfuß zwischen den Schritten zu wechseln.</p>\n\n<p>Für die Naht einen Standard-Fuß und für den Reißverschluss den Reißverschluss-Fuß.</p>',
                rating: 0,
                date: 1502652494,
                modified_date: 1502652494,
                status: 'public',
                replies: [],
            },
            {
                commentid: 947105,
                locale: 'en',
                context: 'guide',
                contextid: 69115,
                parentid: null,
                author: {
                    userid: 4484053,
                    username: 'Eleonore Hatfield',
                    unique_username: 'eleonoreha71192',
                    join_date: 1711458914,
                    image: {
                        id: 3264245,
                        guid: 'shvUAJFNoaMrhFKX',
                        mini: 'https://guide-images.cdn.ifixit.com/igi/shvUAJFNoaMrhFKX.mini',
                        thumbnail:
                            'https://guide-images.cdn.ifixit.com/igi/shvUAJFNoaMrhFKX.thumbnail',
                        original: 'https://guide-images.cdn.ifixit.com/igi/shvUAJFNoaMrhFKX.full',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/4484053/Eleonore+Hatfield',
                    teams: [],
                },
                title: 'How do I replace a broken zip on my Vaude jacket?',
                text_raw:
                    'Great videos thank you! My only question is how do you ensure you are catching the linning and outer fabric as you sew down the top side?',
                text_rendered:
                    '<p>Great videos thank you! My only question is how do you ensure you are catching the linning and outer fabric as you sew down the top side?</p>',
                rating: 0,
                date: 1711460045,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
        ],
        category: 'Zipper',
        url: 'https://www.ifixit.com/Guide/How+do+I+replace+a+broken+zip+on+my+Vaude+jacket-/69115',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    69137: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69137,
        image: {
            id: 909195,
            guid: 'YUnbHtRArnHNQ2KY',
            mini: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/YUnbHtRArnHNQ2KY.full',
        },
        introduction_raw:
            'A two-way zip (two sliders for opening the zip from either the top or the bottom) can sometimes create a split zip where the teeth no longer align with each other. We’ll show you how to fix this in just a few easy steps.',
        introduction_rendered:
            '<p>A two-way zip (two sliders for opening the zip from either the top or the bottom) can sometimes create a split zip where the teeth no longer align with each other. We’ll show you how to fix this in just a few easy steps.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Carefully open the zip using the seam ripper.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Carefully open the zip using the seam ripper.',
                    },
                ],
                guideid: 69137,
                stepid: 139088,
                orderby: 1,
                revisionid: 1552722,
                media: {
                    type: 'video',
                    data: {
                        objectid: 661,
                        videoid: 585,
                        srcid: null,
                        image: {
                            image: {
                                id: 909192,
                                guid: 'gyYfKkM5hWIgRH2b',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/gyYfKkM5hWIgRH2b.full',
                            },
                            srcid: null,
                            width: 1920,
                            height: 1080,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '02 separating the zipper thooths .mov',
                        duration: 7040,
                        width: 1920,
                        height: 1080,
                        meta: {
                            id: 307916912,
                            format: 'mpeg4',
                            frame_rate: 25,
                            duration_in_ms: 7040,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 1080,
                            width: 1920,
                            file_size_in_bytes: 9063359,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 10282,
                            channels: '2',
                            video_bitrate_in_kbps: 10154,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/hZBuEq1J2npDLf4U_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [
                    {
                        commentid: 792534,
                        locale: 'en',
                        context: 'step',
                        contextid: 139088,
                        parentid: null,
                        author: {
                            userid: 4095369,
                            username: 'H.M. Krämer',
                            unique_username: 'hmkraemer',
                            join_date: 1645729703,
                            image: {
                                id: 2588240,
                                guid: 'mJ1OqweMFcSLd2Qc',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/mJ1OqweMFcSLd2Qc.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/mJ1OqweMFcSLd2Qc.thumbnail',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/mJ1OqweMFcSLd2Qc.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/mJ1OqweMFcSLd2Qc.full',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/4095369/H.M.+Kr%C3%A4mer',
                            teams: [],
                        },
                        title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                        text_raw: 'Super! Hat funktioniert! Danke :))',
                        text_rendered: '<p>Super! Hat funktioniert! Danke :))</p>',
                        rating: 0,
                        date: 1645729704,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Guide the slider back onto the end of the zip.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Guide the slider back onto the end of the zip.',
                    },
                    {
                        text_raw:
                            'Pull the lower slider up a bit so that there’s an “opening” between the two sliders.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Pull the lower slider up a bit so that there’s an “opening” between the two sliders.',
                    },
                ],
                guideid: 69137,
                stepid: 139089,
                orderby: 2,
                revisionid: 1552724,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909197,
                            guid: 'HGOmxakBnEEhxceZ',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/HGOmxakBnEEhxceZ.full',
                        },
                        {
                            id: 909207,
                            guid: 'EIRrYsHphvKATjSc',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/EIRrYsHphvKATjSc.full',
                        },
                        {
                            id: 909193,
                            guid: '5YDniJyeno2HS2NU',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/5YDniJyeno2HS2NU.full',
                        },
                    ],
                },
                comments: [
                    {
                        commentid: 958574,
                        locale: 'de',
                        context: 'step',
                        contextid: 139089,
                        parentid: null,
                        author: {
                            userid: 4515815,
                            username: 'Michael Schmidt',
                            unique_username: 'michaelsch75345',
                            join_date: 1716988814,
                            image: {
                                id: 3332379,
                                guid: 'JSSfTdJLIEYjoPuI',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/JSSfTdJLIEYjoPuI.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/JSSfTdJLIEYjoPuI.thumbnail',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/JSSfTdJLIEYjoPuI.full',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/4515815/Michael+Schmidt',
                            teams: [],
                        },
                        title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                        text_raw: 'Danke für den Tipp\n\nHat nach dem 4. Versuch geklappt',
                        text_rendered:
                            '<p>Danke für den Tipp</p>\n\n<p>Hat nach dem 4. Versuch geklappt</p>',
                        rating: 0,
                        date: 1716988816,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'With a small jerk, pull the upper zip slider downwards.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'With a small jerk, pull the upper zip slider downwards.',
                    },
                ],
                guideid: 69137,
                stepid: 139090,
                orderby: 3,
                revisionid: 1562243,
                media: {
                    type: 'video',
                    data: {
                        objectid: 662,
                        videoid: 586,
                        srcid: null,
                        image: {
                            image: {
                                id: 909213,
                                guid: 'PELQwac1N1Z1qtH5',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/PELQwac1N1Z1qtH5.full',
                            },
                            srcid: null,
                            width: 1920,
                            height: 1080,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '01 pulling the zipper silder VAUDE.mov',
                        duration: 4440,
                        width: 1920,
                        height: 1080,
                        meta: {
                            id: 307917559,
                            format: 'mpeg4',
                            frame_rate: 25,
                            duration_in_ms: 4440,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 1080,
                            width: 1920,
                            file_size_in_bytes: 5735272,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 10310,
                            channels: '2',
                            video_bitrate_in_kbps: 10182,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/hBD4yBc1GPVelFmy_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [
                    {
                        commentid: 788579,
                        locale: 'de',
                        context: 'step',
                        contextid: 139090,
                        parentid: null,
                        author: {
                            userid: 4085716,
                            username: 'Tessa',
                            unique_username: 'tessa20333',
                            join_date: 1643807470,
                            image: {
                                id: 2574773,
                                guid: 'rJKtXZOWomBg6glS',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/rJKtXZOWomBg6glS.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/rJKtXZOWomBg6glS.thumbnail',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/rJKtXZOWomBg6glS.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/rJKtXZOWomBg6glS.full',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/4085716/Tessa',
                            teams: [],
                        },
                        title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                        text_raw: 'Beim zweiten Versuch hat es geklappt. Danke für die Anleitung.',
                        text_rendered:
                            '<p>Beim zweiten Versuch hat es geklappt. Danke für die Anleitung.</p>',
                        rating: 0,
                        date: 1643807471,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 789422,
                        locale: 'en',
                        context: 'step',
                        contextid: 139090,
                        parentid: null,
                        author: {
                            userid: 4087667,
                            username: 'dustin sandwich',
                            unique_username: 'dustinsandwich',
                            join_date: 1644213590,
                            image: {
                                id: 2578052,
                                guid: 'BPxwN5JvXGQVCB61',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/BPxwN5JvXGQVCB61.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/BPxwN5JvXGQVCB61.thumbnail',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/BPxwN5JvXGQVCB61.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/BPxwN5JvXGQVCB61.full',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/4087667/dustin+sandwich',
                            teams: [],
                        },
                        title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                        text_raw:
                            'Man, thank you. I used the awl on a Swiss army knife. Saved my $600 jacket, and the only good solution I could find anywhere on the internet.',
                        text_rendered:
                            '<p>Man, thank you. I used the awl on a Swiss army knife. Saved my $600 jacket, and the only good solution I could find anywhere on the internet.</p>',
                        rating: 0,
                        date: 1644213590,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 801084,
                        locale: 'de',
                        context: 'step',
                        contextid: 139090,
                        parentid: null,
                        author: {
                            userid: 4117772,
                            username: 'Abraxas',
                            unique_username: 'abraxas31635',
                            join_date: 1650136380,
                            image: {
                                id: 2629244,
                                guid: 'Dlf4CQDc3JqNJ4Xb',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/Dlf4CQDc3JqNJ4Xb.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/Dlf4CQDc3JqNJ4Xb.thumbnail',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/Dlf4CQDc3JqNJ4Xb.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/Dlf4CQDc3JqNJ4Xb.full',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/4117772/Abraxas',
                            teams: [],
                        },
                        title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                        text_raw:
                            'Vielen tausend Dank! Habe endlich den Reissverschluss meiner Lieblingsjacke reparieren können. Euer Tipp ist echt der einzige, den ich im Internet fand, welcher auch funktioniert :)',
                        text_rendered:
                            '<p>Vielen tausend Dank! Habe endlich den Reissverschluss meiner Lieblingsjacke reparieren können. Euer Tipp ist echt der einzige, den ich im Internet fand, welcher auch funktioniert :)</p>',
                        rating: 0,
                        date: 1650136381,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 870466,
                        locale: 'de',
                        context: 'step',
                        contextid: 139090,
                        parentid: null,
                        author: {
                            userid: 4284260,
                            username: 'Ahnsbeckerin',
                            unique_username: 'ahnsbeckerin',
                            join_date: 1678949075,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/4284260/Ahnsbeckerin',
                            teams: [],
                        },
                        title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                        text_raw:
                            'Super, Jacke gerettet, die einzige Anleitung im Internet, die funktioniert, die sollte eine weitere Verbreitung finden. Ergänzung: das Herunterziehen im 3. Schritt gelang bei mir erst nicht, ich habe dann den Reißverschluss im unteren Teil großzügig mit einer Kerze  abgerieben, dann klappte es ohne viel Kraft. Einen Nahtauftrenner hatte ich nicht zur Hand, es funktionierte auch mit einer Pinwandsteckernadel.',
                        text_rendered:
                            '<p>Super, Jacke gerettet, die einzige Anleitung im Internet, die funktioniert, die sollte eine weitere Verbreitung finden. Ergänzung: das Herunterziehen im 3. Schritt gelang bei mir erst nicht, ich habe dann den Reißverschluss im unteren Teil großzügig mit einer Kerze  abgerieben, dann klappte es ohne viel Kraft. Einen Nahtauftrenner hatte ich nicht zur Hand, es funktionierte auch mit einer Pinwandsteckernadel.</p>',
                        rating: 0,
                        date: 1678949075,
                        modified_date: 1678949136,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 927850,
                        locale: 'de',
                        context: 'step',
                        contextid: 139090,
                        parentid: null,
                        author: {
                            userid: 4431497,
                            username: 'Sabine Lechner',
                            unique_username: 'sabinelechner',
                            join_date: 1702205960,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/4431497/Sabine+Lechner',
                            teams: [],
                        },
                        title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                        text_raw: 'Super Tip. Reißverschluss war innerhalb Sekunden repariert 😀',
                        text_rendered:
                            '<p>Super Tip. Reißverschluss war innerhalb Sekunden repariert 😀</p>',
                        rating: 0,
                        date: 1702205961,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
        ],
        subject: 'Two-way zip slider',
        summary: 'A two-way zip (two sliders for opening the zip...',
        time_required: '10 minutes',
        time_required_min: 600,
        time_required_max: 600,
        title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Seam Ripper',
                notes: null,
                url: 'http://www.amazon.com/Clover-463-Seam-Ripper/dp/B000YZ8MVU/ref=sr_1_1?ie=UTF8&qid=1346440445&sr=8-1&keywords=seam+ripper',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'replacement',
        revisionid: 1017594,
        created_date: 1476101339,
        published_date: 1481132844,
        modified_date: 1701264662,
        prereq_modified_date: 0,
        public: true,
        comments: [
            {
                commentid: 593253,
                locale: 'de',
                context: 'guide',
                contextid: 69137,
                parentid: null,
                author: {
                    userid: 3278419,
                    username: 'Leda Kurth',
                    unique_username: 'ledakurth',
                    join_date: 1574350534,
                    image: {
                        id: 1930921,
                        guid: 'FTMQLShEsc6Ix5KW',
                        mini: 'https://guide-images.cdn.ifixit.com/igi/FTMQLShEsc6Ix5KW.mini',
                        thumbnail:
                            'https://guide-images.cdn.ifixit.com/igi/FTMQLShEsc6Ix5KW.thumbnail',
                        '140x105':
                            'https://guide-images.cdn.ifixit.com/igi/FTMQLShEsc6Ix5KW.140x105',
                        '200x150':
                            'https://guide-images.cdn.ifixit.com/igi/FTMQLShEsc6Ix5KW.200x150',
                        standard:
                            'https://guide-images.cdn.ifixit.com/igi/FTMQLShEsc6Ix5KW.standard',
                        '440x330':
                            'https://guide-images.cdn.ifixit.com/igi/FTMQLShEsc6Ix5KW.440x330',
                        medium: 'https://guide-images.cdn.ifixit.com/igi/FTMQLShEsc6Ix5KW.medium',
                        original: 'https://guide-images.cdn.ifixit.com/igi/FTMQLShEsc6Ix5KW.full',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/3278419/Leda+Kurth',
                    teams: [],
                },
                title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                text_raw:
                    'Super, es hat funktioniert. Mein Mann kann jetzt wieder seine teure Cashmere Jacke tragen.\n\nDanke schön.\n\nLeda',
                text_rendered:
                    '<p>Super, es hat funktioniert. Mein Mann kann jetzt wieder seine teure Cashmere Jacke tragen.</p>\n\n<p>Danke schön.</p>\n\n<p>Leda</p>',
                rating: 0,
                date: 1574350535,
                modified_date: 1574350535,
                status: 'public',
                replies: [],
            },
            {
                commentid: 596597,
                locale: 'de',
                context: 'guide',
                contextid: 69137,
                parentid: null,
                author: {
                    userid: 3296892,
                    username: 'ulep',
                    unique_username: 'ulep',
                    join_date: 1575468698,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/3296892/ulep',
                    teams: [],
                },
                title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                text_raw:
                    'War schon kurz davor, den Reißverschluss mit brachialen Mitteln zu zerstören, zum Glück diesen Trick gefunden, funktioniert super!!',
                text_rendered:
                    '<p>War schon kurz davor, den Reißverschluss mit brachialen Mitteln zu zerstören, zum Glück diesen Trick gefunden, funktioniert super!!</p>',
                rating: 0,
                date: 1575468699,
                modified_date: 1575468699,
                status: 'public',
                replies: [],
            },
            {
                commentid: 728053,
                locale: 'en',
                context: 'guide',
                contextid: 69137,
                parentid: null,
                author: {
                    userid: 3885301,
                    username: 'Albert Einstein',
                    unique_username: 'bigalbertstein',
                    join_date: 1615886486,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/3885301/Albert+Einstein',
                    teams: [],
                },
                title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                text_raw:
                    'My guy I thought my coat was done out but by following this guide I fixed it',
                text_rendered:
                    '<p>My guy I thought my coat was done out but by following this guide I fixed it</p>',
                rating: 0,
                date: 1615886535,
                modified_date: 1615886535,
                status: 'public',
                replies: [],
            },
            {
                commentid: 770941,
                locale: 'de',
                context: 'guide',
                contextid: 69137,
                parentid: null,
                author: {
                    userid: 4044792,
                    username: 'Monika Jaekel',
                    unique_username: 'monikajaekel',
                    join_date: 1635426352,
                    image: {
                        id: 2507710,
                        guid: 'tSbplMiJQIZMgT53',
                        mini: 'https://guide-images.cdn.ifixit.com/igi/tSbplMiJQIZMgT53.mini',
                        thumbnail:
                            'https://guide-images.cdn.ifixit.com/igi/tSbplMiJQIZMgT53.thumbnail',
                        medium: 'https://guide-images.cdn.ifixit.com/igi/tSbplMiJQIZMgT53.medium',
                        original: 'https://guide-images.cdn.ifixit.com/igi/tSbplMiJQIZMgT53.full',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/4044792/Monika+Jaekel',
                    teams: [],
                },
                title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                text_raw:
                    'Es hat geklappt. Es war etwas verzwickt, vielleicht weil es ein Metall RV war.  Ich wollte schon aufgeben, aber beim 6. Mal war er dann doch offen!\n\nVielen Dank,  ich wollte die Jacke schon umtauschen, das wäre schade gewesen.',
                text_rendered:
                    '<p>Es hat geklappt. Es war etwas verzwickt, vielleicht weil es ein Metall RV war.  Ich wollte schon aufgeben, aber beim 6. Mal war er dann doch offen!</p>\n\n<p>Vielen Dank,  ich wollte die Jacke schon umtauschen, das wäre schade gewesen.</p>',
                rating: 0,
                date: 1635426352,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
            {
                commentid: 798674,
                locale: 'de',
                context: 'guide',
                contextid: 69137,
                parentid: null,
                author: {
                    userid: 4111179,
                    username: 'mullerben5452',
                    unique_username: 'mullerben5452',
                    join_date: 1648834300,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/4111179/mullerben5452',
                    teams: [],
                },
                title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                text_raw: 'Herzlichen Dank',
                text_rendered: '<p>Herzlichen Dank</p>',
                rating: 0,
                date: 1648834336,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
            {
                commentid: 870467,
                locale: 'de',
                context: 'guide',
                contextid: 69137,
                parentid: null,
                author: {
                    userid: 4284260,
                    username: 'Ahnsbeckerin',
                    unique_username: 'ahnsbeckerin',
                    join_date: 1678949075,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/4284260/Ahnsbeckerin',
                    teams: [],
                },
                title: 'How do I repair a two-way zip slider on my Vaude jacket on a split zip?',
                text_raw:
                    'Super, Jacke gerettet, die einzige Anleitung im Internet, die funktioniert, die sollte eine weitere Verbreitung finden. Ergänzung: das Herunterziehen im 3. Schritt gelang bei mir erst nicht, ich habe dann den Reißverschluss im unteren Teil großzügig mit einer Kerze abgerieben, dann klappte es ohne viel Kraft. Einen Nahtauftrenner hatte ich nicht zur Hand, es funktionierte auch mit einer Pinwandsteckernadel.',
                text_rendered:
                    '<p>Super, Jacke gerettet, die einzige Anleitung im Internet, die funktioniert, die sollte eine weitere Verbreitung finden. Ergänzung: das Herunterziehen im 3. Schritt gelang bei mir erst nicht, ich habe dann den Reißverschluss im unteren Teil großzügig mit einer Kerze abgerieben, dann klappte es ohne viel Kraft. Einen Nahtauftrenner hatte ich nicht zur Hand, es funktionierte auch mit einer Pinwandsteckernadel.</p>',
                rating: 0,
                date: 1678949204,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
        ],
        category: 'Zipper',
        url: 'https://www.ifixit.com/Guide/How+do+I+repair+a+two-way+zip+slider+on+my+Vaude+jacket+on+a+split+zip-/69137',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    69138: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Moderate',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69138,
        image: {
            id: 909223,
            guid: 'YKedCLdRcckkGXLs',
            mini: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.full',
        },
        introduction_raw:
            'If a seam on your Velcro (i.e. on the flap of your jacket) comes loose, it is relatively easy to sew it back on. You’ll need to be able to use a sewing machine.',
        introduction_rendered:
            '<p>If a seam on your Velcro (i.e. on the flap of your jacket) comes loose, it is relatively easy to sew it back on. You’ll need to be able to use a sewing machine.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [
            {
                text: 'Passender Klett',
                notes: null,
                type: '',
                quantity: 1,
                url: '/Item/Passender_Klett',
                thumbnail: null,
                isoptional: false,
            },
        ],
        prerequisites: [],
        steps: [
            {
                title: 'Variations',
                lines: [
                    {
                        text_raw:
                            'If the Velcro comes loose on the outer flap of your jacket, you can easily sew it back on using matching thread. If the loop side of the Velcro comes loose, it’s a little harder to fix. We’ll show you in detail how to accomplish this.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'If the Velcro comes loose on the outer flap of your jacket, you can easily sew it back on using matching thread. If the loop side of the Velcro comes loose, it’s a little harder to fix. We’ll show you in detail how to accomplish this.',
                    },
                ],
                guideid: 69138,
                stepid: 139091,
                orderby: 1,
                revisionid: 1552709,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909223,
                            guid: 'YKedCLdRcckkGXLs',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/YKedCLdRcckkGXLs.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Using the seam ripper, completely take out the seam of the jacket flap for about 10 cm where the Velcro is loose.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Using the seam ripper, completely take out the seam of the jacket flap for about 10 cm where the Velcro is loose.',
                    },
                    {
                        text_raw:
                            'The more of the seam you take out, the better the new sewing on the Velcro will be.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'The more of the seam you take out, the better the new sewing on the Velcro will be.',
                    },
                ],
                guideid: 69138,
                stepid: 139092,
                orderby: 2,
                revisionid: 1552712,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909216,
                            guid: '3LMrNCxUQCKFWIXx',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/3LMrNCxUQCKFWIXx.full',
                        },
                        {
                            id: 909214,
                            guid: 'J2VSOFswc2NYEQon',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/J2VSOFswc2NYEQon.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Thread the sewing machine with matching thread and place the sewing machine foot as near to the edge of the Velcro as possible. Sew until you get to the corner.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Thread the sewing machine with matching thread and place the sewing machine foot as near to the edge of the Velcro as possible. Sew until you get to the corner.',
                    },
                ],
                guideid: 69138,
                stepid: 139093,
                orderby: 3,
                revisionid: 1580471,
                media: {
                    type: 'video',
                    data: {
                        objectid: 663,
                        videoid: 587,
                        srcid: null,
                        image: {
                            image: {
                                id: 909224,
                                guid: 'sxrUJiRPZsTX1GFS',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/sxrUJiRPZsTX1GFS.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/sxrUJiRPZsTX1GFS.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/sxrUJiRPZsTX1GFS.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/sxrUJiRPZsTX1GFS.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/sxrUJiRPZsTX1GFS.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/sxrUJiRPZsTX1GFS.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/sxrUJiRPZsTX1GFS.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/sxrUJiRPZsTX1GFS.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/sxrUJiRPZsTX1GFS.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '01 sewing velcro on Jacket VAUDE .mov',
                        duration: 4292,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307919985,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 4292,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 3244175,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 5959,
                            channels: '2',
                            video_bitrate_in_kbps: 5831,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/1OHysEJLZbALlIHl_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Stop at the first corner and lift the sewing machine foot, leaving the needle in the Velcro. Turn the jacket, put down the foot and continue to stitch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Stop at the first corner and lift the sewing machine foot, leaving the needle in the Velcro. Turn the jacket, put down the foot and continue to stitch.',
                    },
                    {
                        text_raw:
                            'When you get back to where you started, lock down the thread by making a few stitches back and forth.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'When you get back to where you started, lock down the thread by making a few stitches back and forth.',
                    },
                ],
                guideid: 69138,
                stepid: 139094,
                orderby: 4,
                revisionid: 1552717,
                media: {
                    type: 'video',
                    data: {
                        objectid: 664,
                        videoid: 588,
                        srcid: null,
                        image: {
                            image: {
                                id: 909226,
                                guid: 't2WgV2bfbuHxKywZ',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/t2WgV2bfbuHxKywZ.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/t2WgV2bfbuHxKywZ.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/t2WgV2bfbuHxKywZ.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/t2WgV2bfbuHxKywZ.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/t2WgV2bfbuHxKywZ.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/t2WgV2bfbuHxKywZ.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/t2WgV2bfbuHxKywZ.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/t2WgV2bfbuHxKywZ.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/t2WgV2bfbuHxKywZ.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '02 sewing edges of velcro on Jacket VAUDE.mov',
                        duration: 29833,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307920374,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 29833,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 22901723,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6133,
                            channels: '2',
                            video_bitrate_in_kbps: 6005,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/WemRy5EE5JFTVkKE_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'To finish the repair, resew the opened flap.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'To finish the repair, resew the opened flap.',
                    },
                ],
                guideid: 69138,
                stepid: 139095,
                orderby: 5,
                revisionid: 1552719,
                media: {
                    type: 'video',
                    data: {
                        objectid: 666,
                        videoid: 590,
                        srcid: null,
                        image: {
                            image: {
                                id: 909228,
                                guid: '1cxItRmEcZxcUAbU',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/1cxItRmEcZxcUAbU.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/1cxItRmEcZxcUAbU.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/1cxItRmEcZxcUAbU.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/1cxItRmEcZxcUAbU.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/1cxItRmEcZxcUAbU.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/1cxItRmEcZxcUAbU.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/1cxItRmEcZxcUAbU.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/1cxItRmEcZxcUAbU.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/1cxItRmEcZxcUAbU.full',
                            },
                            srcid: null,
                            width: 1280,
                            height: 720,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '03 closing seam on flap VAUDE.mov',
                        duration: 9417,
                        width: 1280,
                        height: 720,
                        meta: {
                            id: 307920680,
                            format: 'mpeg4',
                            frame_rate: 24,
                            duration_in_ms: 9417,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 720,
                            width: 1280,
                            file_size_in_bytes: 7198344,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 6101,
                            channels: '2',
                            video_bitrate_in_kbps: 5973,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/EGT1ITDhgGQMOwTS_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'If a seam on your Velcro (i.e. on the flap of...',
        time_required: '20 minutes',
        time_required_min: 1200,
        time_required_max: 1200,
        title: 'How can I resew a loose Velcro on a Vaude jacket?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Yarn',
                notes: null,
                url: '/Item/Yarn',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Seam Ripper',
                notes: null,
                url: 'http://www.amazon.com/Clover-463-Seam-Ripper/dp/B000YZ8MVU/ref=sr_1_1?ie=UTF8&qid=1346440445&sr=8-1&keywords=seam+ripper',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Scissors',
                notes: null,
                url: 'https://eustore.ifixit.com/products/utility-scissors',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/YZFc6xfCFTuHFhsP.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1023993,
        created_date: 1476102208,
        published_date: 1481132581,
        modified_date: 1482776769,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'Outerwear',
        url: 'https://www.ifixit.com/Guide/How+can+I+resew+a+loose+Velcro+on+a+Vaude+jacket-/69138',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    69141: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69141,
        image: {
            id: 909247,
            guid: 'TRvEAoDfkajwpF2D',
            mini: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.large',
            original: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.full',
        },
        introduction_raw:
            'Thermal appeal with down fill has to be treated specially. The general rule is: was as little as possible and as often as necessary. Use liquid detergent for fine wash or for functional clothing. We recommend Nikwax Down Wash.',
        introduction_rendered:
            '<p>Thermal appeal with down fill has to be treated specially. The general rule is: was as little as possible and as often as necessary. Use liquid detergent for fine wash or for functional clothing. We recommend Nikwax Down Wash.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Read the care label',
                lines: [
                    {
                        text_raw:
                            'There are symbols for the recommended care products on the smaller label. We recommend Nikwax Down Wash for the washing cycle and TX Direct Spray from Nikwax for waterproofing if necessary.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'There are symbols for the recommended care products on the smaller label. We recommend Nikwax Down Wash for the washing cycle and TX Direct Spray from Nikwax for waterproofing if necessary.',
                    },
                    {
                        text_raw:
                            "Down jackets usually are washed at 30 °C with a gentle cycle. You'll find detailed information on the sewn in care label.",
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            "Down jackets usually are washed at 30 °C with a gentle cycle. You'll find detailed information on the sewn in care label.",
                    },
                    {
                        text_raw:
                            'Details to the care symbols for washing, bleaching, drying or ironing you will find here: http://help.vaude.com/care-instructions/?lang=en',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Details to the care symbols for washing, bleaching, drying or ironing you will find here: <a href="http://help.vaude.com/care-instructions/?lang=en" rel="nofollow noopener noreferrer" title="http://help.vaude.com/care-instructions/?lang=en">http://help.vaude.com/care-instructions/...</a>',
                    },
                ],
                guideid: 69141,
                stepid: 139099,
                orderby: 1,
                revisionid: 1586361,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909247,
                            guid: 'TRvEAoDfkajwpF2D',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.large',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/TRvEAoDfkajwpF2D.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Washing',
                lines: [
                    {
                        text_raw:
                            'Set your washing machine to a gentle cycle with extra rinse and the recommended temperature. If you are using NIKWAX Down Wash, you can omit the extra rinse cycle. The extra rinse cycle is important for ensuring that no residues are left behind on the product.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Set your washing machine to a gentle cycle with extra rinse and the recommended temperature. If you are using NIKWAX Down Wash, you can omit the extra rinse cycle. The extra rinse cycle is important for ensuring that no residues are left behind on the product.',
                    },
                    {
                        text_raw:
                            'Please always close buttons, zippers and Velcro closures before washing. This keeps fibers from catching and the clothing from stretching out during the wash cycle.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Please always close buttons, zippers and Velcro closures before washing. This keeps fibers from catching and the clothing from stretching out during the wash cycle.',
                    },
                ],
                guideid: 69141,
                stepid: 139100,
                orderby: 2,
                revisionid: 1576747,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 985235,
                            guid: 'h6hsqlvv126AV3Vx',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/h6hsqlvv126AV3Vx.full',
                        },
                        {
                            id: 909235,
                            guid: 'ygQNXTVZTh5DOVdI',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ygQNXTVZTh5DOVdI.full',
                        },
                        {
                            id: 985237,
                            guid: 'LQyYpT1lRUMU6NSS',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/LQyYpT1lRUMU6NSS.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Drying',
                lines: [
                    {
                        text_raw:
                            'After the wash cycle, put the down jacket into the dryer with 2 to 3 tennis balls. Dry for 40 – 60 minutes at a low temperature using the gentle cycle.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'After the wash cycle, put the down jacket into the dryer with 2 to 3 tennis balls. Dry for 40 – 60 minutes at a low temperature using the gentle cycle.',
                    },
                    {
                        text_raw: 'The tennis balls will restore the loft in your jacket.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered: 'The tennis balls will restore the loft in your jacket.',
                    },
                ],
                guideid: 69141,
                stepid: 139101,
                orderby: 3,
                revisionid: 1552749,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909250,
                            guid: 'GAJGOQXBKDPNxqOV',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/GAJGOQXBKDPNxqOV.full',
                        },
                        {
                            id: 909236,
                            guid: 'L1VPctBfCE5QfbpF',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/L1VPctBfCE5QfbpF.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Waterproofing',
                lines: [
                    {
                        text_raw:
                            'Hang the jacket up on a clothes hanger, close all zips and spray evenly with the waterproofing spray. This keeps the inside untreated so that moisture can be absorbed and transferred outward.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Hang the jacket up on a clothes hanger, close all zips and spray evenly with the waterproofing spray. This keeps the inside untreated so that moisture can be absorbed and transferred outward.',
                    },
                    {
                        text_raw: 'Please follow the instructions printed on the care product.',
                        bullet: 'icon_caution',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Please follow the instructions printed on the care product.',
                    },
                ],
                guideid: 69141,
                stepid: 139103,
                orderby: 4,
                revisionid: 1572217,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909951,
                            guid: 'eCpxIBSaSgCd3XM1',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/eCpxIBSaSgCd3XM1.full',
                        },
                        {
                            id: 909233,
                            guid: 'bVL4xiELOeHEQxkH',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/bVL4xiELOeHEQxkH.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Thermal appeal with down fill has to be treated...',
        time_required: '2 hours',
        time_required_min: 7200,
        time_required_max: 7200,
        title: 'What’s the right way to wash and dry my Vaude down jacket?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Liquid light-duty or functional detergent',
                notes: null,
                url: 'https://www.vaude.com/de-DE/Wo-kaufen/Finde-einen-Haendler/Online-Haendler/',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1017605,
        created_date: 1476103215,
        published_date: 1481132850,
        modified_date: 1523350647,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'Outerwear',
        url: 'https://www.ifixit.com/Guide/What%E2%80%99s+the+right+way+to+wash+and+dry+my+Vaude+down+jacket-/69141',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    69150: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69150,
        image: {
            id: 909266,
            guid: 'dteZX6GRtOZ6TdtL',
            mini: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.full',
        },
        introduction_raw:
            'In general: wash as little as possible and as often as necessary. Please follow the instructions on the product’s care label. The following is an example of what steps to wash a waterproof membrane jacket the best way. Only use liquid detergent for fine wash or for functional clothing. Even better is functional detergent like Nikwax Tech Wash.',
        introduction_rendered:
            '<p>In general: wash as little as possible and as often as necessary. Please follow the instructions on the product’s care label. The following is an example of what steps to wash a waterproof membrane jacket the best way. Only use liquid detergent for fine wash or for functional clothing. Even better is functional detergent like Nikwax Tech Wash.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Read the care label on the product',
                lines: [
                    {
                        text_raw:
                            'Set the washing machine to 30 °C with a gentle cycle. There are symbols for the recommended care products on the smaller label. We recommend Nikwax Tech Wash for the washing cycle and TX Direct Spray from Nikwax for waterproofing if necessary.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Set the washing machine to 30 °C with a gentle cycle. There are symbols for the recommended care products on the smaller label. We recommend Nikwax Tech Wash for the washing cycle and TX Direct Spray from Nikwax for waterproofing if necessary.',
                    },
                    {
                        text_raw:
                            'Details to the care symbols for washing, bleaching, drying or ironing you will find here: http://help.vaude.com/care-instructions/?lang=en',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Details to the care symbols for washing, bleaching, drying or ironing you will find here: <a href="http://help.vaude.com/care-instructions/?lang=en" rel="nofollow noopener noreferrer" title="http://help.vaude.com/care-instructions/?lang=en">http://help.vaude.com/care-instructions/...</a>',
                    },
                ],
                guideid: 69150,
                stepid: 139106,
                orderby: 1,
                revisionid: 1575041,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909266,
                            guid: 'dteZX6GRtOZ6TdtL',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/dteZX6GRtOZ6TdtL.full',
                        },
                        {
                            id: 909269,
                            guid: 'xTdZUtsb6KeQwPgP',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/xTdZUtsb6KeQwPgP.full',
                        },
                        {
                            id: 909267,
                            guid: 'J1DlapeYXhHfFDTo',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/J1DlapeYXhHfFDTo.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Fill the washing machine. For optimal results you should wash similar colours together.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Fill the washing machine. For optimal results you should wash similar colours together.',
                    },
                    {
                        text_raw:
                            'Please always close buttons, zippers and Velcro closures before washing. This keeps fibers from catching and the clothing from stretching out during the wash cycle.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Please always close buttons, zippers and Velcro closures before washing. This keeps fibers from catching and the clothing from stretching out during the wash cycle.',
                    },
                ],
                guideid: 69150,
                stepid: 139107,
                orderby: 2,
                revisionid: 1911305,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 1354829,
                            guid: 'fMgeHZ63p1iDrXSM',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/fMgeHZ63p1iDrXSM.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Washing',
                lines: [
                    {
                        text_raw:
                            'Set the washing machine to the cycle and temperature recommended on the apparel. In our example it is 30 °C and a gentle cycle. Put the correct amount of liquid in the dispenser depending on the hardness of your water. You can find out how much you should use by reading the instructions on the care product.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Set the washing machine to the cycle and temperature recommended on the apparel. In our example it is 30 °C and a gentle cycle. Put the correct amount of liquid in the dispenser depending on the hardness of your water. You can find out how much you should use by reading the instructions on the care product.',
                    },
                    {
                        text_raw: 'Do not use fabric softener or bleach!',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Do not use fabric softener or bleach!',
                    },
                ],
                guideid: 69150,
                stepid: 139108,
                orderby: 3,
                revisionid: 1553161,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909275,
                            guid: 'QHRbfO35Q4cqvn1W',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/QHRbfO35Q4cqvn1W.full',
                        },
                        {
                            id: 909277,
                            guid: 'S3L4tNJa6jWX6LqC',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/S3L4tNJa6jWX6LqC.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Drying',
                lines: [
                    {
                        text_raw:
                            'For optimal reactivation of the waterproofing (DWR), put the clothing into the dryer for 30 to 50 minutes after washing',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'For optimal reactivation of the waterproofing (DWR), put the clothing into the dryer for 30 to 50 minutes after washing',
                    },
                    {
                        text_raw:
                            'Alternatively, you can air dry the clothing in a warm and dry place, however, the waterproofing will not be reactivated.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Alternatively, you can air dry the clothing in a warm and dry place, however, the waterproofing will not be reactivated.',
                    },
                ],
                guideid: 69150,
                stepid: 139109,
                orderby: 4,
                revisionid: 1573860,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909273,
                            guid: 'F32CJ6uJaJCfIIN4',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/F32CJ6uJaJCfIIN4.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Waterproofing if necessary',
                lines: [
                    {
                        text_raw:
                            'When your jacket is dry, you can do a water beading test to find out if you need to re-waterproof it. Water should pearl up into beads on the surface of the jacket. To waterproof, we recommend TX-Direct Spray from Nikwax. Spray the outside of the clothing evenly. This leaves the inside untreated to that it can absorb moisture and move it outward.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'When your jacket is dry, you can do a water beading test to find out if you need to re-waterproof it. Water should pearl up into beads on the surface of the jacket. To waterproof, we recommend TX-Direct Spray from Nikwax. Spray the outside of the clothing evenly. This leaves the inside untreated to that it can absorb moisture and move it outward.',
                    },
                    {
                        text_raw: 'Please follow the instructions printed on the care product.',
                        bullet: 'icon_caution',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Please follow the instructions printed on the care product.',
                    },
                ],
                guideid: 69150,
                stepid: 139110,
                orderby: 5,
                revisionid: 1552772,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909264,
                            guid: 'IZxXBgT1jHejnZCT',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/IZxXBgT1jHejnZCT.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'In general: wash as little as possible and as...',
        time_required: '2 hours',
        time_required_min: 7200,
        time_required_max: 7200,
        title: 'How do I wash and dry my Vaude membrane jacket or trousers?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Liquid light-duty or functional detergent',
                notes: null,
                url: 'https://www.vaude.com/de-DE/Wo-kaufen/Finde-einen-Haendler/Online-Haendler/',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1017596,
        created_date: 1476104635,
        published_date: 1481132592,
        modified_date: 1519822682,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'Outerwear',
        url: 'https://www.ifixit.com/Guide/How+do+I+wash+and+dry+my+Vaude+membrane+jacket+or+trousers-/69150',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    69155: {
        brandId: '122278',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Easy',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 69155,
        image: {
            id: 909285,
            guid: 'Ibxfla6ECFy3BbCE',
            mini: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.full',
        },
        introduction_raw:
            'In general, waterproof, water repellent and windproof performance apparel  (Sympatex, Ceplex and Windproof products) should be washed as rarely as possible and as often as necessary. Please follow the instructions on the product’s care label. Only use liquid detergent for fine wash or for functional clothing. Even better is functional detergent like Nikwax Tech Wash.',
        introduction_rendered:
            '<p>In general, waterproof, water repellent and windproof performance apparel  (Sympatex, Ceplex and Windproof products) should be washed as rarely as possible and as often as necessary. Please follow the instructions on the product’s care label. Only use liquid detergent for fine wash or for functional clothing. Even better is functional detergent like Nikwax Tech Wash.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: 'Read the care label on the product',
                lines: [
                    {
                        text_raw:
                            'Set the washing machine to 30 °C with a gentle cycle. There are symbols for the recommended care products on the smaller label. We recommend Nikwax Tech Wash for the washing cycle and TX Direct Spray from Nikwax for waterproofing if necessary.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Set the washing machine to 30 °C with a gentle cycle. There are symbols for the recommended care products on the smaller label. We recommend Nikwax Tech Wash for the washing cycle and TX Direct Spray from Nikwax for waterproofing if necessary.',
                    },
                    {
                        text_raw:
                            'Details to the care symbols for washing, bleaching, drying or ironing you will find here: http://help.vaude.com/care-instructions/?lang=en',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Details to the care symbols for washing, bleaching, drying or ironing you will find here: <a href="http://help.vaude.com/care-instructions/?lang=en" rel="nofollow noopener noreferrer" title="http://help.vaude.com/care-instructions/?lang=en">http://help.vaude.com/care-instructions/...</a>',
                    },
                ],
                guideid: 69155,
                stepid: 139111,
                orderby: 1,
                revisionid: 1598938,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909285,
                            guid: 'Ibxfla6ECFy3BbCE',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Ibxfla6ECFy3BbCE.full',
                        },
                        {
                            id: 909286,
                            guid: 'eJASQLdYQnIYkk5u',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/eJASQLdYQnIYkk5u.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Fill the washing machine. For optimal results you should wash similar colors together.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Fill the washing machine. For optimal results you should wash similar colors together.',
                    },
                    {
                        text_raw:
                            'Please always close buttons, zippers and Velcro closures before washing. This keeps fibers from catching and the clothing from stretching out during the wash cycle.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Please always close buttons, zippers and Velcro closures before washing. This keeps fibers from catching and the clothing from stretching out during the wash cycle.',
                    },
                ],
                guideid: 69155,
                stepid: 139113,
                orderby: 2,
                revisionid: 1580606,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909283,
                            guid: 't4eeJVm41GUDqA32',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/t4eeJVm41GUDqA32.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Washing',
                lines: [
                    {
                        text_raw:
                            'Set the washing machine to the cycle and temperature recommended on the apparel. In our example it’s 30 °C and a gentle cycle. Put the correct amount of liquid in the dispenser depending on the hardness of your water. You can find out how much you should use by reading the instructions on the care product.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Set the washing machine to the cycle and temperature recommended on the apparel. In our example it’s 30 °C and a gentle cycle. Put the correct amount of liquid in the dispenser depending on the hardness of your water. You can find out how much you should use by reading the instructions on the care product.',
                    },
                    {
                        text_raw: 'Do not use fabric softener or bleach!',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Do not use fabric softener or bleach!',
                    },
                ],
                guideid: 69155,
                stepid: 139115,
                orderby: 3,
                revisionid: 1580619,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909294,
                            guid: 'n4R1qiJxOvHqr2Cw',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/n4R1qiJxOvHqr2Cw.full',
                        },
                        {
                            id: 909293,
                            guid: 'fJS6kvdiQIYVhSuF',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/fJS6kvdiQIYVhSuF.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Drying',
                lines: [
                    {
                        text_raw:
                            'For optimal reactivation of the waterproofing (DWR), put the clothing into the dryer for 30 to 50 minutes after washing. Alternatively, you can air dry the clothing in a warm and dry place, however, the waterproofing will not be reactivated.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'For optimal reactivation of the waterproofing (DWR), put the clothing into the dryer for 30 to 50 minutes after washing. Alternatively, you can air dry the clothing in a warm and dry place, however, the waterproofing will not be reactivated.',
                    },
                ],
                guideid: 69155,
                stepid: 139116,
                orderby: 4,
                revisionid: 1552791,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 909284,
                            guid: 'FHByxytKIW2MMyvU',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/FHByxytKIW2MMyvU.full',
                        },
                        {
                            id: 909292,
                            guid: 'VxCFAJveCibTIRoT',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/VxCFAJveCibTIRoT.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: 'Waterproofing if necessary',
                lines: [
                    {
                        text_raw:
                            'If the waterproofing has been reduced with time, you can treat your jacket with a spray-on waterproofing. We recommend the spray on product from Nikwax for softshell products.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'If the waterproofing has been reduced with time, you can treat your jacket with a spray-on waterproofing. We recommend the spray on product from Nikwax for softshell products.',
                    },
                    {
                        text_raw: 'Please follow the instructions printed on the care product.',
                        bullet: 'icon_caution',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Please follow the instructions printed on the care product.',
                    },
                ],
                guideid: 69155,
                stepid: 139117,
                orderby: 5,
                revisionid: 1563530,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 940136,
                            guid: 'WlrtOsIr2WgiyKNw',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/WlrtOsIr2WgiyKNw.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/WlrtOsIr2WgiyKNw.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/WlrtOsIr2WgiyKNw.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/WlrtOsIr2WgiyKNw.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/WlrtOsIr2WgiyKNw.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/WlrtOsIr2WgiyKNw.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/WlrtOsIr2WgiyKNw.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/WlrtOsIr2WgiyKNw.large',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/WlrtOsIr2WgiyKNw.full',
                        },
                    ],
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'In general, waterproof, water repellent and...',
        time_required: '2 hours',
        time_required_min: 7200,
        time_required_max: 7200,
        title: 'What’s the best way to wash and dry my Vaude softshell jacket?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Liquid light-duty or functional detergent',
                notes: null,
                url: 'https://www.vaude.com/de-DE/Wo-kaufen/Finde-einen-Haendler/Online-Haendler/',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1017593,
        created_date: 1476105418,
        published_date: 1481132590,
        modified_date: 1519822911,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'Outerwear',
        url: 'https://www.ifixit.com/Guide/What%E2%80%99s+the+best+way+to+wash+and+dry+my+Vaude+softshell+jacket-/69155',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    74517: {
        brandId: '122278',
        conclusion_raw: 'Done! You made it.',
        conclusion_rendered: '<p>Done! You made it.</p>',
        difficulty: 'Moderate',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 74517,
        image: {
            id: 985279,
            guid: 'rTyQjDhPMJQmaMRE',
            mini: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/rTyQjDhPMJQmaMRE.full',
        },
        introduction_raw:
            'During prolonged or heavy use, a snap can come off your clothing or gear and get lost. Not to worry – a worn-out or defective snap on a garment, tent, backpack or sleeping bag can easily be replaced.  Replacement snaps  often come with the product in a small bag. If you don’t have a replacement snap, you can pick one up in a fabric supplies store – they come in several shapes and sizes and fit almost anywhere. Here we’ll show you an example of how you can replace a snap on a pair of trousers.',
        introduction_rendered:
            '<p>During prolonged or heavy use, a snap can come off your clothing or gear and get lost. Not to worry – a worn-out or defective snap on a garment, tent, backpack or sleeping bag can easily be replaced.  Replacement snaps  often come with the product in a small bag. If you don’t have a replacement snap, you can pick one up in a fabric supplies store – they come in several shapes and sizes and fit almost anywhere. Here we’ll show you an example of how you can replace a snap on a pair of trousers.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [
            {
                text: 'Druckknopf-Set von Prym',
                notes: null,
                type: '',
                quantity: 1,
                url: '/Item/Druckknopf-Set_von_Prym',
                thumbnail: null,
                isoptional: false,
            },
        ],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'In our example, the snap stud is missing. The snap post can be removed with the pliers.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'In our example, the snap stud is missing. The snap post can be removed with the pliers.',
                    },
                ],
                guideid: 74517,
                stepid: 150462,
                orderby: 1,
                revisionid: 1739184,
                media: {
                    type: 'video',
                    data: {
                        objectid: 756,
                        videoid: 662,
                        srcid: null,
                        image: {
                            image: {
                                id: 971385,
                                guid: 'YdlMWLUvCv4VryPM',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/YdlMWLUvCv4VryPM.full',
                            },
                            srcid: null,
                            width: 1920,
                            height: 1080,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '01 removing pressbutton with tongs VAUDE .mov',
                        duration: 18040,
                        width: 1920,
                        height: 1080,
                        meta: {
                            id: 323725327,
                            format: 'mpeg4',
                            frame_rate: 25,
                            duration_in_ms: 18040,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 1080,
                            width: 1920,
                            file_size_in_bytes: 23110225,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 10239,
                            channels: '2',
                            video_bitrate_in_kbps: 10111,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/ImlcjJMPIKVYxgyT_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Lay out the two pieces for the lower flap.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Lay out the two pieces for the lower flap.',
                    },
                ],
                guideid: 74517,
                stepid: 150463,
                orderby: 2,
                revisionid: 1786796,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 971391,
                            guid: 'OJCqHTcqNZZAgyQ2',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/OJCqHTcqNZZAgyQ2.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Place the stud component on the lower flap where the previous stud was positioned and hold it there with a plastic snap holder if necessary. Rivet the two parts together with a few strong blows of the hammer.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Place the stud component on the lower flap where the previous stud was positioned and hold it there with a plastic snap holder if necessary. Rivet the two parts together with a few strong blows of the hammer.',
                    },
                    {
                        text_raw:
                            'Alternatively, you can purchase a hand press for snaps at a sewing supplies store.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Alternatively, you can purchase a hand press for snaps at a sewing supplies store.',
                    },
                ],
                guideid: 74517,
                stepid: 150465,
                orderby: 3,
                revisionid: 1624798,
                media: {
                    type: 'video',
                    data: {
                        objectid: 757,
                        videoid: 663,
                        srcid: null,
                        image: {
                            image: {
                                id: 971402,
                                guid: 'phS3TCjHRM5wQPjJ',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/phS3TCjHRM5wQPjJ.full',
                            },
                            srcid: null,
                            width: 1920,
                            height: 1080,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '02 fixing a new pressbutton VAUDE.mov',
                        duration: 30000,
                        width: 1920,
                        height: 1080,
                        meta: {
                            id: 323729775,
                            format: 'mpeg4',
                            frame_rate: 25,
                            duration_in_ms: 30000,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 1080,
                            width: 1920,
                            file_size_in_bytes: 38156316,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 10167,
                            channels: '2',
                            video_bitrate_in_kbps: 10039,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/AQP1vBeq2wa4kdbQ_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Lay out the other two pieces for the top flap.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Lay out the other two pieces for the top flap.',
                    },
                ],
                guideid: 74517,
                stepid: 150466,
                orderby: 4,
                revisionid: 1994077,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 971403,
                            guid: 'rR2WuiWMHFTXkiII',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/rR2WuiWMHFTXkiII.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Place the post part of the snap on the top flap where the previous post was positioned and attach it with a plastic holder if necessary. Rivet the two parts together with a few strong blows of the hammer.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Place the post part of the snap on the top flap where the previous post was positioned and attach it with a plastic holder if necessary. Rivet the two parts together with a few strong blows of the hammer.',
                    },
                    {
                        text_raw:
                            'Alternatively, you can purchase a hand press for snaps at a sewing supplies store.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Alternatively, you can purchase a hand press for snaps at a sewing supplies store.',
                    },
                ],
                guideid: 74517,
                stepid: 150467,
                orderby: 5,
                revisionid: 1624803,
                media: {
                    type: 'video',
                    data: {
                        objectid: 758,
                        videoid: 664,
                        srcid: null,
                        image: {
                            image: {
                                id: 971406,
                                guid: 'O4IOZFmgVAufcbQq',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/O4IOZFmgVAufcbQq.full',
                            },
                            srcid: null,
                            width: 1920,
                            height: 1080,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '03 fixing a new pressbutton on frontside VAUDE.mov',
                        duration: 29560,
                        width: 1920,
                        height: 1080,
                        meta: {
                            id: 323730228,
                            format: 'mpeg4',
                            frame_rate: 25,
                            duration_in_ms: 29560,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 1080,
                            width: 1920,
                            file_size_in_bytes: 37670217,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 10187,
                            channels: '2',
                            video_bitrate_in_kbps: 10059,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/ekg5OGYnjQiIl5uc_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'During prolonged or heavy use, a snap can come...',
        time_required: '10 minutes',
        time_required_min: 600,
        time_required_max: 600,
        title: 'How do I attach a new snap?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Beißzange',
                notes: null,
                url: '/Item/bei%C3%9Fzange',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/B1jcW1QHqNAjy526.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Hammer',
                notes: null,
                url: 'https://smile.amazon.com/-/en/Hammers/b/?ie=UTF8&node=553190',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/IUtvcdnHhRTX2Tbo.thumbnail',
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1148496,
        created_date: 1480325237,
        published_date: 1481132867,
        modified_date: 1516183710,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'Tops',
        url: 'https://www.ifixit.com/Guide/How+do+I+attach+a+new+snap-/74517',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
    74532: {
        brandId: '122278',
        conclusion_raw: 'Done! You made it.',
        conclusion_rendered: '<p>Done! You made it.</p>',
        difficulty: 'Moderate',
        documents: [],
        flags: [
            {
                title: 'Featured Guide',
                flagid: 'GUIDE_STARRED',
                text: 'This guide has been found to be exceptionally cool by the iFixit staff.',
            },
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 74532,
        image: {
            id: 973595,
            guid: 'nshdoEAeS5MjKUHh',
            mini: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/nshdoEAeS5MjKUHh.full',
        },
        introduction_raw:
            'Elbows and knees are classic areas of wear on garments. With the right fabric patch, you can hide the damage and make your product look great again. If you don’t have the same color as the garment for your patch, a contrasting color can also create an optical highlight. You can also use the same type of repair for a tear. In this example, we’ll show you how to do it.',
        introduction_rendered:
            '<p>Elbows and knees are classic areas of wear on garments. With the right fabric patch, you can hide the damage and make your product look great again. If you don’t have the same color as the garment for your patch, a contrasting color can also create an optical highlight. You can also use the same type of repair for a tear. In this example, we’ll show you how to do it.</p>',
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [
            {
                text: 'Patch',
                notes: null,
                type: '',
                quantity: 1,
                url: '/Item/Flicken',
                thumbnail: null,
                isoptional: false,
            },
        ],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Using a piece of tailor’s chalk, outline the size of the patch you’ll need to fully cover the area.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Using a piece of tailor’s chalk, outline the size of the patch you’ll need to fully cover the area.',
                    },
                    {
                        text_raw:
                            'In order to ensure that the patch looks good and fits the garment, take note of any seams or design elements you can use to design your patch.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'In order to ensure that the patch looks good and fits the garment, take note of any seams or design elements you can use to design your patch.',
                    },
                ],
                guideid: 74532,
                stepid: 150497,
                orderby: 1,
                revisionid: 1624860,
                media: {
                    type: 'video',
                    data: {
                        objectid: 763,
                        videoid: 669,
                        srcid: null,
                        image: {
                            image: {
                                id: 971569,
                                guid: 'nMdELlPouY4GXirh',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/nMdELlPouY4GXirh.full',
                            },
                            srcid: null,
                            width: 1920,
                            height: 1080,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: "01 marking with a tailor's chalk around the cut VAUDE.mov",
                        duration: 24760,
                        width: 1920,
                        height: 1080,
                        meta: {
                            id: 323759449,
                            format: 'mpeg4',
                            frame_rate: 25,
                            duration_in_ms: 24760,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 1080,
                            width: 1920,
                            file_size_in_bytes: 31221317,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 10079,
                            channels: '2',
                            video_bitrate_in_kbps: 9951,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/GnNOfjxarIfFoUGv_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Transfer the size and shape of the patch to an appropriate piece of fabric for the patch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Transfer the size and shape of the patch to an appropriate piece of fabric for the patch.',
                    },
                    {
                        text_raw: 'Add 1 cm for a seam allowance along the entire patch.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Add 1 cm for a seam allowance along the entire patch.',
                    },
                ],
                guideid: 74532,
                stepid: 150498,
                orderby: 2,
                revisionid: 1624864,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 971574,
                            guid: 'xJWVUjAVmo6ZSPsU',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/xJWVUjAVmo6ZSPsU.full',
                        },
                    ],
                },
                comments: [
                    {
                        commentid: 851630,
                        locale: 'de',
                        context: 'step',
                        contextid: 150498,
                        parentid: null,
                        author: {
                            userid: 4240164,
                            username: 'Hedwig',
                            unique_username: 'hipi',
                            join_date: 1672344453,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/4240164/Hedwig',
                            teams: [],
                        },
                        title: 'How do I patch a garment?',
                        text_raw:
                            'Wie verfährt man mit Merino-Shirts? Welches Flickenmaterial, Garnmaterial oder besser Klebeflicken?',
                        text_rendered:
                            '<p>Wie verfährt man mit Merino-Shirts? Welches Flickenmaterial, Garnmaterial oder besser Klebeflicken?</p>',
                        rating: 0,
                        date: 1672344453,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Cut along the outer line of the patch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Cut along the outer line of the patch.',
                    },
                ],
                guideid: 74532,
                stepid: 150499,
                orderby: 3,
                revisionid: 1624869,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 971575,
                            guid: 'MX3PblNLPGmYTOjq',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/MX3PblNLPGmYTOjq.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Fold over the seam allowance on the patch and iron it down – this will help with sewing the patch on.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Fold over the seam allowance on the patch and iron it down – this will help with sewing the patch on.',
                    },
                ],
                guideid: 74532,
                stepid: 150504,
                orderby: 4,
                revisionid: 1624876,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 971571,
                            guid: 'YxZPB5xUvtKFFtJw',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/YxZPB5xUvtKFFtJw.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Lay the patch on the marked line and top stitch it to the fabric close to the edge of the patch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Lay the patch on the marked line and top stitch it to the fabric close to the edge of the patch.',
                    },
                    {
                        text_raw:
                            'You can use pins to attach the patch to the jacket so it won’t slide around while you’re sewing it on.',
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'You can use pins to attach the patch to the jacket so it won’t slide around while you’re sewing it on.',
                    },
                ],
                guideid: 74532,
                stepid: 150506,
                orderby: 5,
                revisionid: 1624880,
                media: {
                    type: 'video',
                    data: {
                        objectid: 765,
                        videoid: 671,
                        srcid: null,
                        image: {
                            image: {
                                id: 971595,
                                guid: 'uBfUMQJ5ZNA2aDMX',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/uBfUMQJ5ZNA2aDMX.full',
                            },
                            srcid: null,
                            width: 1920,
                            height: 1080,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '02 sewing the patch on the jacket VAUDE.mov',
                        duration: 18480,
                        width: 1920,
                        height: 1080,
                        meta: {
                            id: 323765217,
                            format: 'mpeg4',
                            frame_rate: 25,
                            duration_in_ms: 18480,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 1080,
                            width: 1920,
                            file_size_in_bytes: 23432977,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 10135,
                            channels: '2',
                            video_bitrate_in_kbps: 10007,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/24ouPdXdM1sJl1RQ_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [
                    {
                        commentid: 288388,
                        locale: 'de',
                        context: 'step',
                        contextid: 150506,
                        parentid: null,
                        author: {
                            userid: 1859530,
                            username: 'sebastian.beck',
                            unique_username: 'sebastianbeck',
                            join_date: 1483901804,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-11.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/1859530/sebastian.beck',
                            teams: [],
                        },
                        title: 'How do I patch a garment?',
                        text_raw: 'Welches Garn sollte man denn verwenden?',
                        text_rendered: '<p>Welches Garn sollte man denn verwenden?</p>',
                        rating: 0,
                        date: 1483901805,
                        modified_date: 1483901805,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 288648,
                        locale: 'de',
                        context: 'step',
                        contextid: 150506,
                        parentid: null,
                        author: {
                            userid: 1511745,
                            username: 'VAUDE Reparaturteam',
                            unique_username: 'vaudesport2',
                            join_date: 1456153000,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
                            },
                            reputation: 6303,
                            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
                            teams: [12497],
                        },
                        title: 'How do I patch a garment?',
                        text_raw:
                            'Hallo Sebastian, wir nehmen für Softshellstoffe ein feineres Garn 100 -120 z. B. von Gütermann.\n\nUnd um Maschensprengung (Einstichslöcher) zu vermeiden, eignet sich ein Nähnadel mit Kugelspitze.',
                        text_rendered:
                            '<p>Hallo Sebastian, wir nehmen für Softshellstoffe ein feineres Garn 100 -120 z. B. von Gütermann.</p>\n\n<p>Und um Maschensprengung (Einstichslöcher) zu vermeiden, eignet sich ein Nähnadel mit Kugelspitze.</p>',
                        rating: 0,
                        date: 1483962443,
                        modified_date: 1483962443,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Use a top stitch to sew around the patch, locking off the first and last stitches by stitching back and forth a few times.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Use a top stitch to sew around the patch, locking off the first and last stitches by stitching back and forth a few times.',
                    },
                ],
                guideid: 74532,
                stepid: 150507,
                orderby: 6,
                revisionid: 1624892,
                media: {
                    type: 'video',
                    data: {
                        objectid: 769,
                        videoid: 675,
                        srcid: null,
                        image: {
                            image: {
                                id: 971616,
                                guid: '3KOca2qNvIwcqACW',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/3KOca2qNvIwcqACW.full',
                            },
                            srcid: null,
                            width: 1920,
                            height: 1080,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '04 sewing the patch on the jacket part 3 VAUDE.mov',
                        duration: 16040,
                        width: 1920,
                        height: 1080,
                        meta: {
                            id: 323769257,
                            format: 'mpeg4',
                            frame_rate: 25,
                            duration_in_ms: 16040,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 1080,
                            width: 1920,
                            file_size_in_bytes: 20614011,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 10271,
                            channels: '2',
                            video_bitrate_in_kbps: 10143,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/rQZhaaYtKSIHhLZA_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Cut away the torn area inside the jacket along the new seam.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Cut away the torn area inside the jacket along the new seam.',
                    },
                ],
                guideid: 74532,
                stepid: 150509,
                orderby: 7,
                revisionid: 1624894,
                media: {
                    type: 'video',
                    data: {
                        objectid: 767,
                        videoid: 673,
                        srcid: null,
                        image: {
                            image: {
                                id: 971601,
                                guid: 'LwoU2xK31OOkuZwK',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/LwoU2xK31OOkuZwK.full',
                            },
                            srcid: null,
                            width: 1920,
                            height: 1080,
                            ratio: 'VARIABLE',
                            markup: null,
                            exif: [],
                        },
                        filename: '05 cutting around the defect fabric VAUDE.mov',
                        duration: 15240,
                        width: 1920,
                        height: 1080,
                        meta: {
                            id: 323766771,
                            format: 'mpeg4',
                            frame_rate: 25,
                            duration_in_ms: 15240,
                            audio_sample_rate: 48000,
                            audio_bitrate_in_kbps: 128,
                            audio_codec: 'aac',
                            height: 1080,
                            width: 1920,
                            file_size_in_bytes: 19195072,
                            video_codec: 'h264',
                            total_bitrate_in_kbps: 10066,
                            channels: '2',
                            video_bitrate_in_kbps: 9938,
                            state: 'finished',
                            md5_checksum: null,
                        },
                        encodings: [
                            {
                                column: 'MP4_720',
                                label: '480p',
                                width: 720,
                                height: 480,
                                codecs: 'avc1.4D401E, mp4a.40.2',
                                ffmpeg_args:
                                    '-vcodec libx264 -profile:v main -level 3.1 -crf 23 -acodec aac -vf format=yuv420p',
                                mime: 'video/mp4',
                                always_generate: true,
                                url: 'https://documents.cdn.ifixit.com/igo/video/ifixit/XvrFnFObpYMSaOOy_MP4_720.mp4',
                                format: 'mp4',
                            },
                        ],
                    },
                },
                comments: [],
            },
        ],
        subject: '',
        summary: 'Elbows and knees are classic areas of wear on...',
        time_required: '30 minutes',
        time_required_min: 1800,
        time_required_max: 1800,
        title: 'How do I patch a garment?',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Scissors',
                notes: null,
                url: 'https://eustore.ifixit.com/products/utility-scissors',
                thumbnail:
                    'https://cart-products.cdn.ifixit.com/cart-products/YZFc6xfCFTuHFhsP.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Sewing Machine',
                notes: null,
                url: 'https://www.amazon.com/Brother-GX37-Lightweight-Featured-Machine/dp/B07GCFGHY4/ref=sr_1_7?dchild=1&keywords=sewing+machine&qid=1622645018&sr=8-7',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/GQYmjEXZeDBFf2E6.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Yarn',
                notes: null,
                url: '/Item/Yarn',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: "Tailor's Chalk",
                notes: null,
                url: 'http://www.amazon.com/Clover-Triangle-Tailors-Chalk-White/dp/B000B836MA/ref=sr_1_1_m?s=arts-crafts&ie=UTF8&qid=1386713671&sr=1-1&keywords=tailors+chalk',
                thumbnail: null,
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Protractor',
                notes: null,
                url: 'https://www.amazon.com/s/ref=nb_sb_noss_2?url=search-alias%3Daps&field-keywords=protractor&rh=i%3Aaps%2Ck%3Aprotractor',
                thumbnail: null,
                isoptional: false,
            },
        ],
        type: 'technique',
        revisionid: 1153011,
        created_date: 1480340566,
        published_date: 1481132862,
        modified_date: 1658245777,
        prereq_modified_date: 0,
        public: true,
        comments: [],
        category: 'Outerwear',
        url: 'https://www.ifixit.com/Guide/How+do+I+patch+a+garment-/74532',
        patrol_threshold: 500,
        can_edit: true,
        favorited: false,
        author: {
            userid: 1511745,
            username: 'VAUDE Reparaturteam',
            unique_username: 'vaudesport2',
            join_date: 1456153000,
            image: {
                id: -32,
                guid: 'default_avatar_placeholder_guid',
                original:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                thumbnail:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                '140x105':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                '200x150':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                standard:
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                '440x330':
                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
            },
            reputation: 6303,
            url: 'https://www.ifixit.com/User/1511745/VAUDE+Reparaturteam',
            teams: [12497],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },

    // Misc
    811: {
        brandId: '1',
        conclusion_raw: '',
        conclusion_rendered: '',
        difficulty: 'Moderate',
        documents: [
            {
                text: 'ma2srJJhFukFZqnY.pdf',
                url: '/Document/H1iIjS4TT4nNRtMh/ma2srJJhFukFZqnY.pdf',
                download_url: 'https://documents.cdn.ifixit.com/H1iIjS4TT4nNRtMh.pdf',
                documentid: 6268,
                icon: 'file-pdf-o',
                extension: 'pdf',
            },
        ],
        flags: [
            {
                title: 'Community-Contributed Guide',
                flagid: 'GUIDE_USER_CONTRIBUTED',
                text: 'An awesome member of our community made this guide. It was not created by iFixit staff.',
            },
        ],
        guideid: 811,
        image: {
            id: 8961,
            guid: 'ma2srJJhFukFZqnY',
            mini: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.mini',
            thumbnail: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.thumbnail',
            '140x105': 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.140x105',
            '200x150': 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.200x150',
            standard: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.standard',
            '440x330': 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.440x330',
            medium: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.medium',
            large: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.large',
            huge: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.huge',
            original: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.full',
        },
        introduction_raw:
            "In this teardown, we open a banana to see what's inside.  Yellow and delicious, but most importantly, yellow.",
        introduction_rendered:
            "<p>In this teardown, we open a banana to see what's inside.  Yellow and delicious, but most importantly, yellow.</p>",
        featured_document_embed_url: '',
        featured_document_thumbnail_url: '',
        locale: 'en',
        parts: [],
        prerequisites: [],
        steps: [
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Take one banana from the bunch.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Take one banana from the bunch.',
                    },
                    {
                        text_raw: "Don't squeeze too hard!",
                        bullet: 'icon_caution',
                        level: 0,
                        lineid: null,
                        text_rendered: "Don't squeeze too hard!",
                    },
                ],
                guideid: 811,
                stepid: 4383,
                orderby: 1,
                revisionid: 947143,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 8961,
                            guid: 'ma2srJJhFukFZqnY',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/ma2srJJhFukFZqnY.full',
                        },
                        {
                            id: 8967,
                            guid: 'QlgIemJkxpVCKBJC',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/QlgIemJkxpVCKBJC.full',
                        },
                    ],
                },
                comments: [
                    {
                        commentid: 2506,
                        locale: 'en',
                        context: 'step',
                        contextid: 4383,
                        parentid: null,
                        author: {
                            userid: 90776,
                            username: 'Eleventeen',
                            unique_username: 'eleventeen',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/90776/Eleventeen',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'Do you have a parts list available in case we break something during disassembly?',
                        text_rendered:
                            '<p>Do you have a parts list available in case we break something during disassembly?</p>',
                        rating: 0,
                        date: 1246036139,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 2511,
                        locale: 'en',
                        context: 'step',
                        contextid: 4383,
                        parentid: null,
                        author: {
                            userid: 17,
                            username: 'Miroslav Djuric',
                            unique_username: 'miro',
                            join_date: null,
                            image: {
                                id: 277241,
                                guid: 'CRgnKw4upTQn2Emj',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/CRgnKw4upTQn2Emj.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/CRgnKw4upTQn2Emj.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/CRgnKw4upTQn2Emj.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/CRgnKw4upTQn2Emj.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/CRgnKw4upTQn2Emj.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/CRgnKw4upTQn2Emj.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/CRgnKw4upTQn2Emj.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/CRgnKw4upTQn2Emj.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/CRgnKw4upTQn2Emj.full',
                            },
                            reputation: 158687,
                            url: 'https://www.ifixit.com/User/17/Miroslav+Djuric',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            '[quote|Eleventeen]Do you have a parts list available in case we break something during disassembly?[/quote]\n\n1. Banana exterior case.\n2. Banana interior phloem bundles.\n3. Banana interior edible portion.\n4. Banana EMI shield (only some models).',
                        text_rendered:
                            '<blockquote class="long"><p class="quoted">Quote from Eleventeen:</p>\n<p>Do you have a parts list available in case we break something during disassembly?</p></blockquote>\n\n<p>1. Banana exterior case.</p>\n\n<p>2. Banana interior phloem bundles.</p>\n\n<p>3. Banana interior edible portion.</p>\n\n<p>4. Banana EMI shield (only some models).</p>',
                        rating: 0,
                        date: 1246041300,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 2887,
                        locale: 'en',
                        context: 'step',
                        contextid: 4383,
                        parentid: null,
                        author: {
                            userid: 92682,
                            username: 'Username',
                            unique_username: 'username56650',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/92682/Username',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'You forgot to be sure that the banana is not rotten or unripe. If it is unripe (when the exterior case is green) its flavor will be of lower quality and somewhat sour. If it is rotten (when the exterior case is brown or black and the unit has an odd odor) it may explode or leak and cause personal injury as well as damage to your banana unit.',
                        text_rendered:
                            '<p>You forgot to be sure that the banana is not rotten or unripe. If it is unripe (when the exterior case is green) its flavor will be of lower quality and somewhat sour. If it is rotten (when the exterior case is brown or black and the unit has an odd odor) it may explode or leak and cause personal injury as well as damage to your banana unit.</p>',
                        rating: 0,
                        date: 1247676070,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 6233,
                        locale: 'en',
                        context: 'step',
                        contextid: 4383,
                        parentid: null,
                        author: {
                            userid: 98898,
                            username: 'paddy',
                            unique_username: 'paddy57166',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.large',
                            },
                            reputation: 2,
                            url: 'https://www.ifixit.com/User/98898/paddy',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'Be carrefull : a banana can be a weapon. As seen on\n\nhttp://www.youtube.com/watch?v=piWCBOsJr-w\n:+)',
                        text_rendered:
                            '<p>Be carrefull : a banana can be a weapon. As seen on</p>\n\n<p><a href="http://www.youtube.com/watch?v=piWCBOsJr-w" title="http://www.youtube.com/watch?v=piWCBOsJr-w">http://www.youtube.com/watch?v=piWCBOsJr...</a></p>\n\n<p>:+)</p>',
                        rating: 0,
                        date: 1257851273,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 6401,
                        locale: 'en',
                        context: 'step',
                        contextid: 4383,
                        parentid: null,
                        author: {
                            userid: 111532,
                            username: 'calicojack',
                            unique_username: 'calicojack',
                            join_date: 1258101258,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/111532/calicojack',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'Please can you attach in detail how to "Take one banana from the bunch." cannot follow the rest of the tutorial until i get past this tricky step, sorry for my noob question. Also does the bunch switch need to be in the on or off position???',
                        text_rendered:
                            '<p>Please can you attach in detail how to &quot;Take one banana from the bunch.&quot; cannot follow the rest of the tutorial until i get past this tricky step, sorry for my noob question. Also does the bunch switch need to be in the on or off position???</p>',
                        rating: 0,
                        date: 1258101259,
                        modified_date: 0,
                        status: 'public',
                        replies: [
                            {
                                commentid: 391470,
                                locale: 'en',
                                context: 'step',
                                contextid: 4383,
                                parentid: 6401,
                                author: {
                                    userid: 1339177,
                                    username: 'Aiden',
                                    unique_username: 'someaspy',
                                    join_date: 1442693288,
                                    image: {
                                        id: 2340512,
                                        guid: 'ICg6fkKKbLkMWXa2',
                                        mini: 'https://guide-images.cdn.ifixit.com/igi/ICg6fkKKbLkMWXa2.mini',
                                        thumbnail:
                                            'https://guide-images.cdn.ifixit.com/igi/ICg6fkKKbLkMWXa2.thumbnail',
                                        '140x105':
                                            'https://guide-images.cdn.ifixit.com/igi/ICg6fkKKbLkMWXa2.140x105',
                                        '200x150':
                                            'https://guide-images.cdn.ifixit.com/igi/ICg6fkKKbLkMWXa2.200x150',
                                        standard:
                                            'https://guide-images.cdn.ifixit.com/igi/ICg6fkKKbLkMWXa2.standard',
                                        medium: 'https://guide-images.cdn.ifixit.com/igi/ICg6fkKKbLkMWXa2.medium',
                                        original:
                                            'https://guide-images.cdn.ifixit.com/igi/ICg6fkKKbLkMWXa2.full',
                                    },
                                    reputation: 17604,
                                    url: 'https://www.ifixit.com/User/1339177/Aiden',
                                    teams: [],
                                },
                                title: 'Banana Teardown',
                                text_raw:
                                    'Sorry the reply took so long. You must set it to the third setting: bunch detach mode.',
                                text_rendered:
                                    '<p>Sorry the reply took so long. You must set it to the third setting: bunch detach mode.</p>',
                                rating: 0,
                                date: 1513394248,
                                modified_date: 1513394248,
                                status: 'public',
                            },
                        ],
                    },
                    {
                        commentid: 860073,
                        locale: 'en',
                        context: 'step',
                        contextid: 4383,
                        parentid: null,
                        author: {
                            userid: 1164779,
                            username: 'nicO',
                            unique_username: 'ngurney',
                            join_date: 1429024710,
                            image: {
                                id: 2699656,
                                guid: 'pmfPdsbDZe4dXrUP',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/pmfPdsbDZe4dXrUP.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/pmfPdsbDZe4dXrUP.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/pmfPdsbDZe4dXrUP.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/pmfPdsbDZe4dXrUP.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/pmfPdsbDZe4dXrUP.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/pmfPdsbDZe4dXrUP.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/pmfPdsbDZe4dXrUP.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/pmfPdsbDZe4dXrUP.large',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/pmfPdsbDZe4dXrUP.full',
                            },
                            reputation: 15927,
                            url: 'https://www.ifixit.com/User/1164779/nicO',
                            teams: [696, 45671],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'This is simply the incorrect end to open the banana. We in the business call this the "handle".',
                        text_rendered:
                            '<p>This is simply the incorrect end to open the banana. We in the business call this the &quot;handle&quot;.</p>',
                        rating: 0,
                        date: 1675204390,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Hold the banana in your left hand and grip the stem between your right thumb and forefinger.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Hold the banana in your left hand and grip the stem between your right thumb and forefinger.',
                    },
                ],
                guideid: 811,
                stepid: 4384,
                orderby: 2,
                revisionid: 1375700,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 8968,
                            guid: 'AccRAcWkPglGvHmR',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/AccRAcWkPglGvHmR.full',
                        },
                        {
                            id: 8954,
                            guid: 'iEWaSBWPYm3tYjpg',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/iEWaSBWPYm3tYjpg.full',
                        },
                    ],
                },
                comments: [
                    {
                        commentid: 2173,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 89059,
                            username: 'keener31',
                            unique_username: 'keener31',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/89059/keener31',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'if you start the process at the non-stem end, it gives you a useful handle for keeping your hands clean while eating the banana and makes cleanup a breeze!',
                        text_rendered:
                            '<p>if you start the process at the non-stem end, it gives you a useful handle for keeping your hands clean while eating the banana and makes cleanup a breeze!</p>',
                        rating: 0,
                        date: 1244750351,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 2475,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 90670,
                            username: 'mbishop',
                            unique_username: 'mbishop56603',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/90670/mbishop',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            "This is clever, but I've found it more effective to simply pinch the non-stem end of a banana between two fingers. It easily breaks and lets you peel back the banana, retaining the stem (like keener31).",
                        text_rendered:
                            "<p>This is clever, but I've found it more effective to simply pinch the non-stem end of a banana between two fingers. It easily breaks and lets you peel back the banana, retaining the stem (like keener31).</p>",
                        rating: 0,
                        date: 1245945218,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 2512,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 84700,
                            username: 'Sterling Hirsh',
                            unique_username: 'sterlinghirsh',
                            join_date: null,
                            image: {
                                id: 279256,
                                guid: 'H3reiZJU5qcqYDss',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.200x150',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.full',
                            },
                            reputation: 4601,
                            url: 'https://www.ifixit.com/User/84700/Sterling+Hirsh',
                            teams: [1],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            "[quote|mbishop]This is clever, but I've found it more effective to simply pinch the non-stem end of a banana between two fingers. It easily breaks and lets you peel back the banana, retaining the stem (like keener31).[/quote]\n\nI tried this today, and it worked great except that when I got to the end, about 1/3 of the banana fell on the floor. Luckily, it was still edible. I'll have to perfect my technique.",
                        text_rendered:
                            '<blockquote class="long"><p class="quoted">Quote from mbishop:</p>\n<p>This is clever, but I\'ve found it more effective to simply pinch the non-stem end of a banana between two fingers. It easily breaks and lets you peel back the banana, retaining the stem (like keener31).</p></blockquote>\n\n<p>I tried this today, and it worked great except that when I got to the end, about 1/3 of the banana fell on the floor. Luckily, it was still edible. I\'ll have to perfect my technique.</p>',
                        rating: 0,
                        date: 1246047952,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 3373,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 95942,
                            username: 'jdf',
                            unique_username: 'jdf20012',
                            join_date: null,
                            image: {
                                id: 11521,
                                guid: 'd5TSdMGgAQPq4CPX',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/d5TSdMGgAQPq4CPX.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/d5TSdMGgAQPq4CPX.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/d5TSdMGgAQPq4CPX.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/d5TSdMGgAQPq4CPX.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/d5TSdMGgAQPq4CPX.standard',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/d5TSdMGgAQPq4CPX.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/d5TSdMGgAQPq4CPX.full',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/95942/jdf',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw: 'You really ought to mention that this invalidates the warranty.',
                        text_rendered:
                            '<p>You really ought to mention that this invalidates the warranty.</p>',
                        rating: 0,
                        date: 1250018109,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 3507,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 97137,
                            username: 'dvsboy',
                            unique_username: 'dvsboy',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/97137/dvsboy',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            "[quote|keener31]if you start the process at the non-stem end, it gives you a useful handle for keeping your hands clean while eating the banana and makes cleanup a breeze![/quote]\n\nThis is the way I've preferred to use a banana for about 15 yrs .. mess free (unless the end is going brown)",
                        text_rendered:
                            '<blockquote class="long"><p class="quoted">Quote from keener31:</p>\n<p>if you start the process at the non-stem end, it gives you a useful handle for keeping your hands clean while eating the banana and makes cleanup a breeze!</p></blockquote>\n\n<p>This is the way I\'ve preferred to use a banana for about 15 yrs .. mess free (unless the end is going brown)</p>',
                        rating: 0,
                        date: 1250787227,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 3680,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 97967,
                            username: 'hexapodium',
                            unique_username: 'hexapodium',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/97967/hexapodium',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'I prefer to use a thumbnail to break the skin on the inside curve of the banana, just below where the stem meets the top. Aids in access to the internals, but sadly leaves noticeable marking on the outer case.',
                        text_rendered:
                            '<p>I prefer to use a thumbnail to break the skin on the inside curve of the banana, just below where the stem meets the top. Aids in access to the internals, but sadly leaves noticeable marking on the outer case.</p>',
                        rating: 0,
                        date: 1251285441,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 3723,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 98129,
                            username: 'h4ck',
                            unique_username: 'h4ck37627',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/98129/h4ck',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            "i hate to be a snob, but this is actually NOT the correct way to open a banana case. if you peel from the other end, NOT the stem, you eliminate that weird brown nub at the end, and you don't get those starchy strings that hang off.\n\nyes, im being serious. try it.",
                        text_rendered:
                            "<p>i hate to be a snob, but this is actually NOT the correct way to open a banana case. if you peel from the other end, NOT the stem, you eliminate that weird brown nub at the end, and you don't get those starchy strings that hang off.</p>\n\n<p>yes, im being serious. try it.</p>",
                        rating: 0,
                        date: 1251344155,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 3742,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 98269,
                            username: 'techydude',
                            unique_username: 'techydude83972',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/98269/techydude',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'monkeys worked out it was easier to start at the other end millions of years ago...',
                        text_rendered:
                            '<p>monkeys worked out it was easier to start at the other end millions of years ago...</p>',
                        rating: 0,
                        date: 1251421162,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 4178,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 101246,
                            username: 'snickers',
                            unique_username: 'snickers30886',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/101246/snickers',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            '[quote|techydude]monkeys worked out it was easier to start at the other end millions of years ago...[/quote]\n\nLOL. Funny but true.',
                        text_rendered:
                            '<blockquote class="long"><p class="quoted">Quote from techydude:</p>\n<p>monkeys worked out it was easier to start at the other end millions of years ago...</p></blockquote>\n\n<p>LOL. Funny but true.</p>',
                        rating: 0,
                        date: 1252999385,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 14905,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 218544,
                            username: 'Sammy',
                            unique_username: 'sammy64004',
                            join_date: 1268891795,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/218544/Sammy',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            "[quote|mbishop]This is clever, but I've found it more effective to simply pinch the non-stem end of a banana between two fingers. It easily breaks and lets you peel back the banana, retaining the stem (like keener31).[/quote]\n\n+1",
                        text_rendered:
                            '<blockquote class="long"><p class="quoted">Quote from mbishop:</p>\n<p>This is clever, but I\'ve found it more effective to simply pinch the non-stem end of a banana between two fingers. It easily breaks and lets you peel back the banana, retaining the stem (like keener31).</p></blockquote>\n\n<p>+1</p>',
                        rating: 0,
                        date: 1268891914,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 59042,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 369362,
                            username: 'Arroway NP',
                            unique_username: 'arrowaynp',
                            join_date: 1319209760,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/369362/Arroway+NP',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'If you are left-handed, should you just reverse the hands in this step?  Or is there a reason to do it right-handed?',
                        text_rendered:
                            '<p>If you are left-handed, should you just reverse the hands in this step?  Or is there a reason to do it right-handed?</p>',
                        rating: 0,
                        date: 1319209762,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 678248,
                        locale: 'en',
                        context: 'step',
                        contextid: 4384,
                        parentid: null,
                        author: {
                            userid: 795699,
                            username: 'Philip Le Riche',
                            unique_username: 'pleriche',
                            join_date: 1392739058,
                            image: {
                                id: 320584,
                                guid: 'Z2CAaevj42N4L32x',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/Z2CAaevj42N4L32x.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/Z2CAaevj42N4L32x.thumbnail',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/Z2CAaevj42N4L32x.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/Z2CAaevj42N4L32x.full',
                            },
                            reputation: 30291,
                            url: 'https://www.ifixit.com/User/795699/Philip+Le+Riche',
                            teams: [13246],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'I notice that your banana curves upwards, but mine in fact curves downwards. I presume I have to hold it in my right hand and grip the stem between my left thumb and forefinger. This will be awkwards for a right handed person like myself. Is it possible to purchase right-handed-only bananas anywhere?',
                        text_rendered:
                            '<p>I notice that your banana curves upwards, but mine in fact curves downwards. I presume I have to hold it in my right hand and grip the stem between my left thumb and forefinger. This will be awkwards for a right handed person like myself. Is it possible to purchase right-handed-only bananas anywhere?</p>',
                        rating: 0,
                        date: 1599487879,
                        modified_date: 1599487879,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Pull the stem downward until the peel splits.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Pull the stem downward until the peel splits.',
                    },
                ],
                guideid: 811,
                stepid: 4385,
                orderby: 3,
                revisionid: 553416,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 8972,
                            guid: '5HbDNEZIEUBvhThP',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/5HbDNEZIEUBvhThP.full',
                        },
                        {
                            id: 8963,
                            guid: 'Hcpb3wpDpVF36Sxu',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/Hcpb3wpDpVF36Sxu.full',
                        },
                    ],
                },
                comments: [
                    {
                        commentid: 3888,
                        locale: 'en',
                        context: 'step',
                        contextid: 4385,
                        parentid: null,
                        author: {
                            userid: 99396,
                            username: 'mamoulian',
                            unique_username: 'mamoulian',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/99396/mamoulian',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'No no no. \nYou pull the stem backwards.\n o\nAs hexapodium suggested, sometimes it helps to make a scratch with your thumbnail just below the stem (on the inside of the curve) to ensure it splits there, but this is not always necessary.',
                        text_rendered:
                            '<p>No no no.</p>\n\n<p>You pull the stem backwards.</p>\n\n<p>o</p>\n\n<p>As hexapodium suggested, sometimes it helps to make a scratch with your thumbnail just below the stem (on the inside of the curve) to ensure it splits there, but this is not always necessary.</p>',
                        rating: 0,
                        date: 1252078094,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Insert your thumbs into the split of the peel and pull the two sides apart.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Insert your thumbs into the split of the peel and pull the two sides apart.',
                    },
                    {
                        text_raw:
                            'Expose the top of the banana.  It may be slightly squished from pulling on the stem, but this will not affect the flavor.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Expose the top of the banana.  It may be slightly squished from pulling on the stem, but this will not affect the flavor.',
                    },
                ],
                guideid: 811,
                stepid: 4386,
                orderby: 4,
                revisionid: 553420,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 8952,
                            guid: 'kUuqk6IOCkBhJOKp',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/kUuqk6IOCkBhJOKp.full',
                        },
                        {
                            id: 8956,
                            guid: 'PEdiDbyVQT6G1BwO',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/PEdiDbyVQT6G1BwO.full',
                        },
                    ],
                },
                comments: [
                    {
                        commentid: 31683,
                        locale: 'en',
                        context: 'step',
                        contextid: 4386,
                        parentid: null,
                        author: {
                            userid: 268426,
                            username: 'Sammy Mazola Jr',
                            unique_username: 'sammymazolajr',
                            join_date: 1287945905,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/268426/Sammy+Mazola+Jr',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            "Dude, you're doing it wrong. It's way easier to open from the other end. No danger of mushing the tip.",
                        text_rendered:
                            "<p>Dude, you're doing it wrong. It's way easier to open from the other end. No danger of mushing the tip.</p>",
                        rating: 0,
                        date: 1287945912,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw:
                            'Pull open the peel, starting from your original split, and opening it along the length of the banana.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered:
                            'Pull open the peel, starting from your original split, and opening it along the length of the banana.',
                    },
                ],
                guideid: 811,
                stepid: 4387,
                orderby: 5,
                revisionid: 323072,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 8970,
                            guid: 'OtEwPF6vVZpArJ4R',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/OtEwPF6vVZpArJ4R.full',
                        },
                    ],
                },
                comments: [],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Remove fruit from peel.',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Remove fruit from peel.',
                    },
                ],
                guideid: 811,
                stepid: 4388,
                orderby: 6,
                revisionid: 553427,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 8962,
                            guid: 'gupWBYpZV43dd2Vh',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/gupWBYpZV43dd2Vh.full',
                        },
                        {
                            id: 8953,
                            guid: 'EWtuJRfjyvvfogv3',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/EWtuJRfjyvvfogv3.full',
                        },
                    ],
                },
                comments: [
                    {
                        commentid: 2259,
                        locale: 'en',
                        context: 'step',
                        contextid: 4388,
                        parentid: null,
                        author: {
                            userid: 970,
                            username: 'oblivio92',
                            unique_username: 'oblivio92',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/970/oblivio92',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'Explain your methods for avoiding that strange spike going into the base of the banana.  Is there any way to maximize the amount of this weird-looking thing that is removed?',
                        text_rendered:
                            '<p>Explain your methods for avoiding that strange spike going into the base of the banana.  Is there any way to maximize the amount of this weird-looking thing that is removed?</p>',
                        rating: 0,
                        date: 1245035985,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 2271,
                        locale: 'en',
                        context: 'step',
                        contextid: 4388,
                        parentid: null,
                        author: {
                            userid: 84700,
                            username: 'Sterling Hirsh',
                            unique_username: 'sterlinghirsh',
                            join_date: null,
                            image: {
                                id: 279256,
                                guid: 'H3reiZJU5qcqYDss',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.200x150',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.full',
                            },
                            reputation: 4601,
                            url: 'https://www.ifixit.com/User/84700/Sterling+Hirsh',
                            teams: [1],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            '[quote|oblivio92]Explain your methods for avoiding that strange spike going into the base of the banana.  Is there any way to maximize the amount of this weird-looking thing that is removed?[/quote]\n\nI just bend it off. When you pull it to the side, it pops off.',
                        text_rendered:
                            '<blockquote class="long"><p class="quoted">Quote from oblivio92:</p>\n<p>Explain your methods for avoiding that strange spike going into the base of the banana.  Is there any way to maximize the amount of this weird-looking thing that is removed?</p></blockquote>\n\n<p>I just bend it off. When you pull it to the side, it pops off.</p>',
                        rating: 0,
                        date: 1245094216,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 2477,
                        locale: 'en',
                        context: 'step',
                        contextid: 4388,
                        parentid: null,
                        author: {
                            userid: 90672,
                            username: 'nylund',
                            unique_username: 'nylund',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/90672/nylund',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            "Although removing the entire banana is great when you are going to use the entire banana for something else (like in a smoothie), for good ol' banana eating, some may prefer to peel back the peel in separate strips as you go along, using the unpeeled part below as a handle.\n\nhttp://chidiet.com/images3/banana1.jpg\n\nWhen done properly, one's hands never have to even touch the fruit inside.  Good for times on the go when washing your hands before eating is not an option.",
                        text_rendered:
                            '<p>Although removing the entire banana is great when you are going to use the entire banana for something else (like in a smoothie), for good ol\' banana eating, some may prefer to peel back the peel in separate strips as you go along, using the unpeeled part below as a handle.</p>\n\n<p><a href="http://chidiet.com/images3/banana1.jpg" rel="nofollow noopener noreferrer">http://chidiet.com/images3/banana1.jpg</a></p>\n\n<p>When done properly, one\'s hands never have to even touch the fruit inside.  Good for times on the go when washing your hands before eating is not an option.</p>',
                        rating: 0,
                        date: 1245946898,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 3420,
                        locale: 'en',
                        context: 'step',
                        contextid: 4388,
                        parentid: null,
                        author: {
                            userid: 96398,
                            username: '00F',
                            unique_username: 'jt3c42xprqhcmzf',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/96398/00F',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw: 'do you have any cue concerning re-assembly?',
                        text_rendered: '<p>do you have any cue concerning re-assembly?</p>',
                        rating: 0,
                        date: 1250300673,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 11243,
                        locale: 'en',
                        context: 'step',
                        contextid: 4388,
                        parentid: null,
                        author: {
                            userid: 208924,
                            username: 'nope',
                            unique_username: 'nope95048',
                            join_date: 1265000132,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/208924/nope',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            "[quote|oblivio92]Explain your methods for avoiding that strange spike going into the base of the banana.  Is there any way to maximize the amount of this weird-looking thing that is removed?[/quote]\n\nJust eat it, it won't hurt you any, and it saves valuable time if you're hungry. ;)",
                        text_rendered:
                            '<blockquote class="long"><p class="quoted">Quote from oblivio92:</p>\n<p>Explain your methods for avoiding that strange spike going into the base of the banana.  Is there any way to maximize the amount of this weird-looking thing that is removed?</p></blockquote>\n\n<p>Just eat it, it won\'t hurt you any, and it saves valuable time if you\'re hungry. ;)</p>',
                        rating: 0,
                        date: 1265000133,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                ],
            },
            {
                title: '',
                lines: [
                    {
                        text_raw: 'Eat and enjoy!',
                        bullet: 'black',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Eat and enjoy!',
                    },
                    {
                        text_raw: "This is where you'll need your teeth.",
                        bullet: 'icon_note',
                        level: 0,
                        lineid: null,
                        text_rendered: "This is where you'll need your teeth.",
                    },
                    {
                        text_raw: 'Do not choke on banana!',
                        bullet: 'icon_caution',
                        level: 0,
                        lineid: null,
                        text_rendered: 'Do not choke on banana!',
                    },
                ],
                guideid: 811,
                stepid: 4389,
                orderby: 7,
                revisionid: 320909,
                media: {
                    type: 'image',
                    data: [
                        {
                            id: 8959,
                            guid: 'lPt2dJrcVZe4XApM',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/lPt2dJrcVZe4XApM.full',
                        },
                        {
                            id: 8969,
                            guid: 'V5CAEebHaYIJUcmE',
                            mini: 'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.mini',
                            thumbnail:
                                'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.thumbnail',
                            '140x105':
                                'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.140x105',
                            '200x150':
                                'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.200x150',
                            standard:
                                'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.standard',
                            '440x330':
                                'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.440x330',
                            medium: 'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.medium',
                            large: 'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.large',
                            huge: 'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.huge',
                            original:
                                'https://guide-images.cdn.ifixit.com/igi/V5CAEebHaYIJUcmE.full',
                        },
                    ],
                },
                comments: [
                    {
                        commentid: 2114,
                        locale: 'en',
                        context: 'step',
                        contextid: 4389,
                        parentid: null,
                        author: {
                            userid: 70706,
                            username: 'bdmccaig',
                            unique_username: 'macmagician',
                            join_date: null,
                            image: {
                                id: 376536,
                                guid: 'BZJqXwGaRZqUsLBR',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/BZJqXwGaRZqUsLBR.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/BZJqXwGaRZqUsLBR.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/BZJqXwGaRZqUsLBR.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/BZJqXwGaRZqUsLBR.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/BZJqXwGaRZqUsLBR.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/BZJqXwGaRZqUsLBR.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/BZJqXwGaRZqUsLBR.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/BZJqXwGaRZqUsLBR.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/BZJqXwGaRZqUsLBR.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/BZJqXwGaRZqUsLBR.full',
                            },
                            reputation: 13,
                            url: 'https://www.ifixit.com/User/70706/bdmccaig',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'You forgot the coolest part of a banana tear down.  Before eating but after peeling, press your fingers, gently but firmly all around the top of the banana until the top begins to split into 3 parts lengthwise. You can then either stick your finger gently in the split and work it slowly down the length of the banana, or continue massaging the banana as you move down its length. Eventually you will be able to separate the banana into 3 long pieces.  Now you can make a banana split or have 3 equal pieces to share with 2  friends. Sorry, no pics cause we ate the banana.',
                        text_rendered:
                            '<p>You forgot the coolest part of a banana tear down.  Before eating but after peeling, press your fingers, gently but firmly all around the top of the banana until the top begins to split into 3 parts lengthwise. You can then either stick your finger gently in the split and work it slowly down the length of the banana, or continue massaging the banana as you move down its length. Eventually you will be able to separate the banana into 3 long pieces.  Now you can make a banana split or have 3 equal pieces to share with 2  friends. Sorry, no pics cause we ate the banana.</p>',
                        rating: 0,
                        date: 1244671087,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 2487,
                        locale: 'en',
                        context: 'step',
                        contextid: 4389,
                        parentid: null,
                        author: {
                            userid: 90698,
                            username: 'DarkElf109',
                            unique_username: 'darkelf109',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/90698/DarkElf109',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            "I had trouble re-assembling my banana after following these directions. Does anyone have directions on how to do so? I tried following the steps in the reverse order, but with the missing piece (1xFruit), it seems impossible.\n\nI really don't want to have to buy a replacement banana...",
                        text_rendered:
                            "<p>I had trouble re-assembling my banana after following these directions. Does anyone have directions on how to do so? I tried following the steps in the reverse order, but with the missing piece (1xFruit), it seems impossible.</p>\n\n<p>I really don't want to have to buy a replacement banana...</p>",
                        rating: 0,
                        date: 1245960993,
                        modified_date: 0,
                        status: 'public',
                        replies: [
                            {
                                commentid: 25320,
                                locale: 'en',
                                context: 'step',
                                contextid: 4389,
                                parentid: 2487,
                                author: {
                                    userid: 249958,
                                    username: 'Vince',
                                    unique_username: 'vince51727',
                                    join_date: 1280527092,
                                    image: {
                                        id: -32,
                                        guid: 'default_avatar_placeholder_guid',
                                        original:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12',
                                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.mini',
                                        thumbnail:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.thumbnail',
                                        '140x105':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.140x105',
                                        '200x150':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.200x150',
                                        standard:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.standard',
                                        '440x330':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.440x330',
                                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.medium',
                                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.large',
                                    },
                                    reputation: 97,
                                    url: 'https://www.ifixit.com/User/249958/Vince',
                                    teams: [],
                                },
                                title: 'Banana Teardown',
                                text_raw:
                                    'Re-assembling the banana requires a technique demonstrated in this video: http://video.google.com/videoplay?docid=524022699194083524#',
                                text_rendered:
                                    '<p>Re-assembling the banana requires a technique demonstrated in this video: <a href="http://video.google.com/videoplay?docid=524022699194083524#" rel="nofollow noopener noreferrer" title="http://video.google.com/videoplay?docid=524022699194083524#">http://video.google.com/videoplay?docid=...</a></p>',
                                rating: 0,
                                date: 1280527719,
                                modified_date: 0,
                                status: 'public',
                            },
                        ],
                    },
                    {
                        commentid: 2492,
                        locale: 'en',
                        context: 'step',
                        contextid: 4389,
                        parentid: null,
                        author: {
                            userid: 84732,
                            username: 'Nat Welch',
                            unique_username: 'icco',
                            join_date: 1238799600,
                            image: {
                                id: 11495,
                                guid: 'C1CF6UXIdVMgSCbN',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/C1CF6UXIdVMgSCbN.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/C1CF6UXIdVMgSCbN.thumbnail',
                                '140x105':
                                    'https://guide-images.cdn.ifixit.com/igi/C1CF6UXIdVMgSCbN.140x105',
                                '200x150':
                                    'https://guide-images.cdn.ifixit.com/igi/C1CF6UXIdVMgSCbN.200x150',
                                standard:
                                    'https://guide-images.cdn.ifixit.com/igi/C1CF6UXIdVMgSCbN.standard',
                                '440x330':
                                    'https://guide-images.cdn.ifixit.com/igi/C1CF6UXIdVMgSCbN.440x330',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/C1CF6UXIdVMgSCbN.medium',
                                large: 'https://guide-images.cdn.ifixit.com/igi/C1CF6UXIdVMgSCbN.large',
                                huge: 'https://guide-images.cdn.ifixit.com/igi/C1CF6UXIdVMgSCbN.huge',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/C1CF6UXIdVMgSCbN.full',
                            },
                            reputation: 939,
                            url: 'https://www.ifixit.com/User/84732/Nat+Welch',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'LOL. http://gizmodo.com/5302563/ifixit-banana-teardown-reveals-yellow-components',
                        text_rendered:
                            '<p>LOL. <a href="http://gizmodo.com/5302563/ifixit-banana-teardown-reveals-yellow-components" rel="nofollow noopener noreferrer" title="http://gizmodo.com/5302563/ifixit-banana-teardown-reveals-yellow-components">http://gizmodo.com/5302563/ifixit-banana...</a></p>',
                        rating: 0,
                        date: 1245967158,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 3443,
                        locale: 'en',
                        context: 'step',
                        contextid: 4389,
                        parentid: null,
                        author: {
                            userid: 96591,
                            username: 'Nummael',
                            unique_username: 'nummael',
                            join_date: null,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/96591/Nummael',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw: 'how i can  reassemble the device??? jajajajajaja',
                        text_rendered: '<p>how i can  reassemble the device??? jajajajajaja</p>',
                        rating: 0,
                        date: 1250488745,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 13218,
                        locale: 'en',
                        context: 'step',
                        contextid: 4389,
                        parentid: null,
                        author: {
                            userid: 212907,
                            username: 'Don Crowson',
                            unique_username: 'doncrowson',
                            join_date: 1266599861,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/212907/Don+Crowson',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'This may have been mentioned already, but the easiest way I know of is to grip both ends and pull. This only works with bananas that are slightly ripe to ripe, not over ripe. This is the way primates eat them as well.',
                        text_rendered:
                            '<p>This may have been mentioned already, but the easiest way I know of is to grip both ends and pull. This only works with bananas that are slightly ripe to ripe, not over ripe. This is the way primates eat them as well.</p>',
                        rating: 0,
                        date: 1266599863,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 15288,
                        locale: 'en',
                        context: 'step',
                        contextid: 4389,
                        parentid: null,
                        author: {
                            userid: 220080,
                            username: 'webcellar',
                            unique_username: 'webcellar',
                            join_date: 1269607459,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-2.large',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/220080/webcellar',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'Chefs call this "fingering the banana".\n\n[quote|bdmccaig]You forgot the coolest part of a banana tear down.  Before eating but after peeling, press your fingers, gently but firmly all around the top of the banana until the top begins to split into 3 parts lengthwise. You can then either stick your finger gently in the split and work it slowly down the length of the banana, or continue massaging the banana as you move down its length. Eventually you will be able to separate the banana into 3 long pieces.  Now you can make a banana split or have 3 equal pieces to share with 2  friends. Sorry, no pics cause we ate the banana.[/quote]',
                        text_rendered:
                            '<p>Chefs call this &quot;fingering the banana&quot;.</p>\n\n<blockquote class="long"><p class="quoted">Quote from bdmccaig:</p>\n<p>You forgot the coolest part of a banana tear down.  Before eating but after peeling, press your fingers, gently but firmly all around the top of the banana until the top begins to split into 3 parts lengthwise. You can then either stick your finger gently in the split and work it slowly down the length of the banana, or continue massaging the banana as you move down its length. Eventually you will be able to separate the banana into 3 long pieces.  Now you can make a banana split or have 3 equal pieces to share with 2  friends. Sorry, no pics cause we ate the banana.</p></blockquote>',
                        rating: 0,
                        date: 1269607459,
                        modified_date: 0,
                        status: 'public',
                        replies: [],
                    },
                    {
                        commentid: 25318,
                        locale: 'en',
                        context: 'step',
                        contextid: 4389,
                        parentid: null,
                        author: {
                            userid: 249958,
                            username: 'Vince',
                            unique_username: 'vince51727',
                            join_date: 1280527092,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-12.large',
                            },
                            reputation: 97,
                            url: 'https://www.ifixit.com/User/249958/Vince',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            "A banana's ambition in life is to propagate the banana species. This ambition can be frustrated by steps 1 through 6 but even after removing the peel, the banana may still reach it's intentional goal. However, step 7 will structurally and chemically devastate the banana. In other words: step 7 is extremely hurtful to the innocent fruit!",
                        text_rendered:
                            "<p>A banana's ambition in life is to propagate the banana species. This ambition can be frustrated by steps 1 through 6 but even after removing the peel, the banana may still reach it's intentional goal. However, step 7 will structurally and chemically devastate the banana. In other words: step 7 is extremely hurtful to the innocent fruit!</p>",
                        rating: 0,
                        date: 1280527093,
                        modified_date: 0,
                        status: 'public',
                        replies: [
                            {
                                commentid: 71375,
                                locale: 'en',
                                context: 'step',
                                contextid: 4389,
                                parentid: 25318,
                                author: {
                                    userid: 435509,
                                    username: 'The Chad',
                                    unique_username: 'thechad79671',
                                    join_date: 1333667611,
                                    image: {
                                        id: -32,
                                        guid: 'default_avatar_placeholder_guid',
                                        original:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10',
                                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.mini',
                                        thumbnail:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.thumbnail',
                                        '140x105':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.140x105',
                                        '200x150':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.200x150',
                                        standard:
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.standard',
                                        '440x330':
                                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.440x330',
                                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.medium',
                                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.large',
                                    },
                                    reputation: 13,
                                    url: 'https://www.ifixit.com/User/435509/The+Chad',
                                    teams: [],
                                },
                                title: 'Banana Teardown',
                                text_raw: 'Which is why once exposed, You should- Eat it Quick!',
                                text_rendered:
                                    '<p>Which is why once exposed, You should- Eat it Quick!</p>',
                                rating: 0,
                                date: 1333667756,
                                modified_date: 0,
                                status: 'public',
                            },
                        ],
                    },
                ],
            },
        ],
        subject: '',
        summary: 'In this teardown, we open a banana to see...',
        time_required: 'No estimate',
        time_required_min: 0,
        time_required_max: 0,
        title: 'Banana Teardown',
        tools: [
            {
                type: '',
                quantity: 1,
                text: 'Fingers',
                notes: null,
                url: 'https://www.amazon.com/ANCIRS-Finger-Making-Objects-Disappear/dp/B07ZRDRTGY/ref=sr_1_6?crid=1SRWACZRB4YDW&keywords=fake+finger+cover&qid=1670028581&sprefix=fake+finger+cove%2Caps%2C123&sr=8-6',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/UVXqb3erOD5Zt2XD.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Teeth',
                notes: null,
                url: '/Item/Teeth',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/urtNQYWaUK6ACGOH.thumbnail',
                isoptional: false,
            },
            {
                type: '',
                quantity: 1,
                text: 'Thumbs',
                notes: null,
                url: '/Item/Thumbs',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/PMmOcVFkSbaZITCu.thumbnail',
                isoptional: false,
            },
        ],
        type: 'teardown',
        revisionid: 1483764,
        created_date: 1244357101,
        published_date: 1244357101,
        modified_date: 1718650078,
        prereq_modified_date: 0,
        public: true,
        comments: [
            {
                commentid: 4677,
                locale: undefined,
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 104430,
                    username: 'Hiller89',
                    unique_username: 'hiller89',
                    join_date: undefined,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/104430/Hiller89',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw: '傻',
                text_rendered: '<p>傻</p>',
                rating: 0,
                date: 1254672783,
                modified_date: 0,
                status: 'public',
                replies: [
                    {
                        commentid: 317023,
                        locale: 'zh',
                        context: 'guide',
                        contextid: 811,
                        parentid: 4677,
                        author: {
                            userid: 1956165,
                            username: 'Peter',
                            unique_username: 'peterthehusky',
                            join_date: 1491274851,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.large',
                            },
                            reputation: 25,
                            url: 'https://www.ifixit.com/User/1956165/Peter',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw: '老外的笑点还是跟国人不一样的',
                        text_rendered: '<p>老外的笑点还是跟国人不一样的</p>',
                        rating: 0,
                        date: 1491522670,
                        modified_date: 1491522670,
                        status: 'public',
                    },
                ],
            },
            {
                commentid: 5881,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 109748,
                    username: 'bass0',
                    unique_username: 'bass0',
                    join_date: 1257349570,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/109748/bass0',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw:
                    "Wow! Can't wait for you to cover the orange. I just saw one in the store yesterday.",
                text_rendered:
                    "<p>Wow! Can't wait for you to cover the orange. I just saw one in the store yesterday.</p>",
                rating: 0,
                date: 1257349784,
                modified_date: 0,
                status: 'public',
                replies: [
                    {
                        commentid: 93673,
                        locale: 'en',
                        context: 'guide',
                        contextid: 811,
                        parentid: 5881,
                        author: {
                            userid: 104104,
                            username: 'Andrew Optimus Goldheart',
                            unique_username: 'andrew',
                            join_date: 1255774952,
                            image: {
                                id: -32,
                                guid: 'default_avatar_placeholder_guid',
                                original:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3',
                                mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.mini',
                                thumbnail:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.thumbnail',
                                '140x105':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.140x105',
                                '200x150':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.200x150',
                                standard:
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.standard',
                                '440x330':
                                    'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.440x330',
                                medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.medium',
                                large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-3.large',
                            },
                            reputation: 504691,
                            url: 'https://www.ifixit.com/User/104104/Andrew+Optimus+Goldheart',
                            teams: [1],
                        },
                        title: 'Banana Teardown',
                        text_raw:
                            'Sorry it took us so long, but we got to it! Check out the [http://www.ifixit.com/Teardown/Orange+Teardown/13470/1|Orange Teardown]!',
                        text_rendered:
                            '<p>Sorry it took us so long, but we got to it! Check out the <a href="/Teardown/Orange+Teardown/13470">Orange Teardown</a>!</p>',
                        rating: 0,
                        date: 1364855203,
                        modified_date: 0,
                        status: 'public',
                    },
                    {
                        commentid: 93678,
                        locale: 'en',
                        context: 'guide',
                        contextid: 811,
                        parentid: 5881,
                        author: {
                            userid: 473062,
                            username: 'walkin mn',
                            unique_username: 'walkinmn',
                            join_date: 1341326325,
                            image: {
                                id: 174690,
                                guid: '2fsjWPmXk16aWut2',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/2fsjWPmXk16aWut2.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/2fsjWPmXk16aWut2.thumbnail',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/2fsjWPmXk16aWut2.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/2fsjWPmXk16aWut2.full',
                            },
                            reputation: 1,
                            url: 'https://www.ifixit.com/User/473062/walkin+mn',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw: 'haha some years later...',
                        text_rendered: '<p>haha some years later...</p>',
                        rating: 0,
                        date: 1364862598,
                        modified_date: 0,
                        status: 'public',
                    },
                ],
            },
            {
                commentid: 7546,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 119092,
                    username: 'tearmeister',
                    unique_username: 'tearmeister',
                    join_date: 1259961825,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-1.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/119092/tearmeister',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw:
                    "Where can I get spares for this?\n\nBy the way, that's not how it's generally accepted for the teardown to go.  They way you've done it will invalidate the warranty - it's supposed to be opened from the end first...",
                text_rendered:
                    "<p>Where can I get spares for this?</p>\n\n<p>By the way, that's not how it's generally accepted for the teardown to go.  They way you've done it will invalidate the warranty - it's supposed to be opened from the end first...</p>",
                rating: 0,
                date: 1259961825,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
            {
                commentid: 11041,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 208553,
                    username: 'AlexSA90',
                    unique_username: 'alexsa90',
                    join_date: 1264841083,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/208553/AlexSA90',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw:
                    'What is the usual warrantee of a banana beacause i bought one when it was still green and then i went on holiday. but now its brown and it has leaked all over the table. where could i send it in for repairs?',
                text_rendered:
                    '<p>What is the usual warrantee of a banana beacause i bought one when it was still green and then i went on holiday. but now its brown and it has leaked all over the table. where could i send it in for repairs?</p>',
                rating: 0,
                date: 1264841084,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
            {
                commentid: 14160,
                locale: undefined,
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 215243,
                    username: 'ganfan',
                    unique_username: 'ganfan',
                    join_date: 1267553684,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-8.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/215243/ganfan',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw: 'bravo sb',
                text_rendered: '<p>bravo sb</p>',
                rating: 0,
                date: 1267553731,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
            {
                commentid: 24536,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 230489,
                    username: 'Tim',
                    unique_username: 'tim56569',
                    join_date: 1272415028,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.large',
                    },
                    reputation: 132,
                    url: 'https://www.ifixit.com/User/230489/Tim',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw:
                    'I would like to suggest (as an alternative to step 5) not peeling the entire banana. Reason being is that the peel can actually work as protection from contaminants that may reside upon your hands and also it will protect the hands from becoming gummy from the fruit.\n\nPeel as required until the tasty goodness has been consumed!',
                text_rendered:
                    '<p>I would like to suggest (as an alternative to step 5) not peeling the entire banana. Reason being is that the peel can actually work as protection from contaminants that may reside upon your hands and also it will protect the hands from becoming gummy from the fruit.</p>\n\n<p>Peel as required until the tasty goodness has been consumed!</p>',
                rating: 0,
                date: 1279690383,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
            {
                commentid: 45468,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 319058,
                    username: 'Jon',
                    unique_username: 'jon69826',
                    join_date: 1304029989,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/319058/Jon',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw:
                    "There is an alternative method that can be deployed to remove the casing (aka Skin) from a banana...\n- Grip the banana so that the end formerly attached to the tree is facing downwards and the blunt end is upper-most.\n- You'll note that the bend has a squarish profile.  Using the thumb and forfinger of each hand, grip the end of the banana so that each digit is on one of the 'faces' of the square.\n- Simply squeeze slightly and pull apart to remove the, err, casing.",
                text_rendered:
                    "<p>There is an alternative method that can be deployed to remove the casing (aka Skin) from a banana...</p>\n\n<p>- Grip the banana so that the end formerly attached to the tree is facing downwards and the blunt end is upper-most.</p>\n\n<p>- You'll note that the bend has a squarish profile.  Using the thumb and forfinger of each hand, grip the end of the banana so that each digit is on one of the 'faces' of the square.</p>\n\n<p>- Simply squeeze slightly and pull apart to remove the, err, casing.</p>",
                rating: 0,
                date: 1304029990,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
            {
                commentid: 71374,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 435509,
                    username: 'The Chad',
                    unique_username: 'thechad79671',
                    join_date: 1333667611,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.large',
                    },
                    reputation: 13,
                    url: 'https://www.ifixit.com/User/435509/The+Chad',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw:
                    'Hahaha Seriously!? you really actually did it! I thought this was a joke!\nJust 1 question, once I open the Banana from its original packaging,\nDoes that void the warranty or may I still exchange it for a one of equal specs?',
                text_rendered:
                    '<p>Hahaha Seriously!? you really actually did it! I thought this was a joke!</p>\n\n<p>Just 1 question, once I open the Banana from its original packaging,</p>\n\n<p>Does that void the warranty or may I still exchange it for a one of equal specs?</p>',
                rating: 0,
                date: 1333667667,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
            {
                commentid: 121420,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 855414,
                    username: 'Bill Wright',
                    unique_username: 'billwright89201',
                    join_date: 1399652415,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-6.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/855414/Bill+Wright',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw:
                    'Most valuable but incomplete. I kid you not: At the University of Colorado, Boulder, Conference on World Affairs, April 7 - 11 2014, the Keynote Speaker was Kathleen Sebelius, Secretary of the US Department of Health and Human Services, speaking on "The Globalization of Health" (she resigned about a week later, but said nothing about that in Boulder). At one point she compared her work, in the most off-handed way, as not more difficult than "un-pealing a banana".',
                text_rendered:
                    '<p>Most valuable but incomplete. I kid you not: At the University of Colorado, Boulder, Conference on World Affairs, April 7 - 11 2014, the Keynote Speaker was Kathleen Sebelius, Secretary of the US Department of Health and Human Services, speaking on &quot;The Globalization of Health&quot; (she resigned about a week later, but said nothing about that in Boulder). At one point she compared her work, in the most off-handed way, as not more difficult than &quot;un-pealing a banana&quot;.</p>',
                rating: 0,
                date: 1399652415,
                modified_date: 1399652415,
                status: 'public',
                replies: [],
            },
            {
                commentid: 237076,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 1640391,
                    username: 'Osssss',
                    unique_username: 'younglight',
                    join_date: 1467342286,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-7.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/1640391/Osssss',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw: 'had a little damage but it turned out great.',
                text_rendered: '<p>had a little damage but it turned out great.</p>',
                rating: 0,
                date: 1467676966,
                modified_date: 1467676966,
                status: 'public',
                replies: [
                    {
                        commentid: 380001,
                        locale: 'en',
                        context: 'guide',
                        contextid: 811,
                        parentid: 237076,
                        author: {
                            userid: 2219583,
                            username: 'Lincoln Gubser',
                            unique_username: 'bobby_jeff',
                            join_date: 1509382499,
                            image: {
                                id: 1228089,
                                guid: 'OiuuymY3NeVFnxhu',
                                mini: 'https://guide-images.cdn.ifixit.com/igi/OiuuymY3NeVFnxhu.mini',
                                thumbnail:
                                    'https://guide-images.cdn.ifixit.com/igi/OiuuymY3NeVFnxhu.thumbnail',
                                medium: 'https://guide-images.cdn.ifixit.com/igi/OiuuymY3NeVFnxhu.medium',
                                original:
                                    'https://guide-images.cdn.ifixit.com/igi/OiuuymY3NeVFnxhu.full',
                            },
                            reputation: 11,
                            url: 'https://www.ifixit.com/User/2219583/Lincoln+Gubser',
                            teams: [],
                        },
                        title: 'Banana Teardown',
                        text_raw: 'Good job!!!!!!',
                        text_rendered: '<p>Good job!!!!!!</p>',
                        rating: 0,
                        date: 1510075808,
                        modified_date: 1510075808,
                        status: 'public',
                    },
                ],
            },
            {
                commentid: 380000,
                locale: 'de',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 2219583,
                    username: 'Lincoln Gubser',
                    unique_username: 'bobby_jeff',
                    join_date: 1509382499,
                    image: {
                        id: 1228089,
                        guid: 'OiuuymY3NeVFnxhu',
                        mini: 'https://guide-images.cdn.ifixit.com/igi/OiuuymY3NeVFnxhu.mini',
                        thumbnail:
                            'https://guide-images.cdn.ifixit.com/igi/OiuuymY3NeVFnxhu.thumbnail',
                        medium: 'https://guide-images.cdn.ifixit.com/igi/OiuuymY3NeVFnxhu.medium',
                        original: 'https://guide-images.cdn.ifixit.com/igi/OiuuymY3NeVFnxhu.full',
                    },
                    reputation: 11,
                    url: 'https://www.ifixit.com/User/2219583/Lincoln+Gubser',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw: 'J4efeeftsfetfsteftdfetfe',
                text_rendered: '<p>J4efeeftsfetfsteftdfetfe</p>',
                rating: 0,
                date: 1510075792,
                modified_date: 1510075792,
                status: 'public',
                replies: [],
            },
            {
                commentid: 451216,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 2378166,
                    username: 'Owen',
                    unique_username: 'owenwastaken',
                    join_date: 1518466103,
                    image: {
                        id: 1336826,
                        guid: 'XIKKUjW4mNLBs5ZH',
                        mini: 'https://guide-images.cdn.ifixit.com/igi/XIKKUjW4mNLBs5ZH.mini',
                        thumbnail:
                            'https://guide-images.cdn.ifixit.com/igi/XIKKUjW4mNLBs5ZH.thumbnail',
                        '140x105':
                            'https://guide-images.cdn.ifixit.com/igi/XIKKUjW4mNLBs5ZH.140x105',
                        '200x150':
                            'https://guide-images.cdn.ifixit.com/igi/XIKKUjW4mNLBs5ZH.200x150',
                        standard:
                            'https://guide-images.cdn.ifixit.com/igi/XIKKUjW4mNLBs5ZH.standard',
                        '440x330':
                            'https://guide-images.cdn.ifixit.com/igi/XIKKUjW4mNLBs5ZH.440x330',
                        medium: 'https://guide-images.cdn.ifixit.com/igi/XIKKUjW4mNLBs5ZH.medium',
                        original: 'https://guide-images.cdn.ifixit.com/igi/XIKKUjW4mNLBs5ZH.full',
                    },
                    reputation: 460,
                    url: 'https://www.ifixit.com/User/2378166/Owen',
                    teams: [30332],
                },
                title: 'Banana Teardown',
                text_raw: 'Thats So Funny!',
                text_rendered: '<p>Thats So Funny!</p>',
                rating: 0,
                date: 1530269515,
                modified_date: 1530269515,
                status: 'public',
                replies: [],
            },
            {
                commentid: 464789,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 1670354,
                    username: 'arya z',
                    unique_username: 'artix',
                    join_date: 1469803454,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-10.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/1670354/arya+z',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw: 'i teardown this evry day\n\nthats very hard:/',
                text_rendered: '<p>i teardown this evry day</p>\n\n<p>thats very hard:/</p>',
                rating: 0,
                date: 1533990126,
                modified_date: 1533990126,
                status: 'public',
                replies: [],
            },
            {
                commentid: 477575,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 2389707,
                    username: '[deleted]',
                    unique_username: 'deleted13809',
                    join_date: 1519095350,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/2389707/%5Bdeleted%5D',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw: 'How am I supposed to put it back together again?',
                text_rendered: '<p>How am I supposed to put it back together again?</p>',
                rating: 0,
                date: 1537745255,
                modified_date: 1537745255,
                status: 'public',
                replies: [],
            },
            {
                commentid: 585680,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 3251258,
                    username: 'hbernardy101',
                    unique_username: 'hbernardy101',
                    join_date: 1571974040,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-4.large',
                    },
                    reputation: 1,
                    url: 'https://www.ifixit.com/User/3251258/hbernardy101',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw: 'If you pinch the bottom of the banana it opens easier',
                text_rendered: '<p>If you pinch the bottom of the banana it opens easier</p>',
                rating: 0,
                date: 1571974041,
                modified_date: 1571974041,
                status: 'public',
                replies: [],
            },
            {
                commentid: 693484,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 993502,
                    username: 'paul',
                    unique_username: 'maxcelcat',
                    join_date: 1413260884,
                    image: {
                        id: 399678,
                        guid: 'YHXIsWKLIZ52JOBr',
                        mini: 'https://guide-images.cdn.ifixit.com/igi/YHXIsWKLIZ52JOBr.mini',
                        thumbnail:
                            'https://guide-images.cdn.ifixit.com/igi/YHXIsWKLIZ52JOBr.thumbnail',
                        '140x105':
                            'https://guide-images.cdn.ifixit.com/igi/YHXIsWKLIZ52JOBr.140x105',
                        '200x150':
                            'https://guide-images.cdn.ifixit.com/igi/YHXIsWKLIZ52JOBr.200x150',
                        standard:
                            'https://guide-images.cdn.ifixit.com/igi/YHXIsWKLIZ52JOBr.standard',
                        medium: 'https://guide-images.cdn.ifixit.com/igi/YHXIsWKLIZ52JOBr.medium',
                        original: 'https://guide-images.cdn.ifixit.com/igi/YHXIsWKLIZ52JOBr.full',
                    },
                    reputation: 35,
                    url: 'https://www.ifixit.com/User/993502/paul',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw:
                    'So quick question - are you going to ever cover the less well known monkey open?',
                text_rendered:
                    '<p>So quick question - are you going to ever cover the less well known monkey open?</p>',
                rating: 0,
                date: 1604212819,
                modified_date: 1604212819,
                status: 'public',
                replies: [],
            },
            {
                commentid: 784521,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 3221296,
                    username: 'Sizun Cho',
                    unique_username: 'sizuncho',
                    join_date: 1569418615,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-5.large',
                    },
                    reputation: 24,
                    url: 'https://www.ifixit.com/User/3221296/Sizun+Cho',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw: 'Helped a lot!',
                text_rendered: '<p>Helped a lot!</p>',
                rating: 0,
                date: 1642083531,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
            {
                commentid: 798117,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 1766853,
                    username: 'Claire Miesch',
                    unique_username: 'claire17',
                    join_date: 1477397278,
                    image: {
                        id: 1164167,
                        guid: 'Sx1qRvpXH1aeIEL3',
                        mini: 'https://guide-images.cdn.ifixit.com/igi/Sx1qRvpXH1aeIEL3.mini',
                        thumbnail:
                            'https://guide-images.cdn.ifixit.com/igi/Sx1qRvpXH1aeIEL3.thumbnail',
                        '140x105':
                            'https://guide-images.cdn.ifixit.com/igi/Sx1qRvpXH1aeIEL3.140x105',
                        '200x150':
                            'https://guide-images.cdn.ifixit.com/igi/Sx1qRvpXH1aeIEL3.200x150',
                        standard:
                            'https://guide-images.cdn.ifixit.com/igi/Sx1qRvpXH1aeIEL3.standard',
                        '440x330':
                            'https://guide-images.cdn.ifixit.com/igi/Sx1qRvpXH1aeIEL3.440x330',
                        medium: 'https://guide-images.cdn.ifixit.com/igi/Sx1qRvpXH1aeIEL3.medium',
                        large: 'https://guide-images.cdn.ifixit.com/igi/Sx1qRvpXH1aeIEL3.large',
                        original: 'https://guide-images.cdn.ifixit.com/igi/Sx1qRvpXH1aeIEL3.full',
                    },
                    reputation: 131322,
                    url: 'https://www.ifixit.com/User/1766853/Claire+Miesch',
                    teams: [4401, 43725, 45201, 45202, 48699, 48766],
                },
                title: 'Banana Teardown',
                text_raw: 'The perfect way to open a banana',
                text_rendered: '<p>The perfect way to open a banana</p>',
                rating: 0,
                date: 1648545034,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
            {
                commentid: 852981,
                locale: 'en',
                context: 'guide',
                contextid: 811,
                parentid: null,
                author: {
                    userid: 4145486,
                    username: 'Anchevauls Walker',
                    unique_username: 'anchefixes',
                    join_date: 1655271306,
                    image: {
                        id: -32,
                        guid: 'default_avatar_placeholder_guid',
                        original:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9',
                        mini: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.mini',
                        thumbnail:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.thumbnail',
                        '140x105':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.140x105',
                        '200x150':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.200x150',
                        standard:
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.standard',
                        '440x330':
                            'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.440x330',
                        medium: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.medium',
                        large: 'https://assets.cdn.ifixit.com/static/images/avatars/User/ifixit/avatar-9.large',
                    },
                    reputation: 252,
                    url: 'https://www.ifixit.com/User/4145486/Anchevauls+Walker',
                    teams: [],
                },
                title: 'Banana Teardown',
                text_raw: 'Will this void my warranty?',
                text_rendered: '<p>Will this void my warranty?</p>',
                rating: 0,
                date: 1672854433,
                modified_date: 0,
                status: 'public',
                replies: [],
            },
        ],
        category: 'Banana',
        url: 'https://www.ifixit.com/Teardown/Banana+Teardown/811',
        patrol_threshold: 100000000,
        can_edit: true,
        favorited: false,
        author: {
            userid: 84700,
            username: 'Sterling Hirsh',
            unique_username: 'sterlinghirsh',
            join_date: undefined,
            image: {
                id: 279256,
                guid: 'H3reiZJU5qcqYDss',
                mini: 'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.mini',
                thumbnail: 'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.thumbnail',
                '140x105': 'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.140x105',
                '200x150': 'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.200x150',
                medium: 'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.medium',
                original: 'https://guide-images.cdn.ifixit.com/igi/H3reiZJU5qcqYDss.full',
            },
            reputation: 4601,
            url: 'https://www.ifixit.com/User/84700/Sterling+Hirsh',
            teams: [1],
        },
        langid: 'en',
        featured_documentid: null,
        intro_video_url: null,
        intro_video: null,
        completed: false,
    },
}

export default guides
