import React from 'react'
import T, { I18nKey } from '../../../../components/typography/t'
import { useIsDesktop } from '../../../../mixins/media-query'
import { Stack, Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

export type MultiStepNavigationProps = DumbMultiStepNavigationProps & {
    currentStep: number
    maxSteps: number
    prevStep: () => void
    nextStep: () => void
}

export type DumbMultiStepNavigationProps = {
    prevButtonDisabled?: boolean
    prevButtonLabelKey?: I18nKey
    nextButtonDisabled?: boolean
    nextButtonLabelKey?: I18nKey
    onValidPrevClick?: () => void
    onValidNextClick?: () => void
    hideNextButton?: boolean
}

const noop = () => {}

export const DumbMultiStepNavigation = ({
    prevButtonDisabled,
    prevButtonLabelKey,
    nextButtonDisabled,
    nextButtonLabelKey,
    hideNextButton,
    onValidPrevClick,
    onValidNextClick,
}: DumbMultiStepNavigationProps) => {
    const isDesktop = useIsDesktop()
    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                component="nav"
                mb={1}
                width="100%"
            >
                <Button
                    variant="outlined"
                    disabled={prevButtonDisabled}
                    onClick={prevButtonDisabled ? noop : onValidPrevClick}
                >
                    <ArrowBackIcon />
                    {isDesktop && <T i18nKey={prevButtonLabelKey} ml={1} variant="body2" />}
                </Button>
                <Button
                    variant={nextButtonDisabled ? 'outlined' : 'contained'}
                    sx={{ opacity: hideNextButton ? 0 : 1 }}
                    disabled={nextButtonDisabled}
                    onClick={nextButtonDisabled ? noop : onValidNextClick}
                >
                    {isDesktop && <T i18nKey={nextButtonLabelKey} mr={1} variant="body2" />}
                    <ArrowForwardIcon />
                </Button>
            </Stack>
        </>
    )
}

const MultiStepNavigation = ({
    nextStep,
    prevStep,
    currentStep,
    maxSteps,
    prevButtonDisabled = false,
    prevButtonLabelKey = 'common.back',
    nextButtonDisabled = false,
    nextButtonLabelKey = undefined,
    onValidNextClick = undefined,
    onValidPrevClick = undefined,
    hideNextButton = false,
}: MultiStepNavigationProps) => {
    const nextBtnLabelKey = nextButtonLabelKey
        ? nextButtonLabelKey
        : currentStep >= maxSteps - 1
        ? 'common.submit'
        : 'common.next'

    return (
        <DumbMultiStepNavigation
            prevButtonDisabled={prevButtonDisabled}
            prevButtonLabelKey={prevButtonLabelKey}
            nextButtonDisabled={nextButtonDisabled}
            nextButtonLabelKey={nextBtnLabelKey}
            onValidPrevClick={onValidPrevClick ?? prevStep}
            onValidNextClick={onValidNextClick ?? nextStep}
            hideNextButton={hideNextButton}
        />
    )
}

export default MultiStepNavigation
