import React from 'react'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'
import { useTranslation } from 'react-i18next'
import InputField from '../../../../components/form/input-field'

export const DescriptionStep = ({
    onDescriptionChange,
    initialDescription,
}: {
    onDescriptionChange: (description: string) => void
    initialDescription?: string
}) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.shared.descriptionStep.title" mb={4} />
            <InputField
                id="problem-description"
                multiline
                fullWidth
                defaultValue={initialDescription}
                label={t('serviceDesk.shared.descriptionStep.label' as any)}
                placeholder={t('serviceDesk.shared.descriptionStep.placeholder' as any)}
                minRows={5}
                maxRows={15}
                onChange={(e) => onDescriptionChange(e.target.value)}
            ></InputField>
        </>
    )
}

export default DescriptionStep
