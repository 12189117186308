import { ProductCategoryId, ProductCategoryNode, ProductTypeId } from './types'

export const productCategories: ProductCategoryNode = {
    id: 0 as ProductCategoryId,
    name: 'All',
    Icon: 'null',
    subItems: [
        {
            id: 201 as ProductTypeId,
            name: 'Kite',
            internalName: 'Kite',
            Icon: 'KiteIcon',
            applicableGuides: [],
            applicableServices: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl:
                        'https://ucarecdn.com/c30195a4-7994-48e7-afa2-6ccccaebdc7f/-/preview/662x377/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.bottom',
                    sketchUrl:
                        'https://ucarecdn.com/fcb239b4-3f7d-4693-9705-d1a8d72a7958/-/preview/662x377/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.side',
                    sketchUrl:
                        'https://ucarecdn.com/ad4d86c4-4088-4e83-a9f3-e7ee99361836/-/preview/662x377/',
                    details: [],
                },
            ],
        },
        {
            id: 202 as ProductTypeId,
            name: 'Bar',
            internalName: 'Bar',
            Icon: 'KiteBarIcon',
            applicableGuides: [],
            applicableServices: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.front',
                    sketchUrl:
                        'https://ucarecdn.com/34218637-8144-443a-a49b-f4ab3cd3dd0e/-/preview/662x377/',
                    details: [],
                },
            ],
        },
        {
            id: 203 as ProductTypeId,
            name: 'Wing',
            internalName: 'Wing',
            Icon: 'WingIcon',
            applicableGuides: [],
            applicableServices: [],
            views: [
                {
                    label: 'serviceDesk.shared.pinpointStep.views.top',
                    sketchUrl:
                        'https://ucarecdn.com/6765d16c-8c65-4444-9ba9-8991eb3b07fb/-/preview/662x377/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.bottom',
                    sketchUrl:
                        'https://ucarecdn.com/bd58df0c-e50c-4739-a072-e143e7fef5fd/-/preview/662x377/',
                    details: [],
                },
                {
                    label: 'serviceDesk.shared.pinpointStep.views.side',
                    sketchUrl:
                        'https://ucarecdn.com/9488fcbf-bd3c-494a-8b24-ae8b83a3a8ae/-/preview/662x377/',
                    details: [],
                },
            ],
        },
    ],
}
export default productCategories
