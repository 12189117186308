import React from 'react'
import { RouteComponentProps } from 'react-router'
import whitelabels from '../../../../shared/data/whitelabel'
import RepairMultiStep from './repair-multi-step'
import uploadcareClient from '../../../mixins/uploadcare-client'
import { ThemeProvider, createTheme } from '@mui/material'
import { submitRepairRequest } from 'wasp/client/operations'
import { FormValues, WhitelabelWithRepairConfig } from './context'
import mergeObjects from '../../../mixins/merge-object'
import { useAnalytics } from 'use-analytics'
import { RepairRequestPageViewEvent } from '../../../../shared/analytics'
import { useIsMobile } from '../../../mixins/media-query'
import parseQueryString from '../../../mixins/parse-query-string'

const RepairModule = (props: RouteComponentProps<{ company: string }>) => {
    const company = props.match.params.company
    const companyId = company.split('-').at(-1)!
    const utmData = parseQueryString()
    const analytics = useAnalytics()
    const isMobile = useIsMobile()

    const whitelabel = whitelabels.find((w) => w.id === companyId)

    if (!whitelabel || !whitelabel.config.repair) {
        window.location.href = '//www.brakeable.com'
        return null
    }

    React.useEffect(() => {
        analytics.track(RepairRequestPageViewEvent, { isMobile, brandId: companyId })
    }, [])

    const handleSubmit = async (formValues: FormValues) => {
        await submitRepairRequest({
            companyId: companyId,
            defectPhotoUrl: formValues.uploadedPhotoUrl!,
            pinpoint: formValues.pinpoint,
            description: formValues.description!,
            product: formValues.selectedProduct,
            productView: formValues.selectedProductView,
            contactForm: formValues.contactFormValues,
            utmData: utmData,
            anonymousUserId: analytics.user().anonymousId,
        })
    }

    return (
        <ThemeProvider
            theme={(theme: any) =>
                createTheme({
                    ...theme,
                    palette: {
                        ...theme.palette,
                        ...whitelabel?.theme.palette,
                    },
                    components: mergeObjects(theme.components, whitelabel?.theme.components),
                } as any)
            }
        >
            <RepairMultiStep
                uploadCareClient={uploadcareClient}
                whitelabel={whitelabel as WhitelabelWithRepairConfig}
                onSubmit={handleSubmit}
            />
        </ThemeProvider>
    )
}

export default RepairModule
