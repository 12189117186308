import { FieldPath, type FieldValues, type DefaultValues, useForm } from 'react-hook-form'

const useMultiStepForm = <FormType extends FieldValues>(
    defaultValues: DefaultValues<FormType>,
    onSubmit: (data: FormType) => void
) => {
    const form = useForm<FormType>({
        defaultValues: defaultValues,
    })
    const resetForm = () => {
        form.reset(defaultValues)
    }

    const submitForm = async () => {
        form.handleSubmit(onSubmit)()
    }
    const formValues = (Object.keys(defaultValues) as FieldPath<FormType>[]).reduce(
        (acc, key) => ({ ...acc, [key]: form.watch(key) }),
        {}
    ) as FormType

    return {
        form,
        resetForm,
        submitForm,
        formValues,
    }
}

export default useMultiStepForm
