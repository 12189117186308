import React from 'react'
import Coord from '../../../../../../shared/types/common/coord'
import Pinpoint from './pinpoint'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { ProductView } from '../../../../../../shared/data/products/types'
import { Tab, Tabs, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ImageWrapperAligner = styled('div')`
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-height: 100%;
    flex-shrink: 1;
    min-height: 0; // too shrink element to fit image inside flexbox
    flex-grow: 1;
    width: 100%;
`

export const PinpointStep = ({
    productViews,
    setInspectionImagePinpoint,
    setSelectedComponent,
    initialCoords,
    initialSelectedProductView,
    setSelectedProductView,
}: {
    productViews: ProductView[]
    setInspectionImagePinpoint: (pinpoint?: Coord) => void
    setSelectedComponent: (component?: string) => void
    setSelectedProductView: (view: number) => void
    initialCoords?: Coord
    initialSelectedProductView: number
}) => {
    const [selectedProductView, setSelectedProductViewState] = React.useState(
        initialSelectedProductView
    )
    const [t] = useTranslation()

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedProductViewState(newValue)
    }

    const setPinpoint = (pinpoint: Coord) => {
        setInspectionImagePinpoint(pinpoint)
        setSelectedProductView(selectedProductView)
    }
    const productView = productViews[selectedProductView]

    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.shared.pinpointStep.title" mb={2} />
            {productViews.length > 1 && (
                <Tabs
                    value={selectedProductView}
                    onChange={handleTabChange}
                    aria-label="Different product view tabs"
                >
                    {productViews.map((view) => (
                        <Tab
                            key={view.label}
                            label={t(view.label as any)}
                            id={`product-view-tabs-${view.label}`}
                            aria-controls={`productview-tabpanel-${view.label}`}
                        />
                    ))}
                </Tabs>
            )}
            <ImageWrapperAligner>
                <Pinpoint
                    key={productView.label}
                    productImageUrl={productView.sketchUrl}
                    onPinpointChanged={setPinpoint}
                    setSelectedComponent={setSelectedComponent}
                    initialCoords={
                        initialSelectedProductView === selectedProductView
                            ? initialCoords
                            : undefined
                    }
                    productDetails={productView.details}
                />
            </ImageWrapperAligner>
        </>
    )
}

export default PinpointStep
