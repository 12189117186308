import React from 'react'
import styled from 'styled-components'
import Button from '@mui/material/Button'
import LanguageSwitcher from './shared/components/language-switcher'
import whitelabels from '../../../shared/data/whitelabel'
import { Stack, ThemeProvider, createTheme } from '@mui/material'
import {
    Page,
    Header,
    WidthContainer,
    BrandLogo,
    Content,
    Footer,
    PBB,
    PoweredBy,
    PoweredByBrakeable,
} from './shared/layout'
import routeBuilder from '../../routes'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0 16px;
    position: relative;
`

const Tatajuba = () => {
    return (
        <Page>
            <Header>
                <WidthContainer sx={{ justifyContent: 'space-between' }}>
                    <BrandLogo src="https://ucarecdn.com/9e0bb50d-8d9f-4779-ae66-f32c5b04d9ad/" />

                    <LanguageSwitcher />
                </WidthContainer>
            </Header>
            <Content>
                <WidthContainer>
                    <Container>
                        {whitelabels.map((entry, index) => (
                            <ThemeProvider
                                key={entry.id}
                                theme={(theme: any) =>
                                    createTheme({
                                        ...theme,
                                        palette: {
                                            ...theme.palette,
                                            ...entry?.theme.palette,
                                        },
                                    } as any)
                                }
                            >
                                <Stack direction="row" sx={{ width: '100%' }} gap={1} mt={2}>
                                    {entry.config.repair && (
                                        <Button
                                            variant="contained"
                                            href={routeBuilder.serviceDesk(
                                                entry.id,
                                                entry.name,
                                                'repair'
                                            )}
                                            fullWidth
                                            size="large"
                                        >
                                            {entry.name} - Repair
                                        </Button>
                                    )}
                                    {entry.config.warranty && (
                                        <Button
                                            variant="outlined"
                                            href={routeBuilder.serviceDesk(
                                                entry.id,
                                                entry.name,
                                                'warranty'
                                            )}
                                            fullWidth
                                            size="large"
                                        >
                                            {entry.name} - Warranty
                                        </Button>
                                    )}
                                </Stack>
                            </ThemeProvider>
                        ))}
                    </Container>
                </WidthContainer>
            </Content>
            <Footer>
                <WidthContainer sx={{ justifyContent: 'flex-end' }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}

export default Tatajuba
