import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { Box, Stack, Button, IconButton, styled, Typography, Drawer } from '@mui/material'
import T from '../../../../../components/typography/t'
// import DIYStep from './diy-step'
import { type RepairService } from '../../../../../../shared/data/repair-services'
import useDialog from '../../../../../mixins/use-dialog'
import { useIsDesktop } from '../../../../../mixins/media-query'
import CloseIcon from '@mui/icons-material/Close'
import ContactForm, { type FormData } from './contact-form'
import { type ServiceDeskContactForm } from '../../../../../../shared/data/whitelabel/types'

const Delimitter = styled('div')(
    ({ theme }) => `
    width: 100%;
    border-bottom: 1px solid ${theme.palette.primary.main};
`
)

const InnerDrawer = styled('div')`
    padding: ${({ theme }) => theme.spacing(4, 4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
`

const DetailsCard = styled('div')`
    padding: ${({ theme }) => theme.spacing(4, 4)};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1em;
    flex-grow: 1;
    box-shadow: ${({ theme }) => theme.shadows[4]};
    border-radius: ${({ theme }) => theme.spacing(1)};
`
const CostEstimation: React.FC<{
    selectedServices: RepairService[]
    onDiyClick: () => void
    repairGuideCount: number
    onSubmit: (data: FormData) => void
    onAddMoreDefectsClick: () => void
    contactForm: ServiceDeskContactForm
    useForm: UseFormReturn<FormData>
}> = ({
    selectedServices,
    onDiyClick,
    repairGuideCount,
    onSubmit,
    onAddMoreDefectsClick,
    contactForm,
    useForm,
}) => {
    const isDesktop = useIsDesktop()
    const { isOpen, close, open } = useDialog()
    const maxPrice = Math.max(...selectedServices.flatMap((service) => service.price))
    const minPrice = Math.min(...selectedServices.flatMap((service) => service.price))
    const isPriceRange = maxPrice - minPrice > 0
    const dynamicModalChildren = (
        <>
            <T
                variant="h3"
                fontWeight={500}
                color="primary"
                i18nKey="serviceDesk.repair.contactStep.form.title"
            />
            <ContactForm
                onSubmit={onSubmit}
                onAddMoreDefectsClick={onAddMoreDefectsClick}
                contactForm={contactForm}
                useForm={useForm}
            />
        </>
    )

    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.repair.costEstimationStep.title" mb={4} />
            <Stack
                direction="row"
                gap={8}
                justifyContent="center"
                width="100%"
                alignItems="flex-start"
            >
                <Box
                    sx={{
                        maxWidth: (theme) =>
                            isDesktop ? `calc(50% - ${theme.spacing(4)})` : '100%',
                    }}
                >
                    <T
                        i18nKey="serviceDesk.repair.costEstimationStep.costRange.title"
                        component="h2"
                        variant="h3"
                        color="primary"
                        count={selectedServices.length}
                        mb={1}
                    />
                    <T
                        i18nKey="serviceDesk.repair.costEstimationStep.costRange.subtitle"
                        component="p"
                        variant="body1"
                        mb={2}
                    />
                    <Typography variant="h2" color="primary">
                        {isPriceRange
                            ? `${minPrice.toFixed(2)} - ${maxPrice.toFixed(2)}€`
                            : `${maxPrice.toFixed(2)}€`}
                    </Typography>
                    <Delimitter sx={{ my: 4 }} />
                    <T
                        i18nKey="serviceDesk.repair.costEstimationStep.assignedServices.title"
                        component="h2"
                        variant="h3"
                        color="primary"
                        mb={1}
                    />
                    <T
                        i18nKey="serviceDesk.repair.costEstimationStep.assignedServices.subtitle"
                        component="p"
                        variant="body1"
                        mb={5}
                    />
                    <Stack gap={2} mb={4}>
                        {selectedServices.map((s) => (
                            <Stack direction="row" justifyContent="space-between" key={s.id}>
                                <Typography>{s.title}</Typography>
                                <Typography fontWeight={500}>
                                    {s.price.length > 1
                                        ? `${s.price[0].toFixed(2)} - ${s.price[1].toFixed(2)}`
                                        : s.price[0].toFixed(2)}
                                    €
                                </Typography>
                            </Stack>
                        ))}
                    </Stack>
                    {!isOpen && (
                        <Stack
                            direction={{ md: 'row', sm: 'column' }}
                            mt={2}
                            gap={2}
                            justifyContent={{ md: 'flex-end', xs: 'stretch' }}
                            width="100%"
                        >
                            {repairGuideCount > 0 && (
                                <Button onClick={onDiyClick}>
                                    <T i18nKey="serviceDesk.repair.costEstimationStep.diyAction" />
                                </Button>
                            )}
                            <Button variant="contained" onClick={open}>
                                <T i18nKey="serviceDesk.repair.costEstimationStep.repairAction" />
                            </Button>
                        </Stack>
                    )}
                </Box>
                {!isDesktop ? (
                    <Drawer
                        open={isOpen}
                        onClose={close}
                        PaperProps={{ sx: { borderRadius: '24px 24px 0 0' } }}
                    >
                        <InnerDrawer>
                            <IconButton
                                onClick={(e: any) => {
                                    e.target.blur()
                                    close()
                                }}
                                sx={{
                                    position: 'absolute',
                                    top: (theme) => theme.spacing(1),
                                    right: (theme) => theme.spacing(1),
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            {dynamicModalChildren}
                        </InnerDrawer>
                    </Drawer>
                ) : (
                    isOpen && <DetailsCard>{dynamicModalChildren}</DetailsCard>
                )}
            </Stack>
        </>
    )
}

export default CostEstimation
