import React from 'react'
import { WarrantyMultiStepFormContext } from '../../context'
import StepFrameworkWrapper from '../../../shared/components/step-framework-wrapper'
import NoEstimationScreen from './no-estimation-screen'
import {
    Dialog,
    DialogContent,
    DialogContentText,
    LinearProgress,
    Box,
    Typography,
} from '@mui/material'
import T from '../../../../../components/typography/t'
import { useForm } from 'react-hook-form'
import { FormData } from './contact-form'

const SolutionStepContext = () => {
    const { form, props, ctx, meta } = React.useContext(WarrantyMultiStepFormContext)
    const useFormReturn = useForm<FormData>({ values: form.formValues.contactFormValues })
    const [isLoading, setIsloading] = React.useState(false)
    const [hasSubmitted, setHasSubmitted] = React.useState(false)

    const formSubmit = () => {
        if (!hasSubmitted) {
            form.submitForm()
            props.nextSlide()
        }
    }

    const fileuploadProgress =
        Math.min(
            meta.value.defectPhotoUpload.progress,
            meta.value.receiptPhotoUpload?.progress ?? 1
        ) * 100

    const onRepairFormSubmit = (data: any) => {
        form.setValue('contactFormValues', data)

        const trySubmit = () => {
            meta.setMetaValue((meta: any) => {
                if (
                    meta.defectPhotoUpload.progress >= 1 &&
                    (meta.receiptPhotoUpload?.progress ?? 1) >= 1
                ) {
                    setIsloading(false)
                    clearInterval(intervalId)
                    formSubmit()
                    setHasSubmitted(true)
                } else {
                    setIsloading(true)
                }
                return meta
            })
        }
        const intervalId = setInterval(trySubmit, 1000)
    }

    const addMoreDefectsClick = () => {
        form.resetForm()
        props.setSlide(0)
    }

    return (
        <StepFrameworkWrapper
            wide
            navigationProps={{
                nextButtonLabelKey: 'common.next',
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: true,
                onValidPrevClick: () => {
                    form.setValue('contactFormValues', useFormReturn.getValues())
                    props.prevSlide()
                },
            }}
        >
            <NoEstimationScreen
                onSubmit={onRepairFormSubmit}
                onAddMoreDefectsClick={addMoreDefectsClick}
                contactForm={ctx.whitelabel.config.warranty.contactForm}
                useForm={useFormReturn}
            />
            <Dialog open={isLoading}>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <T i18nKey="serviceDesk.warranty.solutionStep.waitingForPhotoUpload" />
                    </DialogContentText>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                            <LinearProgress variant="determinate" value={fileuploadProgress} />
                        </Box>
                        <Box sx={{ minWidth: 35 }}>
                            <Typography
                                variant="body2"
                                sx={{ color: 'text.secondary' }}
                            >{`${Math.round(fileuploadProgress)}%`}</Typography>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </StepFrameworkWrapper>
    )
}

export default SolutionStepContext
