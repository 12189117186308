import defaultRepairGuides from '../repair-guides'
import defaultRepairServices from '../repair-services'
import penguinProductCategoryTree from '../products/penguin-products'
import { type Whitelabel } from './types'

const contactFields = [
    [
        {
            key: 'Name',
            label: 'serviceDesk.shared.contactStep.form.nameLabel',
            options: { required: true },
        },
    ],
    [
        {
            key: 'Email',
            label: 'serviceDesk.shared.contactStep.form.emailLabel',
            options: { required: true },
            fieldProps: { type: 'email' },
        },
    ],
    [
        {
            key: 'Phone',
            label: 'serviceDesk.shared.contactStep.form.phoneLabel',
            options: { required: true },
        },
    ],
    [
        {
            key: 'Address',
            label: 'serviceDesk.shared.contactStep.form.addressLabel',
            options: { required: true },
        },
    ],
    [
        {
            key: 'ZipCode',
            label: 'serviceDesk.shared.contactStep.form.zipCodeLabel',
            options: { required: true },
            fieldProps: {
                type: 'number',
                fieldControlSx: { width: 150, flexShrink: 0 },
            },
        },
        {
            key: 'City',
            label: 'serviceDesk.shared.contactStep.form.cityLabel',
            options: { required: true },
        },
    ],
    [
        {
            key: 'Country',
            label: 'serviceDesk.shared.contactStep.form.countryLabel',
            options: { required: true },
        },
    ],
]

const purchaseFields = [
    [
        {
            key: 'PurchaseLocation',
            label: 'serviceDesk.shared.contactStep.form.purchaseLocationLabel',
            options: { required: false },
            fieldProps: {
                placeholder: 'Flagship Store Penguin Zürich',
            },
        },
        {
            key: 'PurchaseDate',
            label: 'serviceDesk.shared.contactStep.form.purchaseDateLabel',
            options: { required: true },
            fieldProps: {
                type: 'date',
                fieldControlSx: { width: 150, flexShrink: 0 },
            },
        },
    ],
]

const penguinWhitelabel: Whitelabel = {
    id: '1iutwg',
    name: 'Penguin',
    theme: {
        palette: {
            primary: {
                main: '#282727',
                contrastText: '#E2D4C8',
            },
            secondary: {
                main: '#E2D4C8',
                contrastText: '#282727',
            },
            getCrosshairColors: () => ({ stroke: '#E2D4C8', fill: '#282727' }),
            getIllustrationColors: () => ({ primary: '#282727', accent: '#E2D4C8' }),
            getProgressbarColor: () => '#282727',
        },
    },
    logo: 'https://ucarecdn.com/eb01faa4-cbb2-4fc1-ac03-01abe2fbb6cd/',
    repairGuides: defaultRepairGuides,
    repairServices: defaultRepairServices,
    productCategoryTree: penguinProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [...contactFields, ...purchaseFields],
            },
            activateCostEstimation: false,
            includeDiyGuides: false,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: '//penguin.swiss',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'repair@penguin.swiss',
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
        warranty: {
            contactForm: {
                fields: contactFields,
            },
            proofOfPurchaseFields: [
                [
                    {
                        key: 'Orderreference',
                        label: 'serviceDesk.warranty.purchaseConfirmationStep.form.orderReferenceLabel',
                        options: { required: true },
                    },
                ],
                [
                    {
                        key: 'ArticleNumber',
                        label: 'serviceDesk.warranty.purchaseConfirmationStep.form.articleNumberLabel',
                        options: { required: true },
                    },
                ],
                ...purchaseFields,
            ],
            activateWarrantyEstimation: false,
            outro: {
                lead: 'serviceDesk.warranty.outroStep.subtitle',
                links: [
                    {
                        href: '//penguin.swiss',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: [
                            'repair@penguin.swiss',
                            'marc@brakeable.com',
                            'stefan@brakeable.com',
                        ],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default penguinWhitelabel
