import { type Whitelabel } from './types'
import penguinWhitelabel from './penguin-whitelabel'
import zenitramWhitelabel from './zenitram-whitelabel'
import snowdoniaWhitelabel from './snowdonia-whitelabel'
import vidarWhitelabel from './vidar-whitelabel'
import tradeFairWhitelabel from './trade-fair-whitelabel'
import vaudeWhitelabel from './vaude-whitelabel'
import ortliebWhitelabel from './ortlieb-whitelabel'
import intersportWhitelabel from './intersport-whitelabel'
import berghauseWhitelabel from './berghaus-whitelabel'

export const whitelabels: Whitelabel[] = [
    tradeFairWhitelabel,
    penguinWhitelabel,
    zenitramWhitelabel,
    snowdoniaWhitelabel,
    vidarWhitelabel,
    vaudeWhitelabel,
    ortliebWhitelabel,
    intersportWhitelabel,
    berghauseWhitelabel,
]

export default whitelabels
