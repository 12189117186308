import { styled } from '@mui/material'

const LabelledDelimiter = styled('div')(
    ({ theme }) => `
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;

    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 0;
        top: 50%;
        left: 0;
        border-bottom: 1px solid ${theme.palette.grey[200]};
        z-index: -1;
    }

    > * {
        color: ${theme.palette.grey[200]};
        padding: ${theme.spacing(1, 2)};
        background: #fff;
        width: auto;
        text-transform: uppercase;
    }
`
)

export default LabelledDelimiter
