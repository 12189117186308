import React from 'react'
import { WarrantyMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import PhotoStep, {
    type PhotoStepHandle,
    type UploadedFileInfo,
} from '../../shared/steps/photo-step'
import useCookie from '../../../../mixins/use-cookie'
import generateRandomCode from '../../shared/helper/generate-random-code'
import InspectorStepTitle from '../../../../components/typography/inspector-step-title'

const DefectPhotoStep = () => {
    const childRef = React.useRef<PhotoStepHandle>(null)
    const { props, form, meta, error, uploadCareClient, ctx } = React.useContext(
        WarrantyMultiStepFormContext
    )

    const onPhotoUploadFailed = (err: any) => {
        console.error(err)
        meta.setMetaValue({ ...meta, defectPhotoUpload: { progress: 0 } })
        error.pushError({
            message: 'Foto konnte nicht hochgeladen werden',
            action: {
                label: 'Erneut versuchen',
                onClick: () => {
                    error.popError()
                    props.setSlide(0)
                },
            },
        })
    }

    const setUploadProgress = (progression: number) => {
        meta.setMetaValue((m: any) => ({ ...m, defectPhotoUpload: { progress: progression } }))
    }

    const setUploadedFileInfo = (info?: UploadedFileInfo) => {
        meta.setMetaValue((m: any) => ({ ...m, uploadedDefectPhotoInfo: info }))
    }

    const [getMobileUploadIdFromCookies, setMobileUploadIdToCookies] = useCookie(
        'mobile-defect-photo-upload-id'
    )
    const mobileUploadId =
        getMobileUploadIdFromCookies() ??
        setMobileUploadIdToCookies(generateRandomCode(), { expires: 1 / 96 })

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled:
                    !form.formValues.defectPhotoUrl && !meta.value?.defectPhotoUpload?.progress,
                onValidNextClick: () => {
                    childRef.current?.goNextInterceptor()
                    props.nextSlide()
                },
            }}
        >
            <InspectorStepTitle i18nKey="serviceDesk.shared.photoStep.title" mb={4} />
            <PhotoStep
                ref={childRef}
                setOriginalImageUrl={(url) => form.setValue('defectPhotoUrl', url)}
                onPhotoUploadFailed={onPhotoUploadFailed}
                setUploadProgress={setUploadProgress}
                setUploadedFileInfo={setUploadedFileInfo}
                uploadProgress={meta.value?.defectPhotoUpload?.progress}
                uploadedFileInfo={meta.value?.uploadedDefectPhotoInfo}
                uploadedPhotoUrl={form.formValues.defectPhotoUrl}
                uploadCareClient={uploadCareClient}
                brandId={ctx.whitelabel.id}
                brandName={ctx.whitelabel.name}
                mobileUploadId={mobileUploadId}
            />
        </StepFrameworkWrapper>
    )
}

export default DefectPhotoStep
