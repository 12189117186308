import React from 'react'
import { WarrantyMultiStepFormContext } from '../../context'
import StepFrameworkWrapper from '../../../shared/components/step-framework-wrapper'
import PhotoStep, {
    type PhotoStepHandle,
    type UploadedFileInfo,
} from '../../../shared/steps/photo-step'
import useCookie from '../../../../../mixins/use-cookie'
import generateRandomCode from '../../../shared/helper/generate-random-code'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { useTranslation } from 'react-i18next'
import { WidthContainer } from '../../../shared/layout'
import { Button, Stack } from '@mui/material'
import LinktreeEntry from '../../../shared/components/linktree-entry'
import T from '../../../../../components/typography/t'
import LabelledDelimiter from '../../../shared/components/labelled-delimiter'
import ManualProofOfPurchaseInput from './manual-proof-of-purchase-input'
import { useForm } from 'react-hook-form'

const Linktree: React.FC<{
    onManualClick: () => void
    onPhotoClick: () => void
    onNotAvailableClick: () => void
}> = ({ onManualClick, onPhotoClick, onNotAvailableClick }) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle
                i18nKey="serviceDesk.warranty.purchaseConfirmationStep.linktree.title"
                mb={4}
            />
            <WidthContainer>
                <Stack gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
                    <LinktreeEntry onClick={onPhotoClick}>
                        <T
                            variant="h3"
                            mb={1}
                            i18nKey="serviceDesk.warranty.purchaseConfirmationStep.linktree.photoTitle"
                        />
                        <T
                            variant="body1"
                            i18nKey="serviceDesk.warranty.purchaseConfirmationStep.linktree.photoSubtitle"
                        />
                    </LinktreeEntry>
                    <LinktreeEntry onClick={onManualClick}>
                        <T
                            variant="h3"
                            mb={1}
                            i18nKey="serviceDesk.warranty.purchaseConfirmationStep.linktree.manualTitle"
                        />
                        <T
                            variant="body1"
                            i18nKey="serviceDesk.warranty.purchaseConfirmationStep.linktree.manualSubtitle"
                        />
                    </LinktreeEntry>
                </Stack>
                <LabelledDelimiter sx={{ my: 4 }}>
                    <T
                        component="span"
                        i18nKey="serviceDesk.warranty.purchaseConfirmationStep.linktree.notAvailable.delimiter"
                    />
                </LabelledDelimiter>
                <Button onClick={onNotAvailableClick}>
                    <T i18nKey="serviceDesk.warranty.purchaseConfirmationStep.linktree.notAvailable.button" />
                </Button>
            </WidthContainer>
        </>
    )
}

const PurchaseConfirmationStep = () => {
    const childRef = React.useRef<PhotoStepHandle>(null)
    const [selectedApproach, setSelectedApproach] = React.useState(0)
    const { props, form, meta, error, uploadCareClient, ctx } = React.useContext(
        WarrantyMultiStepFormContext
    )
    const useFormReturn = useForm({ values: form.formValues.proofOfPurchaseForm })

    const onPhotoUploadFailed = (err: any) => {
        console.error(err)
        meta.setMetaValue({ ...meta, receiptPhotoUpload: { progress: 0 } })
        error.pushError({
            message: 'Foto konnte nicht hochgeladen werden',
            action: {
                label: 'Erneut versuchen',
                onClick: () => {
                    error.popError()
                    props.setSlide(0)
                },
            },
        })
    }

    const setUploadProgress = (progression: number) => {
        meta.setMetaValue((m: any) => ({ ...m, receiptPhotoUpload: { progress: progression } }))
    }

    const setUploadedFileInfo = (info?: UploadedFileInfo) => {
        meta.setMetaValue((m: any) => ({ ...m, uploadedReceiptPhotoInfo: info }))
    }

    const [getMobileUploadIdFromCookies, setMobileUploadIdToCookies] = useCookie(
        'mobile-receipt-photo-upload-id'
    )
    const mobileUploadId =
        getMobileUploadIdFromCookies() ??
        setMobileUploadIdToCookies(generateRandomCode(), { expires: 1 / 96 })

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled:
                    selectedApproach === 0 ||
                    (selectedApproach === 1 &&
                        !form.formValues.receiptPhotoUrl &&
                        !meta.value?.receiptPhotoUpload?.progress) ||
                    (selectedApproach === 2 &&
                        !ctx.whitelabel.config.warranty.proofOfPurchaseFields
                            .flat()
                            .filter((field) => field.options.required)
                            .every((field) => !!useFormReturn.watch(field.key))),
                onValidNextClick: () => {
                    form.setValue('proofOfPurchaseForm', useFormReturn.getValues())
                    childRef.current?.goNextInterceptor()
                    props.nextSlide()
                },
                onValidPrevClick: () => {
                    if (selectedApproach === 0) {
                        props.prevSlide()
                    } else {
                        setSelectedApproach(0)
                    }
                },
            }}
        >
            {selectedApproach === 0 && (
                <Linktree
                    onPhotoClick={() => setSelectedApproach(1)}
                    onManualClick={() => setSelectedApproach(2)}
                    onNotAvailableClick={props.nextSlide}
                />
            )}
            {selectedApproach === 1 && (
                <>
                    <InspectorStepTitle
                        i18nKey="serviceDesk.warranty.purchaseConfirmationStep.photo.title"
                        mb={4}
                    />
                    <PhotoStep
                        ref={childRef}
                        setOriginalImageUrl={(url) => form.setValue('receiptPhotoUrl', url)}
                        onPhotoUploadFailed={onPhotoUploadFailed}
                        setUploadProgress={setUploadProgress}
                        setUploadedFileInfo={setUploadedFileInfo}
                        uploadProgress={meta.value?.receiptPhotoUpload?.progress}
                        uploadedFileInfo={meta.value?.uploadedReceiptPhotoInfo}
                        uploadedPhotoUrl={form.formValues.receiptPhotoUrl}
                        uploadCareClient={uploadCareClient}
                        brandId={ctx.whitelabel.id}
                        brandName={ctx.whitelabel.name}
                        mobileUploadId={mobileUploadId}
                    />
                </>
            )}
            {selectedApproach === 2 && (
                <ManualProofOfPurchaseInput
                    fields={ctx.whitelabel.config.warranty.proofOfPurchaseFields}
                    useFormReturn={useFormReturn}
                />
            )}
        </StepFrameworkWrapper>
    )
}

export default PurchaseConfirmationStep
