import React from 'react'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { useTranslation } from 'react-i18next'
import { Stack, styled } from '@mui/material'
import T from '../../../../../components/typography/t'
import { WidthContainer } from '../../../shared/layout'
import LinktreeEntry from '../../../shared/components/linktree-entry'
import { type RepairService } from '../../../../../../shared/data/repair-services'

const Linktree: React.FC<{
    onDiyClick: () => void
    onServicesClick: () => void
    repairGuideCount: number
    selectedServices: RepairService[]
}> = ({ onDiyClick, onServicesClick, repairGuideCount, selectedServices }) => {
    const [t] = useTranslation()
    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.repair.solutionStep.linktree.title" mb={4} />
            <WidthContainer>
                <Stack gap={2} sx={{ width: '100%', justifyContent: 'center' }}>
                    <LinktreeEntry onClick={onDiyClick} disabled={repairGuideCount === 0}>
                        <T
                            variant="h3"
                            mb={1}
                            i18nKey="serviceDesk.repair.solutionStep.linktree.diyTitle"
                        />
                        <T
                            variant="body1"
                            i18nKey="serviceDesk.repair.solutionStep.linktree.diySubtitle"
                        />
                    </LinktreeEntry>
                    <LinktreeEntry onClick={onServicesClick}>
                        {selectedServices.length > 0 ? (
                            <>
                                <T
                                    variant="h3"
                                    mb={1}
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.servicesTitle"
                                />
                                <T
                                    variant="body1"
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.servicesSubtitle"
                                />
                            </>
                        ) : (
                            <>
                                <T
                                    variant="h3"
                                    mb={1}
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.contactFormTitle"
                                />
                                <T
                                    variant="body1"
                                    i18nKey="serviceDesk.repair.solutionStep.linktree.contactFormSubtitle"
                                />
                            </>
                        )}
                    </LinktreeEntry>
                </Stack>
            </WidthContainer>
        </>
    )
}

export default Linktree
