export const RepairRequestPageViewEvent = 'RepairRequest_Pageview'
export const WarrantyRequestPageViewEvent = 'WarrantyRequest_Pageview'

export const InspectorPageViewMobileEvent = 'Inspector_Pageview:Mobile'
export const InspectorPageViewDesktopEvent = 'Inspector_Pageview:Desktop'
export const UnknownUserId = 'unknown'
export const ClientDevice = {
    Mobile: 'mobile',
    Desktop: 'desktop',
}
