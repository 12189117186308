import React from 'react'
import MultiStepNavigation, { MultiStepNavigationProps } from './navigation'
import { styled } from '@mui/material'

export const StepContentContainer = styled('div')<{ $squeezeInScreen: boolean; $wide: boolean }>`
    height: ${({ $squeezeInScreen }) => ($squeezeInScreen ? '100px' : 'auto')};
    padding: 0 ${({ theme }) => theme.spacing(2)};
    flex-grow: 1;
    width: 100%;
    max-width: ${({ $wide }) => ($wide ? '1200px' : '650px')};
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export const WidthContainer = styled('div')`
    height: 100%;
    width: 100%;
    max-width: 1200px;
    padding: 0 ${({ theme }) => theme.spacing(2)};
    align-self: center;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    flex-direction: inherit;
`

type StepFrameworkWrapperProps = {
    children: React.ReactNode
    squeezeInScreen?: boolean
    wide?: boolean
    navigationProps: MultiStepNavigationProps
}

const StepFrameworkWrapper = ({
    navigationProps,
    children,
    squeezeInScreen = false,
    wide = false,
}: StepFrameworkWrapperProps) => {
    const prevButtonDisabled =
        navigationProps.prevButtonDisabled ?? navigationProps.currentStep === 0

    return (
        <>
            <WidthContainer sx={{ height: 'auto' }}>
                <MultiStepNavigation {...navigationProps} prevButtonDisabled={prevButtonDisabled} />
            </WidthContainer>
            <StepContentContainer $squeezeInScreen={squeezeInScreen} $wide={wide}>
                {children}
            </StepContentContainer>
        </>
    )
}

export default StepFrameworkWrapper
