import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import { useTranslation } from 'react-i18next'
import InputField from '../../../../../components/form/input-field'
import { type Field } from '../../../../../../shared/data/whitelabel/types'
import { Stack } from '@mui/material'

export const ManualProofOfPurchaseInput = ({
    useFormReturn,
    fields,
}: {
    useFormReturn: UseFormReturn
    fields: Field[][]
}) => {
    const [t] = useTranslation()
    const { register, handleSubmit, formState } = useFormReturn
    return (
        <>
            <InspectorStepTitle
                i18nKey="serviceDesk.warranty.purchaseConfirmationStep.form.title"
                mb={4}
            />
            {fields.map((cells, index) => (
                <Stack
                    direction="row"
                    mb={index === fields.length - 1 ? 0 : 1}
                    gap={2}
                    sx={{ width: '100%' }}
                    key={index}
                >
                    {cells.map((cell) => (
                        <InputField
                            key={cell.key}
                            id={cell.key}
                            fullWidth
                            {...register(cell.key as any, cell.options)}
                            {...(cell.fieldProps as any)}
                            required={cell.options.required}
                            label={t(cell.label as any)}
                        />
                    ))}
                </Stack>
            ))}
        </>
    )
}

export default ManualProofOfPurchaseInput
