import ortliebProductCategoryTree from '../products/ortlieb-products'
import { type Whitelabel } from './types'
import vaudeRepairGuides from '../repair-guides/vaude-repair-guides-de'
import { repairServicesDe } from '../repair-services'

const vaudeWhitelabel: Whitelabel = {
    id: '1c3fyj',
    name: 'Ortlieb',
    theme: {
        palette: {
            primary: {
                main: '#EE9000',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#EE9000',
                contrastText: '#ffffff',
            },
            getCrosshairColors: () => ({ stroke: '#EE9000', fill: '#ffffff' }),
            getIllustrationColors: () => ({ primary: '#EE9000', accent: '#23201F' }),
            getProgressbarColor: () => '#EE9000',
            getTextColor: () => '#23201F',
        },
    },
    logo: 'https://ucarecdn.com/dc7efdb0-0fae-4c18-b375-59d3b9dd1db0/',
    repairGuides: vaudeRepairGuides,
    repairServices: repairServicesDe,
    productCategoryTree: ortliebProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            options: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: '//www.ortlieb.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default vaudeWhitelabel
