import React from 'react'
import {
    Page,
    Header,
    WidthContainer,
    PoweredByBrakeable,
    Content,
    Footer,
    PBB,
    PoweredBy,
    BrandLogo,
} from '../shared/layout'
import whitelabels from '../../../../shared/data/whitelabel'
import brkWhitelabel from '../../../../shared/data/whitelabel/trade-fair-whitelabel'
import { Button, createTheme, ThemeProvider, Typography } from '@mui/material'

const AnalysatorList = () => {
    return (
        <Page>
            <Header>
                <WidthContainer sx={{ justifyContent: 'space-between' }}>
                    <BrandLogo src={brkWhitelabel.logo} />
                </WidthContainer>
            </Header>
            <Content>
                <WidthContainer>
                    <Typography variant="h3" textAlign="center" mb={1}>
                        Analysator
                    </Typography>
                    <ul>
                        {whitelabels.map((whitelabel) => {
                            return (
                                <ThemeProvider
                                    key={whitelabel.id}
                                    theme={(theme: any) =>
                                        createTheme({
                                            ...theme,
                                            palette: {
                                                ...theme.palette,
                                                ...whitelabel?.theme.palette,
                                            },
                                        } as any)
                                    }
                                >
                                    <Button
                                        variant="contained"
                                        href={`/analysator/${whitelabel.id}`}
                                        fullWidth
                                        size="large"
                                        sx={{ mt: 2 }}
                                    >
                                        {whitelabel.name}
                                    </Button>
                                </ThemeProvider>
                            )
                        })}
                    </ul>
                </WidthContainer>
            </Content>
            <Footer>
                <WidthContainer sx={{ justifyContent: 'flex-end' }}>
                    <PBB>
                        <PoweredBy>Powered by</PoweredBy>{' '}
                        <PoweredByBrakeable>BRAKEABLE</PoweredByBrakeable>
                    </PBB>
                </WidthContainer>
            </Footer>
        </Page>
    )
}

export default AnalysatorList
