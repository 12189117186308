import vaudeProductCategoryTree from '../products/vaude-products'
import { type Whitelabel } from './types'
import vaudeRepairGuides from '../repair-guides/vaude-repair-guides-de'
import { repairServicesDe } from '../repair-services'

const vaudeWhitelabel: Whitelabel = {
    id: '3vk3eo',
    name: 'Vaude',
    theme: {
        palette: {
            primary: {
                main: '#FF5900',
                contrastText: '#ffffff',
            },
            secondary: {
                main: '#FF5900',
                contrastText: '#ffffff',
            },
            getCrosshairColors: () => ({ stroke: '#FF5900', fill: '#ffffff' }),
            getIllustrationColors: () => ({ primary: '#FF5900', accent: '#463c32' }),
            getProgressbarColor: () => '#FF5900',
            getTextColor: () => '#463c32',
        },
    },
    logo: 'https://ucarecdn.com/89eaea03-b46b-4d80-b3f2-9e2c05d6925b/',
    links: {
        storeFinder: 'https://www.vaude.com/de/de/haendlersuche.html',
    },
    repairGuides: vaudeRepairGuides,
    repairServices: repairServicesDe,
    productCategoryTree: vaudeProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            options: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [
                    {
                        href: 'https://www.vaude.com',
                        label: 'serviceDesk.repair.outroStep.links.backToStore',
                    },
                ],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default vaudeWhitelabel
