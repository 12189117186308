import React from 'react'
import { WarrantyMultiStepFormContext } from '../context'
import StepFrameworkWrapper from '../../shared/components/step-framework-wrapper'
import DescriptionStep from '../../shared/steps/description-step'

const DescriptionStepContext = () => {
    const { form, props } = React.useContext(WarrantyMultiStepFormContext)

    return (
        <StepFrameworkWrapper
            navigationProps={{
                currentStep: props.currentStep,
                maxSteps: props.maxSteps,
                prevStep: props.prevSlide,
                nextStep: props.nextSlide,
                nextButtonDisabled: !form.formValues.description,
            }}
        >
            <DescriptionStep
                onDescriptionChange={(description) => form.setValue('description', description)}
                initialDescription={form.formValues.description}
            />
        </StepFrameworkWrapper>
    )
}

export default DescriptionStepContext
