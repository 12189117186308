import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import InspectorStepTitle from '../../../../../components/typography/inspector-step-title'
import ContactForm, { type FormData } from './contact-form'
import { type ServiceDeskContactForm } from '../../../../../../shared/data/whitelabel/types'

const NoEstimationScreen: React.FC<{
    onSubmit: (data: FormData) => void
    onAddMoreDefectsClick: () => void
    contactForm: ServiceDeskContactForm
    useForm: UseFormReturn<FormData>
}> = ({ onSubmit, onAddMoreDefectsClick, contactForm, useForm }) => {
    return (
        <>
            <InspectorStepTitle i18nKey="serviceDesk.warranty.contactStep.title" mb={4} />
            <ContactForm
                onSubmit={onSubmit}
                onAddMoreDefectsClick={onAddMoreDefectsClick}
                contactForm={contactForm}
                useForm={useForm}
            />
        </>
    )
}

export default NoEstimationScreen
