import React from 'react'
import { styled, useTheme, Theme } from '@mui/material'
import T from '../../../../../components/typography/t'
import InspectorStepSubtitle from '../../../../../components/typography/inspector-step-subtitle'
import QRCode from 'react-qr-code'
import routeBuilder from '../../../../../routes'

const QRCodeBox = styled('div')`
    padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(5)};
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    color: ${({ theme }) => theme.palette.getTextColor?.() ?? theme.palette.primary.main};
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(4)};
`

const QrMobilePhotoUpload = ({
    mobileUploadId,
    brandId,
    brandName,
}: {
    mobileUploadId: string
    brandId: string
    brandName: string
}) => {
    const theme = useTheme()
    // The "socket" instance is typed with the types you defined on the server.

    return (
        <QRCodeBox>
            <QRCode
                value={routeBuilder.serviceDeskMobilePhotoUpload(brandId, mobileUploadId)}
                size={120}
                fgColor={theme.palette.getTextColor?.() ?? theme.palette.primary.main}
            />
            <div>
                <T variant="h3" mb={1} i18nKey="serviceDesk.shared.photoStep.qrPhotoUpload.title" />
                <InspectorStepSubtitle
                    i18nKey="serviceDesk.shared.photoStep.qrPhotoUpload.text"
                    sx={{
                        textAlign: 'left',
                        color: (theme: Theme) =>
                            theme.palette.getTextColor?.() ?? theme.palette.primary.main,
                    }}
                />
            </div>
        </QRCodeBox>
    )
}

export default QrMobilePhotoUpload
