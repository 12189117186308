import React from 'react'

export type Slide = {
    name: string
    Component: () => JSX.Element
    skip: boolean
}

export type StepProps = {
    currentStep: number
    maxSteps: number
    setSlide: (index: number) => void
    nextSlide: () => void
    prevSlide: () => void
    gotoEnd: () => void
    setSlideSkip: (name: string, skip: boolean) => void
}

const useSlider = (
    slideDefinition: Array<Slide>,
    initialSlide = 0
): StepProps & { slideIndex: number; slides: Slide[] } => {
    const [slides, setSlides] = React.useState(slideDefinition.filter((slide) => !slide.skip))
    const [slideIndex, setSlideIndex] = React.useState(initialSlide)

    const nextSlide = () => setSlideIndex(Math.min(slideIndex + 1, maxSteps - 1))
    const prevSlide = () => {
        setSlideIndex(Math.max(slideIndex - 1, 0))
    }
    const setSlide = (index: number) => setSlideIndex(index)
    const maxSteps = slides.length
    const gotoEnd = () => setSlideIndex(maxSteps - 1)
    const currentStep = slideIndex
    const setSlideSkip = (name: string, skip: boolean) => {
        const slide = slideDefinition.find((slide) => slide.name === name)
        if (slide) {
            slide.skip = skip
            setSlides(slideDefinition.filter((slide) => !slide.skip))
        } else {
            console.warn('slide not found')
        }
    }

    return {
        slideIndex,
        nextSlide,
        prevSlide,
        setSlide,
        gotoEnd,
        currentStep,
        maxSteps,
        setSlideSkip,
        slides,
    }
}

export default useSlider
