import React from 'react'
import { styled, useTheme, alpha, Box } from '@mui/material'
import Coord from '../../../../../../shared/types/common/coord'
import { ProductDetail } from '../../../../../../shared/data/products/types'
import useCookie from '../../../../../mixins/use-cookie'
import createUploadcareImageSrc from '../../../../../mixins/create-uploadcare-image-src'
// @ts-ignore
import CrosshairSvg from '../../../../../assets/img/crosshair.svg?react'
import PinpointNudge from './pinpoint-nudge'

function debounce<T extends (...args: any[]) => any>(
    func: T,
    delay: number
): (...args: Parameters<T>) => void {
    let timeoutId: ReturnType<typeof setTimeout>

    return function (this: any, ...args: Parameters<T>) {
        const context = this

        // Clear the previous timeout
        clearTimeout(timeoutId)

        // Set a new timeout
        timeoutId = setTimeout(() => {
            func.apply(context, args)
        }, delay)
    }
}

const ImageWrapper = styled('div')`
    max-width: 100%;
    max-height: 100%;
    position: relative;
    min-height: 0; // to shrink element to fit image inside flexbox
    flex-shrink: 1;
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`

const ProductImage = styled('img')`
    max-width: 100%;
    max-height: 100%;
    position: relative;
    cursor: pointer;
`

const Crosshair = styled('div')`
    width: 60px;
    height: 60px;
    position: absolute;
    transform: translate(-50%, -50%);
    pointer-events: none;
    z-index: 100;

    svg {
        width: 100%;
        height: 100%;
    }
`

const Zoom = styled('div')(
    ({ theme }) => `
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid ${theme.palette.primary.main};
    box-shadow: 0px 3px 5px -1px ${alpha(
        theme.palette.primary.contrastText,
        0.2
    )},0px 6px 10px 0px ${alpha(theme.palette.primary.contrastText, 0.14)},0px 1px 18px 0px ${alpha(
        theme.palette.primary.contrastText,
        0.12
    )};
    z-index: 101;
    pointer-events: none; /* Prevent interaction */
    background-repeat: no-repeat;
    background-color: white;
    transition: transform .2s;
`
)

const MiniCrosshair = styled(CrosshairSvg)(
    ({ theme }) => `
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: 45px;
        height: 45px;
`
)

const Pinpoint = ({
    productImageUrl,
    onPinpointChanged,
    setSelectedComponent,
    initialCoords = { x: -1000, y: -1000 },
    productDetails,
}: {
    productImageUrl: string
    onPinpointChanged: (coord: Coord) => void
    setSelectedComponent: (component?: string) => void
    initialCoords?: Coord
    productDetails: ProductDetail[]
}) => {
    const [getDebug] = useCookie('debug')
    const theme = useTheme()
    const [coordinates, setCoordinates] = React.useState<Coord>(initialCoords)
    const [imageSize, setImageSize] = React.useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    })
    const [showZoom, setShowZoom] = React.useState(false)
    const [displayZoomLeft, setDisplayZoomLeft] = React.useState(false)

    const imageRef = React.useRef<HTMLImageElement>(null)
    const crosshairRef = React.useRef<HTMLDivElement>(null)

    const updateCoordinates = (coord: Coord) => {
        if (imageRef.current === null) {
            return
        }
        const imageRect = imageRef.current.getBoundingClientRect()

        const calculatedCoordinates = {
            x: Math.floor(Math.min(Math.max(coord.x - imageRect.x, 0), imageRect.width)),
            y: Math.floor(Math.min(Math.max(coord.y - imageRect.y, 0), imageRect.height)),
        }

        const proportionalCoordinates = {
            x: calculatedCoordinates.x / imageRect.width,
            y: calculatedCoordinates.y / imageRect.height,
        }

        const crosshairRelativeScreenOffset =
            (crosshairRef.current?.getBoundingClientRect().left ?? 0) / window.innerWidth
        const isDraggingRight = proportionalCoordinates.x > coordinates.x
        const isDraggingLeft = proportionalCoordinates.x < coordinates.x

        if (isDraggingRight && crosshairRelativeScreenOffset > 0.6) {
            setDisplayZoomLeft(true)
        } else if (isDraggingLeft && crosshairRelativeScreenOffset < 0.25) {
            setDisplayZoomLeft(false)
        }

        setCoordinates(proportionalCoordinates)
    }

    const confirmCoordinates = () => {
        onPinpointChanged(coordinates)
        selectComponent(coordinates)
    }

    const selectComponent = (coord: Coord) => {
        const comps = productDetails
            .sort((a, b) => {
                return (a.x2 - a.x1) * (a.y2 - a.y1) - (b.x2 - b.x1) * (b.y2 - b.y1)
            })
            .filter((c) => c.x1 <= coord.x && coord.x <= c.x2 && c.y1 <= coord.y && coord.y <= c.y2)
            .map((c) => c.name)
        setSelectedComponent(comps.join(', '))
    }

    const debouncedEventHandling = (e: any) => {
        if (e.type === 'touchend' || e.type === 'touchmove' || e.type === 'touchstart') {
            var evt = typeof e.originalEvent === 'undefined' ? e : e.originalEvent
            var touch = evt.touches[0] || evt.changedTouches[0]
            updateCoordinates({ x: touch.clientX, y: touch.clientY })
        } else if (
            (e.type === 'mousemove' && e.buttons === 1) ||
            e.type === 'mousedown' ||
            e.type === 'mouseup'
        ) {
            updateCoordinates({ x: e.clientX, y: e.clientY })
        }

        if (e.type === 'touchend' || e.type === 'mouseup') {
            confirmCoordinates()
        }
    }

    const handleEvent = (e: any) => {
        e.preventDefault()
        if (e.type === 'touchmove' || (e.type === 'mousemove' && e.buttons === 1)) {
            setShowZoom(true)
        } else if (e.type === 'touchend' || e.type === 'mouseup') {
            setShowZoom(false)
        }
        return requestAnimationFrame(() => debouncedEventHandling(e))
    }

    const updateImageSize = (e: any) => {
        if (imageRef.current === null) {
            return
        }

        setImageSize({
            width: imageRef.current.offsetWidth,
            height: imageRef.current.offsetHeight,
        })
    }

    React.useEffect(() => {
        window.addEventListener('resize', updateImageSize)
        window.addEventListener('pageshow', updateImageSize)
        window.addEventListener('focus', updateImageSize)
        window.addEventListener('visibilitychange', updateImageSize)

        if (imageRef.current === null) {
            return
        }

        imageRef.current.addEventListener('touchstart', handleEvent, { passive: false })
        imageRef.current.addEventListener('touchmove', handleEvent, { passive: false })
        imageRef.current.addEventListener('touchend', handleEvent, { passive: false })
        imageRef.current.addEventListener('mouseup', handleEvent, { passive: false })
        imageRef.current.addEventListener('mousemove', handleEvent, { passive: false })
        imageRef.current.addEventListener('mousedown', handleEvent, { passive: false })
        imageRef.current.addEventListener('load', updateImageSize, { passive: false })

        return () => {
            window.removeEventListener('resize', updateImageSize)
            window.removeEventListener('pageshow', updateImageSize)
            window.removeEventListener('focus', updateImageSize)
            window.removeEventListener('visibilitychange', updateImageSize)

            if (imageRef.current === null) {
                return
            }
            imageRef.current.removeEventListener('touchstart', handleEvent)
            imageRef.current.removeEventListener('touchmove', handleEvent)
            imageRef.current.removeEventListener('touchend', handleEvent)
            imageRef.current.removeEventListener('mouseup', handleEvent)
            imageRef.current.removeEventListener('load', updateImageSize)
        }
    }, [imageRef, imageRef.current, updateImageSize, handleEvent])

    const pinpointSet = coordinates.x >= 0
    const zoomLevel = 1.5

    return (
        <>
            <ImageWrapper
                style={{
                    width: imageSize.width > 0 ? imageSize.width + 'px' : '100%',
                    height: imageSize.height > 0 ? imageSize.height + 'px' : '100%',
                }}
            >
                <ProductImage
                    data-testid="pinpoint-image"
                    ref={imageRef}
                    onLoad={updateImageSize}
                    src={createUploadcareImageSrc(productImageUrl, {
                        preview: '700x1400',
                    })}
                    key={productImageUrl}
                    sx={{ opacity: pinpointSet ? 1 : 0.4 }}
                />
                {imageRef.current && !pinpointSet && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            pointerEvents: 'none',
                        }}
                    >
                        <PinpointNudge
                            style={{
                                opacity: !imageRef.current || pinpointSet ? 0 : 1,
                            }}
                        />
                    </Box>
                )}
                <Crosshair
                    ref={crosshairRef}
                    style={{
                        display: imageRef.current ? 'block' : 'none',
                        left: coordinates.x * imageSize.width + 'px',
                        top: coordinates.y * imageSize.height + 'px',
                        opacity: pinpointSet ? 1 : 0,
                        fill:
                            theme.palette.getCrosshairColors?.().fill ?? theme.palette.primary.main,
                        stroke:
                            theme.palette.getCrosshairColors?.().stroke ??
                            theme.palette.primary.main,
                    }}
                >
                    <CrosshairSvg />
                </Crosshair>
                <Zoom
                    sx={{
                        opacity: showZoom ? 1 : 0,
                        backgroundImage: `url(${createUploadcareImageSrc(productImageUrl, {
                            preview: '1050x2100',
                        })})`,
                        left: coordinates.x * imageSize.width + 20 + 'px',
                        top: coordinates.y * imageSize.height - 120 + 'px',
                        backgroundSize: `${imageSize.width * zoomLevel}px ${
                            imageSize.height * zoomLevel
                        }px`,
                        backgroundPosition: `${
                            -1 * coordinates.x * imageSize.width * zoomLevel + 50
                        }px ${-1 * coordinates.y * imageSize.height * zoomLevel + 50}px`,
                        transform: displayZoomLeft ? 'translateX(calc(-100% - 40px))' : undefined,
                    }}
                >
                    <MiniCrosshair
                        sx={{
                            fill:
                                theme.palette.getCrosshairColors?.().fill ??
                                theme.palette.primary.main,
                            stroke:
                                theme.palette.getCrosshairColors?.().stroke ??
                                theme.palette.primary.main,
                        }}
                    />
                </Zoom>
                {!!getDebug() &&
                    productDetails.map((c) => (
                        <div
                            key={c.name + c.x1 + c.x2 + c.y1 + c.y2}
                            style={{
                                position: 'absolute',
                                top: c.y1 * 100 + '%',
                                left: c.x1 * 100 + '%',
                                height: (c.y2 - c.y1) * 100 + '%',
                                width: (c.x2 - c.x1) * 100 + '%',
                                border: '1px solid red',
                                pointerEvents: 'none',
                                fontSize: '.25em',
                            }}
                        >
                            {c.name}
                        </div>
                    ))}
            </ImageWrapper>
        </>
    )
}

export default Pinpoint
