import React from 'react'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import LanguageIcon from '@mui/icons-material/Language'
import { styled } from '@mui/material'

const Wrapper = styled('label')`
    position: relative;
    width: ${({ theme }) => theme.spacing(4)};
    height: ${({ theme }) => theme.spacing(4)};
    padding: ${({ theme }) => theme.spacing(0.5)};
    border-radius: 50%;
    transition: background 0.25s;

    svg {
        transition: fill 0.25s;
    }

    &:hover {
        background: ${({ theme }) => theme.palette.primary.main};

        svg {
            fill: ${({ theme }) => theme.palette.primary.contrastText};
        }
    }
`

const StyledSelect = styled(Select)`
    opacity: 0;
    padding: 0;
    width: 100%;
    height: 100%;

    div {
        padding: 0 !important;
    }
`

const LanguageSwitcher = () => {
    const { t, i18n } = useTranslation()

    const onLanguageSettingChange = async (e: SelectChangeEvent) => {
        i18n.changeLanguage(e.target.value)
    }

    return (
        <>
            <Wrapper>
                <LanguageIcon sx={{ position: 'absolute' }} color="primary" />
                <StyledSelect
                    labelId="language-swticher-label"
                    id="language-swticher-select"
                    value={i18n.language.slice(0, 2)}
                    label={t('common.language' as any)}
                    onChange={onLanguageSettingChange as any}
                >
                    <MenuItem value="de">Deutsch</MenuItem>
                    <MenuItem value="en">English</MenuItem>
                </StyledSelect>
            </Wrapper>
        </>
    )
}

export default LanguageSwitcher
