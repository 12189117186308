export default [
    {
        id: 1,
        title: 'Patches',
        description: 'Patching a hole or a rip in a textile by applying a patch',
        productCategory: 1,
        price: [15, 30],
    },
    {
        id: 2,
        title: 'Fleece/Softshell Zip Replacement',
        description: 'Replacing a zip in a fleece or softshell jacket',
        productCategory: 1,
        price: [30, 40],
    },
    {
        id: 3,
        title: 'Down/Insulated Zip Replacement',
        description: 'Replacing a zip in a down or insulated jacket',
        productCategory: 1,
        price: [30, 60],
    },
    {
        id: 4,
        title: 'Waterproof Jacket Zip Replacement',
        description: 'Replacing a zip in a waterproof jacket',
        productCategory: 1,
        price: [45, 55],
    },
    {
        id: 5,
        title: 'Pocket Zip Replacement',
        description: 'Replacing a pocket zip',
        productCategory: 1,
        price: [40],
    },
    {
        id: 6,
        title: 'Trouser Leg Zip Replacement',
        description: 'Replacing a trouser leg zip',
        productCategory: 1,
        price: [30],
    },
    {
        id: 7,
        title: 'Backpack Zip Replacement',
        description: 'Replacing a zip of a backpack',
        productCategory: 1,
        price: [40],
    },
    {
        id: 8,
        title: 'Zipper Slider Replacement',
        description: 'Replacing the slider of a zipper',
        productCategory: 1,
        price: [15],
    },
    {
        id: 9,
        title: 'Shoulder & Neck Seam Sealing',
        description: 'Sealing the seam at the shoulder and neck',
        productCategory: 1,
        price: [38],
    },
    {
        id: 10,
        title: 'Full Jacket Seam Sealing',
        description: 'Sealing the seam of the complete Jacket',
        productCategory: 1,
        price: [60],
    },
]
