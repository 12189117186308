import React from 'react'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import Root from '../../../../src/client/root.tsx'

import createAuthRequiredPage from "./auth/pages/createAuthRequiredPage"

import Home from '../../../../src/client/pages/routing/home'
import CompanyIntroductionPage from '../../../../src/client/pages/company/onboarding/introduction-page'
import LoginPage from '../../../../src/client/pages/auth/login-page'
import SignupPage from '../../../../src/client/pages/auth/signup-page'
import CompanyOnboardingPage from '../../../../src/client/pages/company/onboarding/onboarding-page'
import { EmailVerification as EmailVerificationPage } from '../../../../src/client/pages/auth/email-verification-page'
import { PasswordReset as PasswordResetPage } from '../../../../src/client/pages/auth/password-reset-page'
import { RequestPasswordReset as RequestPasswordResetPage } from '../../../../src/client/pages/auth/request-password-reset-page'
import InspectorAppPage from '../../../../src/client/pages/company/inspector/index'
import ProductDetail from '../../../../src/client/pages/company/inspector/product-dashboard/index'
import CompanyProductList from '../../../../src/client/pages/customer/company/products/page'
import CompanySupportService from '../../../../src/client/pages/customer/company/support/page'
import ReportAppPage from '../../../../src/client/pages/company/report/index'
import UserManagementAppPage from '../../../../src/client/pages/company/user-management/index'
import SupportAppPage from '../../../../src/client/pages/company/support/index'
import CompanySettingsPage from '../../../../src/client/pages/company/settings/index'
import UserInspectorPage from '../../../../src/client/pages/customer/inspector/inspector-page'
import SupportInspectorPage from '../../../../src/client/pages/customer/support/inspector-page'
import Playground from '../../../../src/client/pages/playground'
import Ufixit from '../../../../src/client/pages/poc/ufixit/index'
import Analysator from '../../../../src/client/pages/service-desk/analysator/index'
import POCMobilePhotoUpload from '../../../../src/client/pages/poc/support/mobile-photo-upload'
import Tatajuba from '../../../../src/client/pages/service-desk/tatajuba'
import ServiceDeskRepairPage from '../../../../src/client/pages/service-desk/repair/index'
import ServiceDeskWarrantyPage from '../../../../src/client/pages/service-desk/warranty/index'
import MobilePhotoUpload from '../../../../src/client/pages/service-desk/mobile-photo-upload'


import { routes } from 'wasp/client/router'

export const routeNameToRouteComponent = {
  HomeRoute: createAuthRequiredPage(Home),
  CompanyIntroductionRoute: createAuthRequiredPage(CompanyIntroductionPage),
  LoginRoute: LoginPage,
  SignupRoute: SignupPage,
  CompanyOnboardingRoute: CompanyOnboardingPage,
  EmailVerificationRoute: EmailVerificationPage,
  PasswordResetRoute: PasswordResetPage,
  RequestPasswordResetRoute: RequestPasswordResetPage,
  InspectorAppRoute: createAuthRequiredPage(InspectorAppPage),
  AdminProductDetailRoute: createAuthRequiredPage(ProductDetail),
  ProductDetailRoute: createAuthRequiredPage(ProductDetail),
  CompanyProductListRoute: CompanyProductList,
  CompanySupportServiceRoute: CompanySupportService,
  ReportAppRoute: createAuthRequiredPage(ReportAppPage),
  UserManagementAppRoute: createAuthRequiredPage(UserManagementAppPage),
  SupportAppRoute: createAuthRequiredPage(SupportAppPage),
  CompanySettingsRoute: createAuthRequiredPage(CompanySettingsPage),
  UserInspectorRoute: UserInspectorPage,
  SupportInspectorRoute: SupportInspectorPage,
  PlaygroundRoute: Playground,
  UfixitRoute: Ufixit,
  AnalysatorRoute: createAuthRequiredPage(Analysator),
  POCMobilePhotoUploadRoute: POCMobilePhotoUpload,
  TatajubaRoute: Tatajuba,
  ServiceDeskRepairRoute: ServiceDeskRepairPage,
  ServiceDeskWarrantyRoute: ServiceDeskWarrantyPage,
  MobilePhotoUploadRoute: MobilePhotoUpload,
} as const;

const router = (
  <Router basename="/">
    <Root>
    <Switch>
      {Object.entries(routes).map(([routeKey, route]) => (
        <Route
          exact
          key={routeKey}
          path={route.to}
          component={routeNameToRouteComponent[routeKey]}
        />
      ))}
    </Switch>
    </Root>
  </Router>
)

export default router
