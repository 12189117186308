import React from 'react'
import { styled, Button } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import T from '../../../../../components/typography/t'
// @ts-ignore
import PhotoIcon from '../../../../../assets/img/photo.svg?react'
import DropZone from './dropzone'

const PhotoUploadIcon = styled(PhotoIcon)`
    width: ${({ theme }) => theme.spacing(9)};
    height: ${({ theme }) => theme.spacing(9)};
`

const fileTypes = ['.png', '.apng', '.avif', '.jpg', '.jpeg', '.webp', '.svg']

const PhotoDragDrop: React.FC<{
    choosePhoto: (photo?: File) => void
}> = ({ choosePhoto }) => {
    const onDrop = React.useCallback((acceptedFiles: File[]) => {
        choosePhoto(acceptedFiles[0])
    }, [])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const photo = e.target.files?.[0]
        choosePhoto(photo)
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/*': fileTypes,
        },
        useFsAccessApi: false,
    })

    return (
        <DropZone {...getRootProps()} $dragActive={isDragActive}>
            <PhotoUploadIcon sx={{ mb: 3, fill: (theme) => theme.palette.primary.main }} />
            <T variant="h3" mb={1} i18nKey="serviceDesk.shared.photoStep.photoDragDrop.label" />
            {isDragActive ? (
                <T i18nKey="serviceDesk.shared.photoStep.photoDragDrop.dropLabel" />
            ) : (
                <T i18nKey="serviceDesk.shared.photoStep.photoDragDrop.description" />
            )}
            <Button component="span" sx={{ mt: 4 }}>
                <T i18nKey="serviceDesk.shared.photoStep.photoDragDrop.button"></T>
                <input {...getInputProps()} accept="image/*" onChange={onChange} />
            </Button>
        </DropZone>
    )
}

export default PhotoDragDrop
