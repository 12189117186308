import * as React from "react";
const SvgTop = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512", style: {
  shapeRendering: "geometricPrecision",
  textRendering: "geometricPrecision",
  imageRendering: "optimizeQuality",
  fillRule: "evenodd",
  clipRule: "evenodd"
}, xmlnsXlink: "http://www.w3.org/1999/xlink", ...props }, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.931
}, fill: "current", d: "M 147.5,31.5 C 150.187,31.3359 152.854,31.5026 155.5,32C 157.333,32.5 158.5,33.6667 159,35.5C 159.5,55.4972 159.667,75.4972 159.5,95.5C 174.242,94.3479 188.242,97.1813 201.5,104C 207.183,108.417 207.849,113.417 203.5,119C 201.801,119.958 199.967,120.458 198,120.5C 193.031,119.102 188.197,117.268 183.5,115C 163.895,107.103 149.061,112.269 139,130.5C 129.369,157.658 129.036,184.991 138,212.5C 170.335,274.881 173.002,338.548 146,403.5C 140.407,415.021 134.573,426.355 128.5,437.5C 129.098,438.795 129.598,440.128 130,441.5C 133,443.833 136,446.167 139,448.5C 141.633,455.757 139.133,460.09 131.5,461.5C 114.338,456.178 108.504,444.845 114,427.5C 142.588,381.561 152.588,331.894 144,278.5C 142.133,270.033 139.8,261.7 137,253.5C 131.057,239.948 125.391,226.281 120,212.5C 112.349,184.367 112.682,156.367 121,128.5C 125.039,117.135 132.039,108.135 142,101.5C 142.333,80.8333 142.667,60.1667 143,39.5C 143.246,35.9953 144.746,33.3286 147.5,31.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.931
}, fill: "current", d: "M 355.5,31.5 C 358.187,31.3359 360.854,31.5026 363.5,32C 365.333,32.5 366.5,33.6667 367,35.5C 368.124,57.4577 368.957,79.4577 369.5,101.5C 380.282,108.801 387.449,118.801 391,131.5C 397.89,157.423 398.223,183.423 392,209.5C 386.724,223.328 381.057,236.995 375,250.5C 366.457,274.422 362.457,299.089 363,324.5C 362.727,326.329 361.893,327.829 360.5,329C 355.271,331.621 350.771,330.788 347,326.5C 345.156,297.233 349.489,268.899 360,241.5C 364.667,230.833 369.333,220.167 374,209.5C 381.661,183.687 381.327,158.021 373,132.5C 369.559,124.059 363.726,117.893 355.5,114C 341.475,110.576 328.142,112.576 315.5,120C 293.578,134.422 275.912,152.922 262.5,175.5C 257.833,179.5 253.167,179.5 248.5,175.5C 241.124,162.757 232.291,151.09 222,140.5C 220.856,130.638 225.023,126.805 234.5,129C 242.091,136.75 249.091,144.917 255.5,153.5C 268.703,135.131 284.703,119.631 303.5,107C 318.369,98.4495 334.369,94.6161 351.5,95.5C 351.333,75.4972 351.5,55.4972 352,35.5C 352.69,33.6498 353.856,32.3164 355.5,31.5 Z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.934
}, fill: "current", d: "M 357.5,353.5 C 361.803,352.796 365.303,354.13 368,357.5C 374.691,383.249 384.691,407.583 398,430.5C 400.838,443.322 396.671,453.155 385.5,460C 314.106,481.826 241.772,485.493 168.5,471C 160.175,466.675 159.508,461.342 166.5,455C 168,454.833 169.5,454.667 171,454.5C 241.086,468.197 310.253,464.697 378.5,444C 380.662,442.355 381.995,440.188 382.5,437.5C 369.372,415.238 359.538,391.572 353,366.5C 351.379,360.923 352.879,356.589 357.5,353.5 Z" })));
export default SvgTop;
