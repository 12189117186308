import defaultRepairServices from '../repair-services'
import vaudeRepairGuidesEn from '../repair-guides/vaude-repair-guides-en'
import defaultProductCategoryTree from '../products'
import { alpha } from '@mui/material'
import { type Whitelabel } from './types'

const tradeFairWhitelabel: Whitelabel = {
    id: '1wxnj0',
    name: 'Berghaus',
    theme: {
        palette: {
            primary: {
                main: '#F7985D',
                contrastText: '#000000',
            },
            secondary: {
                main: '#E2D4C8',
                contrastText: '#282727',
            },
            getCrosshairColors: () => ({ stroke: '#F7985D', fill: alpha('#F7985D', 0.4) }),
            getIllustrationColors: () => ({ primary: '#F7985D', accent: alpha('#F7985D', 0.4) }),
            getProgressbarColor: () => '#F7985D',
            getTextColor: () => '#000000',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    outlined: {
                        color: '#000000',
                        borderColor: alpha('#F7985D', 0.8),
                    },
                },
            },
        },
    },
    logo: 'https://ucarecdn.com/b4acdc34-a4d7-4872-af20-112223b9e1d7/',

    repairGuides: vaudeRepairGuidesEn,
    repairServices: defaultRepairServices,
    productCategoryTree: defaultProductCategoryTree,
    hideFakeFeatures: false,
    config: {
        repair: {
            contactForm: {
                fields: [
                    [
                        {
                            key: 'Name',
                            label: 'serviceDesk.shared.contactStep.form.nameLabel',
                            options: { required: true },
                        },
                    ],
                    [
                        {
                            key: 'Email',
                            label: 'serviceDesk.shared.contactStep.form.emailLabel',
                            options: { required: true },
                            fieldProps: { type: 'email' },
                        },
                    ],
                    [
                        {
                            key: 'Comment',
                            label: 'serviceDesk.shared.contactStep.form.commentLabel',
                            options: { required: false },
                            fieldProps: {
                                multiline: true,
                                rows: 2,
                            },
                        },
                    ],
                ],
            },
            activateCostEstimation: true,
            includeDiyGuides: true,
            outro: {
                lead: 'serviceDesk.repair.outroStep.subtitle',
                links: [],
            },
            backendPlugins: [
                {
                    name: 'e-mail',
                    config: {
                        recipientMail: ['marc@brakeable.com', 'stefan@brakeable.com'],
                    },
                },
                {
                    name: 'slack',
                    config: {
                        hookId: 'T055GNGKS2J/B07K2AHC7JA/z1Lrq6DZue8spQyV29uQWwjE',
                    },
                },
            ],
        },
    },
}

export default tradeFairWhitelabel
