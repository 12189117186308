import React from 'react'
import {
    TextField,
    styled,
    FormControl,
    InputLabel,
    alpha,
    type OutlinedTextFieldProps,
    type SxProps,
} from '@mui/material'

const FieldControl = styled(FormControl)(
    ({ theme }) => `
    .MuiInputLabel-root {
        position: static;
        color: ${theme.palette.grey[900]};
        margin-bottom: ${theme.spacing(1)};
        transform: none;
    }
        
    input, textarea {
        font-size: 16px;
    }
    
    &.Mui-focused .MuiInputLabel-root {
        font-weight: 600;
    }

    .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.palette.primary.main};
        transition: box-shadow 0.1s ease-in-out;
    }

    input:hover ~ .MuiOutlinedInput-notchedOutline,
    textarea:hover ~ .MuiOutlinedInput-notchedOutline {
        border-color: ${theme.palette.primary.main};
        box-shadow: -1px 1px 0 1px ${alpha(theme.palette.primary.main, 0.15)};
    }
`
)

interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
    fieldControlSx?: SxProps
}

const InputField = React.forwardRef<HTMLInputElement, Props>(
    ({ label, fullWidth, id, fieldControlSx, required, ...rest }, ref) => (
        <FieldControl variant="standard" fullWidth={fullWidth} sx={fieldControlSx}>
            <InputLabel htmlFor={id}>
                {label}
                {required ? '*' : ''}
            </InputLabel>
            <TextField id={id} fullWidth={fullWidth} ref={ref} {...rest}></TextField>
        </FieldControl>
    )
)

export default InputField
